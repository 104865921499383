import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useTargetDigitalOptometry() {
  const targetDigitalOptometryCookie = getCookieByName(
    "targetDigitalOptometry",
    false
  );
  const targetDigitalOptometry = useSelector(
    (state: any) => state.config?.layoutSettings?.targetDigitalOptometry
  );

  if (
    targetDigitalOptometryCookie !== null &&
    targetDigitalOptometryCookie !== undefined
  ) {
    return targetDigitalOptometryCookie as string;
  }
  if (targetDigitalOptometry !== null && targetDigitalOptometry !== undefined) {
    return targetDigitalOptometry as string[];
  }
  return [];
}
