import React, { useEffect } from "react";
import "./default.module.scss";

interface LoaderProps {
  style?: Object;
  greyLoader?: boolean;
}

export function Loader({ style, greyLoader }: LoaderProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      enable-background="new 0 0 42 42"
      className="Loader__container"
    >
      {" "}
      <image
        id="image0"
        width="42"
        height="42"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAwCAMAAABQUBiUAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACBFBMVEUAAACXx+Tz9/hjrdhC
    nNFPotQylM3D3u59ut4AX7Yxk80AdL82ls5dqtZxtNv///8FfcN2ttzX6PJ0tdxLoNMciMhAm9Bk
    rdhnrtkmjcpJn9Jdqdbg7PReqtdWptVFndJvstuFvuA0lM4/mtBEndHO4/AAJZ0AdsAACY8Sg8YA
    N6X///wPgsUhi8kAQ6pGntIAcL40lM1VptVytNweiclnr9kAWLM4l8/q8fZSpNQJfsRDnNFFndEA
    MaIAcL3R5fEAbLyQxOJqsNmdyuUAc79EnNFustoAAIpbqNbW5/J3ttwpj8sii8o2lc5XptWWxuOo
    0OdGntJpsNo5l84ah8gQgsYLgMQaiMj09/gmjcoMgMUEfMMGfcMHfsQYhsfz9vgrkMwKf8QFfMMT
    hMYqj8tGntKizObc6vMSg8YZh8g2ls4IfsQMgMVNodMljcoFfcMUhMYjjMoQgsYskMwljcoFfcMU
    hMYbiMgkjMo2ls4IfsQNgcVWptX///8ThMYFfcMojssRg8YEfMMciMgojssHfcRKoNIskMwJf8QG
    fcMXhsc/mtBtstogiskJf8QGfcMgiskjjMoLf8QEfMMJf8QMgMUMgMULgMQFfMMGfcMah8ibyeUx
    k80WhccLgMQHfsQGfcMJf8QRgsYkjMr//PtBm9Aqj8sjjMo2ls6Iv+AGfcP///+Ifx//AAAAqnRS
    TlMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMMBBVlq8p6AjXC/ebHcgIm0fSCJQsCApVwH+DFEDn2
    kDSPITj1jnVPHt7BDQKg8zeW+mI76w8p1uplDwZCz/RTPtD7x5GJs/LubAMhhM/s8OCnQQESKS4d
    BBKTlq8AAAABYktHRA8YugDZAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gcaDQ81WL5y
    awAAAQpJREFUSMdjYBgFo2CEAEYmZhZWNnbCCjk4ubgDAoN4eAmq5OMXCA4JDQuPECSkUoiBOzIq
    OiY2Ll6YkFIR0YTEpOSU1DQxcUJKJdIzojMZJKWkWWRk5eQVFPEoVcrKzslVVgGyVNXUNTS58SgV
    zcsv0OIHsbR1CouKdfEpLSkt0wMr1TcoX1WhiUepYWVVdY2+EQODonFtXX2DCR6lpvGNTc1m5hb8
    llYtrVFt1niU2oi2d3R2ddua9fT29U+w48ej1J7fYeKkyVOmTps+Y+as2ZaO+ALWydllztx58xcs
    XLR4iasN/khwY5B0X7ps+YqVOh5SDASAp6yXsrePr5+3PyGVo2AU0B0AAK02PI71KAmIAAAAJXRF
    WHRkYXRlOmNyZWF0ZQAyMDIyLTA3LTI2VDEzOjE1OjUzKzAwOjAwkTVQ3wAAACV0RVh0ZGF0ZTpt
    b2RpZnkAMjAyMi0wNy0yNlQxMzoxNTo1MyswMDowMOBo6GMAAAAASUVORK5CYII="
      />
    </svg>
  );
}
