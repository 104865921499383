import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useConfigContent } from "./useConfigContent";
import { useCurrentPackages } from "./useCurrentPackages";

export function useTransitionImage() {
  const imageFrameLayer = useSelector(
    (state: any) => state.config?.imageFrameLayer
  );
  const imageLens = useSelector((state: any) => state.config?.imageLens);
  const logoMap = useSelector((state: any) => state.config?.logoMap);
  const imageLogo = useSelector((state: any) => state.config?.imageLogo);
  const imageLensNoLogoLayer = useSelector(
    (state: any) => state.config?.imageLensNoLogoLayer
  );
  const imageLensLogoLayer = useSelector(
    (state: any) => state.config?.imageLensLogoLayer
  );
  const imageLogoLayer = useSelector(
    (state: any) => state.config?.imageLogoLayer
  );
  const impolicy = useSelector(
    (state: any) => state.config?.imageTransitionLogo
  );
  const imageLensPreview = useSelector(
    (state: any) => state.config?.imageLensPreview
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  const baseUrlCdn = useSelector(
    (state: any) => state.config?.baseURLs?.assetsCDN
  );

  const selectedTransitionColor = useSelector(
    (state: any) => state.color?.transitionSelected
  );
  const currentPackages = useCurrentPackages();

  //const frontImageUrl = useSelector((state: any) => state.config?.data?.frame?.frontImageUrl);
  const imageUrl = useSelector(
    (state: any) => state.config?.data?.frame?.imageUrl
  );
  const modelFrame = useSelector(
    (state: any) => state.config?.data?.frame?.model
  );
  const colorFrame = useSelector(
    (state: any) => state.config?.data?.frame?.color
  );
  const category = useSelector(
    (state: any) => state.config?.data?.frame?.category
  );
  const brandFrame = useSelector(
    (state: any) => state.config?.data?.frame?.brand
  );
  const [cancelToken, setCancelToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [urlImage, setUrlImage] = useState({
    frame: imageUrl,
    lens: null,
    logo: null,
    error: null,
  });
  const configColor = useConfigContent("Color");

  const getBrandByType = (lensBrand?: string) => {
    return logoMap[lensBrand ? lensBrand : brandFrame];
  };

  useEffect(() => {
    if (
      colorFrame &&
      selectedTransitionColor &&
      currentPackages &&
      configColor.length > 0
    ) {
      let url = imageFrameLayer
        .replace("##MODEL##", modelFrame)
        .replace("##COLOR##", colorFrame)
        .replace("##URL##", baseUrlCdn);

      if (cancelToken) {
        cancelToken.cancel();
        setUrlImage({
          frame: imageUrl,
          lens: null,
          logo: null,
          error: null,
        });
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      setCancelToken(source);

      let lensBrand = currentPackages.length
        ? currentPackages[0].lensPackage.brand
        : null;
      const findedPackagesColor = currentPackages.map(
        (currentPackage: any) => currentPackage.lensPackage.color
      );
      const packagesColors = configColor.filter((color: any) =>
        findedPackagesColor.find((cl: any) => cl === color.id)
      );
      const color = packagesColors.find((color) =>
        [
          color.imageFileName,
          color.imageFileName1,
          color.imageFileName2,
        ].includes(selectedTransitionColor)
      );
      // const color = configColor.find((color) =>
      //   [
      //     color.imageFileName,
      //     color.imageFileName1,
      //     color.imageFileName2,
      //   ].includes(selectedTransitionColor)
      // );
      const colorPackage = currentPackages
        .filter(
          ({ lensPackage }) =>
            lensPackage.color === color?.id ||
            lensPackage.productUPC === color?.id
        )
        ?.find(({ lensPackage }) => lensPackage.clen);

      if (!colorPackage || (colorPackage && !colorPackage?.lensPackage?.clen)) {
        console.log(`Clen not found for color "${color?.id}"`);
        setUrlImage({
          frame: null,
          lens: null,
          logo: null,
          error: true,
        });
        return;
      }

      if (brandFrame && brandFrame == lensBrand) {
        const imageLogoMapped = getBrandByType();

        let urlLensesImage = imageLens
          .replace("##MODEL##", modelFrame)
          .replace("##CLEN##", colorPackage.lensPackage.clen)
          .replace("##URL##", baseUrlCdn);

        const lensLogoMapped = getBrandByType(lensBrand);

        setIsLoading(true);

        axios
          .get(urlLensesImage, {
            cancelToken: source.token,
          })
          .then(() => {
            axios
              .get(url, {
                cancelToken: source.token,
              })
              .then(() => {
                let compositionLogo = imageLogoLayer
                  .replace("##MODEL##", modelFrame)
                  .replace("##LOGO##", lensLogoMapped)
                  .replace("##URL##", baseUrlCdn)
                  .replace("&Logo=", "");
                return axios
                  .get(compositionLogo, {
                    cancelToken: source.token,
                  })
                  .then(() => {
                    setUrlImage({
                      frame: url,
                      lens: urlLensesImage,
                      logo: compositionLogo,
                      error: null,
                    });
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setUrlImage({
                      frame: url,
                      lens: urlLensesImage,
                      logo: null,
                      error: true,
                    });
                    setIsLoading(false);
                  });
              })
              .catch(() => {
                setUrlImage({
                  frame: null,
                  lens: null,
                  logo: null,
                  error: true,
                });
                setIsLoading(false);
              });
          })
          .catch(() => {
            setUrlImage({
              frame: null,
              lens: null,
              logo: null,
              error: true,
            });
            setIsLoading(false);
          });
      } else {
        let urlLensesImage = imageLens
          .replace("##MODEL##", modelFrame)
          .replace("##CLEN##", colorPackage.lensPackage.clen)
          .replace("##URL##", baseUrlCdn);

        setIsLoading(true);

        axios
          .get(urlLensesImage, {
            cancelToken: source.token,
          })
          .then(() => {
            axios
              .get(url, {
                cancelToken: source.token,
              })
              .then(() => {
                setUrlImage({
                  frame: url,
                  lens: urlLensesImage,
                  logo: null,
                  error: null,
                });
                setIsLoading(false);
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  setUrlImage({
                    frame: null,
                    lens: null,
                    logo: null,
                    error: true,
                  });
                }
                setIsLoading(false);
                console.error("error:" + error);
              });
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setUrlImage({
                frame: null,
                lens: null,
                logo: null,
                error: true,
              });
              setIsLoading(false);
            }
          });
      }
    } else {
      if (currentPackages && selectedTransitionColor && !colorFrame) {
        //general fallback to show the swatch with slider
        setUrlImage({
          frame: null,
          lens: null,
          logo: null,
          error: true,
        });
      }
    }
  }, [selectedTransitionColor, colorFrame, configColor]);

  return { urlImage, isLoading };
}
