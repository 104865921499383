import {
  RIAAPICall,
  RIAAPICallType,
  RIAAPICallStatus,
} from "./types/RIAAPICall";
import { RIACacheEntry } from "./types/RIACacheEntry";
import { isEqual } from "lodash";

enum InternalStateActionType {
  CREATE,
  UPDATE,
  CLEAR,
  ERROR,
}

interface InternalStateAction {
  type: InternalStateActionType;
  payload: {
    lensCatEntryID?: string;
    status?: RIAAPICallStatus;
    totalRequestsCount?: number;
  };
  originalClassInstance: any;
  errorMessage?: string;
}

interface InternalState {
  totalRequestsCount: number;
  requestsData: {
    lensCatEntryID: string;
    status: RIAAPICallStatus;
  }[];
  completedRequestsIDs: Set<string>;
}

export abstract class RIAEngine {
  private _internalState: InternalState;

  private insuranceFunction: Function;
  private selectedFrame: any;

  constructor(insuranceFunction: Function, selectedFrame: any) {
    this.insuranceFunction = insuranceFunction;
    this.selectedFrame = selectedFrame;
  }

  /**
   * Allows to change the insurance function that will be used to get the insurance prices
   *
   * @param func the new function to be used.
   */
  setInsuranceFunction(func: Function) {
    this.insuranceFunction = func;
  }

  /**
   * This function is being used, even if it doesn't look like it. I'ts being called
   * using originalClassInstance.
   *
   * @param state
   */
  private checkAndNotifyIfCallBlockIsDone(state: InternalState) {
    let allRequestsStarted =
      state.requestsData.length === state.totalRequestsCount;
    let allRequestsOK = state.requestsData.every(
      (request) => request.status === RIAAPICallStatus.OK
    );

    if (allRequestsStarted && allRequestsOK) {
      let catEntryIDs = state.requestsData.map(
        (request) => request.lensCatEntryID
      );
      this.onCallBlockCompleted(catEntryIDs);
    }
  }

  private internalStateUpdate(action: InternalStateAction) {
    if (action.type === InternalStateActionType.CLEAR) {
      this._internalState = null;
      return;
    }

    if (!this._internalState || !this._internalState.requestsData) {
      if (action.type === InternalStateActionType.CREATE) {
        let newState: InternalState = {
          totalRequestsCount: action.payload.totalRequestsCount,
          requestsData: [
            {
              lensCatEntryID: action.payload.lensCatEntryID,
              status: action.payload.status,
            },
          ],
          completedRequestsIDs: new Set<string>(),
        };
        this._internalState = newState;
        return;
      }
    } else {
      if (action.type === InternalStateActionType.CREATE) {
        let newState: InternalState = {
          totalRequestsCount: action.payload.totalRequestsCount,
          requestsData: [
            ...this._internalState.requestsData,
            {
              lensCatEntryID: action.payload.lensCatEntryID,
              status: action.payload.status,
            },
          ],
          completedRequestsIDs: this._internalState.completedRequestsIDs,
        };
        this._internalState = newState;
        return;
      } else {
        let newState: InternalState = {
          totalRequestsCount: action.payload.totalRequestsCount,
          requestsData: this._internalState.requestsData.map((request) => {
            if (request.lensCatEntryID === action.payload.lensCatEntryID) {
              return {
                lensCatEntryID: request.lensCatEntryID,
                status: action.payload.status,
              };
            } else {
              return request;
            }
          }),
          completedRequestsIDs: this._internalState.completedRequestsIDs.add(
            action.payload.lensCatEntryID
          ),
        };
        if (action.type === InternalStateActionType.ERROR) {
          let alreadyNotified = this._internalState.requestsData.find(
            (request) => request.status === RIAAPICallStatus.KO
          );
          if (!alreadyNotified) {
            action.originalClassInstance.onCallBlockError(action.errorMessage);
            //fully clear the store
            this._internalState = null;
            return;
          }
        } else {
          action.originalClassInstance.checkAndNotifyIfCallBlockIsDone(
            newState
          );
        }
        this._internalState = newState;
        return;
      }
    }
  }

  private clearStore() {
    let clearAction: InternalStateAction = {
      type: InternalStateActionType.CLEAR,
      payload: null,
      originalClassInstance: this,
    };
    this.internalStateUpdate(clearAction);
  }

  private checkIfDuplicatedCall(data: RIAAPICall[]) {
    if (!this._internalState || !this._internalState.requestsData.length) {
      return false;
    }
    const currentIDs = new Set<String>();
    const callIDs = new Set<String>();
    data.forEach((call) =>
      call.packages.forEach((pkg) => {
        callIDs.add(pkg.lensPackage.catEntryId);
      })
    );
    this._internalState.requestsData.forEach((call) =>
      currentIDs.add(call.lensCatEntryID)
    );
    return isEqual(currentIDs, callIDs);
  }

  callAPI(data: RIAAPICall[]) {
    if (this.checkIfDuplicatedCall(data)) {
      return;
    }

    this.clearStore();

    //create all the app cache entries that contain the running status of all the calls.
    //they will be written only if a correct insurance function is provided
    let toCache: RIACacheEntry[] = [];
    let totalRequestsCount = 0;
    data.forEach((call) => {
      call.packages.forEach((pkg) => {
        toCache.push({
          catEntryID: pkg.lensPackage.catEntryId,
          status: RIAAPICallStatus.RUNNING,
          value: null,
        });
      });
      totalRequestsCount += call.packages.length;
    });
    if (this.insuranceFunction) {
      this.writeToCache(toCache);
      data.forEach((call) => {
        //update the internal state with the packets for this call in the block
        call.packages.forEach((pkg) => {
          let createObj: InternalStateAction = {
            type: InternalStateActionType.CREATE,
            payload: {
              lensCatEntryID: pkg.lensPackage.catEntryId,
              status: RIAAPICallStatus.RUNNING,
              totalRequestsCount: totalRequestsCount,
            },
            originalClassInstance: this,
          };
          this.internalStateUpdate(createObj);
        });

        //call the function to get the insurance discounts
        console.log(
          "DEBUG_RIAENGINE_TEST: " +
            JSON.stringify(call.packages.map((p) => p.lensPackage.catEntryId))
        );
        this.insuranceFunction(this.selectedFrame, call.packages)
          .then((res) => {
            //update the app cache
            let updateCacheObj: RIACacheEntry[] = res.map((pkg) => {
              let ret: RIACacheEntry = {
                catEntryID: pkg.lensPackage.catEntryId,
                status: RIAAPICallStatus.OK,
                value: pkg,
              };
              return ret;
            });
            this.writeToCache(updateCacheObj);

            /* 
                    update the internal state (always call this after the update of the app cache
                    because it also does the check to see if all calls are completed and can result
                    in a call to the onCallBlockCompleted function before the app cache gets the
                    results of the last call) 
                    */
            res.forEach((pkg) => {
              let updateObj: InternalStateAction = {
                type: InternalStateActionType.UPDATE,
                payload: {
                  lensCatEntryID: pkg.lensPackage.catEntryId,
                  status: RIAAPICallStatus.OK,
                  totalRequestsCount: totalRequestsCount,
                },
                originalClassInstance: this,
              };
              this.internalStateUpdate(updateObj);
            });
          })
          .catch((error) => {
            //update the internal state with the packets for this call in the block
            call.packages.forEach((pkg) => {
              let updateObj: InternalStateAction = {
                type: InternalStateActionType.ERROR,
                payload: {
                  lensCatEntryID: pkg.lensPackage.catEntryId,
                  status: RIAAPICallStatus.KO,
                  totalRequestsCount: totalRequestsCount,
                },
                errorMessage: error,
                originalClassInstance: this,
              };
              this.internalStateUpdate(updateObj);
            });
          });
      });
    } else {
      console.error("No RIA call function was provided to the RIAEngine class");
    }
  }

  /**
   * This function signals to the app that there is insurance data to be stored/updated.
   *
   * @param entries an array of {@link RIACacheEntry} objects
   */
  abstract writeToCache(entries: RIACacheEntry[]): any;

  /**
   * This is a callback function that notifies the app that all calls in the block have resolved
   * correctly. A new call block can now be started.
   *
   * @param catEntryIDs the list of IDs of the packages that were part of the call block
   */
  abstract onCallBlockCompleted(catEntryIDs: string[]);

  /**
   * This is a callback function that notifies the app that at least one of the calls in the
   * call block has failed. It will only be called once per call block. This does not mean
   * that the app won't receive updates on the remaining calls via the {@link writeToCache()}
   * method: it's the app's decision if these updates are to be ignored or not.
   *
   * @param error the error that the call to the insurance API returned
   */
  abstract onCallBlockError(error: any);
}
