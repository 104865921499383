import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useTilesFeatureListLayout() {
  const featureListLayoutCookie = getCookieByName(
    "tilesFeaturesListLayout",
    true
  );

  const featureListLayoutConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.tilesFeaturesListLayout
  );

  if (
    featureListLayoutCookie !== null &&
    featureListLayoutCookie !== undefined
  ) {
    return featureListLayoutCookie as string;
  }
  if (
    featureListLayoutConfig !== null &&
    featureListLayoutConfig !== undefined
  ) {
    return featureListLayoutConfig as string;
  }
  return "BULLET_POINTS";
}
