import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useEnableVisionCareInsurance() {
  const eligibleForInsuranceCookie = getCookieByName(
    "eligibleForInsurance",
    true
  );
  const eligibleForInsurance = useSelector(
    (state: any) => state.config.layoutSettings?.eligibleForInsurance
  );

  if (eligibleForInsuranceCookie !== null) {
    return eligibleForInsuranceCookie as boolean;
  }

  if (eligibleForInsurance !== null && eligibleForInsurance !== undefined) {
    return eligibleForInsurance as boolean;
  }

  return true;
}
