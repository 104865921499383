import { isDate } from "lodash";
import dayjs from "dayjs";

export function convertISODate(arg: {
  isoDate: string;
  format?: { y?: boolean; m?: boolean; d?: boolean };
  divisor?: "." | "/" | null;
}) {
  const format = arg.format || {
    y: true,
    m: true,
    d: true,
  };
  const divisor = arg.divisor || ".";

  let [y, m, d] = isDate(arg.isoDate)
    ? arg.isoDate.toISOString().split("-")
    : arg.isoDate?.split("-");
  d = d.split("T")[0];

  return `${format?.d ? d : ""}${divisor || "-"}${format?.m ? m : ""}${
    divisor || "-"
  }${format?.y ? y.slice(2, 4) : ""}`;
}

export function formatDate(date: Date, format: any) {
  return dayjs(date).format(format);
}

export function isMoreThanYear(now: Date, date: Date): boolean {
  return dayjs(date).isBefore(dayjs(now).subtract(1, "y"));
}
