import React from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import GenericButton from "../../common/GenericButton";

interface IncompatibleErrorProps {
  shopCompatible: boolean;
  noCompatiblePackage: boolean;
  link: string;
  resetCallback: () => void;
}

export function IncompatibleError(props: IncompatibleErrorProps) {
  const incompatibleWithPrescriptionTitle = useTranslate(
    "steps.advancedPrescription.manual.alerts.incompatibleWithPrescriptionTitle"
  );
  const incompatibleWithPackagesTitle = useTranslate(
    "steps.advancedPrescription.manual.alerts.incompatibleWithPackagesTitle"
  );
  const incompatibleWithPrescriptionBody = useTranslate(
    "steps.advancedPrescription.manual.alerts.incompatibleWithPrescriptionBody"
  );
  const shopCompatible = useTranslate(
    "steps.advancedPrescription.manual.alerts.shopCompatible"
  );
  const clearAll = useTranslate(
    "steps.advancedPrescription.manual.alerts.clearAll"
  );

  return (
    <div className="IncompatibleError__container">
      <div className="IncompatibleError__container__content">
        <div
          className={
            "IncompatibleError__container" +
            (props.shopCompatible ? "__firstline" : "__firstlineClear")
          }
        >
          {props.noCompatiblePackage
            ? incompatibleWithPackagesTitle
            : incompatibleWithPrescriptionTitle}
        </div>
        {props.shopCompatible && (
          <div className="IncompatibleError__container__alternative">
            {incompatibleWithPrescriptionBody}
          </div>
        )}
      </div>

      <GenericButton
        className={
          props.shopCompatible
            ? "IncompatibleError__container__shopCompatibleBtn"
            : "IncompatibleError__container__clearAll"
        }
        type="button"
        dataElementId="X_X_LensPanel_Type-IncompatibleErrorButton"
        title={props.shopCompatible ? shopCompatible : clearAll}
        tabIndex={0}
        noArrow={true}
        handleClick={
          props.shopCompatible
            ? () => {
                window.location.replace(props.link);
              }
            : props.resetCallback
        }
      >
        {props.shopCompatible ? shopCompatible : clearAll}
      </GenericButton>
    </div>
  );
}

export default IncompatibleError;
