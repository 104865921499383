import React from 'react'
import "./default.module.scss"
import { useTranslation } from '../../configurator/translations/TranslationHooks'

export function TogglePrescription(props) {
  const translate = useTranslation()
  const nonPrescription = translate("steps.mixAndMatch.nonPrescription")
  const prescription = translate("steps.mixAndMatch.prescription")
  const handleChange = e => {
    props.toggle(e.target.checked)
  }
  return (
    <div className='TogglePrescription__container'>
      <input type="checkbox" role="switch" id="prescription" onChange={handleChange} checked={props.checked} />
      <label htmlFor="prescription">
        <span data-translation="steps.mixAndMatch.nonPrescription" className='non-prescription'>{nonPrescription}</span>
        <span data-translation="steps.mixAndMatch.prescription" className='prescription'>{prescription}</span>
      </label>
    </div>
  )
}
