import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";

export type SelectFromMyAccountProps = {
  isLoggedIn: () => boolean;
  prescriptions: any[];
  login: (c) => Promise<void>;
  onSelect: (p) => void;
  onEdit: (p, s) => void;
  onLoggedIn: () => void;
  onEditPrescription: (p) => void;
  onNewPrescription: () => void;
  onWithoutPrescriptions: () => void;
};

export function SelectFromMyAccount(props: SelectFromMyAccountProps) {
  const [showPrescription, setShowPrescription] = useState(null);

  const subtitleLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.subtitle"
  );
  const subtitleAddPrescriptionLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.subtitleAdd"
  );
  const addPrescriptionButtonLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.addPrescriptionButton"
  );
  useEffect(() => {
    !props.isLoggedIn() && props.login(props.onLoggedIn);
  }, []);

  useEffect(() => {
    if (
      props.isLoggedIn() &&
      (props.prescriptions.every((p) => p.expired) ||
        props.prescriptions.length === 0)
    ) {
      props.onWithoutPrescriptions();
    }
  }, [props.prescriptions]);

  return (
    <div className="SelectFromMyAccount">
      {props.isLoggedIn() && (
        <>
          {props.prescriptions.every((p) => p.expired) ? (
            <button onClick={props.onNewPrescription}>
              {addPrescriptionButtonLabel}
            </button>
          ) : (
            <>
              <p
                className="SelectFromMyAccount__subtitle"
                dangerouslySetInnerHTML={{ __html: subtitleLabel }}
              ></p>{" "}
              <span
                onClick={props.onNewPrescription}
                className="SelectFromMyAccount__add"
              >
                {subtitleAddPrescriptionLabel}
              </span>
            </>
          )}
          {props.prescriptions.map((prescription, i) => (
            <BrandComponent
              componentName="PrescriptionFromMyAccountCard"
              parameter={{
                prescription,
                onSelect: () => props.onSelect(prescription),
                onShowPrescription: () => setShowPrescription(prescription),
              }}
              key={i}
            />
          ))}
          {showPrescription &&
            createPortal(
              <BrandComponent
                componentName="MyAccountPrescriptionDetailModal"
                parameter={{
                  prescription: showPrescription,
                  onEdit: () => props.onEdit(showPrescription, true),
                  onClose: () => setShowPrescription(null),
                }}
              />,
              //@ts-ignore
              document.getElementById("RxcModals")
            )}
        </>
      )}
    </div>
  );
}
