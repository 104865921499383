import React, { ReactNode, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import "./default.module.scss";
import { workflow } from "../../configurator/models/Workflow";

interface ScrollViewProps {
  children: ReactNode;
  idView?: string;
  autoHeight?: boolean;
  scrollTopValue?: number;
}

export function ScrollView({
  autoHeight,
  idView,
  scrollTopValue,
  children,
}: ScrollViewProps) {
  const references = useSelector((state: any) => state.references);

  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );

  const goBottom = () => {
    let scrollDiv = document.querySelector(".ScrollView__container");
    scrollDiv.scrollTop = scrollDiv.scrollHeight;
  };
  const [referenceName, setReferenceName] = useState("");

  useEffect(() => {
    setReferenceName("ScrollView__" + workflow?.currentStep?.key);
  }, [workflow.currentStep, references]);

  return (
    <Scrollbars
      className={"ScrollView__scrollbar"}
      autoHeight={autoHeight}
      autoHide
      renderView={(props) => {
        return (
          <div
            {...props}
            id={idView}
            className={
              "ScrollView__container" +
              (workflow.isLastStep() ? " review" : "") +
              (insuranceLoading ? " insuranceLoadingOpacity" : "")
            }
            style={{}}
          />
        );
      }}
      renderTrackVertical={(props) => (
        <div {...props} className={"ScrollView__trackVertical"} />
      )}
      renderThumbVertical={({ style, props }) => {
        const customStyle = {
          top: (scrollTopValue || 0) * 100,
        };
        return (
          <div
            {...props}
            className={"ScrollView__thumbVertical"}
            style={{ ...style, ...customStyle }}
          />
        );
      }}
    >
      <div className={"ScrollView__innerContainer"}>{children}</div>

      {!workflow.isLastStep() && references[referenceName] == false && (
        <div onClick={goBottom} className={"ScrollView__toBottom"}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F0EAE2" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29289 12.2929C7.90237 12.6834 7.90237 13.3166 8.29289 13.7071L15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071L23.7071 13.7071C24.0976 13.3166 24.0976 12.6834 23.7071 12.2929C23.3166 11.9024 22.6834 11.9024 22.2929 12.2929L16 18.5858L9.70711 12.2929C9.31658 11.9024 8.68342 11.9024 8.29289 12.2929Z"
              fill="#222222"
            />
          </svg>
        </div>
      )}

      {!workflow.isLastStep() && references[referenceName] == false && (
        <div className={"ScrollView__fade"} />
      )}
    </Scrollbars>
  );
}
