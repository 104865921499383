import React, { useState, useEffect } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { useDateComparison } from "@hooks";

export const LastUpdate = (props) => {
  const uploadedOnLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.uploadedOn"
  );
  let justUpdatedLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.justUpdated"
  );
  // format date for IOS safari from '2023-05-08 09:00:59.173' to '2023-05-08T09:00:59.173Z'
  let formattedDate = "";
  if (props?.lastUpdate) {
    formattedDate = props?.lastUpdate?.includes("T")
      ? props.lastUpdate
      : props.lastUpdate.replace(" ", "T");
    formattedDate = formattedDate.includes("Z")
      ? formattedDate
      : formattedDate + "Z";
  }
  let difference: any = useDateComparison(formattedDate);

  return (
    <span className="PrescriptionFromMyAccountCard__lastUpdate">
      {difference && uploadedOnLabel}
      {difference ? difference : justUpdatedLabel}
    </span>
  );
};
