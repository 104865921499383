import React from 'react';
import "./default.module.scss";


export function Chips({ children }) {
  return (
    <div className="LensesSection__chips">
      {children}
    </div>
  )
}