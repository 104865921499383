import React, { useEffect, useRef, useState } from "react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";

interface FullPageProps {
  children: ReactNode;
}

export function FullPage({ children }: FullPageProps) {
  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );
  const [hideContent, setHideContent] = useState(loadPreselectEnabled);
  const windowRef = useRef(null);

  useEffect(() => {
    if (windowRef.current) {
      windowRef.current.focus();
    }
  }, []);
  useEffect(() => {
    setHideContent(loadPreselectEnabled);
  }, [loadPreselectEnabled]);

  return (
    <div
      className={"FullPage_root"}
      tabIndex={0}
      ref={windowRef} /* style={hideContent?{opacity:0}:null} */
    >
      {children}
    </div>
  );
}
