import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StateTranslation = {
  labels: any;
};

const initialState: StateTranslation = {
  labels: null,
};

const tanslationSlice = createSlice({
  name: "translation",
  initialState: initialState,
  reducers: {
    setTranslations: (state, { payload }: PayloadAction<any>) => {
      state.labels = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const translationReducer = tanslationSlice.reducer;
export const translationActions = tanslationSlice.actions;
