import { isFunction } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import { BrandComponent } from "../common/BrandComponent";
import { TranslateText } from "../common/TranslateText";
import "./default.module.scss";
import { useCurrentPackages, useGetDiscount } from "@hooks";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { useCurrentBrand } from "../../configurator/hooks";

interface StepInReviewProps {
  title: string;
  selection: string[];
  strikePrice?: number;
  offerPrice?: number;
  insPrice?: number;
  included?: boolean;
  onEditClick: (title: string) => void;
  showSeeDetails?: boolean;
  hideMainPrice?: boolean;
  hideEdit?: boolean;
  id?: string;
}

/**
 * Component that displays summary information about a step in the review page. It
 * accepts an input of type ```StepInReviewProps``` with the following parameters:
 *  - ```title```: the title of the step
 *  - ```selection```: an array of strings containing the selected values for that
 * step
 *  - ```onEditClick```: the callback function executed when clicking on edit step
 *  - ```strikePrice``` (_optional_): the full price of that step
 *  - ```offerPrice``` (_optional_): the discounted price of that step
 *  - ```included``` (_optional_): a flag that will display the included badge
 */
export function StepInReview({
  title,
  selection,
  strikePrice,
  offerPrice,
  insPrice,
  included,
  onEditClick,
  showSeeDetails,
  hideMainPrice,
  hideEdit,
  id,
}: StepInReviewProps) {
  const translation = useTranslation();
  const reduxDispatch = useDispatch();
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const currentPackages = useCurrentPackages();
  const { someIsLoading } = useGetDiscount();
  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const showSeeDetailsModal = () => {
    if (title === "advancedPrescription") {
      reduxDispatch(
        modalsActions.setShowPrescriptionReviewSeeDetails(true)
      )
    } else if (title === "lensBundle") {
      reduxDispatch(
        modalsActions.setShowBundleReviewSeeDetails({ show: true, id: selection[0] })
      )
    }
  }
  const checkLongTitle = (title: string) => {
    let longTitleParam = translation(`steps.${title}.longTitle`);
    if (longTitleParam !== `steps.${title}.longTitle`) {
      return `steps.${title}.longTitle`;
    } else {
      return `steps.${title}.confirmedTitle`;
    }
  };

  const brand = useCurrentBrand();

  return (
    <div className={"StepInReview__mainContainer"}>
      <div className={"StepInReview__mainContainer__contentContainer"}>
        <div className="StepInReview__mainContainer__contentContainer__titleWrapper">
          <div className="StepInReview__mainContainer__contentContainer__title_container">
            <span
              className={
                "StepInReview__mainContainer__contentContainer__title_container__title"
              }
            >
              <TranslateText label={checkLongTitle(title)} />
            </span>
          </div>
        </div>
        {selection.map((sel, index) => {
          if (sel == null) {
            return (
              <span
                key={"StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection" +
                  (showSeeDetails ? " seeDetails" : "")
                }
              >
                <TranslateText label={"noTreatment"} />{" "}
              </span>
            );
          }
          if (typeof sel === "object") {
            return (
              <span
                key={sel + "_StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection"
                }
              >
                {Object.keys(sel).map((x, index, array) => {
                  return (
                    <div className="StepInReview__mainContainer__contentContainer__selectionContainer">
                      <TranslateText label={index === array.length - 1 ? sel[x].replace(",", "") : sel[x]}
                        checkCommaReplacement={brand === "targetoptical" ? true : false} />
                    </div>
                  );
                })}
              </span>
            );
          } else if (typeof sel === "string") {
            return (
              <span
                key={sel + "_StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection" +
                  (showSeeDetails ? " seeDetails" : "")
                }
              >
                {sel && (
                  <TranslateText
                    label={sel}
                    checkCommaReplacement={title === "protectionplan" && true}
                  />
                )}
                {/* {showSeeDetails && (
                  <span
                    className="StepInReview__mainContainer__contentContainer__selection__seeDetails"
                    onClick={showSeeDetailsModal}
                  >
                    <TranslateText label={"steps.review.seeDetails"} />
                  </span>
                )} */}
                {!sel && <TranslateText label={"noTreatment"} />}
              </span>
            );
          }
        })}
      </div>
    </div>
  );
}
