import { minBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import { getStepBadgeType } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useCheapCurrentStep,
  useCheckPhotocromic,
  useTreatmentsMapper,
  useCurrentBrand
} from "@hooks";
import { ParameterWithTitle } from "../../models/Parameter";
import { workflow } from "../../models/Workflow";
import "./default.module.scss";

/**
 * This component displays the 'treatments' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 * on component loaded this component execute getTreaments method which create 2 array:
 * - first array is included treatments, if the current packages has the same value for treatments;
 * - second array is the avaiable treatments that user can add on lens filter
 */
export function Treatments() {
  const currentPackage = useCheapCurrentStep();
  const currentPackages = useAllCurrentPackages();
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  const [included, setIncluded] = useState(null);
  const [treatmentAvaiable, setTreatmentAvaiable] = useState(null);
  const dispatch = useDispatch();

  const loadTreatments = useTreatmentsMapper();

  const isPhotocromic = useCheckPhotocromic();

  const brand = useCurrentBrand();

  useEffect(() => {
    if (currentStep) {
      const tStep = workflow.findByStepKey("Treatments");
      if (tStep && currentStep.progressive <= tStep.progressive) {
        dispatch(workflowActions.setCurrentIncluded(null));
        dispatch(workflowActions.setParameterPrice(null));
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentPackage && loadTreatments) {
      getTreatments();
    }
  }, [currentPackages, currentPackage, loadTreatments]);

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );

  const getTreatments = () => {
    const familyFilter = getFamilyFilter();
    if (familyFilter) {
      let forcedTreatments = [];
      if (isPhotocromic) forcedTreatments.push("transition");

      let filteredPackages = filterPackagesByFamily(
        currentPackages,
        familyFilter.value?.required,
        familyFilter.value?.excluded
      );
      let { included, treatments } = loadTreatments(
        filteredPackages,
        true,
        forcedTreatments
      );
      setIncluded(included);
      dispatch(
        workflowActions.setCurrentIncluded(
          included.map((i) => ({ key: i.id, value: i.name }))
        )
      );
      const onlyFamilyTreatments = filterTreatmentsByFamily(
        treatments,
        familyFilter.value?.required,
        familyFilter.value?.additional
      );
      setTreatmentAvaiable(onlyFamilyTreatments);

      let minTreatmentPrice = minBy(onlyFamilyTreatments, (elem) => {
        if (isInsuranceEnabled) return elem.insPrice;
        return elem.price - elem.discount;
      });

      if (minTreatmentPrice) {
        let treatmentToSetInRedux = new ParameterWithTitle();
        const idTa = minTreatmentPrice.id.split("$")[0];
        const valueTa = minTreatmentPrice.id.split("$")[1];
        treatmentToSetInRedux.key = idTa;
        treatmentToSetInRedux.value = valueTa;
        treatmentToSetInRedux.title = minTreatmentPrice.title;
        treatmentToSetInRedux.price = minTreatmentPrice.price;
        treatmentToSetInRedux.discount = minTreatmentPrice.discount;
        treatmentToSetInRedux.insPrice = minTreatmentPrice.insPrice;

        dispatch(workflowActions.setParameterPrice([treatmentToSetInRedux]));
      }
    } else {
      let { included, treatments } = loadTreatments(currentPackages);
      setIncluded(included);

      dispatch(
        workflowActions.setCurrentIncluded(
          included.map((i) => ({ key: i.id, value: i.name }))
        )
      );
      setTreatmentAvaiable(treatments);
    }
  };

  const getFamilyFilter = () => {
    return workflow.currentStep.params.find((p) => p.key === "familyFilter");
  };

  const filterPackagesByFamily = (
    packages: { validRXPackages: any[]; invalidRXPackages: any[] },
    requiredoptions: string[],
    excludedoptions?: string[]
  ) => {
    let filteredPackages = packages.validRXPackages;
    let greyOutFilteredPackages = packages.invalidRXPackages;

    if (requiredoptions?.length) {
      filteredPackages = filteredPackages.filter((cp) => {
        let ret = true;
        requiredoptions.forEach(
          (t) => (ret = ret && cp.lensPackage[t] != null)
        );
        if (excludedoptions?.length)
          excludedoptions.forEach(
            (t) => (ret = ret && cp.lensPackage[t] === null)
          );
        return ret;
      });

      greyOutFilteredPackages = greyOutFilteredPackages.filter((cp) => {
        let ret = true;
        requiredoptions.forEach(
          (t) => (ret = ret && cp.lensPackage[t] != null)
        );
        if (excludedoptions?.length)
          excludedoptions.forEach(
            (t) => (ret = ret && cp.lensPackage[t] === null)
          );
        return ret;
      });
    }

    return {
      validRXPackages: filteredPackages,
      invalidRXPackages: greyOutFilteredPackages,
    };
  };

  const filterTreatmentsByFamily = (
    treatments: any[],
    required: string[],
    additional?: string[]
  ) => {
    let toFilterData = [];
    if (required) {
      toFilterData = [...toFilterData, ...required];
    }
    if (additional) {
      toFilterData = [...toFilterData, ...additional];
    }
    if (treatments) {
      return treatments.filter((t) => {
        const key = t.id.split("$")[0];
        return toFilterData.some((f) => f === key);
      });
    }
    return null;
  };

  const onClickTreatment = (obj: {
    id: string;
    title: string;
    price: number;
    discount: number;
    insPrice: number;
  }) => {
    const idT = obj.id.split("$")[0];
    const valueT = obj.id.split("$")[1];
    const mapped = treatmentAvaiable.map((ta: any) => {
      const idTa = ta.id.split("$")[0];
      const valueTa = ta.id.split("$")[1];
      if (idTa === idT) {
        if (valueTa === valueT) {
          ta.isSelected = !ta.isSelected;
        } else {
          ta.isSelected = false;
        }
      }
      return ta;
    });
    setTreatmentAvaiable(mapped);
    //to reload price
    let onlySelected = [
      ...mapped
        .filter((ta: any) => ta.isSelected)
        .map((ta: any) => {
          let ret = new ParameterWithTitle();
          const idTa = ta.id.split("$")[0];
          const valueTa = ta.id.split("$")[1];
          ret.key = idTa;
          ret.value = valueTa;
          ret.title = obj.title;
          ret.price = obj.price;
          ret.discount = obj.discount;
          ret.insPrice = obj.insPrice;

          return ret;
        }),
      {
        key: "photocromic",
        value: isPhotocromic,
      },
    ];

    dispatch(workflowActions.setParameterPrice(onlySelected));
    workflow.goNextStep([]);
  };

  const nextOrSkip = (
    selected: any,
    skipped?: boolean,
    isPreselected?: boolean
  ) => {
    if (!skipped) {
      onClickTreatment(selected);
    } else {
      if (!isPhotocromic) {
        const familyFilter = getFamilyFilter();
        if (familyFilter) {
          if (selected && familyFilter.value?.nextStep) {
            let prevTreatmentFamilyParams = workflow
              .getPrevCurrentParameterStep()
              .filter((param) => {
                return param.key === "treatmentsFamily";
              });

            let treatmentToSet = new ParameterWithTitle();
            treatmentToSet.key = familyFilter.value.required[0];

            let filtered = included.filter((tIncluded) => {
              return tIncluded.id === familyFilter.value.required[0];
            });
            if (filtered.length) {
              treatmentToSet.value = filtered[0].key;
            }

            treatmentToSet.skipped = true;
            dispatch(workflowActions.setParameterPrice([treatmentToSet]));

            workflow.goToStep(
              familyFilter.value?.nextStep,
              [
                {
                  key: prevTreatmentFamilyParams.length
                    ? "treatmentsFamily"
                    : "treatment",
                  value: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].value
                    : selected,
                  price: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].price
                    : null,
                  discount: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].discount
                    : null,
                  insPrice: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].insPrice
                    : null,
                },
              ],
              true
            );
          } else {
            workflow.goToStep("Thickness", workflow.currentStep.params, true);
          }
        } else {
          if (!selected) {
            workflow.goNextStep(workflow.currentStep.params, true);
          }
        }
      } else if (isPhotocromic && isPreselected) {
        onClickTreatment(selected);
      }
    }
  };

  const getKey = (title: string) =>
    "Treatments_sc_" + title.split(" ").join("_");

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: "Complete your lens",
        subtitle: brand !== "oakley" && brand !== "osi" &&
          "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        showGrayOutBanner:
          treatmentAvaiable && treatmentAvaiable.find((t) => t.disabled),
      }}
    >
      <div className={"Treatmentsfamily__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Treatments",
            onNext: nextOrSkip,
            parameterPreselection: ["blueLight", "transition", "uvProtection"],
            forceRender: isPhotocromic,
            onPreselect: (selected, skipped: boolean) => {
              let selectedT = null;
              let selectedB = false;
              treatmentAvaiable.forEach((t) => {
                const id = t.id.split("$")[0];
                const val = t.id.split("$")[1];
                if (selected[id] === val && !selectedB) {
                  selectedT = t;
                  selectedB = true;
                }
              });

              nextOrSkip(selectedT, skipped, true);
            },
          }}
        >
          {treatmentAvaiable &&
            treatmentAvaiable.map((c: any) => (
              <BrandComponent
                key={getKey(c.title) || "MISSING"}
                componentName="StepCard"
                parameter={{
                  ...c,
                  title: c.title || "MISSING",
                  description: c.description || "MISSING",
                  badge: c.textBadge
                    ? getStepBadgeType(c.textBadge)
                    : c.badge
                      ? getStepBadgeType(c.badge)
                      : null,
                  disabled:
                    c.badge == "coming soon" || c.textBadge == "coming soon"
                      ? true
                      : false,
                  onClick:
                    c.badge == "coming soon" ||
                      c.disabled ||
                      c.textBadge == "coming soon"
                      ? null
                      : () => onClickTreatment(c),
                  hideSign: true,
                }}
              />
            ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
