import "./default.module.scss";
import { TranslateText } from "../common/TranslateText";
import { BrandComponent } from "../common/BrandComponent"
import { useShowSavingsLabel, useBundlesPrices } from "@hooks";
interface Savings {
  discount: number
}

export function Savings({ discount }: Savings) {
  const showSavingsLabel = useShowSavingsLabel()

  if (!showSavingsLabel || discount <= 0) {
    return null
  }
  return (
    <div className="savings">
      <span><TranslateText label="savings" /></span>
      <BrandComponent
        componentName="StepPrice"
        parameter={{
          price: discount,
          hideSign: true,
          ignorePriceLabels: true,
          hideInsuranceLoader: true,
        }}
      />
    </div>
  )
}