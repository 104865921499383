import { Adapter } from "../models/Adapter";
import { Step } from "../models/Step";

type ProgressiveKeyStep = {
  progressive: number;
  key: string;
};

class ConfigStepAdapter extends Adapter<ProgressiveKeyStep, Step> {
  adapt: (source: ProgressiveKeyStep) => Step = (
    source: ProgressiveKeyStep
  ) => {
    return new Step(source.key, source.progressive, []);
  };
  adaptReverse: (source: Step) => ProgressiveKeyStep = (source: Step) => {
    return source ? { progressive: source.progressive, key: source.key } : null;
  };
}

export const configStepAdapter = new ConfigStepAdapter();
