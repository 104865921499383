import { useMemo } from "react";
import { getStepBadgeType } from "../ConfigLoader";
import { useAllCurrentPackages } from "./useAllCurrentPackages";
import { useConfigContent } from "./useConfigContent";
import { usePriceStepItem } from "./usePriceStepItem";
import { useSelector } from "react-redux";

export function useBundles() {
  const configBundles: any[] = useConfigContent("lensBundle");
  const currentPackages = useAllCurrentPackages();
  const getPriceStep = usePriceStepItem();
  const bundles = useSelector(
    (state: any) => state.config?.lensesData?.bundles
  );
  const bundlesPrices = useSelector(
    (state: any) => state.config?.lensesData?.bundlesPrices
  );

  const bundlesEnabled = bundles?.length && bundlesPrices?.length

  const _bundles = useMemo(() => {
    if (currentPackages && configBundles) {
      return (
        configBundles
          .sort((a, b) =>
            a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
          )
          .map((bundle) => {
            const priceObj = bundle.disabled
              ? null
              : getPriceStep({ lensBundle: bundle.id });
            return {
              ...bundle,
              ...priceObj,
              textBadge: bundle.badge,
              imageUrl: bundle.imageFileName,
              badge: bundle.badge ? getStepBadgeType(bundle.badge) : null,
              disabled: bundle.disabled || bundle.badge == "coming soon",
            };
          })
      );
    }
  }, [configBundles, currentPackages]);

  return { bundles: _bundles || [], bundlesEnabled };
}
