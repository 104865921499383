import { useMemo } from "react";
import { formatDate } from "../../utils/date";
import { useTranslate } from "../translations/TranslationHooks";

export function useDateComparison(date1) {
  let today = Date.now();
  let todayDate = new Date(today);
  let uploadedDate = new Date(date1);
  let yesterdayLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.yesterday"
  );
  let todayLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.today"
  );

  const difference = useMemo(() => {
    const diffInMilliseconds = Math.abs(
      todayDate.getTime() - uploadedDate.getTime()
    );
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    if (diffInHours <= 1) {
      return null;
    } else if (diffInHours <= 24) {
      return todayLabel;
    } else if (diffInHours > 24 && diffInHours <= 48) {
      return yesterdayLabel;
    } else {
      let date = formatDate(new Date(date1), "MM/DD/YYYY");
      return date;
    }
  }, [date1, yesterdayLabel, todayLabel]);

  return difference;
}
