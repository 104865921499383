import React, { useState, useEffect } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";

export type IncompatibleLensTypeProps = {
  type: string;
};

export const IncompatibleLensType = (props: IncompatibleLensTypeProps) => {
  let incompatibleLensTypeError = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLensTypeError" + props.type
  );

  return (
    <div className="IncompatibleLensType">
      <div
        className={"IncompatibleLensType__message"}
        dangerouslySetInnerHTML={{ __html: incompatibleLensTypeError }}
      ></div>
    </div>
  );
};
