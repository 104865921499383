import React from "react";
import { ErrorModal } from "../ErrorModal/component";
import "./default.module.scss";

export class ErrorBoundary extends React.Component<
  any,
  { error: any; errorInfo: any; dispatch: any; action: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      dispatch: props.dispatch,
      action: props.modalsActions,
    };
  }
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      console.error(this.state.error && this.state.error.toString());
      console.error(this.state.errorInfo.componentStack);
      this.state.dispatch(this.state.action.setConfigError(true));
      return (
        <div className="ErrorBundary__container">{/* <ErrorModal />  */}</div>
      );
    }
    return this.props.children;
  }
}
