import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StateReferences = {
  [key: string]: boolean;
};
export type Reference = {
  key: string;
  value: boolean;
};

const initialState: StateReferences = {};

const referenceSlice = createSlice({
  name: "references",
  initialState: initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<Reference>) => {
      state[payload.key] = payload.value;
    },
    loaded: (state, { payload }: PayloadAction<boolean>) => {
      state["loaded"] = payload;
    },
    reset: (state, {}: PayloadAction<void>) => {
      state = initialState;
    },
  },
});

export const referenceReducer = referenceSlice.reducer;
export const referenceActions = referenceSlice.actions;
