import React, { ReactNode, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./default.module.scss";
import { workflow } from "../../configurator/models/Workflow";
import { useSelector } from "react-redux";

interface ScrollViewProps {
  children: ReactNode;
  idView?: string;
  autoHeight?: boolean;
  scrollTopValue?: number;
}

export function ScrollView({
  autoHeight,
  idView,
  scrollTopValue,
  children,
}: ScrollViewProps) {
  const references = useSelector((state: any) => state.references);

  const goBottom = () => {
    let scrollDiv = document.querySelector(".ScrollView__container");
    scrollDiv.scrollTop = scrollDiv.scrollHeight;
  };
  const [referenceName, setReferenceName] = useState("");

  useEffect(() => {
    setReferenceName("ScrollView__" + workflow?.currentStep?.key);
  }, [workflow.currentStep, references]);

  return (
    <Scrollbars
      className={"ScrollView__scrollbar"}
      autoHeight={autoHeight}
      autoHide
      renderView={(props) => {
        return (
          <div
            {...props}
            id={idView}
            className={
              "ScrollView__container" + (workflow.isLastStep() ? " review" : "")
            }
            style={{}}
          />
        );
      }}
      renderTrackVertical={(props) => (
        <div {...props} className={"ScrollView__trackVertical"} />
      )}
      renderThumbVertical={({ style, props }) => {
        const customStyle = {
          top: (scrollTopValue || 0) * 100,
        };
        return (
          <div
            {...props}
            className={"ScrollView__thumbVertical"}
            style={{ ...style, ...customStyle }}
          />
        );
      }}
    >
      <div className={"ScrollView__innerContainer"}>{children}</div>

      {!workflow.isLastStep() &&
        workflow.currentStep?.key !== "LensColor" &&
        workflow.currentStep?.key !== "TransitionColor" &&
        references[referenceName] == false && (
          <div onClick={goBottom} className={"ScrollView__toBottom"}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#ECEFF0" />
              <path
                d="M27.8535 10.6035L27.1465 9.89648L16 21.043L4.85348 9.89648L4.14648 10.6035L16 22.457L27.8535 10.6035Z"
                fill="#182446"
              />
            </svg>
          </div>
        )}

      {!workflow.isLastStep() &&
        workflow.currentStep?.key !== "LensColor" &&
        workflow.currentStep?.key !== "TransitionColor" &&
        references[referenceName] == false && (
          <div className={"ScrollView__fade"} />
        )}
    </Scrollbars>
  );
}
