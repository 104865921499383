import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useInstallmentsLabel,
  usePaymentLabelInModal,
} from "../../configurator/hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import Image from "../common/Image";
import "./default.module.scss";

interface PaymentInstallmentsModalOpt {}

export function PaymentInstallmentsModal({}: PaymentInstallmentsModalOpt) {
  const dispatch = useDispatch();
  const currentBrand = useSelector((state: any) => state.config?.brandName);
  const configPaymentInstallments = useSelector(
    (state: any) => state.config?.paymentInstallment
  );
  const projectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const modalTitle = useTranslate(
    "steps.review.paymentInstallments.modal.title"
  );
  const subtitle = useTranslate(
    "steps.review.paymentInstallments.modal.subtitle"
  );
  const modalSubtitle = subtitle.replace("###BRAND###", currentBrand);

  const {
    type: paymentType,
    multiplePaymentInstallment,
    types: paymentTypes = [],
  } = configPaymentInstallments;

  const getModalPaymentTypeDescription = (type: string) => {
    let description = useTranslate(
      `steps.review.paymentInstallments.modal.${type}`
    );
    description = usePaymentLabelInModal({ payment: description, type });
    return description;
  };
  const installmentsLabel = useInstallmentsLabel({
    initialString: modalSubtitle,
    withQuotes: true,
  });

  const closeLabel = useTranslate("generic.modal.close");
  const sortedPaymentTypes = [...paymentTypes]?.sort((a, b) =>
    a === "klarna" ? -1 : b === "klarna" ? 1 : 0
  );

  return (
    <div className="PaymentInstallmentsModal__container">
      <div className="PaymentInstallmentsModal__container__overlay"></div>
      <div className="PaymentInstallmentsModal__container__popup">
        <div
          className="PaymentInstallmentsModal__container__popup__closeContainer"
          onClick={() => {
            dispatch(modalsActions.setShowPaymentInstallmentsModal(false));
          }}
        >
          <a
            className={
              "PaymentInstallmentsModal__container__popup__closeContainer__close"
            }
          />
          <span>{closeLabel}</span>
        </div>
        <div className="PaymentInstallmentsModal__container__popup__content">
          <div className="PaymentInstallmentsModal__container__popup__content__wrapper">
            <div className="PaymentInstallmentsModal__container__popup__content__title">
              {modalTitle}
            </div>
            <div className="PaymentInstallmentsModal__container__popup__content__subtitle">
              {installmentsLabel}
            </div>
          </div>
          <div className="PaymentInstallmentsModal__container__popup__content__options_container">
            {multiplePaymentInstallment ? (
              <>
                {sortedPaymentTypes.map((type: string) => (
                  <div className="PaymentInstallmentsModal__container__popup__content__options_container__option_box">
                    <Image
                      imageClass="PaymentInstallmentsModal__container__popup__content__options_container__option_box__logo"
                      url={projectImage}
                      fileName={`${type}_logo`}
                      alt={`${type}`}
                    />
                    <div
                      className="PaymentInstallmentsModal__container__popup__content__options_container__option_box__description"
                      dangerouslySetInnerHTML={{
                        __html: getModalPaymentTypeDescription(type),
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="PaymentInstallmentsModal__container__popup__content__options_container__option_box">
                <Image
                  imageClass="PaymentInstallmentsModal__container__popup__content__options_container__option_box__logo"
                  url={projectImage}
                  fileName={`${paymentType}_logo`}
                  alt={`${paymentType}`}
                />
                <div
                  className="PaymentInstallmentsModal__container__popup__content__options_container__option_box__description"
                  dangerouslySetInnerHTML={{
                    __html: getModalPaymentTypeDescription(paymentType),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
