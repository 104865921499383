import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WarrantyOptionConfigModel } from "../../components/WarrantyOptions/component";
import { Step } from "../../configurator/models/Step";

type TPayload = {
  steps: Step[];
};

export type InsuranceState = {
  loading: boolean;
  insuranceEnabled: boolean;
  reviewInsuranceObject: any;
  warranty?: WarrantyOptionConfigModel;
  pricingMethodConfig: any;
};

const initialState: InsuranceState = {
  loading: false,
  insuranceEnabled: false,
  reviewInsuranceObject: null,
  pricingMethodConfig: undefined,
};

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: initialState,
  reducers: {
    setInsuranceEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.insuranceEnabled = payload;
    },
    setInsuranceLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setReviewInsuranceObject: (state, { payload }: PayloadAction<any>) => {
      state.reviewInsuranceObject = {
        ...payload,
      };
    },
    setPricingMethodConfig: (state, { payload }: PayloadAction<any>) => {
      state.pricingMethodConfig = payload;
    },
    setWarranty: (
      state,
      { payload }: PayloadAction<WarrantyOptionConfigModel>
    ) => {
      state.warranty = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const insuranceReducer = insuranceSlice.reducer;
export const insuranceActions = insuranceSlice.actions;
