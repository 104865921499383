import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";
import { useBundlesPrices } from "./useBundlesPrices";

export function useShowSavingsLabel() {
  const showSavingsLabelValueCookie = getCookieByName("showFramePlusLensesPrice", true);
  const showSavingsLabel = useSelector(
    (state: any) => state.config.layoutSettings?.showSavingsLabel
  );

  const bundlesEnabled = useBundlesPrices();

  if (bundlesEnabled) {
    return true
  }

  if (showSavingsLabelValueCookie !== null) {
    return showSavingsLabelValueCookie as boolean;
  }

  if (showSavingsLabel !== null && showSavingsLabel !== undefined) {
    return showSavingsLabel as boolean;
  }

  return false;
}