import React from 'react'
import "./default.module.scss";
import { useSmoothHorizontalScroll } from '@hooks';

export function Carousel({ children }) {
  const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } =
    useSmoothHorizontalScroll();

  return (
    <div className='Carousel__wrapper'>
      <button
        onClick={() => scrollTo("left")}
        disabled={isAtStart}
        style={{ display: (isAtStart || (children && Array.from(children).length < 5)) ? "none" : "flex" }}
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.70711 0.292893C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292893L0.292893 7.29289C-0.0976321 7.68342 -0.0976322 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L2.41421 8L8.70711 1.70711C9.09763 1.31658 9.09763 0.683418 8.70711 0.292893Z"
            fill="#222222"
          />
        </svg>
      </button>
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        style={{ overflowX: "scroll" }}
        className="Carousel__content"
      >
        {children}
      </div>
      <button
        onClick={() => scrollTo("right")}
        disabled={isAtEnd}
        style={{ display: (isAtEnd || (children && Array.from(children).length < 5)) ? "none" : "flex" }}
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.70711 0.292893C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292893L0.292893 7.29289C-0.0976321 7.68342 -0.0976322 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L2.41421 8L8.70711 1.70711C9.09763 1.31658 9.09763 0.683418 8.70711 0.292893Z"
            fill="#222222"
          />
        </svg>
      </button>

    </div>
  )
}

