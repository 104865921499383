import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";
import dayjs from "dayjs";

export function usePrescriptionIssueDate() {
  const checkPrescriptionIssueDateCookie = getCookieByName(
    "checkPrescriptionIssueDate"
  );
  const checkPrescriptionIssueDateConfig = useSelector(
    (state: any) => state.config.prescriptionModule?.checkPrescriptionIssueDate
  );

  const validTimeInMonth = useMemo(() => {
    if (![null, undefined].includes(checkPrescriptionIssueDateCookie)) {
      return Number(checkPrescriptionIssueDateCookie) as number;
    }

    if (![null, undefined].includes(checkPrescriptionIssueDateConfig)) {
      return Number(checkPrescriptionIssueDateConfig);
    }
  }, [checkPrescriptionIssueDateCookie, checkPrescriptionIssueDateConfig]);

  const isExpiredPrescriptionCallback = useCallback(
    (issueDate) =>
      isValidIssueDate(issueDate) &&
      isExpiredPrescription(issueDate, new Date(), validTimeInMonth),
    [validTimeInMonth]
  );

  const isValidIssueDateCallback = useCallback(isValidIssueDate, []);

  const convertToISO = (date: string) => {
    if (!isValidIssueDate(date)) return null;
    const month = date?.split("/")[0];
    const year = date?.split("/")[1];
    return new Date(`${year}-${month}`).toISOString();
  };

  const convertToMMYYYY = (date: string) => {
    return dayjs(date).format("MM/YYYY");
  };

  return {
    validTimeInMonth,
    isExpiredPrescription: isExpiredPrescriptionCallback,
    isValidIssueDate: isValidIssueDateCallback,
    convertToISO,
    convertToMMYYYY,
  };
}

export const isExpiredPrescription = (
  issueDate: string,
  actualDay: Date,
  validTimeInMonth: number = 24
) => {
  if (!validTimeInMonth) throw new Error(`Valid time not setted`);
  const issueMonth = issueDate?.split("/")[0];
  const issueYear = issueDate?.split("/")[1];

  const actualMonth = actualDay.getMonth() + 1;
  const actualYear = actualDay.getFullYear();

  return (
    Number(issueMonth) + Number(issueYear) * 12 <
    actualMonth + actualYear * 12 - validTimeInMonth
  );
};

export const isValidIssueDate = (issueDate) => {
  const issueMonth = issueDate?.split("/")[0];
  const issueYear = issueDate?.split("/")[1];

  const actualMonth = new Date().getMonth() + 1;
  const actualYear = new Date().getFullYear();

  if (
    issueMonth &&
    issueYear &&
    issueMonth.length == 2 &&
    issueYear.length == 4 &&
    Number(issueMonth) > 0 &&
    Number(issueMonth) <= 12 &&
    new Date(
      `${actualYear}-${actualMonth < 10 ? `0${actualMonth}` : actualMonth}`
    ).getTime() >= new Date(`${issueYear}-${issueMonth}`).getTime()
  )
    return true;
};
