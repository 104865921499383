import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function usePolarToggle() {
  const polarToggleCookie = getCookieByName("polarToggle", false);
  const polarToggle = useSelector(
    (state: any) => state.config.layoutSettings?.polarToggle
  );

  if (polarToggleCookie !== null) {
    return polarToggleCookie as string;
  }

  if (polarToggle !== null && polarToggle !== undefined) {
    return polarToggle as string;
  }

  return "OFF";
}
