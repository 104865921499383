import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";
import { env } from "../ConfigLoader";

export function useVirtualMirror() {
  const vmmvtransitionsMapUrl = useSelector((state: any) =>
    !state.config?.baseURLs?.assetsCDN
      ? null
      : `${state.config.baseURLs.assetsCDN}/extra/image/rxc/rxc_settings/rxc2vmmv_transitions_map.json`
  );
  const vmmvModule = useSelector((state: any) => state.config?.vmmvModule);
  const frame = useSelector((state: any) => state.config?.data?.frame);
  const dispatch = useDispatch();

  useEffect(() => {
    // fetch TransitionsTechnologiesMap
    async function fetchTransitionsTechnologiesMap() {
      try {
        const { data } = await axios.get(vmmvtransitionsMapUrl || "");
        dispatch(virtualMirrorActions.setTransitionsTechnologiesMap(data));
      } catch (err) {
        console.log(err);
      }
    }
    // fetch TransitionsSwatchesMap
    async function fetchTransitionsSwatchesMap() {
      const environment = env();
      const transitionsSwatchesUrl =
        environment === "prod"
          ? "https://vmmv.luxottica.com/assets/transitions/transitions-swatches.json"
          : "https://vmmv-uat.luxottica.com/assets/transitions/transitions-swatches.json";
      try {
        const { data } = await axios.get(transitionsSwatchesUrl);
        dispatch(virtualMirrorActions.setTransitionsSwatchesMap(data));
      } catch (err) {
        console.log(err);
      }
    }

    if (vmmvModule?.vmExperience && vmmvtransitionsMapUrl) {
      fetchTransitionsTechnologiesMap();
      fetchTransitionsSwatchesMap();
    }
  }, [vmmvModule, vmmvtransitionsMapUrl]);

  useEffect(() => {
    const checkUpcAvailability = async () => {
      const integrationKey = vmmvModule?.vmInit?.key;
      const upc = frame?.upc;
      if (integrationKey && upc) {
        const upcAvailability = await window?.vmmv?.isUPCSupported(
          [upc],
          integrationKey
        );
        if (upcAvailability?.[upc]?.available) {
          dispatch(virtualMirrorActions.setShowVirtualMirrorButton(true));
        }
      }
    };
    // set initial config from vmmvModule
    if (vmmvModule?.vmExperience) {
      let vmappConfig = { ...vmmvModule };
      if (!vmmvModule?.products) {
        const products = [
          {
            upc: frame.upc,
            code: `${frame.model}__${frame.code}`,
            name: frame.model,
            colorCode: frame.color,
            category: frame?.category || "",
            thumbnailUrl: frame.imageUrl,
            brand: {
              name: frame.brand,
              logoUrl: frame.brandImageUrl ? frame.brandImageUrl : vmmvModule?.store?.logo,
            },
            lensColorLabel: "",
            frameColorLabel: "",
            styleName: `${frame.model}__${frame.code}`,
            size: "",
            isTransition: true,
            rxAvailable: false,
            isSelected: true,
          },
        ];
        vmappConfig.products = products;
      }
      dispatch(virtualMirrorActions.setVmappConfig(vmappConfig));
      checkUpcAvailability();
    }
  }, [vmmvModule, frame]);
}
