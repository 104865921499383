import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCurrentBrand, useCurrentType } from "@hooks";
import { referenceActions } from "../../redux/slices/references";

interface BrandComponentOptions {
  componentName: string;
  brand?: string;
  parameter?: any;
  basePath?: string;
  children?: ReactNode;
  path?: string;
}

/**
 * This component performs a lazy loading of components based on the current brand. It
 * accepts an input of type ```BrandComponentOptions``` with the following parameters:
 *
 * - ```componentName```: the name of the component to load, passed as string
 * - ```brand``` (_optional_): the name of the brand that you want to use, in case
 * you need to load the component (and style) of another brand different from the current one
 * - ```parameter``` (_optional_): the object containing all of the parameters that you want to pass to the
 * loaded component
 * - ```basePath``` (_optional_): the base path of the folder where the component is located. The default
 * value is ```src/components```
 * - ```children``` (_optional_): any content that should be displayed as a children of the loaded component
 *
 *
 * For details about this lazy loading, please refer to the **Project structure** section
 * of the main documentation page.
 * 
 * Component selection logic: 
    se brand non definito
      - se SUNGLASSES
        - /sun.component
        - /component
      - no SUNGLASSES
        - /component

    se SUNGLASSES
    - /sun.brand.component
    - /brand.component
    - /sun.component
    - /component

    se brand definito e non è SUN
      - /brand.component
      - /component

 */
export function BrandComponent(props: BrandComponentOptions) {
  const [Comp, setComp] = useState(null);

  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType();
  const redux = useDispatch();
  const componentProps = {
    ...props.parameter,
    brand: currentBrand,
  };

  useEffect(() => {
    redux(referenceActions.loaded(Comp == null));
  }, [Comp]);

  useEffect(() => {
    if (currentType) {
      setComp(
        React.lazy(() => {
          const brand = props.brand || currentBrand;
          const componentPath = props.path || props.componentName;
          if (!brand) {
            if (currentType === "SUNGLASSES") {
              return import("../" + componentPath + "/sun.component")
                .then((comp) => ({ default: comp[props.componentName] }))
                .catch(() =>
                  import("../" + componentPath + "/component").then((comp) => ({
                    default: comp[props.componentName],
                  }))
                );
            }
            return import("../" + componentPath + "/component").then(
              (comp) => ({ default: comp[props.componentName] })
            );
          }

          if (currentType === "SUNGLASSES") {
            return import(
              "../" + componentPath + "/sun." + brand + ".component"
            )
              .then((comp) => ({ default: comp[props.componentName] }))
              .catch(() =>
                import("../" + componentPath + "/" + brand + ".component")
                  .then((comp) => ({ default: comp[props.componentName] }))
                  .catch(() =>
                    import("../" + componentPath + "/sun.component")
                      .then((comp) => ({ default: comp[props.componentName] }))
                      .catch(() =>
                        import("../" + componentPath + "/component").then(
                          (comp) => ({ default: comp[props.componentName] })
                        )
                      )
                  )
              );
          }

          return import("../" + componentPath + "/" + brand + ".component")
            .then((comp) => ({ default: comp[props.componentName] }))
            .catch(() =>
              import("../" + componentPath + "/component").then((comp) => ({
                default: comp[props.componentName],
              }))
            );
        })
      );
    }

    //}
  }, [currentBrand, currentType]);

  return (
    <Suspense
      fallback={null /*<p>Loading brand compoenent {props.componentName}</p>*/}
    >
      {Comp && <Comp {...componentProps}>{props.children}</Comp>}
    </Suspense>
  );
}
