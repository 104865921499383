import React from "react"

type Props = {
  activeTabKey?: string,
  tabKey: string,
  label?: string,
  hide?: boolean,
  children: any
}

export const TabPanel = (props: Props) => {
  return (
    <div
      className="TabPanel"
      tabIndex={0}
      aria-labelledby={props.tabKey}
      role="tabpanel"
      hidden={props.activeTabKey !== props.tabKey}
    >
      {(props.activeTabKey !== props.tabKey) ? null : props.children}
    </div>
  )
}