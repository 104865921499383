import React, { useEffect, useState } from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import { StepCardProps } from "../StepCard/component";
import GenericButton from "../common/GenericButton";

interface StepCardCheckProps extends StepCardProps {
  isSelected?: boolean;
}

export function StepCardCheck({
  title,
  description,
  price,
  discount,
  isSelected,
  onClick,
  imageUrl,
  id,
}: StepCardCheckProps) {
  const [selected, setSelected] = useState(false);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );

  const onCardClick = () => {
    if (onClick) onClick({ id, title, price, discount });
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <>
      <div className={"StepCardCheck__substep"}>
        <div className={"StepCardCheck__substep_header"}>
          <div className={"StepCardCheck__substep_title"}>{title}</div>
          <div className={"StepCardCheck__substep_learnmore"}>
            <GenericButton className="cursor-hand border-button">
              Learn more
            </GenericButton>
          </div>
        </div>
      </div>
      <div className={"StepCardCheck__container"} onClick={onCardClick}>
        <div
          id={title}
          className={
            "StepCardCheck_button" +
            " " +
            (selected ? "StepCardCheck_button_selected" : "")
          }
          tabIndex={0}
        >
          <div className={"StepCardCheck_button_content"}>
            {imageUrl && (
              <Image
                imageClass={"StepCardCheck_button_image"}
                url={configProjectImage}
                fileName={imageUrl}
                alt={imageUrl}
              />
            )}
            <div className={"StepCardCheck_button_innercontent"}>
              <div className={"StepCardCheck_button_title"}>{title}</div>
              {price && (
                <div className={"StepCardCheck_button_pricecontainer"}>
                  <BrandComponent
                    componentName="StepPrice"
                    parameter={{ price, discount }}
                  />
                </div>
              )}
              <div className={"StepCardCheck_button_description"}>
                {description}
              </div>
            </div>
          </div>
          <div className={"StepCardCheck_button_icon"}></div>
        </div>
      </div>
      <div className={"StepCardCheck_buttonnext_container"}>
        <div className={"StepCardCheck_buttonnext"}>Continue</div>
      </div>
    </>
  );
}
