import { useCurrentType } from "./useCurrentType";
import { getCorrectKey } from "../ConfigLoader";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export function useRetriveContextualMessage() {
  const currentType = useCurrentType();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);

  const ctxMessages = useSelector((state: any) => state?.config?.ctxMessages);

  const [ctxMessage, setCtxMessage] = useState("")

  useEffect(() => {
    let ctxKey: string = getCorrectKey(
      currentStep,
      currentType === "EYEGLASSES" ? true : false,
      configPackages,
      configData
    );
    if (ctxMessages.hasOwnProperty(ctxKey)) {
      setCtxMessage(ctxMessages[ctxKey]);
    }
  }, [currentStep, ctxMessages, currentType, configPackages, configData])

  return ctxMessage;
}
