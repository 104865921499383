import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useEnableAgreement() {
  const enableAgreementCookie = getCookieByName(
    "enableAgreementCheckbox",
    true
  );

  const enableAgreemenConfig = useSelector(
    (state: any) => state.config?.prescriptionModule?.enableAgreementCheckbox
  );

  if (enableAgreementCookie !== null && enableAgreementCookie !== undefined) {
    return enableAgreementCookie as boolean;
  }
  if (enableAgreemenConfig !== null && enableAgreemenConfig !== undefined) {
    return enableAgreemenConfig as boolean;
  }
  return true;
}
