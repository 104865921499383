import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useEnableDefaultLogo() {
  const enableDefaultLogoCookie = getCookieByName("enableDefaultLogo", true);

  const enableDefaultLogoConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.enableDefaultLogo
  );

  if (
    enableDefaultLogoCookie !== null &&
    enableDefaultLogoCookie !== undefined
  ) {
    return enableDefaultLogoCookie as boolean;
  }
  if (
    enableDefaultLogoConfig !== null &&
    enableDefaultLogoConfig !== undefined
  ) {
    return enableDefaultLogoConfig as boolean;
  }
  return false;
}
