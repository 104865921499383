/* eslint-disable react/prop-types */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntersectionObserver } from "../../configurator/hooks";
import { referenceActions } from "../../redux/slices/references";

interface ReferencedSectionProps {
  name: string;
  className?: string;
  children?: ReactNode;
  style?: any;
}

const ReferencedSection = (props: ReferencedSectionProps) => {
  const ref = useRef();
  const isVisible = useIntersectionObserver(ref);
  const reduxDispatch = useDispatch();
  const className = props.className !== undefined ? props.className : "";
  const style = props.style !== undefined ? props.style : {};

  useEffect(() => {
    reduxDispatch(referenceActions.set({ key: props.name, value: isVisible }));
  }, [isVisible]);

  return (
    <section ref={ref} className={className} style={{ ...style }}>
      {props.children}
    </section>
  );
};

export default ReferencedSection;
