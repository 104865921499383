import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import JSZip from "jszip";
import { isArray } from "lodash";
import { getChannelFromBrand, sunRule, env } from "./configurator/ConfigLoader";

// const packagesUrl = "https://cors-anywhere.herokuapp.com/https://assets.davidclulow.com/extra/image/rxcbe/test/packages/DC/package_0AX1053.json.zip"
// const pricesUrl = "https://cors-anywhere.herokuapp.com/https://assets.clearly.ca/extra/image/rxcbe/test/prices/CL/prices_CA.json";
// const contentUrl = "https://cors-anywhere.herokuapp.com/https://assets.davidclulow.com/extra/image/rxcbe/test/pof/DC/pof_DC_EYEGLASSES_en_US.json.zip";

// go to: https://cors-anywhere.herokuapp.com/corsdemo and enable CORS

// PRICES:

// <base URL>/<env>/prices/<channel>/prices_<country>.json

// PACKAGES:

// <base URL>/<env>/packages/<channel>/packages_<model>.json.zip

// CONTENT:

// <base URL>/<env>/pof/<channel>/pof_<channel>_<category>_<locale>.json.zip

function isLocalHost() {
  return (
    window.location.href.indexOf("localhost") !== -1 ||
    window.location.href.indexOf("127.0.0.1") !== -1
  );
}

const extractJsonfromZip = async (file, callback) => {
  const zip = new JSZip();
  const extractedFiles = await zip.loadAsync(file);
  extractedFiles.forEach(async (relativePath, file) => {
    const content = await file.async("string");
    callback(JSON.parse(content));
  });
};

const getLensesDataPackagesMock = (onSucces) => {
  axios
    .get("https://run.mocky.io/v3/dcd64592-10d9-40ef-9999-4e33a192081b")
    .then((res) => {
      console.log(res.data);
      onSucces(res.data);
    })
    .catch((e) => {
      console.log("Error retrieving lensesData.packages MOCK");
    });
};
const getLensesDataPricesMock = (onSucces) => {
  axios
    .get("https://run.mocky.io/v3/37ab3d12-58ca-4e2b-bc2c-4668e42b1c67")
    .then((res) => {
      console.log(res.data);
      onSucces(res.data);
    })
    .catch((e) => {
      console.log("Error retrieving lensesData.prices MOCK");
    });
};
const getLensesDataContentMock = (onSucces) => {
  axios
    .get("https://run.mocky.io/v3/fe76d25f-86f2-436e-9e6c-1040dcb6b54b")
    .then((res) => {
      console.log(res.data);
      onSucces(res.data);
    })
    .catch((e) => {
      console.log("Error retrieving lensesData.content MOCK");
    });
};

interface BackendProps {
  config: any;
  children: React.ReactNode;
}

const getLensesDataContent = (url, onSuccess) => {
  axios
    .get(url, {
      responseType: "arraybuffer",
      decompress: true,
    })
    .then((res) => {
      extractJsonfromZip(res.data, onSuccess);
    })
    .catch((e) => {
      console.log(e);
    });
};
const getLensesDataPackages = (url, onSuccess) => {
  axios
    .get(url, {
      responseType: "arraybuffer",
      decompress: true,
    })
    .then((res) => {
      extractJsonfromZip(res.data, onSuccess);
    })
    .catch((e) => {
      console.log(e);
    });
};
const getLensesDataPrices = (url, onSuccess) => {
  axios
    .get(url)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

const getFrameRxValues = (url, onSuccess) => {
  axios
    .get(url)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

const getRxValues = (locale, rxValues) => {
  if (!rxValues[locale]) {
    const defaultRxValues = rxValues["en_US"];
    if (defaultRxValues) {
      return defaultRxValues;
    } else {
      throw new Error("no rxValues for en_US");
    }
  } else {
    return rxValues[locale];
  }
};

const isPublished = (locale, published) => {
  if (!published[locale]) {
    const defaultRxValues = published["en_US"];
    if (defaultRxValues === true || defaultRxValues === false) {
      return defaultRxValues;
    } else {
      throw new Error("no published for en_US");
    }
  } else {
    return published[locale];
  }
};

export function Backend({ config, children }) {
  const [packages, setPackages] = useState(config?.lensesData?.packages);
  const [prices, setPrices] = useState(config?.lensesData?.prices);
  const [content, setContent] = useState(config?.lensesData?.content);
  const [frameRxValues, setFrameRxValues] = useState(
    config?.data?.frame?.rxValues
  );
  const [newConfig, setNewConfig] = useState(config);
  const [isLoading, setIsLoading] = useState(true);

  const baseURL =
    config?.baseURLs?.assetsCDN || "https://assets.lenscrafters.com";
  const frameModel = config.data?.frame?.model;
  const frameColor = config.data?.frame?.color;
  const channel = getChannelFromBrand(config?.brand);
  const locale = config?.translation?.language || "en_US";
  const country =
    config?.data?.country ||
    config?.translation?.language?.split("_")?.[1] ||
    "US";

  const noPricesInPackages = (packages) => {
    if (!packages) return null;
    const onlyLensPackages = packages.find((pkg) => pkg?.frame)
      ? packages.map((pkg) => pkg.lensPackage)
      : packages;
    return onlyLensPackages.every((pkg) => {
      const keys = Object.keys(pkg);
      return !keys.includes("offerPrice") && !keys.includes("listPrice");
    });
  };

  const category = useMemo(() => {
    const pkgs = packages?.filter(
      (pkg: any) => pkg.lensPackage.type.toLowerCase() !== "frame only"
    );
    return (
      (pkgs?.every((p) => sunRule(p.lensPackage)) && "SUNGLASSES") ||
      "EYEGLASSES"
    );
  }, [packages]);

  useEffect(() => {
    if (!content) {
      getLensesDataContent(URLS.content, setContent);
    }
  }, [content]);

  useEffect(() => {
    if (packages && packages.length > 0) {
      if (noPricesInPackages(packages)) {
        getLensesDataPrices(URLS.prices, setPrices);
      }
    }
  }, [packages]);

  const URLS = {
    prices: `${baseURL}/extra/image/rxcbe/${
      env() || "test"
    }/prices/${channel}/prices_${country}.json`,
    packages: `${baseURL}/extra/image/rxcbe/${
      env() || "test"
    }/packages/${channel}/package_${frameModel}.json.zip`,
    content: `${baseURL}/extra/image/rxcbe/${
      env() || "test"
    }/pof/${channel}/pof_${channel}_${category}_${locale}.json.zip`,
    powers: `${baseURL}/extra/image/rxcbe/${
      env() || "test"
    }/powers/${channel}/power_range_${frameModel}.json`,
  };

  useEffect(() => {
    const setLensesDataPackages = (data) => {
      console.log(data);
      const moco = `${frameModel}__${frameColor}`;
      const lensesIds = data.frames[moco];
      let packages = Object.keys(data.packages)
        .filter((lensId) => lensesIds.includes(lensId))
        .reduce((acc, lensId) => {
          const lensPkgs = data.packages[lensId].map((pkg) => ({
            frame: {
              offerPrice: config.data.frame.offerPrice,
              insPrice: null,
            },
            lensPackage: pkg,
          }));
          return [...acc, ...lensPkgs];
        }, [])
        .filter((pkg) => isPublished(locale, pkg.lensPackage.published));

      packages.forEach((pkg) => {
        pkg.lensPackage.rxValues = getRxValues(
          locale,
          pkg.lensPackage.rxValues
        );
      });

      console.log(packages);
      setPackages(packages);
    };

    const setAllFramePowerRanges = (data) => {
      const moco = `${frameModel}__${frameColor}`;
      const currentFrame = data.find((frame) => frame.moco === moco);
      setFrameRxValues(getRxValues(locale, currentFrame.rxValues));
    };

    if (!config?.lensesData?.packages) {
      console.log("packages from akamai");
      getLensesDataPackages(URLS.packages, setLensesDataPackages);
      //getLensesDataPackagesMock(setLensesDataPackages)
    }
    if (!config?.lensesData?.prices) {
      if (
        config?.lensesData?.packages &&
        noPricesInPackages(config?.lensesData?.packages)
      ) {
        getLensesDataPrices(URLS.prices, setPrices);
      }
    }
    if (!config.data.frame?.rxValues) {
      getFrameRxValues(URLS.powers, setAllFramePowerRanges);
    }
  }, []);

  const mergeLensesDataPrices = (packages, prices) => {
    return packages
      .filter(({ lensPackage }) => {
        return prices.find(
          (p) => p.lensPackage.catEntryId === lensPackage.catEntryId
        );
      })
      .map(({ frame, lensPackage }) => {
        const pkg = prices.find(
          (p) => p.lensPackage.catEntryId === lensPackage.catEntryId
        );
        return {
          frame: {
            ...frame,
          },
          lensPackage: {
            ...lensPackage,
            ...pkg?.lensPackage,
          },
        };
      });
  };

  const mergeAkamaiPrices = (packages, prices) => {
    return packages
      .filter(({ lensPackage }) => {
        return prices.prices.find((p) => p.lensPackage.upc === lensPackage.upc);
      })
      .map(({ frame, lensPackage }) => {
        const pkg = prices.prices.find(
          (p) => p.lensPackage.upc === lensPackage.upc
        );
        return {
          frame: {
            ...frame,
          },
          lensPackage: {
            ...lensPackage,
            ...pkg?.lensPackage,
            offerPrice: pkg?.lensPackage.listPrice,
          },
        };
      });
  };

  const lensPackagesWithPrices = useMemo(() => {
    if (!packages) return null;
    if (!noPricesInPackages(packages)) {
      return packages;
    }
    if (prices) {
      console.log("no prices in lensesData.packages");
      if (isArray(prices)) {
        console.log("prices from lensesData.prices");
        return mergeLensesDataPrices(packages, prices);
      } else {
        console.log("prices from akamai");
        return mergeAkamaiPrices(packages, prices);
      }
    }
    return null;
  }, [packages, prices]);

  useEffect(() => {
    if (lensPackagesWithPrices && content && frameRxValues) {
      const newContent = content?.content;
      let newConfig;
      if (newContent) {
        newConfig = {
          ...config,
          data: {
            ...config.data,
            frame: {
              ...config.data.frame,
              rxValues: frameRxValues,
            },
          },
          lensesData: {
            ...config?.lensesData,
            packages: lensPackagesWithPrices,
            content: { ...newContent, badge: null },
          },
        };
      } else {
        newConfig = {
          ...config,
          data: {
            ...config.data,
            frame: {
              ...config.data.frame,
              rxValues: frameRxValues,
            },
          },
          lensesData: {
            ...config?.lensesData,
            packages: lensPackagesWithPrices,
            content: { ...content, badge: null },
          },
        };
      }
      console.log(newConfig);
      setNewConfig(newConfig);
      setIsLoading(false);
    }
  }, [lensPackagesWithPrices, content, frameRxValues]);

  const app = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      config: newConfig,
    });
  });

  return isLoading ? <p>loading...</p> : <>{app}</>;
}
