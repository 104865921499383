import { Adapter } from "../models/Adapter";
import { Parameter } from "../models/Parameter";

export interface LabelsParams {
  title: string;
  subtitle: string;
  confirmedTitle: string;
}

class LabelsParamsAdapters extends Adapter<LabelsParams, Parameter[]> {
  adapt: (source: LabelsParams) => Parameter[] = (source: LabelsParams) => {
    const title = new Parameter();
    title.key = "title";
    title.value = source.title;
    const subtitle = new Parameter();
    subtitle.key = "subtitle";
    subtitle.value = source.subtitle;
    const confirmedTitle = new Parameter();
    confirmedTitle.key = "confirmedTitle";
    confirmedTitle.value = source.confirmedTitle;

    return [title, subtitle, confirmedTitle];
  };
  adaptReverse: (source: Parameter[]) => LabelsParams = (
    source: Parameter[]
  ) => {
    const title = source.find((p) => p.key === "title");
    const subtitle = source.find((p) => p.key === "subtitle");
    const confirmedTitle = source.find((p) => p.key === "confirmedTitle");
    return {
      title: title?.value,
      subtitle: subtitle?.value,
      confirmedTitle: confirmedTitle?.value,
    };
  };
}

export const labelsParamsAdapters = new LabelsParamsAdapters();
