import { SliderObject } from "../../components/optionsSlider/component";
import { Adapter } from "../models/Adapter";

class ConfigObjectToSliderObject extends Adapter<
  Record<string, any>,
  SliderObject
> {
  adapt: (source: Record<string, any>) => SliderObject = (
    source: Record<string, any>
  ) => {
    let ret: SliderObject = null;
    if (source) {
      ret = {
        id: source.id,
        name: source.title,
      };
    }
    return ret;
  };
  adaptReverse: (source: SliderObject) => Record<string, any> = (
    source: SliderObject
  ) => {
    return null;
  };
}

export const configObjectToSliderObject = new ConfigObjectToSliderObject();
