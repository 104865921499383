export const getSimplePrescriptionValuesArray = (
  start: number,
  end: number,
  incrementValue: number,
  noValueSymbol?: string,
  toFixed?: number,
  values?: string[]
) => {
  let valuesArray: {
    name: string | number;
    value: string | number;
    default: boolean;
  }[] = [];
  if (noValueSymbol) {
    valuesArray.push({ name: noValueSymbol, value: "", default: true });
  }

  if (values) {
    //it's the non-numeric baseVert and baseHor fields
    values.map((val) => {
      valuesArray.push({ name: val, value: val, default: false });
    });
  } else {
    for (let i = start; i <= end; i += incrementValue) {
      valuesArray.push({
        name: toFixed ? Number(i).toFixed(toFixed) : i,
        value: i,
        default: false,
      });
    }
  }

  return valuesArray;
};
