import { Adapter } from "../models/Adapter";

class RecommendedUseArrayToString extends Adapter<
  Array<string | Array<string>>,
  Array<string>
> {
  adapt: (source: Array<string | any>) => Array<string> = (
    source: Array<string | any>
  ) => {
    let ret = new Set();
    if (source?.length) {
      source.map((x) => {
        if (typeof x === "string") {
          ret.add(x);
        } else {
          x.map((y) => {
            ret.add(y);
          });
        }
      });
      let arr: any = Array.from(ret.values());
      return arr;
    }
  };
  adaptReverse: (source: Array<string | any>) => Array<string> = (
    source: Array<string | any>
  ) => {
    return null;
  };
}

export const recommendedUseArrayToString = new RecommendedUseArrayToString();
