import React, { useRef, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";

export interface UploadTableReadOnlyProps {
  open: boolean;
}

const UploadTableReadOnly = (props: UploadTableReadOnlyProps) => {
  const reduxDispatch = useDispatch();

  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );

  const uploadFileDataPreview = useSelector(
    (state: any) => state.prescription?.uploadFileDataPreview
  );
  const uploadedFileName = useSelector(
    (state: any) => state.prescription?.currentPrescription?.fileName
  );
  const uploadDifferentLabel = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const filePreviewLabel = useTranslate(
    "steps.advancedPrescription.upload.filePreviewTitle"
  );

  const fileInputRef = useRef(null);

  const [newFileName, setNewFileName] = useState(null);


  const handleUploadDifferentFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    let configMaxSize = prescriptionModule?.maxFileSize
      ? prescriptionModule?.maxFileSize
      : 10;
    let maximumFileSize = configMaxSize * 1024 * 1024;
    if (file && file.size <= maximumFileSize) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const filePreviewData = reader.result as string;
        reduxDispatch(prescriptionActions.setUploadFileDataPreview(filePreviewData));
      };
      reader.readAsDataURL(file);
      setNewFileName(file.name);
    }
  };

  return (
    <div
      className={"UploadTableReadOnly__container"}
      style={
        props.open
          ? {
            transform: "scale(1)",
          }
          : {
            transform: "scale(0.00001)",
            height: "0px",
            margin: "0px",
            padding: "0px"
          }
      }
    >
      <div className={"UploadTableReadOnly__container__previewLabel"}>
        {filePreviewLabel}
      </div>
      <div className={"UploadTableReadOnly__container__fileInfo"}>
        <img
          src={uploadFileDataPreview}
          alt="document"
          className="UploadTableReadOnly__container__"
          style={{ cursor: "pointer", width: "75px", height: "90px" }}
          onClick={() => {
            reduxDispatch(
              modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
            );
          }}
        />
        <div className="" style={{ marginLeft: "19px" }}>
          <p className="UploadTableReadOnly__container__fileInfo__fileName">
            {newFileName ? newFileName : uploadedFileName}
          </p>
          <p className="UploadTableReadOnly__container__fileInfo__uploadDifferent" onClick={handleUploadDifferentFile}>
            {uploadDifferentLabel}
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default UploadTableReadOnly;
