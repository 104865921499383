import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useCurrentBrand() {
  const [currentBrand, setCurrentBrand] = useState(null);
  const brandConfig = useSelector((state: any) => state.config?.brand);

  useEffect(() => {
    if (brandConfig) {
      let brand = null;
      if (process.env.CURRENT_MODE === "standalone") {
        const urlBrand = window.localStorage?.getItem("brand");
        if (urlBrand) {
          brand = urlBrand;
        }
      } else {
        brand = brandConfig; // || CURRENT_BRAND;
      }

      if (!brand) {
        brand = "default";
      }
      setCurrentBrand(brand);
    }
  }, [brandConfig]);

  return currentBrand;
}
