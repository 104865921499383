import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StepCardProps } from "../../components/StepCard/component";
import { useTranslation } from "../translations/TranslationHooks";
import { useGPVPrices } from "./usePriceStepItem";

export function useGVPOptions() {
  const options = useSelector((state: any) => state.config.treatmentsOptions);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  ); // Per le etichette
  const getPrice = useGPVPrices(); // Calcolo Prezzo
  const translate = useTranslation(); // Traduzioni

  const [ret, setRet] = useState(null); // Risultato finale da restituire con l'Hook GVP Options

  const getOptP = (
    opt: string,
    treatmentName: string,
    currentPackages,
    disabled?: boolean
  ) => {
    //'antiReflective','blueLight','antiScratch','uvProtection'
    let ret: StepCardProps = {
      id: opt + "$" + treatmentName,
      title: treatmentName,
    };
    let priceObj = null;

    if (
      configContent["treatment"] &&
      configContent["treatment"][treatmentName]
    ) {
      priceObj = disabled
        ? null
        : getPrice({ ["treatment"]: treatmentName }, currentPackages);
      ret.title = configContent["treatment"][treatmentName]?.title;
      ret.description = configContent["treatment"][treatmentName]?.description;
      ret.imageUrl = configContent["treatment"][treatmentName]?.imageFileName;
      ret.sequence = configContent["treatment"][treatmentName]?.sequence;
    } else {
      priceObj = disabled ? null : getPrice({ [opt]: true }, currentPackages);
      ret.title = translate(
        "steps.treatments.extraTreatments." + opt + ".title"
      );
      ret.description = translate(
        "steps.treatments.extraTreatments." + opt + ".description"
      );
      ret.imageUrl = translate(
        "steps.treatments.extraTreatments." + opt + ".imageFileName"
      );
    }
    if (priceObj) {
      ret.price = priceObj.price;
      ret.discount = priceObj.discount;
      ret.insPrice = priceObj.insPrice;
    }

    if (disabled) {
      ret.disabled = true;
    }
    return ret;
  };

  const getTreatments = (currentPackages: {
    validRXPackages: any[];
    invalidRXPackages: any[];
  }) => {
    let treatments: StepCardProps[] = [];
    let currentPackagesFilteredByGroup = currentPackages.validRXPackages.filter(
      (cp) => cp.lensPackage.treatmentGroup
    );
    let greyOutCurrentPackagesFilteredByGroup =
      currentPackages.invalidRXPackages.filter(
        (cp) => cp.lensPackage.treatmentGroup
      );
    let currentPackagesFilteredByCategory =
      currentPackages.validRXPackages.filter(
        (cp) => cp.lensPackage.treatmentCategory
      );
    let greyOutCurrentPackagesFilteredByCategory =
      currentPackages.invalidRXPackages.filter(
        (cp) => cp.lensPackage.treatmentCategory
      );

    const treatmentsGroups = {
      ...groupBy(currentPackagesFilteredByGroup, "lensPackage.treatmentGroup"),
      ...groupBy(
        currentPackagesFilteredByCategory,
        "lensPackage.treatmentCategory"
      ),
    };

    const greyOutTreatmentsGroups = {
      ...groupBy(
        greyOutCurrentPackagesFilteredByGroup,
        "lensPackage.treatmentGroup"
      ),
      ...groupBy(
        greyOutCurrentPackagesFilteredByCategory,
        "lensPackage.treatmentCategory"
      ),
    };

    let mappedGroups = [];
    let disabledGroups = [];

    const treatmentsGroupsKeys = Object.keys(treatmentsGroups);
    const greyOutTreatmentsGroupsKeys = Object.keys(
      greyOutTreatmentsGroups
    ).filter((gotg) => !treatmentsGroupsKeys.find((tg) => tg === gotg));

    treatmentsGroupsKeys.forEach((key) => {
      const treatment = treatmentsGroups[key];
      const availableTreatments = groupBy(treatment, "lensPackage.treatment");
      const at_keys = Object.keys(availableTreatments);
      if (at_keys.length <= 0) {
        //not found
      } else {
        mappedGroups.push(key);
        //avaiable and included
        at_keys.forEach((at) => {
          treatments.push(getOptP(key, at, currentPackages.validRXPackages));
        });
      }
    });

    greyOutTreatmentsGroupsKeys.forEach((key) => {
      const treatment = greyOutTreatmentsGroups[key];
      const availableTreatments = groupBy(treatment, "lensPackage.treatment");
      const at_keys = Object.keys(availableTreatments);
      if (at_keys.length <= 0) {
        //not found
      } else {
        disabledGroups.push(key);
        //avaiable and included
        at_keys.forEach((at) => {
          treatments.push(
            getOptP(key, at, currentPackages.invalidRXPackages, true)
          );
        });
      }
    });

    return {
      groups: mappedGroups,
      disabledGroups: disabledGroups,
      options: treatments,
    };
  };

  useEffect(() => {
    setRet(
      () =>
        (currenPackages: {
          validRXPackages: any[];
          invalidRXPackages: any[];
        }) =>
          getTreatments(currenPackages)
    );
  }, [options, configContent, translate]);

  return ret;
}
