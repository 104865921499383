import { useMemo } from "react";
import { getStepBadgeType } from "../ConfigLoader";
import { useAllCurrentPackages } from "./useAllCurrentPackages";
import { useConfigContent } from "./useConfigContent";
import { useMoreInfo } from "./useMoreInfo";
import { usePriceStepItem } from "./usePriceStepItem";
import { sortingGreyOut } from "../ConfigLoader";

export function useBrands() {
  const configBrand: any[] = useConfigContent("Brand");
  const mapperMoreInfo = useMoreInfo("brand");
  const currentPackages = useAllCurrentPackages();
  const getPriceStep = usePriceStepItem();

  const brands = useMemo(() => {
    if (currentPackages && configBrand && mapperMoreInfo) {
      let brandsT = configBrand.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.brand === c.id
        )
      );
      let brandsGreyOut = configBrand
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.brand === c.id
          )
        )
        .map((brand) => {
          return {
            ...brand,
            disabled: true,
          };
        })
        .filter((brand) => !brandsT.find((b) => b.id === brand.id));

      return (
        mapperMoreInfo(brandsT)
          .concat(brandsGreyOut)
          //sorting greyout
          .sort((a, b) =>
            a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
          )
          .map((brand) => {
            const priceObj = brand.disabled
              ? null
              : getPriceStep({ brand: brand.id });
            return {
              ...brand,
              ...priceObj,
              textBadge: brand.badge,
              imageUrl: brand.imageFileName,
              badge: brand.badge ? getStepBadgeType(brand.badge) : null,
              disabled: brand.disabled || brand.badge == "coming soon",
              /* onClick:
              brand.badge == "coming soon" || brand.disabled
                ? null
                : () => onBrandSelected(brand.id, false),
            discountLoading: isLoading(brand.id), */
            };
          })
      );
    }
  }, [configBrand, currentPackages, mapperMoreInfo]);

  return { brands: brands || [] };
}
