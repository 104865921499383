import React, { useEffect } from "react";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import {
  useTranslate,
  useTranslation,
} from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";

interface VideoFrameOpt {
  onStart: () => void;
  onPause: () => void;
  onPlay: () => void;
  onExit: () => void;
  onMadeButtonClick: () => void;
  id: string;
  videoIndex: number;
  hasMadeButton?: boolean;
  hasTap?: boolean;
  videoClassName?: string;
  cardId?: string;
  forceShowPauseButton?: any;
  forceShowPlayButton?: any;
}

export function VideoFrame({
  onStart,
  onPause,
  onPlay,
  onExit,
  id,
  videoIndex,
  videoClassName,
  hasMadeButton,
  hasTap,
  onMadeButtonClick,
  cardId,
  forceShowPauseButton,
  forceShowPlayButton,
}: VideoFrameOpt) {
  const madeLabel = useTranslate("learnMoreMade");
  const treatmentLable = useTranslate("learnMoreTreatment");

  useEffect(() => {
    if (hasTap) {
      showPauseButton();
      animateProgressBar();
    }
  }, []);

  useEffect(() => {
    if (forceShowPauseButton) {
      showPauseButton();
      animateProgressBar();
    }
  }, [forceShowPauseButton]);

  useEffect(() => {
    if (forceShowPlayButton) {
      showPlayButton();
    }
  }, [forceShowPlayButton]);

  const handlePause = () => {
    if (onPause) {
      onPause();
    }
    showPlayButton();
  };

  const handlePlay = () => {
    if (onPlay) {
      onPlay();
    }
    showPauseButton();
    animateProgressBar();
  };

  const handleExit = () => {
    if (onExit) {
      onExit();
    }
  };

  const handleMadeButtonClick = () => {
    if (onMadeButtonClick) {
      if (!hasTap && videoIndex !== null) {
        handlePause();
      }
      onMadeButtonClick();
    }
  };

  const showPlayButton = () => {
    document
      .getElementById("videoFrameButtonPlay" + id)
      .classList.remove("invisible");
    document
      .getElementById("videoFrameButtonPause" + id)
      .classList.add("invisible");
  };

  const showPauseButton = () => {
    document
      .getElementById("videoFrameButtonPlay" + id)
      .classList.add("invisible");
    document
      .getElementById("videoFrameButtonPause" + id)
      .classList.remove("invisible");
  };

  const animateProgressBar = () => {
    let progressBar = document.getElementById("videoFrameElapsed" + id);
    let video = document.querySelector("#" + cardId + " video");
    setTimeout(() => {
      if (!video || !progressBar) {
        return;
      }
      //@ts-ignore
      let percentage = (video.currentTime / video.duration) * 100;
      progressBar.style.width = percentage + "%";
      progressBar.style.transition = "width 0.1s linear";
      //@ts-ignore
      if (!video.ended && percentage < 100) {
        animateProgressBar();
      } else {
        showPlayButton();
      }
    }, 100);
  };

  const getDataElementIdForClose = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreClose";
  };

  const getDataElementIdForMadeButton = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreSee";
  };

  return (
    <div
      className={
        "VideoFrame__container" +
        (videoClassName.indexOf("invisible") >= 0 ? " invisible" : "")
      }
      id={id}
    >
      <a
        className={"VideoFrame__buttonExit"}
        onClick={handleExit}
        data-element-id={getDataElementIdForClose()}
      ></a>
      {videoIndex !== null && (
        <div className={"VideoFrame__controls"}>
          <div
            id={"videoFrameButtonPlay" + id}
            className={"VideoFrame__buttonPlay"}
            onClick={handlePlay}
          ></div>
          <div
            id={"videoFrameButtonPause" + id}
            className={"VideoFrame__buttonPause invisible"}
            onClick={handlePause}
          ></div>
          <div
            className={"VideoFrame__progressBar" + (hasTap ? " invisible" : "")}
          >
            <div
              id={"videoFrameElapsed" + id}
              className={
                "VideoFrame__progressBar__elapsed" +
                (hasTap ? " invisible" : "")
              }
            ></div>
          </div>
        </div>
      )}
      {hasMadeButton && (
        <button
          type="button"
          className="VideoFrame__buttonMade"
          onClick={handleMadeButtonClick}
          data-element-id={getDataElementIdForMadeButton()}
        >
          {videoClassName.indexOf("made") >= 0 ? madeLabel : treatmentLable}
        </button>
      )}
    </div>
  );
}
