import { AdvancedManualPrescription } from "../../types";
import { Adapter } from "../models/Adapter";

class AdvancedManualPrescriptionAdapter extends Adapter<
  Record<string, any>,
  AdvancedManualPrescription
> {
  adapt: (source: Record<string, any>) => AdvancedManualPrescription = (
    source: Record<string, any>
  ) => {
    let advancedManualPrescriptionObject: AdvancedManualPrescription = {
      ...source,
      SPH: {
        OD: source.SPH_OD,
        OS: source.SPH_OS,
      },
      CYL: {
        OD: source.CYL_OD,
        OS: source.CYL_OS,
      },
      AX:
        !source.AX_OD && !source.AX_OS
          ? null
          : {
            OD: source.AX_OD === 0 ? null : source.AX_OD,
            OS: source.AX_OS === 0 ? null : source.AX_OS,
          },
      ADD:
        source.ADD_OD === null ||
          source.ADD_OD === undefined ||
          source.ADD_OD === null ||
          source.ADD_OS === undefined
          ? null
          : {
            OD: source.ADD_OD,
            OS: source.ADD_OS,
          },
      PD: {
        OD: source.PD_OD,
        OS: source.PD_OS,
      },
      PRISM_ENABLED: source.PRISM_ENABLED,
      VPRISM: {
        OD: source.VPRISM_OD,
        OS: source.VPRISM_OS,
      },
      VBASEDIR: {
        OD: source.VBASEDIR_OD,
        OS: source.VBASEDIR_OS,
      },
      HPRISM: {
        OD: source.HPRISM_OD,
        OS: source.HPRISM_OS,
      },
      HBASEDIR: {
        OD: source.HBASEDIR_OD,
        OS: source.HBASEDIR_OS,
      },
      COMMENT: source.COMMENT,
      prescriptionFlow: source.prescriptionFlow,
      prescriptionId: source.prescriptionId,
      rxType: source.rxType,
      /* 
      myAccount: source.myAccount,
      name: source.name,
      lastUpdate: source.lastUpdate, */
      POWER: source.POWER
    };

    return advancedManualPrescriptionObject;
  };

  adaptReverse: (source: AdvancedManualPrescription) => Record<string, any> = (
    source: AdvancedManualPrescription
  ) => {
    return null;
  };
}

export const advancedManualPrescriptionAdapter =
  new AdvancedManualPrescriptionAdapter();
