import React, { useEffect, useState } from "react";
import "./default.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { modalsActions } from "..//..//redux/slices/modals";
import { prescriptionActions } from "..//..//redux/slices/prescription";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PhoneInput from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

//ciao

export function AddMyDoctorModal(props) {
  const reduxDispatch = useDispatch();
  const [name, setName] = useState(null);
  const [tel, setTel] = useState(null);
  const [isPhoneFocus, setIsPhoneFocus] = useState(false);

  const [errors, setErrors] = useState({ tel: false, name: false });
  const [validTick, setValidTick] = useState({ tel: false, name: false });
  const [showMissingValueError, setShowMissingValueError] = useState(false);

  const title = useTranslate(
    "steps.advancedPrescription.doctor.addNewDoctorModal.title"
  );
  const clinicOrDoctorName = useTranslate(
    "steps.advancedPrescription.doctor.addNewDoctorModal.clinicOrDoctorName"
  );
  const phoneNumber = useTranslate(
    "steps.advancedPrescription.doctor.addNewDoctorModal.phoneNumber"
  );
  const addDoctor = useTranslate(
    "steps.advancedPrescription.doctor.addNewDoctorModal.addDoctor"
  );
  const missingInfoError = useTranslate(
    "steps.advancedPrescription.doctor.missingInfoError"
  );

  useEffect(() => {
    let errorsToSet = {};
    if (tel && errors.tel) {
      errorsToSet["tel"] = false;
    }
    if (name && errors.name) {
      errorsToSet["name"] = false;
    }

    if (Object.keys(errorsToSet).length > 0) {
      setErrors((prevState) => ({
        ...prevState,
        ...errorsToSet,
      }));

      if (
        (errorsToSet["tel"] === false || !errors.tel) &&
        (errorsToSet["name"] === false || !errors.name)
      ) {
        setShowMissingValueError(false);
      }
    }
  }, [tel, name]);

  const validateInput = () => {
    if (
      tel &&
      tel.toString().trim() !== "" &&
      name &&
      name.toString().trim() !== ""
    ) {
      setShowMissingValueError(false);
      return true;
    } else {
      let errorsToSet = { tel: false, name: false };
      if (!tel || tel.toString().trim() === "") {
        errorsToSet["tel"] = true;
      }
      if (!name || name.toString().trim() === "") {
        errorsToSet["name"] = true;
      }
      setErrors((prevState) => ({
        ...prevState,
        ...errorsToSet,
      }));
      setShowMissingValueError(true);
      return false;
    }
  };

  const handleAddClick = () => {
    if (validateInput()) {
      reduxDispatch(modalsActions.setShowAddMyDoctorModal(false));
      reduxDispatch(
        prescriptionActions.setCallMyDoctorParams({
          name: name,
          phone: formatPhoneNumberIntl(tel),
        })
      );
    }
  };

  const checkIfValidAndShowTick = (fieldName) => {
    let map = {
      tel: {
        val: tel,
        setter: setTel,
      },
      name: {
        val: name,
        setter: setName,
      },
    };

    if (map[fieldName].val && map[fieldName].val.toString().trim() !== "") {
      setValidTick((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  };

  return (
    <div className="AddMyDoctorModal">
      <div className={"AddMyDoctorModal__container__overlay"}></div>
      <div className={"AddMyDoctorModal__container__popup"}>
        <a
          className={"AddMyDoctorModal__container__exit"}
          onClick={() =>
            reduxDispatch(modalsActions.setShowAddMyDoctorModal(false))
          }
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z"
              fill="#222222"
              stroke="#222222"
              strokeWidth="0.75"
            />
          </svg>
        </a>
        <div className={"AddMyDoctorModal__container__title"}>{title}</div>
        <div className={"AddMyDoctorModal__container__inputContainer"}>
          <label className={validTick.name ? "input valid" : "input"}>
            <input
              className={
                validTick.name
                  ? "input__field green"
                  : !errors.name
                    ? "input__field"
                    : "input__field error"
              }
              type="text"
              placeholder=" "
              onChange={(e) => setName(e.target.value)}
              onFocus={() =>
                setValidTick((prevState) => ({ ...prevState, name: false }))
              }
              onBlur={() => checkIfValidAndShowTick("name")}
            />
            <span className="input__label">{clinicOrDoctorName}*</span>
          </label>
          <div
            className={
              validTick.tel
                ? "SearchBy_numberContainerGreen"
                : !errors.tel
                  ? "SearchBy_numberContainer"
                  : "SearchBy_numberContainerError"
            }
          >
            <PhoneInput
              defaultCountry="US"
              value={tel}
              type="text"
              placeholder=" "
              autocomplete={false}
              onChange={setTel}
              onFocus={(e) => {
                setIsPhoneFocus(true);
                setValidTick((prevState) => ({
                  ...prevState,
                  tel: false,
                }));
              }}
              onBlur={(e) => {
                if (!tel) {
                  setIsPhoneFocus(false);
                } else {
                  checkIfValidAndShowTick("tel");
                }
              }}
              numberInputProps={{ maxLength: 14 }}
            />
            <span
              className={
                isPhoneFocus
                  ? "PhoneInputInput__labelFloat"
                  : "PhoneInputInput__label"
              }
            >
              {phoneNumber}*
            </span>
          </div>
        </div>
        {showMissingValueError && (
          <div className="AddMyDoctorModal__container__missingValueError">
            {missingInfoError}
          </div>
        )}
        <div className={"AddMyDoctorModal__container__buttonsContainer"}>
          <button
            className="AddMyDoctorModal__container__buttonsContainer__buttonExit"
            onClick={() => handleAddClick()}
          >
            {addDoctor}
          </button>
        </div>
      </div>
    </div>
  );
}
