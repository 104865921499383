import React from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";

export const IncompatibleLenses = () => {
  const incompatibleLensesLabel = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLenses"
  );

  return (
    <div className="IncompatibleLenses">
      <div
        className={"IncompatibleLenses__text"}
        dangerouslySetInnerHTML={{ __html: incompatibleLensesLabel }}
      ></div>
    </div>
  );
};
