import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";

export interface filterPillsProps {
  pills: any;
  callback?: (prop) => void;
  selected?: any;
}

/**
 * Component that displays the title and subtitle of a step. It accepts an input
 * of type ```filterPillsProps``` with the following parameters:
 *  - ```pills```: an array of strings to display as filterpills
 *  - ```callback``` self explanatory
 */
export function filterPills({ pills, callback, selected }: filterPillsProps) {
  const [valueSelected, setValueSelected] = useState(selected);
  const colorSelected = useSelector((state: any) => state.color?.colorSelected);

  useEffect(() => {
    setValueSelected(selected);
  }, [selected]);

  const buttonColorChangeAndCallback = (prop) => {
    /* if (ref.current.scrollLeft >= maxScrollLeft - 40) {setFadeVisibilityRight(false)} */
    callback(prop);
  };

  /* FilterPillsFade */

  const ref = useRef(null);
  const [fadeVisibilityRight, setFadeVisibilityRight] = useState(false);
  const [scrollButtonVisibilityRight, setScrollButtonVisibilityRight] =
    useState(false);
  const [fadeVisibilityLeft, setFadeVisibilityLeft] = useState(false);
  const [scrollButtonVisibilityLeft, setScrollButtonVisibilityLeft] =
    useState(false);
  const counter = pills?.length;
  let divHasOverflow = document.getElementById(
    "ScrollbarHorizontalContainer" + counter
  );

  const scrollHorizontalRight = (scrollOffset) => {
    let maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
    if (ref.current.scrollLeft >= maxScrollLeft - 40) {
      setFadeVisibilityRight(false);
    }
    if (ref.current.scrollLeft < maxScrollLeft) {
      setFadeVisibilityRight(true);
    }
    if (ref.current.scrollLeft > 0) {
      setFadeVisibilityLeft(true);
      setScrollButtonVisibilityLeft(true);
    }
    ref.current.scrollLeft += scrollOffset;
  };

  const scrollHorizontalLeft = (scrollOffset) => {
    if (ref.current.scrollLeft > 0) {
      setFadeVisibilityLeft(true);
    }
    if (ref.current.scrollLeft <= 0) {
      setFadeVisibilityLeft(false);
      setScrollButtonVisibilityLeft(false);
    }
    ref.current.scrollLeft += scrollOffset;
  };

  const checkCurrentScrollHorizontalPostion = () => {
    let maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
    if (ref.current.scrollLeft < maxScrollLeft - 40) {
      setFadeVisibilityRight(true);
    }
    if (ref.current.scrollLeft >= maxScrollLeft) {
      setFadeVisibilityRight(false);
      setScrollButtonVisibilityRight(false);
    }
    if (ref.current.scrollLeft <= 0) {
      setFadeVisibilityLeft(false);
      setScrollButtonVisibilityLeft(false);
    }
    if (ref.current.scrollLeft > 0 && ref.current.scrollLeft < maxScrollLeft) {
      setFadeVisibilityLeft(true);
      setFadeVisibilityRight(true);
      setScrollButtonVisibilityLeft(true);
      setScrollButtonVisibilityRight(true);
    }
  };
  const checkIfHasScrollHorizontaRight = () => {
    divHasOverflow = document.getElementById(
      "ScrollbarHorizontalContainer" + counter
    );
    if (divHasOverflow) {
      let maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      if (
        divHasOverflow.scrollWidth > divHasOverflow.clientWidth &&
        ref.current.scrollLeft < maxScrollLeft
      ) {
        setFadeVisibilityRight(true);
        setScrollButtonVisibilityRight(true);
      }
      if (ref.current.scrollLeft > 0) {
        setFadeVisibilityLeft(true);
      } else if (ref.current.scrollLeft === 0) {
        setFadeVisibilityLeft(false);
      }
    }
  };

  useEffect(() => {
    divHasOverflow = document.getElementById(
      "ScrollbarHorizontalContainer" + counter
    );
    if (divHasOverflow) {
      checkIfHasScrollHorizontaRight();
    }
  }, [divHasOverflow, colorSelected, ref?.current?.scrollLeft, pills]);

  return (
    <div className={"filterPills__container"}>
      <div className={"filterPills__fadecontainer"}>
        {fadeVisibilityLeft && (
          <div
            className={
              "filterPills__fadecontainer__filtersContainer__fade__left"
            }
          />
        )}
        {scrollButtonVisibilityLeft && (
          <button
            id="Scroll-Button"
            onClick={() => scrollHorizontalLeft(-35)}
            className={
              "filterPills__fadecontainer__filtersContainer__scrollButton__left"
            }
            disabled={false}
          />
        )}
        {scrollButtonVisibilityLeft && (
          <div
            className={
              "filterPills__fadecontainer__filtersContainer__separatorvertical__left"
            }
          />
        )}
        {pills && pills?.length > 0 && (
          <div
            className={"filterPills__fadecontainer__filtersContainer"}
            key={counter}
            id={"ScrollbarHorizontalContainer" + counter}
            ref={ref}
            onScroll={() => checkCurrentScrollHorizontalPostion()}
          >
            {pills.map((value) => {
              return (
                <button
                  key={value.id}
                  className={
                    "filterPills__filterPill" +
                    (valueSelected == value.id ? " selected" : "")
                  }
                  onClick={() => buttonColorChangeAndCallback(value.id)}
                >
                  {value.name}
                </button>
              );
            })}
          </div>
        )}
        {fadeVisibilityRight && (
          <div
            className={
              "filterPills__fadecontainer__filtersContainer__fade__right"
            }
          />
        )}
        {scrollButtonVisibilityRight && (
          <div
            className={
              "filterPills__fadecontainer__filtersContainer__separatorvertical__right"
            }
          />
        )}
        {scrollButtonVisibilityRight && (
          <button
            id="Scroll-Button"
            onClick={() => scrollHorizontalRight(35)}
            className={
              "filterPills__fadecontainer__filtersContainer__scrollButton__right"
            }
            disabled={false}
          />
        )}
      </div>
    </div>
  );
}
