import { useCurrentBrand } from "@hooks";
import React, { useMemo } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { CustomCheckbox } from "./CustomCheckbox";
import { SimpleTextError } from "./SimpleTextError";
import { isMobile, isDesktop } from "react-device-detect";

export const AgreementCheckbox = (props) => {
  const agreementText = useTranslate(
    "steps.advancedPrescription.manual.alerts.agreementText"
  );
  const agreementTextUpload = useTranslate(
    "steps.advancedPrescription.upload.alerts.agreementText"
  );
  const agreementTextMobile = useTranslate(
    "steps.advancedPrescription.manual.alerts.agreementTextMobile"
  );
  const requiredToProceed = useTranslate(
    "steps.advancedPrescription.manual.alerts.requiredToProceed"
  );
  const currentBrand = useCurrentBrand();

  return (
    <>
      <div className={"AgreementCheckbox__container"} style={{ marginBottom: isDesktop && props.formErrors["agreement"] ? currentBrand === "glasses" ? "" : currentBrand === "costa" || currentBrand === "opsm" ? "14px" : "0px" : "" }}>
        <CustomCheckbox
          value={props.agreement}
          setter={props.setAgreement}
          formErrorName={"agreement"}
          formErrors={props.formErrors}
          setFormErrors={props.setFormErrors}
          disabled={props.disabled}
          marginTop={true}
        />
        <label
          className={`AgreementCheckbox__text ${props.disabled ? "disabled" : ""
            }`}
        >
          {!isMobile && !props.uploadPrescription ? (
            <div style={{ display: "inline" }}>
              <p
                dangerouslySetInnerHTML={{ __html: agreementText }}
                style={{ margin: "0px", padding: "0px", display: "inline" }}
                onClick={() =>
                  !props.disabled && props.setAgreement(!props.agreement)
                }
              />
            </div>
          ) : !isMobile && props.uploadPrescription ? (
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: agreementTextUpload }}
            />
          ) : currentBrand === "opsm" || props.uploadPrescription ? (
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: agreementTextMobile }}
            />
          ) : (
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: agreementText }}
            />
          )}
          <div className="AgreementCheckbox__tooltip" tabIndex={0}>
            <div className="AgreementCheckbox__tooltip__body">
              {props.tooltipText}
            </div>
          </div>
        </label>
      </div >
      {
        props.formErrors["agreement"] ? (
          <SimpleTextError message={requiredToProceed} />
        ) : null
      }
    </>
  );
};
