import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../translations/TranslationHooks";

export function useFullPrescriptionForm(prescriptionObject) {
  const [sphOD, setSphOD] = useState(0);
  const [sphOS, setSphOS] = useState(0);
  const [cylOD, setCylOD] = useState(0);
  const [cylOS, setCylOS] = useState(0);
  const [axisOD, setAxisOD] = useState(null);
  const [axisOS, setAxisOS] = useState(null);
  const [addOD, setAddOD] = useState(null);
  const [addOS, setAddOS] = useState(null);
  const [vertOD, setVertOD] = useState(null);
  const [vertOS, setVertOS] = useState(null);
  const [baseVertOD, setBaseVertOD] = useState(null);
  const [baseVertOS, setBaseVertOS] = useState(null);
  const [horOD, setHorOD] = useState(null);
  const [horOS, setHorOS] = useState(null);
  const [baseHorOD, setBaseHorOD] = useState(null);
  const [baseHorOS, setBaseHorOS] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const sphereName = useTranslate("steps.advancedPrescription.manual.sphere");
  const cylinderName = useTranslate("steps.advancedPrescription.manual.cylinder");
  const axisName = useTranslate("steps.advancedPrescription.manual.axis");
  const addName = useTranslate("steps.advancedPrescription.manual.add");
  const selectPlaceholder = useTranslate(
    "steps.advancedPrescription.manual.addPlaceholder"
  );
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );

  const prescriptionTableColumns = [
    {
      id: "sph",
      type: "sph",
      placeholder: "Sphere SPH",
      required: true,
      name: sphereName,
      min: prescriptionDefaults.sphMin,
      max: prescriptionDefaults.sphMax,
      res: prescriptionDefaults.sphRes,
      rows: [
        {
          id: "right_eye_sph",
        },
        {
          id: "left_eye_sph",
        },
      ],
    },
    {
      id: "cyl",
      type: "cyl",
      placeholder: "Cylinder CYL",
      required: true,
      name: cylinderName,
      min: prescriptionDefaults.cylMin,
      max: prescriptionDefaults.cylMax,
      res: prescriptionDefaults.cylRes,
      rows: [
        {
          id: "right_eye_cyl",
        },
        {
          id: "left_eye_cyl",
        },
      ],
    },
    {
      id: "axis",
      type: "axis",
      placeholder: "",
      required: true,
      name: axisName,
      rows: [
        {
          id: "right_eye_axis",
        },
        {
          id: "left_eye_axis",
        },
      ],
    },
    {
      id: "add",
      type: "add",
      placeholder: selectPlaceholder,
      required: true,
      name: addName,
      min: prescriptionDefaults.addMin,
      max: prescriptionDefaults.addMax,
      res: prescriptionDefaults.addRes,
      rows: [
        {
          id: "right_eye_add",
        },
        {
          id: "left_eye_add",
        },
      ],
    },
  ];

  const formSetter = (setter) => (val) => {
    setter(val);
  };

  const isAddDisabled = useCallback(() => {
    const isReading = prescriptionObject.rxType === "READING";
    if (isReading) return false;
    if (prescriptionObject.rxType !== "READING" && prescriptionObject.rxType !== "PROGRESSIVE" && prescriptionObject.rxType !== "BIFOCAL") {
      return true;
    }
    return false;
  }, [prescriptionObject]);

  const formValues = {
    prescription: {
      right_eye_sph: {
        value: sphOD,
        setter: formSetter(setSphOD),
        hasError: formErrors["right_eye_sph"],
      },
      left_eye_sph: {
        value: sphOS,
        setter: formSetter(setSphOS),
        hasError: formErrors["left_eye_sph"],
      },
      right_eye_cyl: {
        value: cylOD,
        setter: formSetter(setCylOD),
        hasError: formErrors["right_eye_cyl"],
      },
      left_eye_cyl: {
        value: cylOS,
        setter: formSetter(setCylOS),
        hasError: formErrors["left_eye_cyl"],
      },
      right_eye_axis: {
        value: axisOD,
        setter: formSetter(setAxisOD),
        hasError: formErrors["right_eye_axis"],
      },
      left_eye_axis: {
        value: axisOS,
        setter: formSetter(setAxisOS),
        hasError: formErrors["left_eye_axis"],
      },
      right_eye_add: {
        value: addOD,
        setter: formSetter(setAddOD),
        hasError: formErrors["right_eye_add"],
      },
      left_eye_add: {
        value: addOS,
        setter: formSetter(setAddOS),
        hasError: formErrors["left_eye_add"],
      },
    },
    prism: {
      right_eye_vertical: {
        value: vertOD,
        setter: formSetter(setVertOD),
        hasError: formErrors["right_eye_vertical"],
      },
      left_eye_vertical: {
        value: vertOS,
        setter: formSetter(setVertOS),
        hasError: formErrors["left_eye_vertical"],
      },
      right_eye_baseDirVert: {
        value: baseVertOD,
        setter: formSetter(setBaseVertOD),
        hasError: formErrors["right_eye_baseDirVert"],
      },
      left_eye_baseDirVert: {
        value: baseVertOS,
        setter: formSetter(setBaseVertOS),
        hasError: formErrors["left_eye_baseDirVert"],
      },
      right_eye_horizontal: {
        value: horOD,
        setter: formSetter(setHorOD),
        hasError: formErrors["right_eye_horizontal"],
      },
      left_eye_horizontal: {
        value: horOS,
        setter: formSetter(setHorOS),
        hasError: formErrors["left_eye_horizontal"],
      },
      right_eye_baseDirHor: {
        value: baseHorOD,
        setter: formSetter(setBaseHorOD),
        hasError: formErrors["right_eye_baseDirHor"],
      },
      left_eye_baseDirHor: {
        value: baseHorOS,
        setter: formSetter(setBaseHorOS),
        hasError: formErrors["left_eye_baseDirHor"],
      },
    },
  };

  useEffect(() => {
    if (cylOD === 0) {
      setAxisOD(null);
    }
    if (cylOS === 0) {
      setAxisOS(null);
    }
  }, [cylOD, cylOS]);
  //Initialize prescription state with props


  useEffect(() => {
    if (prescriptionObject) {

      let prescObj = prescriptionObject;
      if (prescObj.SPH) {
        if (prescObj.SPH.OD) {
          setSphOD(prescObj.SPH.OD);
        }
        if (prescObj.SPH.OS) {
          setSphOS(prescObj.SPH.OS);
        }
      }
      if (prescObj.CYL) {
        if (prescObj.CYL.OD) {
          setCylOD(prescObj.CYL.OD);
        }
        if (prescObj.CYL.OS) {
          setCylOS(prescObj.CYL.OS);
        }
      }
      if (prescObj.AX) {
        if (prescObj.AX.OD) {
          setAxisOD(parseInt(prescObj.AX.OD));
        }
        if (prescObj.AX.OS) {
          setAxisOS(parseInt(prescObj.AX.OS));
        }
      }
      if (prescObj.ADD) {
        if (prescObj.ADD.OD !== null && prescObj.ADD.OD !== undefined) {
          setAddOD(parseFloat(prescObj.ADD.OD));
        }
        if (prescObj.ADD.OS !== null && prescObj.ADD.OS !== undefined) {
          setAddOS(parseFloat(prescObj.ADD.OS));
        }
      }
      let isPrismEnabled =
        typeof prescObj.PRISM_ENABLED === "string"
          ? prescObj.PRISM_ENABLED.toLowerCase() === "true"
          : prescObj.PRISM_ENABLED;
      if (isPrismEnabled) {
        //toggleMoreOptionsOpen();
        if (prescObj.HPRISM) {
          if (prescObj.HPRISM.OD) {
            setHorOD(parseFloat(prescObj.HPRISM.OD));
          }
          if (prescObj.HPRISM.OS) {
            setHorOS(parseFloat(prescObj.HPRISM.OS));
          }
        }
        if (prescObj.HBASEDIR) {
          if (prescObj.HBASEDIR.OD) {
            setBaseHorOD(prescObj.HBASEDIR.OD);
          }
          if (prescObj.HBASEDIR.OS) {
            setBaseHorOS(prescObj.HBASEDIR.OS);
          }
        }
        if (prescObj.VPRISM) {
          if (prescObj.VPRISM.OD) {
            setVertOD(parseFloat(prescObj.VPRISM.OD));
          }
          if (prescObj.VPRISM.OS) {
            setVertOS(parseFloat(prescObj.VPRISM.OS));
          }
        }
        if (prescObj.VBASEDIR) {
          if (prescObj.VBASEDIR.OD) {
            setBaseVertOD(prescObj.VBASEDIR.OD);
          }
          if (prescObj.VBASEDIR.OS) {
            setBaseVertOS(prescObj.VBASEDIR.OS);
          }
        }

        //setShowPrismValuesForm(true);
      }

    }
  }, [prescriptionObject]);

  // same prescription for both eyes
  useEffect(() => {
    if (prescriptionObject?.samePrescriptionBothEyes) {
      setSphOS(sphOD)
      setCylOS(cylOD)
      setAxisOS(axisOD)
      setAddOS(addOD)
      setVertOS(vertOD)
      setBaseVertOS(baseVertOD)
      setHorOS(horOD)
      setBaseHorOS(baseHorOD)
    }
  }, [
    sphOS,
    sphOD,
    cylOD,
    cylOS,
    axisOD,
    axisOS,
    addOD,
    addOS,
    vertOD,
    vertOS,
    baseVertOD,
    baseVertOS,
    horOD,
    horOS,
    baseHorOD,
    baseHorOS,
    prescriptionObject
  ])

  return {
    prescriptionTableColumns,
    formValues,
    formErrors,
    isAddDisabled,
    selectPlaceholder
  }
}
