import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { configActions } from "../../redux/slices/config";
import { consoleActions } from "../../redux/slices/console";

export function usePreCheck(config: any) {
  const [isLoaded, setIsloaded] = useState(false);
  const ERROR = "error";
  const INFO = "log";
  const [message, setMessage] = useState({
    desc: "",
    type: "",
  });
  const dispatch = useDispatch();

  function log(message: string) {
    console.log(message);
    dispatch(
      consoleActions.setConfigPreCheckLogs({ type: "log", message: message })
    );
  }
  function error(message: string) {
    console.error(message);
    dispatch(
      consoleActions.setConfigPreCheckLogs({ type: "error", message: message })
    );
  }

  function warn(message: string) {
    console.warn(message);
    dispatch(
      consoleActions.setConfigPreCheckLogs({ type: "warn", message: message })
    );
  }

  useEffect(() => {
    if (config && !isLoaded) {
      main(config)
        .then((m: string) => setMessage({ desc: m, type: INFO }))
        .catch((e: string) => setMessage({ desc: e, type: ERROR }));
      setIsloaded(true);
    }
  }, [config]);

  useEffect(() => {
    if (message.type !== "") {
      console[message.type](message.desc);
    }
  }, [message]);

  const checkFrame = (frame) => {
    log("---------------------------------------");
    log("Check data.frame");
    let check = true;
    if (frame) {
      let mandatoryFields = [
        "name",
        "catEntryId",
        "listPrice",
        "color",
        "brand",
        "upc",
        "rxValues",
      ];

      mandatoryFields.map((m) => {
        if (!frame[m]) {
          check = check && false;
          error("Missing attribute key:" + m + " in config.data.frame!");
        }
      });
    }
    if (check) {
      log("Check successfully completed");
    } else {
      log("---- Something went wrong! See error logs!  ----");
    }
  };

  const checkObjsContent = (
    key: string,
    source,
    obj: any,
    mandatoryFields: string[],
    warnFileds: string[] = []
  ) => {
    let check = true;
    let keys = obj ? Object.keys(obj) : [];
    if (keys.length > 0) {
      keys.map((k) => {
        let subKeys = Object.keys(obj[k]);
        let innerValue = Object.values(obj[k]);
        mandatoryFields.map((m) => {
          if (!subKeys.includes(m)) {
            check = check && false;
            error(
              "Missing attribute key:" +
                m +
                " in " +
                source +
                "." +
                key +
                "." +
                k
            );
          } else {
            let value = obj[k][m];
            if (value === undefined || value === null || value === "") {
              check = check && false;
              error(
                "Missing value for key: " +
                  m +
                  " in " +
                  source +
                  "." +
                  key +
                  "." +
                  k
              );
            }
          }
        });

        warnFileds.map((m) => {
          if (!subKeys.includes(m)) {
            warn(
              "Missing attribute key:" +
                m +
                " in " +
                source +
                "." +
                key +
                "." +
                k
            );
          } else {
            let value = obj[k][m];
            if (value === undefined || value === null || value === "") {
              check = check && false;
              warn(
                "Missing value for key: " +
                  m +
                  " in " +
                  source +
                  "." +
                  key +
                  "." +
                  k
              );
            }
          }
        });
      });
    }
    return check;
  };

  const checkContent = (content) => {
    let contentData = Object.keys(content);
    let check = true;
    log("---------------------------------------");
    log("Check lensesData");
    log("---------------------------------------");
    log("Check lensesData.content");
    contentData.forEach((key) => {
      if (key == "promo") {
        let cc = checkObjsContent(key, "content", content[key], ["title"]);
        check = check && cc;
      } else {
        let cc = checkObjsContent(
          key,
          "content",
          content[key],
          ["title"],
          ["sequence"]
        );
        check = check && cc;
      }
    });
    if (check) {
      log("Check successfully completed");
    } else {
      log("---- Something went wrong! See error logs!  ----");
    }
  };

  const checkPrescription = (prescription) => {
    if (prescription && prescription.prescriptionType !== "SIMPLE") {
      log("---------------------------------------");
      log("Check prescriptionModule");
      let check = true;
      let mandatoryFields = [
        "prescriptionType",
        "prescriptionFlows",
        "loadExtendedPrescription",
        "saveExtendedPrescription",
        "clearExtendedPrescription",
      ];
      if (prescription?.prescriptionFlows?.includes("UPLOAD")) {
        mandatoryFields.push(
          ...["uploadExtendedPrescription", "downloadExtendedPrescription"]
        );
      }
      if (prescription?.prescriptionFlows?.includes("CMD")) {
        mandatoryFields.push("searchDoctors");
      }
      if (prescription?.prescriptionFlows?.includes("MANUAL")) {
        mandatoryFields.push("checkAvailableFrames");
      }
      if (
        prescription?.prescriptionType &&
        prescription?.prescriptionType == "NESTED"
      ) {
        if (prescription?.nestedFlows && prescription.nestedFlows == "UPLOAD") {
          mandatoryFields.push(
            ...["uploadExtendedPrescription", "downloadExtendedPrescription"]
          );
        }
        if (prescription?.nestedFlows && prescription.nestedFlows == "CMD") {
          mandatoryFields.push("searchDoctors");
        }
      }
      mandatoryFields.map((m) => {
        if (!Object.keys(prescription).includes(m)) {
          check = check && false;
          error("Missing attribute key:" + m + " in prescriptionModule!");
        } else {
          let value = prescription[m];
          if (value === undefined || value === null || value === "") {
            check = check && false;
            error("Missing value for key: " + m + " in prescriptionModule!");
          }
        }
      });
      if (check) {
        log("Check successfully completed");
      } else {
        log("---- Something went wrong! See error logs!  ----");
      }
    }
  };

  const checkActionsModule = (actionsModule) => {
    if (actionsModule) {
      log("---------------------------------------");
      log("Check actionsModule");
      let check = true;
      let mandatoryFields = [
        "genericAddToCart",
        "genericSaveEditFromCart",
        "loadContent",
      ];
      mandatoryFields.map((m) => {
        if (!Object.keys(actionsModule).includes(m)) {
          check = check && false;
          error("Missing attribute key:" + m + " in actionModule");
        }
      });
      if (check) {
        log("Check successfully completed");
      } else {
        log("---- Something went wrong! See error logs!  ----");
      }
    }
  };

  const checkInsuranceModule = (insuranceModule) => {
    if (insuranceModule) {
      log("---------------------------------------");
      log("Check insuranceModule");
      let check = true;
      let mandatoryFields = [
        "isEnabled",
        "isLoading",
        "isLogged",
        "getEvents",
        "getInsuranceDiscounts",
        "openInsurancePanel",
      ];
      mandatoryFields.map((m) => {
        if (!Object.keys(insuranceModule).includes(m)) {
          check = check && false;
          error("Missing attribute key:" + m + " in insuranceModule");
        }
      });
      if (check) {
        log("Check successfully completed");
      } else {
        log("---- Something went wrong! See error logs!  ----");
      }
    }
    log("---------------------------------------");
  };

  const checkPayment = (installment) => {
    if (installment) {
      log("---------------------------------------");
      log("Check paymentInstallment");
      let check = true;

      let mF = [];
      let optF = ["contentIdentifier"];
      if (installment.multiplePaymentInstallment) {
        mF.push("types");
        mF.push("multiplePaymentInstallment");
      } else {
        mF.push("type");
        warn(
          "Missing attribute key:multiplePaymentInstallment in paymentInstallment!\nIs there only one payment installment?"
        );
      }
      let ks = Object.keys(installment);
      mF.map((m) => {
        if (!ks.includes(m)) {
          check = check && false;
          error("Missing attribute key:" + m + " in paymentInstallment!");
        } else {
          let value = installment[m];
          if (value === undefined || value === null || value === "") {
            check = check && false;
            error("Missing value for key: " + m + " in paymentInstallment!");
          }
        }
      });
      if (!ks.includes(optF[0])) {
        warn("Missing attribute key:" + optF[0] + " in paymentInstallment!");
      }

      if (check) {
        log("Check successfully completed");
      } else {
        log("---- Something went wrong! See error logs!  ----");
      }
    }
  };

  const checkPackages = (isManual: boolean, packages: any[]) => {
    log("---------------------------------------");
    log("Check lensesData.packages");
    let check = true;
    const mandatoryFrameProps = ["offerPrice"];
    const mandatoryProps = [
      "catEntryId",
      "type",
      "listPrice",
      "offerPrice",
      "brand",
      "thickness",
      "upc",
    ];
    if (isManual) {
      mandatoryProps.push("rxValues");
    }
    const optionalProps = [
      "blueLight",
      "transition",
      "uvProtection",
      "antiScratch",
      "color",
      "clen",
      "antiReflective",
      "treatment",
      "treatmentGroup",
      "treatmentFamily",
      "treatmentCategory",
      "designType",
      "colorCategory",
      "polar",
      "productUPC",
    ];
    packages.forEach((p, index) => {
      const missingProps = new Set<string>();
      let { frame, lensPackage } = p;

      mandatoryFrameProps.forEach((m) => {
        if (!Object.keys(frame).includes(m)) {
          check = check && false;
          error(
            "Missing attribute key:" +
              m +
              " in frame inside lensesData.packages!\ncatEntryId:" +
              lensPackage?.catEntryId +
              (lensPackage?.catEntryId == undefined ? "\nIndex:" + index : "")
          );
          missingProps.add(m);
        } else {
          let value = frame.offerPrice;
          if (value === undefined || value === null || value === "") {
            check = check && false;
            error(
              "Missing value for key: offerPrice in lensesData.packages.frame"
            );
          }
        }
      });
      mandatoryProps.forEach((m) => {
        if (!Object.keys(lensPackage).includes(m)) {
          check = check && false;
          error(
            "Missing attribute key:" +
              m +
              " in lensPackage inside lensesData.packages!\ncatEntryId:" +
              lensPackage?.catEntryId +
              (lensPackage?.catEntryId == undefined ? "\nIndex:" + index : "")
          );
          missingProps.add(m);
        } else {
          let value = lensPackage[m];
          if (value == undefined || value == null || value == "") {
            check = check && false;
            error(
              "Missing value for key:" +
                m +
                " in lensPackage inside lensesData.packages!\ncatEntryId:" +
                lensPackage?.catEntryId +
                (lensPackage?.catEntryId == undefined ? "\nIndex:" + index : "")
            );
          }
        }
      });

      optionalProps.forEach((prop) => {
        if (!Object.keys(lensPackage).includes(prop)) {
          missingProps.add(prop);
        }
      });
      dispatch(
        configActions.setMissingProps({
          catEntryId: lensPackage.catEntryId,
          missingProps: Array.from(missingProps),
        })
      );
    });
    if (check) {
      log("Check successfully completed");
    } else {
      log("---- Something went wrong! See error logs!  ----");
    }
  };

  const checkContentPackagesMatch = (content, packages, frames) => {
    let check = true;
    let mF = [
      "type",
      "antiReflective",
      "polar",
      "color",
      "colorCategory",
      "blueLight",
      "thickness",
      "transition",
      "brand",
      "designType",
    ];
    const missingContents = new Set<[string, string]>();
    log("---------------------------------------");
    log(
      "Checks matching between lensesData.content \nand values in each lensPackage attributes inside lensesData.packages"
    );
    packages.forEach((p) => {
      let { lensPackage } = p;
      mF.forEach((k) => {
        if (lensPackage[k]) {
          let value = lensPackage[k];
          let keys = content[k] ? Object.keys(content[k]) : [];
          if (!keys.includes(value)) {
            check = check && false;
            error(
              "Missing key: " +
                value +
                " in content." +
                k +
                (lensPackage.catEntryId
                  ? "\nSee catEntryId: " + lensPackage.catEntryId
                  : "")
            );
            missingContents.add([k, value]);
          }
        }
      });
    });
    if (!content?.frameColor) {
      if (frames && frames.length > 0) {
        frames.forEach((f) => {
          missingContents.add(["frameColor", f.color]);
        });
      }
    }
    if (check) {
      log("Check successfully completed");
    } else {
      log("---- Something went wrong! See error logs!  ----");
    }
    for (const missingContent of missingContents) {
      const [property, value] = missingContent;
      dispatch(configActions.addDefaultContent({ property, value }));
    }
  };

  const main = (config) => {
    return new Promise((_resolve, _reject) => {
      try {
        checkFrame(config.data.frame);
        config.lensesData.content && checkContent(config.lensesData.content);
        config.lensesData.packages &&
          checkPackages(
            config.prescriptionModule &&
              config.prescriptionModule.prescriptionType !== "SIMPLE" &&
              config.prescriptionModule.prescriptionFlows.includes("MANUAL"),
            config.lensesData.packages
          );
        config.lensesData.content &&
          config.lensesData.packages &&
          checkContentPackagesMatch(
            config.lensesData.content,
            config.lensesData.packages,
            config.data?.frames
          );
        checkPrescription(config.prescriptionModule);
        checkPayment(config.paymentInstallment);
        checkActionsModule(config.actionsModule);
        checkInsuranceModule(config.insuranceModule);
      } catch (e) {
        _reject(e);
      }
      _resolve("RXC - Config check completed!");
    });
  };

  return message;
}
