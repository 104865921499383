import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/Store";
import { Backend } from "./Backend"
import { App } from "./App";

//export const CURRENT_BRAND = process.env.CURRENT_BRAND==='default'?null:process.env.CURRENT_BRAND;

const rxcWidget = {
  new: (config: any) => {
    rxcWidget["selector"] = config?.selector || "";
    return {
      render: () => {
        ReactDOM.render(
          <Provider store={store}>
            <Backend config={config}>
              <App />
            </Backend>
          </Provider>,
          document.querySelector(config.selector)
        );
      },
    };
  },
  close: (selector?: string) => {
    let isDifferentSelector = selector
      ? selector !== (rxcWidget as any)?.selector
        ? true
        : false
      : false;

    try {
      if (!selector) {
        selector = (rxcWidget as any)?.selector;
        delete rxcWidget["selector"];
      }
      ReactDOM.unmountComponentAtNode(document.querySelector(selector));
    } catch (error) {
      isDifferentSelector
        ? !document.querySelector(selector) &&
        console.error(
          "Error during widget unmount. Check if the widget is actually instantiated or the selector is correct. Selector inserted: " +
          selector
        )
        : !document.querySelector(selector) &&
        console.error(
          "Error during widget unmount. The widget is already unmounted."
        );

      let eventListenerList = (window as any).RXC.rxcWidget.listeners;

      if (eventListenerList) {
        Object.keys(eventListenerList).forEach((key: string) => {
          window.removeEventListener(key, eventListenerList[key]);
        });
        delete rxcWidget["listeners"];
      }
    }
  },
};

/* declare global {
    interface Window {
        RXC: any;
    }
} */
window.RXC = { rxcWidget };
