import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";

interface IncompatiblePrescriptionModalOpt {}

export function IncompatiblePrescriptionModal({}: IncompatiblePrescriptionModalOpt) {
  const title = useTranslate("prescription.compatibleFrameError");
  const subtitle = useTranslate("prescription.compatibleFrameError");
  const btnText = useTranslate("prescription.compatibleFramesButton");

  const framesUrl = useSelector(
    (state: any) => state.modals.urlPrescriptionModal
  );

  const handleModalClose = () => {
    location.href = framesUrl;
  };

  return (
    <div className={"IncompatiblePrescriptionModal__container"}>
      <div
        className={"IncompatiblePrescriptionModal__container__overlay"}
      ></div>
      <div className={"IncompatiblePrescriptionModal__container__popup"}>
        <div className={"IncompatiblePrescriptionModal__container__title"}>
          {title}
        </div>
        <div className={"IncompatiblePrescriptionModal__container__subtitle"}>
          {subtitle}
        </div>
        <GenericButton
          noArrow={true}
          className={"IncompatiblePrescriptionModal__container_button"}
          handleClick={handleModalClose}
        >
          {btnText}
        </GenericButton>
      </div>
    </div>
  );
}
