import { useEffect, useRef, useState } from "react";

export function useSmoothHorizontalScroll() {
  const scrollContainerRef = useRef(null);
  const isFirstRender = useRef(true);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    if (!scrollContainerRef.current) return;
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setIsAtEnd(
        scrollContainerRef.current.scrollWidth ===
        scrollContainerRef.current.offsetWidth
      );
    }
  }, [scrollContainerRef, isFirstRender]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    setIsAtStart(scrollContainerRef.current?.scrollLeft === 0);
    setIsAtEnd(
      Math.floor(
        scrollContainerRef.current?.scrollWidth -
        scrollContainerRef.current?.scrollLeft
      ) <= scrollContainerRef.current?.offsetWidth
    );
  };

  const scrollTo = (direction) => {
    const amount =
      direction === "right"
        ? scrollContainerRef.current.clientWidth
        : -scrollContainerRef.current.clientWidth;

    scrollContainerRef.current?.scrollTo({
      left: scrollContainerRef.current?.scrollLeft + amount,
      behavior: "smooth",
    });
  };

  return {
    scrollContainerRef,
    handleScroll,
    scrollTo,
    isAtStart,
    isAtEnd,
  };
};
