import React, { useEffect, useState } from "react";
import { BrandComponent } from "../../../components/common/BrandComponent";
import GenericButton from "../../../components/common/GenericButton";
import { SwitchControl } from "../../../components/common/Switch";
import {
  callInsuranceService,
  getCheapPackageFromList,
  getCorrectAnalyticsStepContainerName,
} from "../../ConfigLoader";
import {
  useAddonsMapper,
  useAllCurrentPackages,
  useConfigContent,
  useContextualMessage,
  usePriceStepItem,
  useReviewIncludedTreatmentsMapper,
  useGetDiscount,
  useRetriveContextualMessage,
} from "@hooks";
import { StepComponentOptions } from "../StepComponent";
import "./default.module.scss";
import { groupBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../translations/TranslationHooks";
import { colorActions } from "../../../redux/slices/color";
import { TranslateText } from "../../../components/common/TranslateText";
import { workflow } from "../../models/Workflow";
import { PlainLensColorPromoAdapter } from "../../adapters/PlainToLensColorPromoAdapter";
import ReactTooltip from "react-tooltip";
import { configObjectToSliderObject } from "../../adapters/ConfigObjectToSliderObject";
import { workflowActions } from "../../../redux/slices/workflow";
import { recommendedUseArrayToString } from "../../adapters/RecommendedUseArrayToString";
import { LensColorDetails } from "./LensColorDetails/LensColorDetails";
import { createPortal } from "react-dom";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { useEnableBrandLastStep } from "../../hooks";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";

export interface LensColorGroup {
  name: string;
  colors: LensColorModel[];
  hasPromo: boolean;
}
export interface LensColorPromo {
  title: string;
  id: string;
  amount: number | string;
}
interface LensColorModel {
  name: string;
  value: string;
  id: string;
  selected?: boolean;
  promo?: LensColorPromo[];
  price: number;
  discount: number;
  insPrice: number;
  recommendedUse: string;
  colorCategory: string;
  polar: string;
  colorCategoryTitle: string;
  disabled?: boolean;
  promoBadge?: string;
}

interface LensColorOpt {
  colorGroups?: LensColorGroup[];
  onSelectedChange?: (color: LensColorModel, group: LensColorGroup) => void;
}
interface LensColorStepOpt extends StepComponentOptions, LensColorOpt {}

/**
 * This component displays the 'lensColor' step. It uses the
 * ```LensColorStepOpt``` to work in his flow.
 * this component get the color list by method ```getColors``` ;
 * this method allow to get all color for currentPackages grouped by ```colorGroup```.
 */
export function LensColor({ step }: LensColorStepOpt) {
  const [colorsG, setColorsG] = useState([]);

  const [selectedColor, setSelectedColor] = useState(null);
  const currentPackages = useAllCurrentPackages();
  const configColor: any[] = useConfigContent("Color");
  const colorCategoryContent = useSelector(
    (state: any) => state.config?.lensesData.content.colorCategory
  );
  const lens = useSelector((state: any) => state.config?.data?.lens);

  const [ignorePreselection, setIgnorePreselection] = useState(false);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const brandLastStep = useEnableBrandLastStep();
  const skipConfiguration = useSelector(
    (state: any) => state.config?.skipConfiguration
  );

  //FALLBACK IMAGE
  const imageSrcsetTemplate = useSelector(
    (state: any) => state.config?.imageSrcsetTemplate
  );
  //FALLBACK IMAGE
  const getUrlSwatch = (value: string) => {
    let format = value.indexOf(".") > 0 ? "svg" : "png";
    return imageSrcsetTemplate
      .replace("{url}", configProjectImage)
      .replace("{url}", configProjectImage)
      .replace("{filename}", value)
      .replace("{filename}", value)
      .replace("{format}", format)
      .replace("{format}", format);
  };

  const prescription = useSelector(
    (state: any) => state.prescription.currentPrescription
  );

  const translation = useTranslation();
  const dispatch = useDispatch();
  const ctxMessage = useRetriveContextualMessage();

  const [polarSwitch, setPolarSwitch] = useState(false);

  const changePriceStyle = {
    color: "#222222",
    fontSize: "14px",
    fontWeight: "normal",
    fontFamily: "Roboto",
  };
  const changeDiscountStyle = {
    color: "#222222",
    fontSize: "12px",
    fontWeight: "normal",
    fontFamily: "Roboto",
  };
  const plainLensColorPromoAdapter = new PlainLensColorPromoAdapter(
    translation
  );
  const [showAlert, setShowAlert] = useState(false);
  const bannerTooltiptext = translation("steps.brand.tooltipBannerText");

  const [alreadyIncluded, setalreadyIncluded] = useState(null);
  const currentTreatments = useSelector(
    (state: any) => state.workflow?.currentTreatments
  );
  const loadTreatments = useAddonsMapper();
  const tempOptions = ["uvProtection", "antiReflective"];

  const [showPolarSwitch, setShowPolarSwitch] = useState(true);

  const currentColor = useSelector((state: any) => state.color.colorSelected);
  const loadIncludedTreatments = useReviewIncludedTreatmentsMapper();
  const [currentPackagesFiltered, setCurrentPackagesFiltered] =
    useState(currentPackages);

  const getPriceForColor = usePriceStepItem();

  //Brands
  const configBrand: any[] = useConfigContent("Brand");
  const [brandSelected, setBrandselected] = useState(null);
  const [brandsAvaiable, setBrandsAvaiable] = useState(null);
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.gvpTreatment.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.lensColor.learnMoreCTA");
  const [toSkip, setToSkip] = useState(false);
  const { getDiscount, isLoading } = useGetDiscount();

  useEffect(() => {
    if (brandSelected) {
      dispatch(colorActions.setLensBrand(brandSelected));
    }
  }, [brandSelected]);

  useEffect(() => {
    if (configBrand && currentPackages) {
      const currentBrands = configBrand?.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.brand === c.id
        )
      );
      const greyOutCurrentBrands = configBrand
        ?.filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.brand === c.id
          )
        )
        .filter((goB) => !currentBrands.find((b) => b.id === goB.id));

      if (currentBrands && currentBrands.length) {
        if (currentBrands.length + greyOutCurrentBrands.length > 1) {
          setBrandsAvaiable(
            configObjectToSliderObject.adaptList(
              currentBrands.concat(greyOutCurrentBrands)
            )
          );
        }
        if (!brandLastStep && !brandSelected) {
          setBrandselected(currentBrands[0].id);
        }
      }
    }
  }, [configBrand, currentPackages]);

  const handleBrandCallback = (callbackValue) => {
    setBrandselected(callbackValue);
    handlePolarFilterChange(false);
    setCategorySelected("All");
  };
  //end Brands

  //Polar

  useEffect(() => {
    if (currentPackages) {
      const listPolirazedList = currentPackages.validRXPackages.filter((cp) => {
        const polarCondition =
          cp.lensPackage.polar !== null && cp.lensPackage.polar !== false;
        const brandCondition =
          !brandSelected ||
          brandSelected === "All" ||
          cp.lensPackage.brand === brandSelected;
        return polarCondition && brandCondition;
      });
      const greyOutListPolirazedList = currentPackages.invalidRXPackages.filter(
        (cp) => {
          const polarCondition =
            cp.lensPackage.polar !== null && cp.lensPackage.polar !== false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        }
      );

      const listNotPolirazedList = currentPackages.validRXPackages.filter(
        (cp) => {
          const polarCondition =
            cp.lensPackage.polar === null || cp.lensPackage.polar === false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        }
      );
      const greyOutListNotPolirazedList =
        currentPackages.invalidRXPackages.filter((cp) => {
          const polarCondition =
            cp.lensPackage.polar === null || cp.lensPackage.polar === false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        });

      setShowPolarSwitch(
        listPolirazedList.length + greyOutListPolirazedList.length >= 1 &&
          listNotPolirazedList.length + greyOutListNotPolirazedList.length >= 1
      );
    }
  }, [currentPackages, brandsAvaiable]);

  //end Polar

  //DesignType
  const [designTypesAvailable, setDesignTypesAvailable] = useState(null);
  const [designTypeSelected, setDesignTypeSelected] = useState(null);
  const configDesignType = useSelector(
    (state: any) => state.config?.lensesData?.content?.designType
  );

  useEffect(() => {
    if (currentPackages) {
      const currentDesignTypes = groupBy(
        currentPackages.validRXPackages
          .concat(currentPackages.invalidRXPackages)
          .filter((cp) => {
            const polarCondition =
              !polarSwitch ||
              (polarSwitch &&
                cp.lensPackage.polar !== null &&
                cp.lensPackage.polar !== false);
            const brandCondition =
              !brandSelected ||
              brandSelected === "All" ||
              cp.lensPackage.brand === brandSelected;
            return (
              polarCondition && brandCondition && cp.lensPackage.designType
            );
          }),
        "lensPackage.designType"
      );
      const deisgnTypesId = Object.keys(currentDesignTypes);
      if (deisgnTypesId.length) {
        let designTypes = deisgnTypesId
          .map((key) => ({
            id: key,
            title:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].title
                ? configDesignType[key].title
                : translation("steps.lensColor.designType." + key + ".title"),
            sequence:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].sequence !== null &&
              configDesignType[key].sequence !== undefined
                ? configDesignType[key].sequence
                : deisgnTypesId.length,
          }))
          .sort((a, b) => {
            return a.sequence > b.sequence ? 1 : -1;
          });
        if (deisgnTypesId.length > 1) {
          designTypes.unshift({
            id: "All",
            title: "All",
            sequence: 0,
          });
          setDesignTypesAvailable(
            configObjectToSliderObject.adaptList(designTypes)
          ); //if there's only one filterpill it's not shown to show it move this line after the if
        }

        setDesignTypeSelected(designTypes[0].id);
        setCategorySelected(
          categoriesAvaiable ? categoriesAvaiable[0]?.id : "All"
        );
        setReccomendedUseSelected(null);
      }
    }
  }, [currentPackages, configDesignType, polarSwitch, brandSelected]);

  const handleDesignTypeSelected = (prop) => {
    setDesignTypeSelected(prop);
  };

  //end DesignType

  //Reccomended Use
  const [reccomendedUseAvaiable, setReccomendedUseAvaiable] = useState(null);
  const [reccomendedUseSelected, setReccomendedUseSelected] = useState(null);
  const configReccomendedUse = useSelector(
    (state: any) => state.config?.lensesData?.content?.designType
  );

  useEffect(() => {
    if (currentPackages) {
      const currentReccomendedUse = currentPackages.validRXPackages
        .concat(currentPackages.invalidRXPackages)
        .filter((cp) => {
          const polarCondition =
            !polarSwitch ||
            (polarSwitch &&
              cp.lensPackage.polar !== null &&
              cp.lensPackage.polar !== false);
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          const designTypeCondition =
            !designTypeSelected ||
            designTypeSelected === "All" ||
            cp.lensPackage.designType === designTypeSelected;
          return (
            polarCondition &&
            brandCondition &&
            designTypeCondition &&
            cp.lensPackage.recommendedUse
          );
        });

      // RecommendedUseValue
      const reccomendedUseId = Object.keys(currentReccomendedUse);
      let allRecommendedUses = currentReccomendedUse.map((x) => {
        return x.lensPackage.recommendedUse;
      });
      allRecommendedUses =
        recommendedUseArrayToString.adapt(allRecommendedUses);
      if (allRecommendedUses?.length) {
        let reccomendedUses = allRecommendedUses.map((key) => ({
          id: key,
          title: key
            ? key
            : translation("steps.lensColor.categories." + key + ".title"),
        }));
        if (allRecommendedUses?.length > 1) {
          reccomendedUses.unshift({
            id: "All",
            title: "All",
          });
        }
        setReccomendedUseAvaiable(
          configObjectToSliderObject.adaptList(reccomendedUses)
        );
        if (!reccomendedUseSelected) {
          setReccomendedUseSelected(reccomendedUses[0].id);
        }
      } else {
        setReccomendedUseAvaiable(null);
        setReccomendedUseSelected(null);
        setCategorySelected(null);
      }
    }
  }, [
    currentPackages,
    configReccomendedUse,
    designTypeSelected,
    brandSelected,
    polarSwitch,
  ]);

  const handleReccomendedUseSelected = (prop) => {
    setReccomendedUseSelected(prop);
  };

  //end Reccomended Use

  //Category
  const [categoriesAvaiable, setCategoriesAvaiable] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const configCategory = useSelector(
    (state: any) => state.config?.lensesData?.content?.colorCategory
  );

  useEffect(() => {
    if (currentPackages) {
      const currentCategories = groupBy(
        currentPackages.validRXPackages
          .concat(currentPackages.invalidRXPackages)
          .filter((cp) => {
            const polarCondition =
              !polarSwitch ||
              (polarSwitch &&
                cp.lensPackage.polar !== null &&
                cp.lensPackage.polar !== false);
            const brandCondition =
              !brandSelected ||
              brandSelected === "All" ||
              cp.lensPackage.brand === brandSelected;
            const designTypeCondition =
              !designTypeSelected ||
              designTypeSelected === "All" ||
              cp.lensPackage.designType === designTypeSelected;
            const reccomendedUseCondition =
              !reccomendedUseSelected ||
              reccomendedUseSelected === "All" ||
              handleRecUseArray(
                cp.lensPackage.recommendedUse,
                reccomendedUseSelected
              );
            return (
              polarCondition &&
              brandCondition &&
              designTypeCondition &&
              reccomendedUseCondition &&
              cp.lensPackage.colorCategory
            );
          }),
        "lensPackage.colorCategory"
      );
      console.log("currentCategories", currentCategories);
      const categoriesId = Object.keys(currentCategories);
      if (categoriesId.length) {
        let categories = categoriesId.map((key) => ({
          id: key,
          title:
            configCategory && configCategory[key] && configCategory[key].title
              ? configCategory[key].title
              : translation("steps.lensColor.categories." + key + ".title"),
        }));
        if (categoriesId.length > 1) {
          categories.unshift({
            id: "All",
            title: "All",
          });
        }
        setCategoriesAvaiable(configObjectToSliderObject.adaptList(categories));
        if (!categorySelected) {
          let preselectedCategory = lens && lens.colorCategory;
          if (preselectedCategory) {
            if (categoriesId.find((cat) => cat === preselectedCategory)) {
              setCategorySelected(lens.colorCategory);
            } else {
              setCategorySelected(categories[0].id);
            }
          } else {
            setCategorySelected(categories[0].id);
          }
        } else if (categorySelected === "All") {
          setCategorySelected(categories[0].id);
        }
      } else setCategoriesAvaiable(null);
    }
  }, [
    currentPackages,
    configCategory,
    brandSelected,
    polarSwitch,
    designTypeSelected,
    categorySelected,
    reccomendedUseSelected,
  ]);
  //end Category

  const handleSelected = (indexG: number, indexC: number) => {
    let selectedColor: LensColorModel = null;
    let swatchString: any = null;
    const mapped = colorsG.map((cg, cgI) => {
      cg.colors = cg.colors.map((c: LensColorModel, cI: number) => {
        if (cI === indexC && cgI === indexG) {
          //FALLBACK
          swatchString = {
            url: getUrlSwatch(c.value),
            name: c.name,
            value: c.value,
          };
          c.selected = true;
          selectedColor = c;
        } else {
          c.selected = false;
        }

        return c;
      });
      return cg;
    });
    setColorsG(mapped);
    setSelectedColor(selectedColor);
    dispatch(colorActions.selectColor(selectedColor.id));
    //FALLBACK
    dispatch(colorActions.setSwatchUrl(swatchString));
  };
  const handleNextStep = (skipped?: boolean, preselectedColor?: any) => {
    let selectedColorPrice = 0;
    let selectedColorDiscount = 0;
    let selectedColorInsPrice = 0;
    let selectedColorCategory = null;
    let selectedColorPolar = null;

    //if the color was pre-selected (like when editing from cart), dispatch the selection
    //to see the correct lens in the product container component
    if (preselectedColor) {
      dispatch(colorActions.selectColor(preselectedColor.id));
    }

    const colorSel = preselectedColor ? preselectedColor : selectedColor;

    colorsG.map((category) => {
      category.colors.map((color) => {
        if (
          color.id === selectedColor.id &&
          color.value === selectedColor.value
        ) {
          selectedColorPrice = color.price;
          selectedColorDiscount = color.discount;
          selectedColorInsPrice = color.insPrice;
          selectedColorCategory = color?.colorCategory;
          selectedColorPolar = color?.polar;
        }
      });
    });

    workflow.goToStep(
      "Thickness",
      [
        {
          key: "color",
          value: colorSel.id,
          price: selectedColorPrice,
          discount: selectedColorDiscount,
          insPrice:
            skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
          attributes: {
            colorCategory: selectedColorCategory,
            polar: selectedColorPolar,
          },
          optionalAttributes: !brandLastStep
            ? {
                key: brandSelected && brandSelected !== "ALL" ? "brand" : "",
                value: brandSelected,
              }
            : { key: "", value: "" },
        },
        {
          key: "lensColor",
          value: colorSel.id,
          price: selectedColorPrice,
          discount: selectedColorDiscount,
          insPrice:
            skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
          attributes: {
            colorCategory: selectedColorCategory,
            polar: selectedColorPolar,
          },
          optionalAttributes: !brandLastStep
            ? {
                key: brandSelected && brandSelected !== "ALL" ? "brand" : "",
                value: brandSelected,
              }
            : { key: "", value: "" },
        },
      ],
      skipped
    );
  };

  const handlePolarFilterChange = (isChecked: boolean) => {
    setPolarSwitch(isChecked);
    setCategorySelected("All");
  };

  const handleFilterPillsCallback = (prop) => {
    setCategorySelected(prop);
  };

  const handleRecUseArray = (prop, value) => {
    let filterBy = new RegExp(value);
    if (prop) {
      if (typeof prop === "object") {
        if (filterBy.test(prop)) {
          return true;
        }
      } else if (prop === value) {
        return true;
      } else return false;
    } else return false;
  };

  const getColors = (): LensColorGroup[] => {
    const temp = currentPackages.validRXPackages.filter((cp) => {
      const hasColor = cp.lensPackage.color;
      const polarCondition =
        !polarSwitch ||
        (polarSwitch &&
          cp.lensPackage.polar !== null &&
          cp.lensPackage.polar !== false);
      const brandCondition =
        !brandSelected ||
        brandSelected === "All" ||
        cp.lensPackage.brand === brandSelected;
      const categoryCondition =
        !categorySelected ||
        categorySelected === "All" ||
        cp.lensPackage.colorCategory === categorySelected;
      const designTypeCondition =
        !designTypeSelected ||
        designTypeSelected === "All" ||
        cp.lensPackage.designType === designTypeSelected;
      const reccomendedUseCondition =
        !reccomendedUseSelected ||
        reccomendedUseSelected === "All" ||
        cp.lensPackage.recommendedUse === reccomendedUseSelected;
      return (
        hasColor &&
        polarCondition &&
        brandCondition &&
        categoryCondition &&
        designTypeCondition &&
        reccomendedUseCondition
      );
    });

    const greyOutTemp = currentPackages.invalidRXPackages
      .filter((cp) => {
        const hasColor = cp.lensPackage.color;
        const polarCondition =
          !polarSwitch ||
          (polarSwitch &&
            cp.lensPackage.polar !== null &&
            cp.lensPackage.polar !== false);
        const brandCondition =
          !brandSelected ||
          brandSelected === "All" ||
          cp.lensPackage.brand === brandSelected;
        const categoryCondition =
          !categorySelected ||
          categorySelected === "All" ||
          cp.lensPackage.colorCategory === categorySelected;
        const designTypeCondition =
          !designTypeSelected ||
          designTypeSelected === "All" ||
          cp.lensPackage.designType === designTypeSelected;
        const reccomendedUseCondition =
          !reccomendedUseSelected ||
          reccomendedUseSelected === "All" ||
          cp.lensPackage.recommendedUse === reccomendedUseSelected;
        return (
          hasColor &&
          polarCondition &&
          brandCondition &&
          categoryCondition &&
          designTypeCondition &&
          reccomendedUseCondition
        );
      })
      .map((cp) => {
        return {
          ...cp,
          disabled: true,
        };
      });

    return Object.entries(
      groupBy(temp.concat(greyOutTemp), "lensPackage.colorCategory")
    ).map(([key, value], indexG) => {
      if (key != null) {
        let hasPromo = false;
        let color: LensColorModel[] = [];
        if (value) {
          let groupedByColor = groupBy(value, "lensPackage.color");
          Object.keys(groupedByColor).map((cg) => {
            let cgPackages = groupedByColor[cg];
            let cheapestCgp = getCheapPackageFromList(cgPackages);

            if (key === "null") {
              key = null;
            }

            const prices = !brandLastStep
              ? getPriceForColor({
                  color: cg,
                  colorCategory: key,
                })
              : getPriceForColor({
                  color: cg,
                  colorCategory: key,
                  brand: brandSelected,
                });

            let listPrice = 0;
            let offerPrice = 0;
            let insPrice = 0;

            if (prices) {
              listPrice = prices.price;
              if (isInsuranceEnabled) {
                if (prices.insPrice) {
                  offerPrice = prices.insPrice;
                }
              } else {
                offerPrice = prices.discount;
              }
              insPrice = prices.insPrice;
            }

            const colorName = cheapestCgp.lensPackage.color;
            let recUse = cheapestCgp.lensPackage.recommendedUse;
            let promo: LensColorPromo[] = [];
            let colorCategory = cheapestCgp.lensPackage.colorCategory;
            let polar = cheapestCgp.lensPackage.polar;
            const promoFrame = plainLensColorPromoAdapter.adapt(
              cheapestCgp.frame.promo
            );
            const lensPromo = plainLensColorPromoAdapter.adapt(
              cheapestCgp.lensPackage.promo
            );
            if (promoFrame) {
              promo = [...promo, ...promoFrame];
              hasPromo = true;
            }
            if (lensPromo) {
              promo = [...promo, ...lensPromo];
              hasPromo = true;
            }
            const configColorp = configColor?.find((c) => c.id === colorName);
            const defSelected =
              prescription &&
              lens &&
              lens.clen &&
              cheapestCgp.clen &&
              lens.clen === cheapestCgp.clen;
            if (configColorp) {
              const imageFileName = configColorp.imageFileName1
                ? configColorp.imageFileName1
                : configColorp.imageFileName;
              color.push({
                id: configColorp.id,
                name: configColorp.title,
                value: imageFileName,
                promo: promo.length ? promo : null,
                selected: defSelected ? defSelected : false,
                price: listPrice,
                discount: offerPrice,
                insPrice: insPrice,
                recommendedUse: recUse,
                colorCategory: colorCategory,
                polar: polar,
                colorCategoryTitle:
                  colorCategoryContent && colorCategoryContent[key]
                    ? colorCategoryContent[key].title
                    : key,
                disabled: cheapestCgp.disabled,
                promoBadge: configColorp.promoBadge,
              });
            }
          });
        }
        return { name: key, colors: color, hasPromo };
      }
    });
  };

  useEffect(() => {
    if (getPriceForColor && configColor && currentPackages) {
      let filteredPackages = currentPackages.validRXPackages.filter(
        (p) => p.lensPackage.color
      );
      const differentColors = groupBy(filteredPackages, "lensPackage.color");
      setToSkip(
        Object.keys(differentColors).length === 1 &&
          (!skipConfiguration ||
            !skipConfiguration.LensColor ||
            skipConfiguration.LensColor === "SKIP")
      );

      const colors = getColors();
      if (colors.length && colors[0]) {
        if (!selectedColor) {
          let preselectedColorPackage =
            lens && lens.clen
              ? currentPackages.validRXPackages.find(
                  (pkg) => pkg.lensPackage.clen === lens.clen
                )
              : null;
          let preselectedColor = preselectedColorPackage
            ? preselectedColorPackage.lensPackage.color
            : null;

          if (!preselectedColor || (preselectedColor && ignorePreselection)) {
            let colorsArr = colors[0].colors;
            if (colorsArr && colorsArr.length) {
              let selectableColors = colorsArr.filter((c) => !c.disabled);

              if (selectableColors && selectableColors.length) {
                colors[0].colors[0].selected = true;
                setSelectedColor(colors[0].colors[0]);
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(colors[0].colors[0].value),
                    name: colors[0].colors[0].name,
                    value: colors[0].colors[0].value,
                  })
                );
                dispatch(colorActions.selectColor(colors[0].colors[0].id));
              }
            }
          } else {
            setIgnorePreselection(true);
            colors[0].colors = colors[0].colors.map((col) => {
              if (col.id !== preselectedColor) {
                return col;
              } else {
                let newCol = col;
                newCol.selected = true;

                setSelectedColor(newCol);
                // Qui il primo colore
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(newCol.value),
                    name: newCol.name,
                    value: newCol.value,
                  })
                );
                dispatch(colorActions.selectColor(newCol.id));

                return newCol;
              }
            });
          }
        } else {
          let hasSelectedColor = false;
          colors.map((c, indexC) => {
            c.colors.map((cc, indexCC) => {
              if (cc.id === selectedColor.id) {
                colors[indexC].colors[indexCC].selected = true;
                hasSelectedColor = true;
              }
              return cc;
            });
            return c;
          });

          if (!hasSelectedColor) {
            let colorsArr = colors[0].colors;
            if (colorsArr && colorsArr.length) {
              let selectableColors = colorsArr.filter((c) => !c.disabled);

              if (selectableColors && selectableColors.length) {
                colors[0].colors[0].selected = true;
                setSelectedColor(colors[0].colors[0]);
                // Qui il primo colore
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(colors[0].colors[0].value),
                    name: colors[0].colors[0].name,
                    value: colors[0].colors[0].value,
                  })
                );
                dispatch(colorActions.selectColor(colors[0].colors[0].id));
              }
            }
          }
        }
      }

      dispatch(
        colorActions.selectRecommendedUse(
          !reccomendedUseSelected && reccomendedUseSelected === "All"
            ? ""
            : reccomendedUseSelected
        )
      );

      let colorsToSet = [];
      let greyOutColors = [];
      colors.forEach((cG) => {
        let ret = {
          ...cG,
          colors: cG.colors.filter((c) => !c.disabled),
        };
        greyOutColors = [
          ...greyOutColors,
          ...cG.colors.filter((c) => c.disabled),
        ];

        colorsToSet.push(ret);
      });

      colorsToSet.push({
        name: "DISABLED",
        hasPromo: false,
        colors: greyOutColors,
      });

      setColorsG(colorsToSet);
    }
  }, [
    getPriceForColor,
    currentPackages,
    configColor,
    prescription,
    polarSwitch,
    categorySelected,
    brandSelected,
    designTypeSelected,
    reccomendedUseSelected,
    skipConfiguration,
  ]);

  // -------- INSURANCE CODE -------- //

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );

  useEffect(() => {
    if (isInsuranceEnabled && selectedColor) {
      let filteredPackages = currentPackages.validRXPackages.filter((p) => {
        return (
          p.lensPackage.color === selectedColor.id &&
          brandSelected == p.lensPackage.brand
        );
      });
      let cheapestPackages = null;
      if (filteredPackages.length > 1) {
        cheapestPackages = [getCheapPackageFromList(filteredPackages)];
      } else {
        cheapestPackages = filteredPackages;
      }

      let insurablePackages = cheapestPackages.filter(
        (pkg) => pkg.lensPackage.insurable
      );
      if (insurablePackages.length) {
        callInsuranceService(
          cheapestPackages,
          configInsurance,
          frame,
          dispatch
        );
      }
    }
  }, [isInsuranceEnabled, selectedColor]);

  // -------- INSURANCE CODE -------- //

  useEffect(() => {
    if (toSkip && colorsG && selectedColor) {
      handleNextStep(true);
      setToSkip(false);
    }
  }, [toSkip, colorsG, selectedColor]);

  useEffect(() => {
    dispatch(
      workflowActions.setAdditionalParametersPackages({
        brand: brandSelected,
        color: selectedColor?.id,
      })
    );
    return () => {
      dispatch(workflowActions.setAdditionalParametersPackages(null));
    };
  }, [brandSelected, selectedColor]);

  useEffect(() => {
    for (const colorG of colorsG) {
      const { colors } = colorG;
      for (const color of colors) {
        getDiscount(`${color.id}`, currentPackages.validRXPackages, {
          color: color.id,
          colorCategory: color.colorCategory,
          brand: brandSelected,
        });
      }
    }
  }, [colorsG]);

  const getColorList = () => {
    let list = null;

    if (colorsG) {
      list = [];
      colorsG.forEach((cg: LensColorGroup, cgI) => {
        if (cg.colors) {
          const mappedColors = cg.colors.map((c, colorIndex) => ({
            ...c,
            cgI,
            groupName:
              c.colorCategoryTitle !== "null" ? c.colorCategoryTitle : null,
            colorIndex,
          }));
          list = [...list, ...mappedColors];
        }
      });

      const cardClassName = list.find((c) => c.promoBadge) ? " promoBadge" : "";

      return list.map((c) => {
        return (
          <BrandComponent
            componentName="LensColorCard"
            parameter={{
              color: c,
              cardClassName: cardClassName,
              selectedColor: selectedColor,
              changePriceStyle: changePriceStyle,
              changeDiscountStyle: changeDiscountStyle,
              onSelected: handleSelected,
              discountLoading: isLoading(c.id),
            }}
          />
        );
      });
    }

    return list;
  };

  const hasDetail = () => {
    let finded = null;
    if (selectedColor && configColor) {
      finded = configColor.find((c) => c.id === selectedColor.id);
      if (
        finded.longTitle ||
        finded.lightTransmission ||
        finded.lightConditions ||
        finded.contrast ||
        finded.baseLensColor
      ) {
        return true;
      } else return false;
    } else return false;
  };

  const getPillsSubtitle = () => {
    if (
      !brandSelected ||
      brandSelected === "All" ||
      !configBrand ||
      configBrand.length <= 1
    ) {
      return translation("steps.color.subtitle");
    }
    const findedBrand = configBrand.find((b) => b.id === brandSelected);
    if (findedBrand) {
      return findedBrand.description;
    }
    return translation("steps.color.subtitle");
  };

  /* Already Included Management */

  useEffect(() => {
    if (!alreadyIncluded && currentPackages) {
      let CorrectAlreadyIncluded = useCorrectAlreadyIncluded(
        currentTreatments,
        step,
        tempOptions
      );
      setalreadyIncluded(CorrectAlreadyIncluded);
    }
  }, [currentPackages, loadTreatments, alreadyIncluded]);

  const useCorrectAlreadyIncluded = (currTreat, addOns, extraTreatments) => {
    let optKeys = Object.keys(extraTreatments);
    let alreadyIncludedArray = [];
    let allOptionsInCP = [];
    optKeys.map((x) => {
      let checkArr = [];
      currentPackages.validRXPackages.forEach((u) => {
        let lpKeys = Object.keys(u.lensPackage);
        lpKeys.map((value) => {
          if (extraTreatments[x] === value) {
            checkArr.push({
              id: value,
              name: u.lensPackage[value],
            });
          }
        });
      });
      allOptionsInCP.push(checkArr);
    });
    let allOptInCPKeys = Object.keys(allOptionsInCP);
    const allEqual = (obj) => obj.every((v) => v.truth === true);
    const filterFor = (obj, tar) =>
      obj.map((t, i) => {
        switch (tar) {
          case currTreat:
            if (tar) {
              if (t.id === tar[0].key) {
                obj.splice(i, 1);
              }
            }
            break;
          case addOns:
            if (tar.step) {
              if (tar.step.params[0]) {
                if (tar.step.params[0].value) {
                  if (t.id === tar.step.params[0].value.type) {
                    obj.splice(i, 1);
                  }
                }
              }
            }
            break;
          case t.name === false:
            obj.splice(i, 1);
            break;
        }
      });
    const setNames = (prop) => {
      prop.map((x) => {
        console.log("SetNames", x);
        if (typeof x.name === "boolean") {
          x.name = translation(
            "steps.treatments.extraTreatments." + x.id + ".title"
          );
        } /* else if (/premium/i.test(x.name)) {
                    x.name = translation(
                        "steps.treatments.extraTreatments." + x.id + ".titlePremium"
                    );
                } */
      });
    };
    allOptInCPKeys.map((v) => {
      let valueToCompare = "";
      let nameToCompare = "";
      let counter = 0;
      let innerKeys = Object.keys(allOptionsInCP[v]);
      let truthArray = [];
      innerKeys.map((f) => {
        let innerKeysLen: number = innerKeys.length;
        if (valueToCompare === "" && nameToCompare === "" && counter === 0) {
          valueToCompare = allOptionsInCP[v][f].id;
          nameToCompare = allOptionsInCP[v][f].name;
          truthArray.push({
            truth: true,
            id: allOptionsInCP[v][f].id,
            name: allOptionsInCP[v][f].name,
          });
          counter = counter + 1;
        } else if (counter > 0 && counter <= innerKeysLen) {
          if (
            valueToCompare === allOptionsInCP[v][f].id &&
            nameToCompare === allOptionsInCP[v][f].name
          ) {
            truthArray.push({
              truth: true,
              id: allOptionsInCP[v][f].id,
              name: allOptionsInCP[v][f].name,
            });
          } else {
            truthArray.push({
              truth: false,
              id: allOptionsInCP[v][f].id,
              name: allOptionsInCP[v][f].name,
            });
          }
        }
      });
      if (truthArray.length && allEqual(truthArray)) {
        if (truthArray[0].name !== false && truthArray[0].name) {
          alreadyIncludedArray.push({
            id: truthArray[0].id,
            name: truthArray[0].name,
          });
        }
      }
    });
    if (currTreat) {
      filterFor(alreadyIncludedArray, currTreat);
    }
    if (addOns) {
      filterFor(alreadyIncludedArray, addOns);
    }
    setNames(alreadyIncludedArray);

    return alreadyIncludedArray;
  };

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  return (
    <>
      {showAlert && (
        <div className={"LensColor__alert_container"}>
          <div style={{ display: "flex" }}>
            <div
              className={"LensColor__alert_title__icon"}
              data-tip={bannerTooltiptext}
              data-class={"LensColor__polarized_container__moreinfo__tooltip"}
            />
            <p className={"LensColor__alert_title"}>
              <TranslateText label="steps.color.alertTitle" />
            </p>
          </div>
          <p className={"LensColor__alert_description"}>
            <TranslateText label="steps.color.alertText" />
          </p>
          <ReactTooltip effect="solid" type="light" place="bottom" />
        </div>
      )}
      <BrandComponent
        componentName="StepContainer"
        parameter={{
          title: translation("steps.color.title"),
          subtitle: ctxMessage,
          pillsSubtitle: !brandLastStep ? getPillsSubtitle() : null,
          pills:
            designTypesAvailable && designTypesAvailable.length > 1
              ? designTypesAvailable
              : null,
          pillsCallback: handleDesignTypeSelected,
          pillSelected: designTypeSelected,
          slider: !brandLastStep ? brandsAvaiable : null,
          brandCallback: handleBrandCallback,
          showGrayOutBanner:
            colorsG &&
            colorsG.find(
              (cg) => cg.colors && cg.colors.find((c) => c.disabled)
            ),
        }}
      >
        {learnMoreButton !== "steps.lensColor.contentIdentifier" &&
          learnMoreButton !== undefined &&
          learnMoreButton !== null &&
          delegatingLearnMore &&
          delegateLearnMoreContent && (
            <a
              className="learnMoreButton"
              onClick={() => delegateLearnMoreContent(learnMoreButton)}
            >
              {moreInfoLabel !== "" &&
              moreInfoLabel !== undefined &&
              moreInfoLabel !== null &&
              moreInfoLabel !== "steps.lensColor.learnMoreCTA"
                ? moreInfoLabel
                : "More information"}
            </a>
          )}
        {alreadyIncluded && (
          <div className={"LensColor__alreadyIncluded__container"}>
            <ul className={"LensColor__alreadyIncluded__container__list"}>
              {alreadyIncluded.map((ai) => (
                <>
                  <li key={"Addons_ai_" + ai.id}>
                    {translation(ai.name)}
                    <span
                      data-tip={translation("steps.color.tooltip." + ai.id)}
                      data-class={
                        "LensColor__polarized_container__moreinfo__tooltip"
                      }
                      data-arrow-color="white"
                      className={
                        "LensColor__alreadyIncluded__container__info--icon"
                      }
                    />
                    <ReactTooltip
                      multiline={true}
                      type="light"
                      place="bottom"
                      effect="solid"
                      className="LensColor__alreadyIncluded__container__tooltip"
                    />
                  </li>
                </>
              ))}
            </ul>
          </div>
        )}
        <div className={"LensColor__container"}>
          {showPolarSwitch && (
            <div className={"LensColor__polarized_container"}>
              <div className={"LensColor__polarized_container_titleContainer"}>
                <div>
                  <TranslateText label={"steps.color.polarized"} />
                </div>
              </div>
              <div
                className={"LensColor__polarized_container__moreinfo"}
                data-tip={translation("steps.color.tooltip.polarized")}
                data-class={"LensColor__polarized_container__moreinfo__tooltip"}
                data-arrow-color="white"
              />
              <div className={"LensColor__polarized_container_switch"}>
                <SwitchControl
                  name="PolarSwitch"
                  checked={polarSwitch}
                  onChange={handlePolarFilterChange}
                />
                <ReactTooltip type="light" place="bottom" effect="solid" />
              </div>
            </div>
          )}
          <div className={"LensColor__container_separator"}></div>
          {reccomendedUseAvaiable && reccomendedUseAvaiable.length > 1 && (
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: reccomendedUseAvaiable,
                callback: handleReccomendedUseSelected,
                selected: reccomendedUseSelected,
              }}
            />
          )}
          {categoriesAvaiable && categoriesAvaiable.length > 1 && (
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: categoriesAvaiable,
                callback: handleFilterPillsCallback,
                selected: categorySelected,
              }}
            />
          )}

          <div className={"LensColor__groupsContainer"}>
            {colorsG && colorsG.length > 0 && (
              <BrandComponent
                componentName="SkipContainer"
                parameter={{
                  stepName: "LensColor",
                  ignoreSkip: true,
                  parameterPreselection: ["color", "colorCategory"],
                  onPreselect: (selected: any, skipped: boolean) => {
                    let colorToSelect = null;
                    let categoryToSelect = selected.colorCategory
                      ? selected.colorCategory
                      : "null";
                    colorsG.forEach((category) => {
                      if (category.name === categoryToSelect) {
                        category.colors.forEach((color) => {
                          if (color.id === selected.color) {
                            colorToSelect = color;
                          }
                        });
                      }
                    });

                    handleNextStep(skipped, colorToSelect);
                  },
                }}
              >
                {getColorList()}
              </BrandComponent>
            )}
          </div>

          <div
            className={
              "LensColor__btn_container" + (hasDetail() ? " borderOutline" : "")
            }
          >
            {hasDetail() ? (
              <>
                <div className="LensColor__detailsContainer_mobile">
                  <LensColorDetails
                    configColor={configColor}
                    selectedColor={selectedColor}
                  />
                </div>
                <div className="LensColor__detailsContainer_desktop">
                  {createPortal(
                    <LensColorDetails
                      configColor={configColor}
                      selectedColor={selectedColor}
                    />,
                    document.querySelector(
                      ".ProductContainer__productImageContainer__productImageContent"
                    )
                  )}
                </div>
              </>
            ) : null}{" "}
            {colorsG?.length > 0 && (
              <GenericButton
                handleClick={() => handleNextStep(false)}
                className={"LensColor__btn"}
                noArrow
              >
                <TranslateText label="steps.color.applyAndContinue" />
              </GenericButton>
            )}
          </div>
        </div>
        <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
      </BrandComponent>
    </>
  );
}
