import { isFunction } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StepCardProps } from "../components/StepCard/component";
import { workflowActions } from "../redux/slices/workflow";
import { STEP_PARAM_NAME } from "./ConfigLoader";
import { Step } from "./models/Step";
import { workflow } from "./models/Workflow";
import { modalsActions } from "../redux/slices/modals";
import { fold } from "fp-ts/lib/Either";
import { colorActions } from "../redux/slices/color";
import { useStepAlias } from "./hooks/useStepAlias";
import { useTreatmentsMapper } from "./hooks/useTreatmentsMapper";
import { useMixAndMatch } from "./hooks/useMixAndMatch";

export function useEditStep() {
  const getAliasStep = useStepAlias();
  const reduxDispatch = useDispatch();
  const mixAndMatch = useMixAndMatch();

  const editStep = (p: StepCardProps) => {
    if (mixAndMatch && p.id === "type") {
      p.id = "MixAndMatch";
    }
    const stepBack = workflow.findByStepKey(p.id);
    const GVPTreatmentStep = workflow.findByStepKey(
      getAliasStep("GVPTreatment")
    );
    if (stepBack && stepBack.progressive <= GVPTreatmentStep?.progressive) {
      reduxDispatch(workflowActions.setParameterPrice(null));
    }
    const LensColorStep = workflow.findByStepKey(getAliasStep("LensColor"));
    if (stepBack && stepBack.progressive < LensColorStep?.progressive) {
      reduxDispatch(colorActions.setShowFallbackImageMessage(false));
      reduxDispatch(colorActions.setShowNotFoundImageMessage(false));
    }
    if (stepBack) {
      reduxDispatch(workflowActions.setIsEditMode(true));
      fold(
        () => { },
        (newStep: Step) => {
          workflow.currentStep = newStep;
        }
      )(workflow.backToStepByKey(stepBack.key));
    }
  };

  const [editFn, setEditFn] = useState(null);

  useEffect(() => {
    if (isFunction(getAliasStep)) {
      const fn = () => (p: StepCardProps) => editStep(p);
      setEditFn(fn);
    }
  }, [getAliasStep]);

  return editFn;
}

const getNavStepKeyFromUrl = (queryString: string): string =>
  new URLSearchParams(queryString).get(STEP_PARAM_NAME);

const NAV_STEP_ON_PAGE_LOADED = getNavStepKeyFromUrl(
  location.href.toString().split("?")[1]
);

export function useWorkflowNavigation() {
  const steps = useSelector((state: any) => state.config.steps);
  const content = useSelector((state: any) => state.config.lensesData.content);
  const automaticBadgeStepSelection = useSelector(
    (state: any) => state.workflow?.automaticDiscountBadgeStepSelection
  );

  //const previousSelectedPackage = useSelector((state:any) => state.config.data?.lens?.catEntryId);
  //const allPackages = useSelector((state:any) => state.config.lensesData?.packages);
  //const initByLens = useSelector((state:any) => state.config.initFromEditCart);
  //const cartMode = useSelector((state: any) => state.config?.cartMode);

  const history = useHistory();
  const currentStep: Step = useSelector(
    (state: any) => state.workflow.currentStep
  );

  const treatmentsMapper = useTreatmentsMapper();

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (workflow && steps && history && treatmentsMapper && content) {
      /* workflow.currentStep=null; */
      workflow.stepChain = steps;

      //end read from config
      const unlisteChange = history.listen((location, action) => {
        //onNavigateChange(getNavStepKeyFromUrl(location.search));
        if (action === "POP") {
          workflow.goPrevStep();
        }
        //close learn more modals
        reduxDispatch(modalsActions.reset());
      });
      if (!workflow.currentStep) {
        //if(cartMode){//FROM CART
        //    if(previousSelectedPackage && allPackages && initByLens){//has all config data
        //        setTimeout(initByCart,500);
        //    }
        //}else{

        workflow.goNextStep([]);

        //close learn more modals
        //reduxDispatch(modalsActions.setOpenLearnMoreModal(null));
        //}
        window.dispatchEvent(new CustomEvent("LoadPrescription"));
      }

      return () => {
        unlisteChange();
      };
    }
  }, [steps, history, treatmentsMapper, content, workflow]);

  /*
    const initByCart = ()=>{
        const findedPackage= allPackages.find(p=>p.lensPackage.catEntryId===previousSelectedPackage);
        if(findedPackage){
            const stepsEdited:Step[]=initByLens(findedPackage, allPackages, content, treatmentsMapper);
            let params=null;
            //filter by params
            stepsEdited.forEach((s) => {
                if(s.params){
                    s.params.forEach(p=>{
                        if (p.key === "addOns") {
                            const mapped = {
                                [p.value.type]: p.value.value
                            }
                            params = { ...params, ...mapped };
                        } else {
                            const mapped = parameterToPlainAdapter.adapt(p);
                            if (mapped)
                                params = { ...params, ...mapped };   
                        }
                    });
                }
                
            });
            const filtered =allPackages.filter((s) => filterExistingProperties(s.lensPackage, params));

            const {included,treatments} = treatmentsMapper(filtered);
            reduxDispatch(workflowActions.setCurrentIncluded(included.map(i => ({ key: i.id, value: i.name }))));
            

            //questo codice serve a prendere le info sullo step dopo treatmentsFamily e settarne i valori in redux
            //brutto come la fame ma funziona per il momento
            let treatmentStepArray = stepsEdited.filter(step => {
                return step.key === 'Treatments'
            });

            if (treatmentStepArray.length) {
                let treatmentStep = treatmentStepArray[0];
                if (treatmentStep.navigated) {
                    let treatmentObj = new ParameterWithTitle();
                    let familyFilterParamList = treatmentStep.params.filter(param => param.key === 'familyFilter');
                    if (familyFilterParamList.length) {
                        let stepToFindTreatmentInfo = familyFilterParamList[0].value.nextStep;
                        let treatmentInfoKey = familyFilterParamList[0].value.required[0];
                        let stepForTreatmentInfo = stepsEdited.filter(step => step.key === stepToFindTreatmentInfo)[0];
                        stepForTreatmentInfo.params.map(param => {
                            if (param.key === treatmentInfoKey) {
                                treatmentObj.key = param.key;
                                treatmentObj.value = param.value;
                                treatmentObj.title = content[treatmentInfoKey][param.value].title;
                            }
                        })
                    }
                    reduxDispatch(workflowActions.setParameterPrice([treatmentObj]));
                }
            }

            workflow.stepChain=stepsEdited;
            workflow.currentStep=workflow.stepChain[workflow.stepChain.length-1];
        }
    }*/

  useEffect(() => {
    if (currentStep) {
      navigateTo(currentStep);
      if (workflow.stepIsLast(currentStep)) {
        reduxDispatch(workflowActions.enablePreselect(false));
      }

      const LensColorStep = workflow.findByStepKey("LensColor");
      if (currentStep.progressive < LensColorStep?.progressive) {
        reduxDispatch(colorActions.setShowFallbackImageMessage(false));
        reduxDispatch(colorActions.setShowNotFoundImageMessage(false));
      }

      if (
        automaticBadgeStepSelection &&
        currentStep.progressive <= automaticBadgeStepSelection.step?.progressive
      ) {
        reduxDispatch(
          workflowActions.setAutomaticDiscountBadgeStepSelection(null)
        );
      }
    }
  }, [currentStep]);

  const navigateTo = (step: Step) => {
    const pathSplitted = location.href.split("?");
    let params = new URLSearchParams();
    if (pathSplitted.length > 1) {
      params = new URLSearchParams(pathSplitted[1]);
      params.delete(STEP_PARAM_NAME);
    }
    params.set(STEP_PARAM_NAME, step.key);
    history.push({
      search: params.toString(),
      state: "rxc-configurator-nav",
    });
  };
}
