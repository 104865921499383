import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertAliasStep } from "../ConfigLoader";

export function useStepAlias() {
  const stepAliases = useSelector((state: any) => state.config.aliasSteps);
  const [stepAliasesFn, setStepAliasesFn] = useState(() => (id: any) => id);

  useEffect(() => {
    if (stepAliases) {
      const fn = () => (id: string) => convertAliasStep(stepAliases, id);
      setStepAliasesFn(fn);
    }
  }, [stepAliases]);

  return stepAliasesFn;
}
