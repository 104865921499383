import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

const useActivateMoneySavingsBadge = (): boolean => {
  const activateMoneySavingsBadge = useSelector(
    (state: any) => state.config.layoutSettings?.activateMoneySavingsBadge
  );
  const activateMoneySavingsBadgeCookie = getCookieByName(
    "activateMoneySavingsBadge",
    true
  );

  if (activateMoneySavingsBadgeCookie) {
    return activateMoneySavingsBadgeCookie as boolean;
  }
  if (activateMoneySavingsBadge) {
    return activateMoneySavingsBadge as boolean;
  }
  return false;
};

export default useActivateMoneySavingsBadge;
