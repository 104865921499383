import { Adapter } from "../models/Adapter";
import { Parameter, ParameterWithTitle } from "../models/Parameter";
import { ConfigContentModel } from "./ConfigContentParamsAdapter";

export class ParameterToParameterWithTitleAdapter extends Adapter<
  Parameter,
  ParameterWithTitle
> {
  content: Record<string, ConfigContentModel>;

  constructor(content: Record<string, ConfigContentModel>) {
    super();
    this.content = content;
  }

  adapt: (source: Parameter) => ParameterWithTitle = (source: Parameter) => {
    let ret: ParameterWithTitle = null;
    if (source) {
      ret = new ParameterWithTitle();

      ret = {
        ...source,
      };

      ret.title = source.value;
      if (
        source.key.toLowerCase() != "treatments" &&
        source.key.toLowerCase() != "treatmentsfamily" &&
        source.key.toLowerCase() != "addons"
      ) {
        if (
          this.content &&
          this.content[source.key] &&
          this.content[source.key][source.value]
        ) {
          ret.title = this.content[source.key][source.value].title;
        }
      }
    }

    return ret;
  };

  adaptReverse: (source: ParameterWithTitle) => Parameter = (
    source: ParameterWithTitle
  ) => {
    return source ? { ...source } : null;
  };
}
