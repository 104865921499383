import React, { ReactNode, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./default.module.scss";
import { workflow } from "../../configurator/models/Workflow";
import { useSelector } from "react-redux";

interface ScrollViewProps {
  children: ReactNode;
  idView?: string;
  autoHeight?: boolean;
  scrollTopValue?: number;
}

export function ScrollView({
  autoHeight,
  idView,
  scrollTopValue,
  children,
}: ScrollViewProps) {
  const references = useSelector((state: any) => state.references);

  const goBottom = () => {
    let scrollDiv = document.querySelector(".ScrollView__container");
    scrollDiv.scrollTop = scrollDiv.scrollHeight;
  };
  const [referenceName, setReferenceName] = useState("");
  useEffect(() => {
    console.log(references);
    setReferenceName("ScrollView__" + workflow?.currentStep?.key);
  }, [workflow.currentStep, references]);

  return (
    <Scrollbars
      className={"ScrollView__scrollbar"}
      autoHeight={autoHeight}
      autoHide
      renderView={(props) => {
        return (
          <div
            {...props}
            id={idView}
            className={
              "ScrollView__container" + (workflow.isLastStep() ? " review" : "")
            }
            style={{}}
          />
        );
      }}
      renderTrackVertical={(props) => (
        <div {...props} className={"ScrollView__trackVertical"} />
      )}
      renderThumbVertical={({ style, props }) => {
        const customStyle = {
          top: (scrollTopValue || 0) * 100,
        };
        return (
          <div
            {...props}
            className={"ScrollView__thumbVertical"}
            style={{ ...style, ...customStyle }}
          />
        );
      }}
    >
      <div className={"ScrollView__innerContainer"}>{children}</div>

      {!workflow.isLastStep() &&
        workflow.currentStep?.key !== "LensColor" &&
        workflow.currentStep?.key !== "TransitionColor" &&
        references[referenceName] == false && (
          <div onClick={goBottom} className={"ScrollView__toBottom"}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="30" height="30" rx="15" fill="#6D6D6D" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.3991 13.1031C19.3323 13.0343 19.2554 13 19.1685 13C19.0818 13 19.0049 13.0343 18.9381 13.1031L15.0001 17.1546L11.0621 13.1033C10.9953 13.0344 10.9185 13.0001 10.8316 13.0001C10.7448 13.0001 10.6679 13.0344 10.6012 13.1033L10.1002 13.6186C10.0333 13.6873 10 13.7664 10 13.8558C10 13.9451 10.0335 14.0242 10.1002 14.0929L14.7696 18.897C14.8364 18.9657 14.9132 19 15.0001 19C15.0869 19 15.1636 18.9657 15.2304 18.897L19.9 14.0929C19.9668 14.0242 20 13.9451 20 13.8558C20 13.7664 19.9668 13.6873 19.9 13.6185L19.3991 13.1031Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      {!workflow.isLastStep() &&
        (workflow.currentStep?.key === "LensColor" || workflow.currentStep?.key === "AdvancedPrescription") &&
        references[referenceName] == false && (
          <div className={"ScrollView__fade"} />
        )}
    </Scrollbars>
  );
}
