export const configuration = {
  brand: "default",
  brandName: "Rayban",
  steps: [
    "Type",
    "AdvancedPrescription",
    "LensBundle",
    "LensColor",
    "GVPTreatment",
    "DesignType",
    "Thickness",
    "Treatments",
    "AddOns",
    "ProtectionPlan",
    "Review",
  ],
  logoMap: {
    "Costa Del Mar": "LOGO01",
    "Ray-Ban_C": "LOGO02",
    "Ray-Ban_P": "LOGO03",
    "Ray-Ban": "LOGO04",
    Oakley: "LOGO05",
    Arnette: "LOGO08",
    Arnette_P: "LOGO09",
  },
  prescriptionModule: {
    mandatory: false,
    enableAgreementCheckbox: true,
    checkPrescriptionIssueDate: 24,
  },
  prescriptionTypes: {
    SIMPLE: "SIMPLE",
    FULL: "FULL",
    NESTED: "NESTED",
  },
  layoutSettings: {
    enableDesignTypeStep: false,
    enableSendItLater: true,
    activateMoneySavingsBadge: false,
    delegatingLearnMore: false,
    enableEPPStep: true,
    enableBrandLastStep: false,
    targetDigitalOptometry: [],
    enableAccordionReview: true,
  },
  skipConfiguration: {
    Type: "SELECT",
    LensColor: "SELECT",
  },
  reviewConfiguration: {
    Brand: "SHOW",
  },
  showLensPriceAtStep: "Thickness",
  //filterPromAtStep:'GVPTreatment',
  treatmentsOptions: [
    "clear",
    "blueLight",
    "uvProtection",
    "antiScratch",
    "antiReflective",
  ],
  addonsOptions: ["antiReflective", "antiScratch"],
  reviewOptions: [
    "blueLight",
    "uvProtection",
    "antiScratch",
    "antiReflective",
    "transition",
  ],
  imageSrcsetTemplate:
    "{url}{filename}@2x.{format} 2x, {url}{filename}@3x.{format} 3x",
  imageFrameLayer:
    "##URL##/extra/image/rxc/frames/##MODEL##__##COLOR##__RXP__FRAME__qt.png",
  imageLensNoLogoLayer:
    "?impolicy=CompositeNoLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLensLogoLayer:
    "?impolicy=CompositeLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLens: "##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLogo: "##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageLogoLayer:
    "&Logo=##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageTransitionLogo: "?impolicy=CompositeLogo&Lenses=",
  imageLensPreview:
    "##URL##/extra/image/rxc/lenspreview/LensPreview__RXP__##CLEN##__qt.png",
  prescriptionDefaults: {
    sphMin: -20,
    sphMax: 20,
    sphRes: 0.25,
    cylMin: -20,
    cylMax: 20,
    cylRes: 0.25,
    addMin: 0.75,
    addMax: 4,
    addRes: 0.25,
    pdHalfMin: 25,
    pdHalfMax: 38,
    pdHalfRes: 1,
    pdMin: 51,
    pdMax: 77,
    pdRes: 1,
    pdSphRangeMin: -3,
    pdSphRangeMax: 3,
    prismVertMin: 0.5,
    prismVertMax: 2,
    prismVertRes: 0.25,
    prismBaseVert: ["Up", "Down"],
    prismHorMin: 0.5,
    prismHorMax: 2,
    prismHorRes: 0.25,
    prismBaseHor: ["In", "Out"],
  },
  baseURLs: {
    genericImage:
      "https://assets.lenscrafters.com/extra/image/LensCrafters/global/",
    projectImage: "https://assets.lenscrafters.com/extra/image/rxc/images/",
    framesImage: "https://assets.lenscrafters.com/is/image/LensCrafters",
    extraImages: "https://images.ray-ban.com/extra/image/rxc/brands/RayBan",
    assetsCDN: "https://assets.lenscrafters.com",
    contextualMessages: "https://images.ray-ban.com/extra/image/rxc/ctxmsg",
    RIAConfig:
      "https://assets.lenscrafters.com/extra/image/rxc/ria/settings.json",
    learnMoreBaseEndpoint: "/wcs/resources/store/10851/espot/",
  },
  currencyFormat: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    prefix: "$",
  },
  translation: {
    language: "en_US",
  },
  linksData: {
    warrantyLearnMore: "/lc-us/purchase-care/details",
  },
  paymentInstallmentsTypes: {
    AFFIRM: "affirm",
  },
  paymentInstallment: {
    installments: 4,
  },
  RIAFlowConfiguration: {
    sunglasses: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "LensColor",
          },
        ],
      },
      LensColor: {
        prev: "Brand",
        key: "color",
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "LensColor",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    sunglassesGVP: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "LensColor",
          },
        ],
      },
      LensColor: {
        prev: "Brand",
        key: "color",
        next: [
          {
            step: "GVPTreatment",
          },
        ],
      },
      GVPTreatment: {
        prev: "LensColor",
        key: null,
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "Thickness",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "GVPTreatment",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
    sunFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
  },
};
