import React, { useEffect, useRef, useState } from "react"
import "./default.module.scss"

type Props = {
  initActiveTabKey?: string,
  children: any,
  onTabChange?: Function,
}

// Tabs child MUST BE a TabPanel component

export const Tabs = (props: Props) => {
  const [activeTabKey, setActiveTabKey] = useState(props.initActiveTabKey)
  const items = Array.isArray(props.children) ? props.children : !props.children ? null : [props.children]

  if (!items) return null

  return (
    <div className="Tabs">
      <ul aria-labelledby="tabs-title" role="tablist" className="Tabs__header">
        {items.map((item: any, index: number) => (
          <li key={index} role="presentation" style={{ display: item.props?.hide ? "none" : "list-item" }}>
            <a
              aria-selected={activeTabKey === item.props.tabKey}
              role="tab"
              id={item.props.tabKey}
              tabIndex={0}
              //tabIndex={activeTabKey === item.props.tabKey ? 0 : -1}
              onClick={() => {
                props?.onTabChange?.(index);
                setActiveTabKey(item.props.tabKey)
              }}
            >
              {item.props.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="Tabs__content" >
        {items.map((item: any) => React.cloneElement(item, {
          ...item.props,
          activeTabKey,
          key: item.props.tabKey
        }))}
      </div>

    </div>
  )
}