function copyToClipboard(data) {
  try {
    const ta = document.createElement("textarea");
    //remove the first "{" and the last "}" to simplify the import in mocked config
    ta.value = JSON.stringify(data).slice(1, JSON.stringify(data).length - 1);
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
  } catch (err) {
    console.error(`Impossible copy to clipboard!!!`);
    console.error(err);
  }
}

export default function (config) {
  const RXCUtils = {
    config: { ...config },
    copyData: () =>
      copyToClipboard({ data: config.data, lensesData: config.lensesData }),
  };
  //@ts-ignore
  window.RXCUtils = RXCUtils;
}
