import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCurrentBrand } from "./useCurrentBrand";
import { useTargetDigitalOptometry } from "@hooks";

type IPDOutput = {
  state: {
    PD1: number | null | undefined;
    PD2: number | null | undefined;
    showBothPdOptions: {
      value: boolean;
      ignoreSplitLogic: boolean;
    };
    showPDWarning: boolean;
    pdValueNotSelectedError: boolean;

    setPD1: (val: number) => void;
    setPD2: (val: number) => void;
    initializeFromPrescriptionObject: (obj) => void;
    setPDvalueNotSelectedError: (b: boolean) => void;
    resetPDState: Function;
    setShowBothPdOptions: Function;
    checkPDSelected: Function;
  };
};

type IPDInput = {
  prescription: any;
};

export function usePupillaryDistance(args: IPDInput): IPDOutput {
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const currentBrand = useCurrentBrand();
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const brand = useCurrentBrand();
  const [showBothPdOptions, setShowBothPdOptions] = useState({
    value: false,
    ignoreSplitLogic: false,
  });
  const [PD1, setPD1] = useState<number | null>(
    enableDigitalOptometry ? null : 63
  );

  const [PD2, setPD2] = useState<number | null>(null);
  const [showPDWarning, setShowPDWarning] = useState(false);
  const [pdValueNotSelectedError, setPDvalueNotSelectedError] = useState(false);


  useEffect(() => {
    if (checkTargetDigitalOptometry() === false) {
      if (!showBothPdOptions.value) {
        setPD1(63);
        setPD2(null);
      }
    }
  }, [showBothPdOptions.value]);

  const targetDigitalOptometry = useTargetDigitalOptometry();

  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.params?.[0]
  );

  const checkTargetDigitalOptometry = () => {
    if (targetDigitalOptometry) {
      if (
        targetDigitalOptometry.includes(currentStep.value) ||
        targetDigitalOptometry === "[]" ||
        targetDigitalOptometry.length === 0 ||
        !targetDigitalOptometry
      ) {
        return true;
      } else {
        return false;
      }
    }
  };


  useEffect(() => {
    if (checkTargetDigitalOptometry() === false) {
      if (!showBothPdOptions.value) {
        setPD1(63);
        setPD2(null);
      }
    }
  }, [showBothPdOptions.value]);


  useEffect(() => {
    if (
      showBothPdOptions.value &&
      (!args?.prescription || !args?.prescription?.myAccount)
    ) {
      if (!PD2) {
        if (currentBrand !== "clearly") {
          if (enableDigitalOptometry) {
            if (PD1 && PD1 % 2 !== 0) {
              setPD1(Math.floor(PD1) / 2);
              setPD2(Math.floor(PD1) / 2);
            } else {
              setPD1(PD1 / 2);
              setPD2(PD1 / 2);
            }
          } else {
            if (PD1 && PD1 % 2 !== 0) {
              setPD1((PD1 - 1) / 2);
              setPD2((PD1 - 1) / 2);
            } else {
              setPD1(PD1 / 2);
              setPD2(PD1 / 2);
            }
          }
        } else {
          if (PD1 && PD1 % 1 != 0) {
            setPD1((PD1 + 0.5) / 2);
            setPD2((PD1 + 0.5) / 2);
          } else if (PD1 && PD1 % 1 === 0) {
            setPD1(PD1 / 2);
            setPD2(PD1 / 2);
          } else {
            setPD1(63 / 2);
            setPD2(63 / 2);
          }
        }
      } else {
        setPD1(args?.prescription?.PD?.OD);
        setPD2(args?.prescription?.PD?.OS);
      }
    } else {
      if (showBothPdOptions.value) {
        if (args.prescription && args.prescription.PD.OS) {
          setPD1(args.prescription.PD.OD);
          setPD2(args.prescription.PD.OS);
        } else {
          if (!enableDigitalOptometry) {
            let valueToSet = Math.floor(PD1 / 2);
            setPD1(valueToSet);
            setPD2(valueToSet);
          } else {
            let valueToSet = PD1 / 2;
            setPD1(valueToSet);
            setPD2(valueToSet);
          }
        }
      } else {
        if (
          (enableDigitalOptometry && PD1) ||
          (!enableDigitalOptometry && PD1 !== 63)
        ) {
          setPD1((prev) => {
            return prev && (prev % 2 === 0 ? prev * 2 : prev * 2 + 1);
          });
        }
        setPD2(null);
      }
    }
  }, [showBothPdOptions]);

  // useEffect(() => {
  //   console.log(PD1);
  // }, [PD1]);

  useEffect(() => {
    if (!showBothPdOptions.value && PD2) {
      setPD1(null);
      setPD2(null);
    }
    if (PD1 === null && showBothPdOptions.value) {
      setPD1(null);
      setPD2(null);
    }
  }, [showBothPdOptions.value]);

  useEffect(() => {
    if (
      (!showBothPdOptions.value && PD1 && PD1 < 55) ||
      (!showBothPdOptions.value && PD1 && PD1 >= 76) ||
      (showBothPdOptions.value && PD1 && PD1 <= 27 && PD2 && PD2 <= 27) ||
      (showBothPdOptions.value && PD1 && PD1 >= 38 && PD2 && PD2 >= 38)
    ) {
      setShowPDWarning(true);
    } else if (showPDWarning) {
      setShowPDWarning(false);
    }
  }, [PD1, PD2]);

  useEffect(() => {
    /* if (brand === "opsm") {
      setPD1(null);
    } */
    initializeFromPrescriptionObject(args.prescription);
  }, [args.prescription]);

  const initializeFromPrescriptionObject = (prescObj) => {
    if (prescObj?.PD) {
      if (prescObj.PD.OD && prescObj.PD.OS) {
        setPD1(parseFloat(prescObj.PD.OD));
        setPD2(parseFloat(prescObj.PD.OS));
        setShowBothPdOptions({
          value: true,
          ignoreSplitLogic: true,
        });
      } else if (prescObj.PD.OD && !prescObj.PD.OS) {
        setPD1(parseFloat(prescObj.PD.OD));
      }
    }
  };

  const setPD1Fn = (val) => {
    setPD1(parseFloat(val));
    setPDvalueNotSelectedError(false);
  };

  const setPD2Fn = (val) => {
    setPD2(parseFloat(val));
    setPDvalueNotSelectedError(false);
  };

  const resetPDState = () => {
    setPD1(brand === "opsm" ? null : 63);
    setPD2(null);
  };

  const checkPDSelected = () => {
    if (showBothPdOptions.value) {
      if (PD1 && PD2) {
        setPDvalueNotSelectedError(false);
        return true;
      } else {
        setPDvalueNotSelectedError(true);
        return false;
      }
    } else {
      if (PD1) {
        setPDvalueNotSelectedError(false);
        return true;
      } else {
        setPDvalueNotSelectedError(true);
        return false;
      }
    }
  };

  return {
    state: {
      PD1,
      PD2,
      setPD1: setPD1Fn,
      setPD2: setPD2Fn,
      initializeFromPrescriptionObject,
      setShowBothPdOptions,
      resetPDState,
      setPDvalueNotSelectedError,
      pdValueNotSelectedError,
      showBothPdOptions,
      showPDWarning,
      checkPDSelected,
    },
  };
}
