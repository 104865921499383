import { useSelector } from "react-redux";

export function useBundlesPrices() {
  const bundles = useSelector(
    (state: any) => state.config?.lensesData?.bundles
  );
  const bundlesPrices = useSelector(
    (state: any) => state.config?.lensesData?.bundlesPrices
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  return bundles?.length && bundlesPrices?.length && !insuranceEnabled
}