import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useProtectionPlan() {
  const enableEPPCockie = getCookieByName("enableEPPStep", true);
  const enableEPP = useSelector(
    (state: any) => state.config.layoutSettings?.enableEPPStep
  );

  if (enableEPPCockie !== null) {
    return enableEPPCockie as boolean;
  }

  if (enableEPP !== null && enableEPP !== undefined) {
    return enableEPP as boolean;
  }

  return false;
}
