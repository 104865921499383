import React, { useEffect } from 'react'
import { useTypes } from '../../configurator/hooks';
import { BrandComponent } from '../common/BrandComponent';
import { mixAndMatchActions } from '../../redux/slices/mixAndMatch';
import { useDispatch, useSelector } from 'react-redux';
import { workflow } from '../../configurator/models/Workflow';
import { colorActions } from '../../redux/slices/color';

export function PrescriptionLenses() {
  const frame = useSelector((state: any) => state.config.data.frame);
  const productImageUrl = useSelector((state: any) => state.color.urlImage);
  let imgUrl = useSelector((state: any) => state.config?.data?.frame?.imageUrl);
  const types = useTypes();
  const dispatch = useDispatch();
  const handleTypeSelected = (type) => {
    dispatch(colorActions.setFrameImgUrl(imgUrl));
    workflow.cleanAllStepChain();
    dispatch(mixAndMatchActions.setSelectedType(type.id))
    types.onSelect(type.id, false, false)
  }
  useEffect(() => {
    dispatch(mixAndMatchActions.setLensPrice(0))
    dispatch(colorActions.selectTransition(null));
    dispatch(colorActions.selectColor(null));
    dispatch(colorActions.setFrameImgUrl(null));
    dispatch(colorActions.setClearClen(""))
    // dispatch(colorActions.setClearClen("CLEAR"));
    // return () => {
    //   dispatch(colorActions.setClearClen(""));
    // }
  }, [])
  return (
    <div className="Cards" >
      {/* {types?.types?.map((type: any) => (
        // <div
        //   className="Card"
        //   onClick={() => types.onSelect(type.id, false, false)}
        //   key={type.id}
        // >
        //   <button>
        //     <div className="Card__title">{type.title}</div>
        //     <div className="Card__content">{type.description}</div>
        //   </button>
        // </div>
        <BrandComponent key={type.id} componentName="StepCard" parameter={{ ...type, onClick: () => types.onSelect(type.id, false, false) }} />
      ))} */}
      {types?.types?.filter(type => type.id !== "Non_prescription").map((type: any) => (
        <BrandComponent key={type.id} componentName="StepCard" parameter={{ ...type, onClick: () => handleTypeSelected(type) }} />
      ))}
    </div>
  );
};