import React, { useEffect, useState } from "react";
import { BrandComponent } from "../../../components/common/BrandComponent";
import {
  callInsuranceService,
  checkLayoutConfigDesignType,
  getStepBadgeType,
  sortingGreyOut,
} from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useConfigContent,
  useContextualMessage,
  useMoreInfo,
  usePriceStepItem,
  useGetDiscount,
  useCurrentType,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { useDispatch, useSelector } from "react-redux";
import "./default.module.scss";
import { groupBy } from "lodash";
import { configObjectToSliderObject } from "../../adapters/ConfigObjectToSliderObject";
import ReferencedSection from "../../../components/common/ReferencedSection";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
/**
 * This component displays the 'thickness' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 */
export function Thickness(props: StepComponentOptions) {
  const translate = useTranslation();
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.thickness
  );
  const enableDesignTypeStep = useSelector(
    (state: any) => state.config?.layoutSettings?.enableDesignTypeStep
  );
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.thickness.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.thickness.learnMoreCTA");
  const config = useSelector((state: any) => state.config);
  const currentType = useCurrentType();
  const onThicknessSelected = (
    selected: { id: string; designType: string } | null,
    skipped?: boolean
  ) => {
    if (!selected) {
      workflow.goNextStep([]);
      //TODO
      //console.log(no value)
      return;
    }
    const filterP: any = { thickness: selected.id };
    if (selected.designType) {
      filterP.designType = selected.designType;
    } else if (designTypeSelected) {
      filterP.designType = designTypeSelected;
    }

    let filteredPackages = currentPackages.validRXPackages.filter(
      (pkg) =>
        (filterP.designType && pkg.lensPackage.designType) ||
        !filterP.designType
    );
    const priceObj = getPriceStep(filterP, filteredPackages);
    if (!skipped) {
      let designTypeSelection = {};
      if (Object.keys(filterP).includes("designType")) {
        designTypeSelection = {
          key: "designType",
          value: filterP.designType,
        };
      }
      if (Object.keys(designTypeSelection).length > 0) {
        workflow.goNextStep(
          [
            {
              key: "thickness",
              value: selected.id,
              price: priceObj?.price,
              discount: priceObj?.discount,
              insPrice:
                skipped && priceObj?.price == 0 ? 0 : priceObj?.insPrice,
              attributes: {
                designType: filterP.designType,
              },
            },
            {
              key: designTypeSelection.key,
              value: designTypeSelection.value,
            },
          ],
          skipped
        );
      } else {
        workflow.goNextStep(
          [
            {
              key: "thickness",
              value: selected.id,
              price: priceObj?.price,
              discount: priceObj?.discount,
              insPrice:
                skipped && priceObj?.price == 0 ? 0 : priceObj?.insPrice,
              attributes: {
                designType: filterP.designType,
              },
            },
          ],
          skipped
        );
      }
    } else {
      workflow.goNextStep(
        [
          {
            key: "thickness",
            value: selected.id,
            price: priceObj?.price,
            discount: priceObj?.discount,
            insPrice: skipped && priceObj?.price == 0 ? 0 : priceObj?.insPrice,
            attributes: {
              designType: filterP.designType,
            },
          },
          ...workflow.currentStep.params,
        ],
        skipped
      );
    }
  };

  const configThickness: any[] = useConfigContent("Thickness");
  const mapperMoreInfo = useMoreInfo("thickness");
  const currentPackages = useAllCurrentPackages();
  const getPriceStep = usePriceStepItem();

  const [thickness, setThickness] = useState(null);

  //DesignType
  const [designTypesAvaiable, setDesignTypesAvaiable] = useState(null);
  const [designTypeSelected, setDesignTypelected] = useState(null);
  const configDesignType = useSelector(
    (state: any) => state.config?.lensesData?.content?.designType
  );
  const { getDiscount, isLoading } = useGetDiscount();

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);

  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      currentPackages &&
      configDesignType &&
      !checkLayoutConfigDesignType(config)
    ) {
      const currentDesignTypes = groupBy(
        currentPackages.validRXPackages.filter((cp) => {
          return cp.lensPackage.designType;
        }),
        "lensPackage.designType"
      );
      const greyOutDesignTypes = groupBy(
        currentPackages.invalidRXPackages.filter((cp) => {
          return cp.lensPackage.designType;
        }),
        "lensPackage.designType"
      );
      const deisgnTypesId = Object.keys(currentDesignTypes);
      const greyOutDesignTypesId = Object.keys(greyOutDesignTypes).filter(
        (dt) => !deisgnTypesId.find((desT) => desT === dt)
      );

      //sort designType keys by sequence in content
      deisgnTypesId.sort((a, b) => {
        return configDesignType[a]?.sequence - configDesignType[b]?.sequence;
      });

      greyOutDesignTypesId.sort((a, b) => {
        return configDesignType[a]?.sequence - configDesignType[b]?.sequence;
      });

      const allDesignTypesId = [...deisgnTypesId, ...greyOutDesignTypesId];

      if (allDesignTypesId.length > 1) {
        let designTypes = [
          ...deisgnTypesId.map((key) => ({
            id: key,
            title:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].title
                ? configDesignType[key].title
                : translate("steps.lensColor.designType." + key + ".title"),
          })),
        ];
        let greyOutDesignTypes = [
          ...greyOutDesignTypesId.map((key) => ({
            id: key,
            title:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].title
                ? configDesignType[key].title
                : translate("steps.lensColor.designType." + key + ".title"),
          })),
        ];
        setDesignTypesAvaiable(
          configObjectToSliderObject.adaptList(
            designTypes.concat(greyOutDesignTypes)
          )
        );
        setDesignTypelected(designTypes[0].id);
      }
    }
  }, [currentPackages, configDesignType]);

  const handleCallbackDesignType = (desType) => {
    if (isInsuranceEnabled) {
      let filteredPackages = currentPackages.validRXPackages.filter((p) => {
        return p.lensPackage.designType === desType;
      });
      let insurablePackages = filteredPackages.filter(
        (pkg) => pkg.lensPackage.insurable
      );
      if (insurablePackages.length) {
        callInsuranceService(
          filteredPackages,
          configInsurance,
          frame,
          dispatch
        );
      }
    }
    setDesignTypelected(desType);
  };

  useEffect(() => {
    if (currentPackages && configThickness && mapperMoreInfo) {
      let thicknessList = null;

      const configOptions = configThickness.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.thickness === c.id
        )
      );
      const greyOutConfigOptions = configThickness
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.thickness === c.id
          )
        )
        .map((th) => {
          return {
            ...th,
            disabled: true,
          };
        })
        .filter(
          (greyOutTh) => !configOptions.find((th) => th.id === greyOutTh.id)
        );

      if (designTypeSelected && designTypeSelected.length) {
        const f = currentPackages.validRXPackages.filter(
          (cp) => cp.lensPackage.designType === designTypeSelected
        );
        const greyOutF = currentPackages.invalidRXPackages.filter(
          (cp) => cp.lensPackage.designType === designTypeSelected
        );
        thicknessList = configOptions.filter((c) =>
          f.some((cp) => cp.lensPackage.thickness === c.id)
        );
        let greyOutThicknessList = greyOutConfigOptions.filter((c) =>
          greyOutF.some((cp) => cp.lensPackage.thickness === c.id)
        );

        thicknessList = mapperMoreInfo(thicknessList);
        thicknessList = thicknessList.concat(greyOutThicknessList);
      } else {
        thicknessList = configOptions;
        thicknessList = mapperMoreInfo(thicknessList);
        thicknessList = configOptions.concat(greyOutConfigOptions);
        thicknessList = sortingGreyOut(thicknessList);
      }
      setThickness(thicknessList);
    }
  }, [configThickness, currentPackages, designTypeSelected, mapperMoreInfo]);

  useEffect(() => {
    if (!thickness) return;
    for (const t of thickness) {
      const { id } = t;
      getDiscount(id, currentPackages.validRXPackages, { thickness: id });
    }
  }, [thickness]);

  const getKey = (title: string) =>
    "Thickness_sc_" + title.split(" ").join("_");

  const getParameter = (c: any) => {
    if (currentPackages) {
      const filterP: any = { thickness: c.id };
      if (designTypeSelected) {
        filterP.designType = designTypeSelected;
      }
      let filteredPackages = currentPackages.validRXPackages.filter(
        (pkg) =>
          (designTypeSelected && pkg.lensPackage.designType) ||
          !designTypeSelected
      );
      const priceObj = c.disabled
        ? null
        : getPriceStep(filterP, filteredPackages);
      return {
        ...c,
        textBadge: c.badge,
        imageUrl: c.imageFileName,
        price: priceObj?.price,
        discount: priceObj?.discount,
        insPrice: priceObj?.insPrice,
        badge: c.badge ? getStepBadgeType(c.badge) : null,
        disabled: c.disabled || c.badge == "coming soon",
        onClick:
          c.badge == "coming soon" || c.disabled
            ? null
            : () => onThicknessSelected(c),
        discountLoading: isLoading(c.id),
      };
    }
  };

  const designTypeDescription = useSelector((state: any) =>
    designTypeSelected
      ? state.config?.lensesData?.content?.designType[designTypeSelected]
          ?.description
      : null
  );

  const checkLenght = (prop) => {
    if (prop.length <= 1) {
      return null;
    }
    return prop;
  };
  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={
        currentType !== "SUNGLASSES" && !enableDesignTypeStep
          ? {
              title: designTypesAvaiable
                ? translate("steps.thickness.designTypeTitle")
                : translate("steps.thickness.title"),
              subtitle: designTypesAvaiable ? null : ctxMessage,
              pillsSubtitle: designTypeDescription
                ? designTypeDescription
                : null,
              pills: designTypesAvaiable,
              pillsCallback: handleCallbackDesignType,
              pillSelected: designTypeSelected,
              showGrayOutBanner:
                thickness && thickness.find((th) => th.disabled),
              /* slider: checkLenght(allDesignTypes), designTypeChangeCallback: handleCallbackDesignType */
            }
          : {
              title: translate("steps.thickness.title"),
              subtitle: ctxMessage,
            }
      }
    >
      {learnMoreButton !== "steps.thickness.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensMaterial-LearnMore"
            }
          >
            {moreInfoLabel !== "" &&
            moreInfoLabel !== undefined &&
            moreInfoLabel !== null &&
            moreInfoLabel !== "steps.thickness.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"Thickness__container"}>
        {designTypesAvaiable && (
          <>
            <div className="Thickness__container__separator" />
            <div className="Thickness__container__title">
              <BrandComponent
                componentName="StepTitle"
                parameter={
                  currentType !== "SUNGLASSES"
                    ? {
                        title: translate("steps.thickness.title"),
                        subtitle: ctxMessage,
                      }
                    : {}
                }
              />
            </div>
          </>
        )}
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Thickness",
            onNext: (selected: any, skipped?: boolean) => {
              //onTypeSelected(selected?.id,skipped);
              if (!selected) {
                onThicknessSelected(null, skipped);
              } else {
                onThicknessSelected(selected, skipped);
              }
            },
            ignoreSkip: designTypesAvaiable && designTypesAvaiable.length > 1,
            parameterPreselection: ["thickness", "designType"],
            onPreselect: (selected, skipped: boolean) => {
              let thicknessObj = {
                id: selected.thickness,
                designType: selected.designType,
              };
              onThicknessSelected(thicknessObj, skipped);
            },
          }}
        >
          {thickness &&
            thickness.map((c: any) => (
              <BrandComponent
                key={getKey(c.title)}
                componentName="StepCard"
                parameter={getParameter(c)}
              />
            ))}
        </BrandComponent>
      </div>
      <ReferencedSection
        name={"ScrollView__" + workflow?.currentStep?.key}
        style={
          ["oliverpeoples", "persol"].includes(props.brand) && {
            paddingTop: "2.5rem",
          }
        }
      />
    </BrandComponent>
  );
}
