import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useEnableBrandLastStep() {
  const enableBrandLastStepCookie = getCookieByName(
    "enableBrandLastStep",
    true
  );

  const enableBrandLastStepConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.enableBrandLastStep
  );

  if (
    enableBrandLastStepCookie !== null &&
    enableBrandLastStepCookie !== undefined
  ) {
    return enableBrandLastStepCookie as boolean;
  }
  if (
    enableBrandLastStepConfig !== null &&
    enableBrandLastStepConfig !== undefined
  ) {
    return enableBrandLastStepConfig as boolean;
  }
  return false;
}
