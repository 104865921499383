import { isArray, isFunction } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import GenericButton from "../../../components/common/GenericButton";
import { TranslateText } from "../../../components/common/TranslateText";
import {
  callInsuranceService,
  checkGVP,
  getCheapPackageFromList,
  getFrameOnlyKey,
  checkTypeValueSelected,
  isBundlePriceEnabled,
} from "../../ConfigLoader";
import {
  useContextualMessage,
  useCurrentBrand,
  useCurrentPackages,
  useCurrentPrices,
  useCurrentType,
  useEnableAccordionReview,
  useEnableBrandLastStep,
  useProductImage,
  useProtectionPlan,
  useReviewIncludedTreatmentsMapper,
  useReviewSteps,
  useTransitionColors,
} from "@hooks";
import {
  useTranslate,
  useTranslation,
} from "../../translations/TranslationHooks";
import { useEditStep } from "../../WorkflowUtils";
import { StepComponentOptions } from "../StepComponent";
import HtmlParser from "html-react-parser";
import "./default.module.scss";
import { modalsActions } from "../../../redux/slices/modals";
import { workflow } from "../../models/Workflow";
import { useInstallmentsLabel } from "../../hooks";
import { workflowActions } from "../../../redux/slices/workflow";
import ReferencedSection from "../../../components/common/ReferencedSection";
import Image from "../../../components/common/Image";
import { ManualTable } from "../../../components/PrescriptionReviewSeeDetails/component";
import { ReadOnlyTable } from "../../../components/ReadOnlyTable/component";
import UploadTableReadOnly from "../../../components/UploadTableReadOnly/component";
import { CallMyDoctorReadOnly } from "../../../components/CallMyDoctorReadOnly/component";
import { isDesktop, isMobile } from "react-device-detect";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";

/**
 * This component displays the 'review' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 * this component get the history of selection by method ```loadHistorySteps``` and treatmnets by method ```loadReviewTreatments```;
 * this component also call toogle insurance for package and addtocart to complete order.
 */

export function Review(props: StepComponentOptions) {
  const translation = useTranslation();
  const currentPrices = useCurrentPrices();
  const enableEPPStep = useProtectionPlan();
  const enableAccordionReview = useEnableAccordionReview();
  const vatIncluded = useSelector(
    (state: any) => state.config?.data?.vatIncluded
  );
  const currencyFormat = useSelector(
    (state: any) => state.config?.currencyFormat
  );
  const references = useSelector((state: any) => state.references);
  const config = useSelector((state: any) => state.config);
  const [pricesToDisplay, setPricesToDisplay] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [promoInPackage, setPromoInPackage] = useState("");
  const stepsSelection = useSelector((state: any) => state.config.steps);
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.review
  );
  const brandConfig = useSelector((state: any) => state?.config?.brand);
  const brand = useCurrentBrand();
  const frameConfigData = useSelector(
    (state: any) => state.config?.data?.frame
  );
  const lensType = useSelector((state: any) => state?.workflow?.lensType);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const configData = useSelector((state: any) => state.config?.data);

  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const [historyStep, setHistoryStep] = useState(null);
  const [treatmentsStep, setTreatmentsStep] = useState(null);
  const currentTreatments = useSelector(
    (state: any) => state.workflow?.currentTreatments
  );
  const [open, setOpen] = useState(false);
  const [openPrism, setOpenPrism] = useState(false);
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  //FALLBACK
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const configPaymentInstallments = useSelector(
    (state: any) => state.config?.paymentInstallment
  );
  const promoType = useSelector((state: any) => state.workflow?.promoType);
  const { type, multiplePaymentInstallment: isMultiplePayments } =
    configPaymentInstallments;
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const genericImage = useSelector(
    (state: any) => state.config?.baseURLs?.genericImage
  );
  const onEdit = useEditStep();
  const { urlImage: productImagePath } = useProductImage();

  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const removeInsuranceLabel = useTranslate("insuranceButtonLabelRemove");
  const addToBagLabel = useTranslate("steps.treatments.addToBag");
  const eligibleForInsurance = useTranslate(
    "steps.review.eligibleForInsurance"
  );
  const visionCareTooltipTitle = useTranslate(
    "steps.review.visionCareTooltipTitle"
  );
  const visionCareTooltipDesc = useTranslate(
    "steps.review.visionCareTooltipDesc"
  );
  const learnMoreButton = useTranslate("steps.review.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.review.learnMoreCTA");
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const configActionLoadContent = useSelector(
    (state: any) => state.config?.actionsModule?.loadContent
  );
  //prescription table variables
  const tableColumns = useSelector(
    (state: any) => state.prescription?.tableColumns
  );
  //product container mobile
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const showNotFoundImageMessage = useSelector(
    (state: any) => state.color?.showNotFoundImageMessage
  );
  const fallbackImageMessageTitle = useTranslate("fallbackImageMessageTitle");
  const fallbackImageMessageSubTitle = useTranslate(
    "fallbackImageMessageSubtitle"
  );
  //product container mobile
  const [promoBanners, setPromoBanners] = useState({
    desktop: "",
    mobile: "",
  });

  const [renderSectionPlaceholder, setRenderSectionPlaceholder] =
    useState(false);

  const [alreadyIncluded, setalreadyIncluded] = useState(null);

  const loadTreatments = useReviewIncludedTreatmentsMapper();

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
  const [isIncludedOpen, setIsIncludedOpen] = useState(false);
  const currentPackages = useCurrentPackages();
  const selectedLensBrand = useSelector((state: any) => state.color.lensBrand);
  const lensBrandTitle = useSelector(
    (state: any) =>
      state.config?.lensesData.content.brand[selectedLensBrand]?.title
  );
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const brandLastStep = useEnableBrandLastStep();
  const { isNestedTransitionSwatchEnabled } = useTransitionColors(null);
  const reduxDispatch = useDispatch();
  const installmentsLink = translation(
    `steps.review.paymentInstallments.installmentsLabel`
  );

  const confirmedTitlesMap = {
    type: useTranslate("steps.type.confirmedTitle"),
    brand: useTranslate("steps.brand.confirmedTitle"),
    advancedPrescription: useTranslate(
      "steps.advancedPrescription.confirmedTitle"
    ),
    designType: useTranslate("steps.designType.confirmedTitle"),
    lensBundle: useTranslate("steps.lensBundle.confirmedTitle"),
    treatmentsFamily: useTranslate("steps.treatmentsFamily.confirmedTitle"),
    gvpTreatment: useTranslate("steps.gvpTreatment.confirmedTitle"),
    thickness: useTranslate("steps.thickness.confirmedTitle"),
    treatments: useTranslate("steps.treatments.confirmedTitle"),
    transitionColor: useTranslate("steps.transitionColor.confirmedTitle"),
    lensColor: useTranslate("steps.lensColor.confirmedTitle"),
    color: useTranslate("steps.color.confirmedTitle"),
    addOns: useTranslate("steps.addOns.confirmedTitle"),
    alreadyIncluded: "alreadyIncluded",
  };

  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType();
  const isInsurable = () => {
    return !!configInsurance;
  };
  const handleInstallmentsAction = useSelector(
    (state: any) => state.config?.paymentInstallment?.onClick
  );
  let reviewSteps = useReviewSteps();

  const remappedReviewSteps = useMemo(() => {
    //replacing type step value if reopening rxc from cart with Reading
    let reviewStepsCopy = null;
    if (reviewSteps?.length > 0) {
      reviewStepsCopy = reviewSteps;
      if (currentPrescription && currentPrescription?.rxType === "READING") {
        let typeStep = reviewStepsCopy?.filter(
          (step: any) => step?.key?.toLowerCase() === "type"
        );
        reviewStepsCopy = reviewStepsCopy?.filter(
          (step: any) => step?.key?.toLowerCase() !== "type"
        );
        if (typeStep && typeStep.length > 0) {
          let readingContent = configContent["type"]["Reading"];
          if (readingContent) {
            typeStep[0].value = readingContent.title;
            typeStep[0].title = readingContent.title;
          }
          reviewStepsCopy.unshift(typeStep?.[0]);
        }
      }
    }
    return reviewStepsCopy;
  }, [reviewSteps, currentPrescription]);

  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  useEffect(() => {
    if (currentStep?.key.toLowerCase() === "review") {
      reduxDispatch(workflowActions.setLoadingStep(false));
      reduxDispatch(workflowActions.setHasRecordedImpression(true));
    }
  }, [currentStep]);

  useEffect(() => {
    if (!alreadyIncluded && loadTreatments) {
      const { included, treatments } = loadTreatments(currentPackages);

      setalreadyIncluded({
        included: included
          .reverse()
          ?.map((t) =>
            t?.name?.includes("steps.treatments.extraTreatments")
              ? { ...t, name: translation(t.name) }
              : t
          ),
        treatments: treatments,
      });
    }
  }, [currentPackages, loadTreatments, alreadyIncluded]);

  useEffect(() => {
    setRenderSectionPlaceholder(true);
  }, []);

  const getReviewObjectForCart = (history: any) => {
    let ret = {};
    history = history.filter(
      (hs: any) => hs?.id?.toLowerCase() !== "protectionplan"
    );
    history.forEach((step, index) => {
      let stepObj = { sequence: index + 1 };
      stepObj["name"] = confirmedTitlesMap[step?.id];

      if (step.id !== "alreadyIncluded" || typeof step.title !== typeof []) {
        stepObj["description"] = step.title;
        if (
          step.strikePrice !== undefined &&
          step.strikePrice !== null &&
          !isNaN(step.strikePrice)
        ) {
          stepObj["listPrice"] = step.strikePrice;
        }
        if (
          step.offerPrice !== undefined &&
          step.offerPrice !== null &&
          !isNaN(step.offerPrice)
        ) {
          stepObj["offerPrice"] = step.offerPrice;
        }
      } else {
        let childrenArray = step.title?.map((selection, index) => {
          let retObj = { sequence: index + 1, name: selection };
          if (index === 0) {
            retObj["listPrice"] = step.strikePrice;
            retObj["offerPrice"] = step.offerPrice;
            if (
              isInsuranceEnabled &&
              currentPrices.lens.insPrice !== undefined &&
              currentPrices.lens.insPrice !== null &&
              !isNaN(currentPrices.lens.insPrice)
            ) {
              retObj["insPrice"] = currentPrices.lens.insPrice;
            }
          }
          return retObj;
        });
        const entries = Object.entries(ret).map((entry: any) => entry[0]);
        if (entries.includes("AddOns")) {
          childrenArray.unshift({
            sequence: 0,
            name: ret["AddOns"].description
              ? ret["AddOns"].description
              : "No treatment",
          });
          delete ret["AddOns"].description;
          ret["AddOns"]["children"] = childrenArray;
        } else {
          stepObj["name"] = confirmedTitlesMap.addOns;
          stepObj["children"] = childrenArray;
        }
      }
      ret[step.originalStep] = stepObj;
    });
    if (ret["GVPTreatment"]?.description?.includes("Blue")) {
      let addOnsSelections = ret["AddOns"]?.children;
      if (addOnsSelections?.some((ch: any) => ch?.name?.includes("Blue"))) {
        delete ret["GVPTreatment"];
      }
    }
    delete ret["undefined"];
    console.log(ret);
    return ret;
  };
  useEffect(() => {
    if (
      remappedReviewSteps &&
      alreadyIncluded &&
      (!isInsuranceEnabled || (isInsuranceEnabled && !isInsuranceLoading))
    ) {
      let remappedReviewStepsCopy = remappedReviewSteps;
      let history = remappedReviewStepsCopy.map((p) => ({
        id: p.key,
        title: p.title,
        originalStep: p.originalStep,
        onClick: () => { },
        strikePrice: p.price,
        offerPrice: p.price - p.discount,
        insPrice: p.insPrice,
        attributes: p.attributes,
        optionalAttributes: p.optionalAttributes,
      }));
      if (alreadyIncluded.included.length) {
        let addOnsSelections = remappedReviewStepsCopy?.find(
          (rs: any) => rs?.key === "addOns"
        );
        let filteredIncluded;
        if (addOnsSelections) {
          let replacedSelections = addOnsSelections?.title?.replace("-", "");
          filteredIncluded = alreadyIncluded.included.filter(
            (ai: any) =>
              !replacedSelections?.toLowerCase().includes(ai?.id.toLowerCase())
          );
        }
        let mappedTitles = filteredIncluded?.map((inc: any, index) => {
          return (
            inc.name +
            (index !== alreadyIncluded.included.length - 1 ? "," : "")
          );
        });
        let alreadyIncNames = alreadyIncluded?.included?.map(
          (ai: any, index) => {
            return (
              ai.name +
              (index !== alreadyIncluded.included.length - 1 ? "," : "") +
              " "
            );
          }
        );
        let alreadyInc = {
          id: "alreadyIncluded",
          title:
            mappedTitles !== undefined && mappedTitles !== null
              ? mappedTitles
              : alreadyIncNames,
          originalStep: !addOnsSelections ? "AddOns" : undefined,
        };
        history.push(alreadyInc);
      }
      if (history && history.length !== 0) {
        if (lensType?.toLowerCase() !== "frame only") {
          let protectionStep = history.find(
            (hs: any) => hs.id === "protectionplan"
          );
          if (brandLastStep) {
            let brandStep = history.find(
              (hs: any) => hs.id.toLowerCase() === "brand"
            );
            history = history.filter(
              (hs: any) => hs.id.toLowerCase() !== "brand"
            );
            brandStep && history.push(brandStep);
          }
          if (protectionStep) {
            history = history.filter(
              (hs: any) => hs.id.toLowerCase() !== "protectionplan"
            );
            history.push(protectionStep);
          }
          setHistoryStep(history);
          console.log(history);
          reduxDispatch(
            workflowActions.setReviewObjectForCart(
              getReviewObjectForCart(history)
            )
          );
        } else {
          setHistoryStep(history);
        }
      }
    }
  }, [
    remappedReviewSteps,
    alreadyIncluded,
    isInsuranceEnabled,
    isInsuranceLoading,
    currentPrices,
  ]);

  useEffect(() => {
    if (currentTreatments && !checkGVP(currentPackages)) loadReviewTreatments();
  }, [currentTreatments, currentPackages]);

  useEffect(() => {
    if (currentPrices) {
      setPricesToDisplay({
        frame: currentPrices.frame,
        lens: currentPrices.lens,
        total: currentPrices.total,
      });
    }
  }, [currentPrices]);

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  const loadReviewTreatments = () => {
    let priceSum = 0;
    let discountSum = 0;
    let insPriceSum = 0;
    let titles = [];

    let filteredTreatments = currentTreatments.filter((treatment) => {
      return treatment.key !== "photocromic";
    });

    filteredTreatments.forEach((ct) => {
      if (!ct.skipped) {
        titles.push(ct.title);
        priceSum += parseFloat(ct.price);
        discountSum += parseFloat(ct.discount);
        insPriceSum += ct.insPrice ? parseFloat(ct.insPrice) : 0;
      }
    });
    if (insPriceSum === 0) {
      insPriceSum = undefined;
    }
    if (titles.length) {
      setTreatmentsStep({
        title: "treatments",
        selection: titles,
        strikePrice: priceSum,
        offerPrice: priceSum - discountSum,
        insPrice: insPriceSum,
      });
    }
  };
  const handleStepEditClick = (stepName: string) => {
    let stepToEdit = stepName;
    if (stepToEdit === "TransitionColor" && isNestedTransitionSwatchEnabled) {
      if (historyStep.find((s) => s.id === "treatments")) {
        stepToEdit = "Treatments";
      } else {
        stepToEdit = "TreatmentsFamily";
      }
    }
    onEdit({ id: stepToEdit });
  };

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  const toggleOpen = () => {
    open === true ? setOpen(false) : setOpen(true);
  };
  const toggleOpenPrism = () => {
    openPrism === true ? setOpenPrism(false) : setOpenPrism(true);
  };
  // useEffect(() => {
  //   checkTableVisible();
  // }, [open]);

  //Yeah, this is a rushed ugly fix. We gotta think this through
  const shouldShowTreatments = (
    historyStep: any[],
    currentHistoryStep: any
  ) => {
    let ret = true;
    const found = historyStep.find(
      (hs) =>
        hs.id === "treatmentsFamily" &&
        hs.attributes &&
        hs.attributes.hideTreatmentsInReview
    );
    ret = !found;
    let displayTreatmentsNow = currentHistoryStep.id === "treatmentsFamily";
    if (ret) {
      let isThereTreatmentsFamily = historyStep.find(
        (hs) => hs.id === "treatmentsFamily"
      );
      if (!isThereTreatmentsFamily) {
        let isThereBrand = historyStep.find((hs) => hs.id === "brand");
        let isThereType = historyStep.find((hs) => hs.id === "type");
        if (isThereBrand) {
          displayTreatmentsNow = currentHistoryStep.id === "brand";
        } else if (isThereType) {
          displayTreatmentsNow = currentHistoryStep.id === "type";
        }
      }
    }
    return treatmentsStep && displayTreatmentsNow && ret;
  };
  // -------- TEMPORARY INSURANCE CODE -------- //

  const frame = useSelector((state: any) => state.config?.data?.frame);

  const [insuranceCalled, setInsuranceCalled] = useState(false);

  useEffect(() => {
    if (
      isInsuranceEnabled &&
      currentPackages &&
      currentPackages.length &&
      configInsurance &&
      frame &&
      !insuranceCalled
    ) {
      setInsuranceCalled(true);
      callInsuranceService(
        [getCheapPackageFromList(currentPackages)],
        configInsurance,
        frame,
        reduxDispatch
      );
    }
  }, [isInsuranceEnabled, currentPackages, configInsurance, frame]);

  // PROMO BANNER //

  const getDiscountForGVP = () => {
    let currentPackage = currentPackages?.[0];
    if (currentPackage) {
      let frameListPrice = config?.data?.frame?.listPrice;
      let lensListPrice = currentPackage?.lensPackage?.listPrice;
      let gvpPrice = getOfferPriceForLens();
      if (frameListPrice && lensListPrice) {
        let fullPrice = frameListPrice + lensListPrice;
        let correctDiscountGVP = fullPrice - gvpPrice;
        return correctDiscountGVP;
      }
    }
  };

  useEffect(() => {
    if (
      configActionLoadContent &&
      historyStep &&
      configContent &&
      currentPackages
    ) {
      let frameOnlyTitle = getFrameOnlyKey(configPackages, configData);
      if (
        frameOnlyTitle &&
        currentPackages.length &&
        currentPackages[0].lensPackage.type === frameOnlyTitle
      ) {
        return;
      } else {
        loadPromoBanner();
      }
    }
  }, [configActionLoadContent, historyStep, configContent, currentPackages]);

  const loadPromoBanner = () => {
    const packageWithPromo = currentPackages?.find((item) => {
      return item.lensPackage.promo !== undefined;
    });
    let promoKey = "";
    let promoInConfig = null;
    if (packageWithPromo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoKey = Object.keys(packageWithPromo?.lensPackage.promo)[0];
        if (promoKey) {
          setPromoInPackage(promoKey);
        }
      }
    }
    if (configContent?.promo) {
      promoInConfig = Object.keys(configContent?.promo);
    }
    if (promoInConfig && promoInConfig.find((promo) => promo === promoKey)) {
      let promoContentKey = promoInConfig.find((promo) => promo === promoKey);
      setPromoBanners({
        desktop: configContent?.promo[promoContentKey].title,
        mobile: configContent?.promo[promoContentKey].title,
      });
    } else if (isFunction(configActionLoadContent)) {
      const contentIds = ["DESK_PromoBanner", "MOB_PromoBanner"];
      let promises: Promise<string>[] = contentIds.map((ids) =>
        configActionLoadContent(ids)
      );
      Promise.all(promises).then((htmls: string[]) => {
        setPromoBanners({
          desktop: htmls[0],
          mobile: htmls[1],
        });
      });
    }
  };

  // -------- TEMPORARY INSURANCE CODE -------- //

  const handleInstallmentsClick = () => {
    reduxDispatch(modalsActions.setShowPaymentInstallmentsModal(true));
  };

  const getPriceForFrame = () => {
    return currentPrices?.frame.price;
  };
  const getPriceForLenses = () => {
    return currentPrices?.lens?.price;
  };

  const getOfferPriceForLens = () => {
    const basePrice = currentPrices.lens.price - currentPrices.lens.discount;
    let frameOfferPriceForGVP = 0;

    currentPackages.forEach((pack) => {
      frameOfferPriceForGVP = pack.frame ? pack.frame.offerPrice : 0;
      //console.log(frameOfferPriceForGVP);
    });

    const gvpResult = Number(basePrice) + Number(frameOfferPriceForGVP);

    return isInsuranceEnabled
      ? getInsPriceForLens()
      : checkGVP(currentPackages)
        ? gvpResult
        : basePrice;
  };

  const getDiscountForLens = () => {
    let price = getPriceForLenses();
    let offerPrice = getOfferPriceForLens();

    return price - offerPrice;
  };

  const getOfferPriceForFrame = () => {
    return isInsuranceEnabled
      ? getInsPriceForFrame()
      : currentPrices.frame.price - currentPrices.frame.discount;
  };

  const getDiscountForFrame = () => {
    let price = getPriceForFrame();
    let offerPrice = getOfferPriceForFrame();

    return price - offerPrice;
  };

  const getInsPriceForFrame = () => {
    return currentPrices.frame.insPrice || currentPrices.frame.insPrice === 0
      ? currentPrices.frame.insPrice
      : currentPrices.frame.price;
  };
  const getInsPriceForLens = () => {
    return currentPrices.lens.insPrice || currentPrices.lens.insPrice === 0
      ? currentPrices.lens.insPrice
      : currentPrices.lens.price;
  };

  const isFrameOnlySelected = () => {
    let configFrameOnly = getFrameOnlyKey(configPackages, configData);
    let typeSelection = workflow
      .getAllCurrentParameterStep()
      .find((par) => par.key === "type")?.value;
    return typeSelection === configFrameOnly;
  };

  const getImagery = (pdImageUrl, swatchImageObj) => {
    let url = null;
    if (swatchImage && showFallbackImageMessage) {
      url = swatchImageObj.url.split(",")[0].split(" ")[0];
    }
    return {
      productImage: pdImageUrl,
      fallbackImage: url,
    };
  };

  const onAddToBag = (ev) => {
    ev.currentTarget.disabled = true;
    setButtonLoading(true);
    document.documentElement.classList.remove("RXC__is-locked");
    document.body.classList.remove("RXC__is-locked");
    window.dispatchEvent(
      new CustomEvent("AddToCartEvent", {
        detail: {
          selectedWarranty:
            sessionWarranty?.id === "asIs" ? null : sessionWarranty,
          reviewObjectForCart:
            historyStep?.length > 0
              ? getReviewObjectForCart(historyStep)
              : null,
          imagery: getImagery(productImagePath, swatchImage),
        },
      })
    );

    setTimeout(() => {
      setButtonLoading(false);
    }, 3000);
  };

  const installmentsLabel = useInstallmentsLabel({
    initialString: installmentsLink,
  });

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };
  const getPrice = (prices: any, curr: any) => {
    if (prices) {
      return (prices?.total.insPrice || prices?.total.insPrice === 0) &&
        isInsuranceEnabled
        ? Number(prices?.total.insPrice).toFixed(
          curr.decimalScale !== undefined ? curr.decimalScale : 2
        )
        : Number(prices?.total.price - prices?.total.discount).toFixed(
          currencyFormat.decimalScale !== undefined ? curr.decimalScale : 2
        );
    }
  };
  return (
    <>
      <BrandComponent
        componentName="StepContainer"
        parameter={{
          title: translation("steps.review.title"),
          subtitle: ctxMessage,
          subtitleStyle: { marginBottom: 0 },
        }}
      >
        {learnMoreButton !== "steps.review.contentIdentifier" &&
          learnMoreButton !== undefined &&
          learnMoreButton !== null &&
          delegatingLearnMore &&
          delegateLearnMoreContent && (
            <a
              className="learnMoreButton"
              onClick={() => delegateLearnMoreContent(learnMoreButton)}
              data-element-id={
                (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
                "_LensReview-LearnMore"
              }
            >
              {moreInfoLabel !== "" &&
                moreInfoLabel !== undefined &&
                moreInfoLabel !== null &&
                moreInfoLabel !== "steps.review.learnMoreCTA"
                ? moreInfoLabel
                : "More information"}
            </a>
          )}
        {productImagePath && (
          <div
            className={"Review__productImageContainer"}
            style={{
              marginBottom:
                showNotFoundImageMessage || showFallbackImageMessage
                  ? "48px"
                  : "unset",
            }}
          >
            <img
              className={"Review__productImageContainer__image"}
              alt="Image"
              src={productImagePath}
              crossOrigin=""
              style={{
                opacity: showNotFoundImageMessage ? "50%" : "unset",
              }}
            />
            {showFallbackImageMessage && (
              <div className={"ProductContainer__fallbackContainer"}>
                {swatchImage && (
                  <Image
                    imageClass={"ProductContainer__fallbackImage"}
                    url={configProjectImage}
                    fileName={swatchImage.value}
                    alt={swatchImage.name}
                    id={swatchImage.value}
                  />
                )}
                <div
                  className={
                    "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessage"
                  }
                >
                  <div
                    className={
                      "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessageTitle"
                    }
                  >
                    {HtmlParser(
                      fallbackImageMessageTitle.replace(/\n/g, "<br>")
                    )}
                  </div>
                  <div
                    className={
                      "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessageSubtitle"
                    }
                  >
                    {HtmlParser(
                      fallbackImageMessageSubTitle.replace(/\n/g, "<br>")
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={"Review__frameContainer"}>
          <div className="Review__frameContainer__contentContainer">
            <div className={"Review__frameContainer__contentContainer__title"}>
              <div>{translation("steps.review.firstLineReview")}</div>
              {isMobile && (
                <div className={"Review__frameContainer__price"}>
                  <BrandComponent
                    componentName="StepPrice"
                    parameter={{
                      price: getPriceForFrame(),
                      discount: getDiscountForFrame(),
                      insPrice: getInsPriceForFrame(),
                      ignorePriceLabels: true,
                      hideSign: true,
                      insuranceLoading: isInsuranceLoading,
                    }}
                  />
                </div>
              )}
            </div>
            <div className={"Review__frameContainer__contentContainer__name"}>
              {frameConfigData
                ? frameConfigData.isStellaProduct
                  ? frameConfigData.name
                  : frameConfigData.brand + " " + frameConfigData.name
                : null}
            </div>
          </div>
          {isDesktop && (
            <div className={"Review__frameContainer__price"}>
              <BrandComponent
                componentName="StepPrice"
                parameter={{
                  price: getPriceForFrame(),
                  discount: getDiscountForFrame(),
                  insPrice: getInsPriceForFrame(),
                  ignorePriceLabels: true,
                  hideSign: true,
                  insuranceLoading: isInsuranceLoading,
                }}
              />
            </div>
          )}
        </div>

        <div className={"Review__stepsSeparator"} />

        <div className={"Review__lensContainer"}>
          <div className="Review__frameContainer__contentContainer">
            <div className={"Review__frameContainer__contentContainer__title"}>
              {translation("steps.review.lensRecapTitle")}
            </div>
          </div>
          <div className={"Review__frameContainer__price"}>
            <BrandComponent
              componentName="StepPrice"
              parameter={{
                price: checkGVP(currentPackages)
                  ? getOfferPriceForLens()
                  : getPriceForLenses() +
                    (sessionWarranty && sessionWarranty.id !== "asIs"
                      ? parseFloat(sessionWarranty.price)
                      : 0),
                discount: !checkGVP(currentPackages) ? getDiscountForLens() : 0,
                insPrice: getInsPriceForLens(),
                ignorePriceLabels: true,
                hideSign: true,
                insuranceLoading: isInsuranceLoading,
              }}
            />
          </div>
        </div>
        {enableAccordionReview && currentPrescription && (
          <div className="Review__toggle">
            <div className="Review__toggle__label">
              {translation("steps.prescription.prescriptionValues")}
            </div>
            <div
              className="Review__toggle__icon"
              onClick={() => toggleOpen()}
              style={
                open ? { transform: "rotate(180deg)" } : { rotate: "0deg" }
              }
            />
          </div>
        )}
        {(currentPrescription?.prescriptionFlow === "MANUAL" ||
          currentPrescription?.prescriptionFlow === "NESTED") || currentPrescription?.prescriptionFlow === "ACCOUNT" && (
            <ReadOnlyTable
              prescription={currentPrescription}
              open={enableAccordionReview ? open : true}
            />
          )}
        {currentPrescription?.prescriptionFlow === "UPLOAD" && (
          <UploadTableReadOnly open={enableAccordionReview ? open : true} />
        )}
        {currentPrescription?.prescriptionFlow === "CMD" && (
          <CallMyDoctorReadOnly props={currentPrescription} />
        )}
        {currentPrescription?.PRISM_ENABLED &&
          (currentPrescription?.VPRISM !== null ||
            currentPrescription.VBASEDIR !== null ||
            currentPrescription.HPRISM !== null ||
            currentPrescription.HBASEDIR !== null) && (
            <>
              {enableAccordionReview && (
                <div className="Review__toggle">
                  <div className="Review__toggle__label">
                    {translation("steps.prescription.prismValues")}
                  </div>

                  <div
                    className="Review__toggle__icon"
                    onClick={() => toggleOpenPrism()}
                    style={
                      openPrism
                        ? { transform: "rotate(180deg)" }
                        : { rotate: "0deg" }
                    }
                  />
                </div>
              )}
              <ReadOnlyTable
                prescription={currentPrescription}
                open={enableAccordionReview ? openPrism : true}
                prismValue={true}
              />
            </>
          )}
        <div
          style={
            currentPrescription?.prescriptionFlow
              ? {
                padding: isMobile ? "16px 0px" : "unset",
                margin: isMobile ? "16px 0px" : "24px 0px 0px 0px",
              }
              : {
                padding: isMobile ? "16px 0px" : "unset",
                margin: isMobile ? "16px 0px" : "unset",
              }
          }
        >
          {historyStep &&
            historyStep.map((hs, index) => {
              if (!isInsuranceEnabled || isNonPrescriptionAvailable()) {
                let currentSelection = "";
                let finishingSelection = historyStep?.find(
                  (hs: any) => hs?.id === ""
                );
                //if it's a skipped step, hide the price and edit button
                let hideEditAndPrice = false;
                if (hs.attributes && hs.attributes.skipped) {
                  hideEditAndPrice = true;
                }
                if (
                  (hs.id === "color" || hs.id === "lensColor") &&
                  !brandLastStep
                ) {
                  // currentSelection = `${lensBrandTitle} - ${hs.title}`;
                  currentSelection = hs.title;
                } else {
                  currentSelection = hs.title;
                }
                return (
                  <React.Fragment key={hs.id + "_review_step"}>
                    <BrandComponent
                      componentName="StepInReview"
                      parameter={{
                        title: hs.id,
                        selection: [currentSelection],
                        strikePrice: hideEditAndPrice ? null : hs.strikePrice,
                        offerPrice: hideEditAndPrice ? null : hs.offerPrice,
                        onEditClick: hideEditAndPrice
                          ? null
                          : (title: string) =>
                            handleStepEditClick(hs.originalStep),
                        isGVP: checkGVP(currentPackages),
                        showSeeDetails:
                          hs.id === "advancedPrescription" &&
                          hs.attributes?.showSeeDetails,
                        hideEdit: hs.hideEdit ? hs.hideEdit : false,
                        hideMainPrice: hs.id.toLowerCase() === "addons",
                        optionalAttributes: hs.optionalAttributes,
                      }}
                    />
                  </React.Fragment>
                );
              } else {
                let showInsPrice = !checkGVP(currentPackages);
                //if it's a skipped step, hide the price and edit button
                let hideEditAndPrice = false;
                if (hs.attributes && hs.attributes.skipped) {
                  hideEditAndPrice = true;
                }
                return (
                  <React.Fragment key={hs.id + "_review_step"}>
                    <BrandComponent
                      componentName="StepInReview"
                      parameter={{
                        title: hs.id,
                        selection: [hs.title],
                        insPrice:
                          hideEditAndPrice || !showInsPrice
                            ? undefined
                            : hs.insPrice,
                        strikePrice: hs.strikePrice,
                        onEditClick: hideEditAndPrice
                          ? null
                          : (title: string) =>
                            handleStepEditClick(hs.originalStep),
                        showSeeDetails:
                          hs.id === "advancedPrescription" &&
                          hs.attributes?.showSeeDetails,
                        hideEdit: hs.hideEdit ? hs.hideEdit : false,
                        hideMainPrice:
                          hs.id.toLowerCase() === "addons" ||
                          isNaN(hs.insPrice),
                      }}
                    />
                    {/* <div className={"Review__stepsSeparator"} /> */}
                  </React.Fragment>
                );
              }
            })}
        </div>
        {!enableEPPStep && <BrandComponent componentName="WarrantyOptions" />}

        {isMobile && <div className="Review__stepsSeparator" />}
        {/* <div className={"Review__priceSummary"} style={{ marginBottom: "8px" }}>
          {promoBanners?.desktop &&
            (promoInPackage ||
              (promoType &&
                currentPackages[0] &&
                (promoType === currentPackages[0].frame.promoTypeDetail ||
                  !currentPackages[0].frame.promoTypeDetail))) && (
              <div className={"Review__topBadge__desktop"}>
                {HtmlParser(promoBanners.desktop)}
              </div>
            )}
          <div className={"Review__priceSummary__row"}>
            <span className={"Review__priceSummary__row__labelTotal"}>
              {!isFrameOnlySelected() && (
                <TranslateText label={"price.totalFrameLens.frameLensTotal"} />
              )}
              {isFrameOnlySelected() && (
                <TranslateText label={"price.totalFrameLens.frame"} />
              )}
            </span>
            <span className={"Review__priceSummary__row__labelTotalMobile"}>
              <TranslateText label={"price.totalFrameLens.total"} />
            </span>
            <BrandComponent componentName="EarlyAccessTooltip">
              <BrandComponent
                componentName="StepPrice"
                parameter={{
                  price: pricesToDisplay?.total.price,
                  discount: pricesToDisplay?.total.discount,
                  insPrice: pricesToDisplay?.total.insPrice,
                  hideSign: true,
                  priceStyle: { fontSize: 20 },
                  strikePriceStyle: { fontSize: 14 },
                  ignorePriceLabels: true,
                  vatIncluded: vatIncluded,
                  insuranceLoading: isInsuranceLoading
                }}
              />
            </BrandComponent>
          </div>
          {configPaymentInstallments && (type || isMultiplePayments) && (
            <div
              className={
                `Review__priceSummary__${isMultiplePayments
                  ? "multipleInstallments"
                  : "paymentInstallment"
                }` + (vatIncluded ? " marginTop" : "")
              }
              onClick={handleInstallmentsClick}
              data-element-id="X_X_StickyBar_PayOverTime"
            >
              <div
                dangerouslySetInnerHTML={{ __html: installmentsLabel }}
              ></div>
            </div>
          )}
          {brandConfig === "clearly" &&
            checkTypeValueSelected(stepsSelection) && (
              <div className="Review__priceSummary__row__visionCareContainer">
                <p
                  className="Review__priceSummary__row__visionCareContainer__visionCareLabel"
                  dangerouslySetInnerHTML={{ __html: eligibleForInsurance }}
                />
                <div className="Review__priceSummary__row__visionCareContainer__visionCareIcon">
                  <div className="VisionCareTooltip">
                    <p className="VisionCareTooltip__title">
                      {visionCareTooltipTitle}
                    </p>
                    <p className="VisionCareTooltip__description">
                      {visionCareTooltipDesc}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div> */}

        {renderSectionPlaceholder && (
          <ReferencedSection
            name={"priceSummary"}
            className={
              !references.priceSummary && "Review__placeholderPriceSUmmary"
            }
          />
        )}

        {/* <div className={"Review__buttonsMobileContainer"}>
        {isInsurable() && (
          <>
            {isInsuranceEnabled &&
            !configInsurance.removeInsuranceBenefits ? null : (
              <GenericButton
                className={"BottomBar__container__left__addInsuranceButton"}
                title={addToBagLabel}
                id="continueToReviewButton"
                type="button"
                noArrow={true}
                handleClick={handleInsuranceClick}
              >
                {isInsuranceEnabled ? removeInsuranceLabel : addInsuranceLabel}
              </GenericButton>
            )}
          </>
        )}

        {brandConfig !== "rayban" && (
          <GenericButton
            className={"BottomBar__container__right__addToBagButton"}
            title={addToBagLabel}
            id="goToCartButton"
            type="button"
            noArrow={true}
            disabled={buttonLoading}
            handleClick={onAddToBag}
            dataElementId="X_X_Prod_AddCart"
          >
            {buttonLoading ? (
              <Image url={genericImage} fileName="loader" />
            ) : (
              addToBagLabel
            )}
          </GenericButton>
        )}
      </div> */}
        {renderSectionPlaceholder && (
          <ReferencedSection
            name="addToCart"
            style={!isMobile && { paddingTop: "50px" }}
          />
        )}
        <div className={"Review__priceSummary"} style={{ marginBottom: "8px" }}>
          {isInsuranceEnabled === false &&
            promoBanners?.desktop &&
            (promoInPackage ||
              (promoType &&
                currentPackages[0] &&
                (promoType === currentPackages[0].frame.promoTypeDetail ||
                  !currentPackages[0].frame.promoTypeDetail))) && (
              <div className={"Review__topBadge__desktop"}>
                {HtmlParser(promoBanners.desktop)}
              </div>
            )}
          <div className={"Review__priceSummary__row"}>
            <span className={"Review__priceSummary__row__labelTotal"}>
              {!isFrameOnlySelected() && (
                <TranslateText label={"price.totalFrameLens.total"} />
              )}
              {isFrameOnlySelected() && (
                <TranslateText label={"price.totalFrameLens.frame"} />
              )}
            </span>
            <span className={"Review__priceSummary__row__labelTotalMobile"}>
              <TranslateText label={"price.totalFrameLens.total"} />
            </span>
            <BrandComponent
              componentName="EarlyAccessTooltip"
              parameter={{
                discount: checkGVP(currentPackages)
                  ? getDiscountForGVP()
                  : pricesToDisplay?.total.discount,
              }}
            >
              <BrandComponent
                componentName="StepPrice"
                parameter={{
                  price: checkGVP(currentPackages)
                    ? getOfferPriceForLens()
                    : pricesToDisplay?.total.price,
                  discount: !checkGVP(currentPackages)
                    ? pricesToDisplay?.total.discount
                    : 0,
                  insPrice: pricesToDisplay?.total.insPrice,
                  hideSign: true,
                  priceStyle: { fontSize: 20 },
                  strikePriceStyle: { fontSize: 14 },
                  ignorePriceLabels: true,
                  vatIncluded: vatIncluded,
                }}
              />
              {/* <BrandComponent
                componentName="Savings"
                parameter={{ discount: pricesToDisplay?.total.discount }}
              ></BrandComponent> */}
            </BrandComponent>
          </div>
          {configPaymentInstallments && (type || isMultiplePayments) && (
            <div
              className={
                `Review__priceSummary__${isMultiplePayments
                  ? "multipleInstallments"
                  : "paymentInstallment"
                }` + (vatIncluded ? " marginTop" : "")
              }
              onClick={
                handleInstallmentsAction !== null &&
                  handleInstallmentsAction !== undefined
                  ? () =>
                    handleInstallmentsAction(
                      pricesToDisplay?.total.price -
                      pricesToDisplay?.total.discount
                    )
                  : handleInstallmentsClick
              }
              data-element-id="X_X_StickyBar_PayOverTime"
            >
              <div
                dangerouslySetInnerHTML={{ __html: installmentsLabel }}
              ></div>
            </div>
          )}
          {brandConfig === "clearly" &&
            checkTypeValueSelected(stepsSelection) && (
              <div className="Review__priceSummary__row__visionCareContainer">
                <p
                  className="Review__priceSummary__row__visionCareContainer__visionCareLabel"
                  dangerouslySetInnerHTML={{ __html: eligibleForInsurance }}
                />
                <div className="Review__priceSummary__row__visionCareContainer__visionCareIcon">
                  <div className="VisionCareTooltip">
                    <p className="VisionCareTooltip__title">
                      {visionCareTooltipTitle}
                    </p>
                    <p className="VisionCareTooltip__description">
                      {visionCareTooltipDesc}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className={"Review__buttonsMobileContainer"}>
          {isInsurable() && (
            <>
              {isInsuranceEnabled &&
                !configInsurance.removeInsuranceBenefits ? null : (
                <GenericButton
                  className={"BottomBar__container__left__addInsuranceButton"}
                  title={addToBagLabel}
                  id="continueToReviewButton"
                  type="button"
                  noArrow={true}
                  handleClick={handleInsuranceClick}
                >
                  {isInsuranceEnabled
                    ? removeInsuranceLabel
                    : addInsuranceLabel}
                </GenericButton>
              )}
            </>
          )}

          {brandConfig !== "rayban" && (
            <GenericButton
              className={"BottomBar__container__right__addToBagButton"}
              title={addToBagLabel}
              id="goToCartButton"
              type="button"
              noArrow={true}
              disabled={buttonLoading}
              handleClick={onAddToBag}
              dataElementId="X_X_Prod_AddCart"
            >
              {buttonLoading && brand === "opsm" ? (
                <BrandComponent componentName="Loader" />
              ) : buttonLoading ? (
                <Image url={genericImage} fileName="loader" />
              ) : (
                addToBagLabel
              )}
            </GenericButton>
          )}
        </div>
        {brandConfig === "rayban" && (
          <div className={"BottomBar__container__sticky BottomBar__mobileOnly"}>
            <GenericButton
              className={
                references.addToCart
                  ? "BottomBar__container__right__addToBagButton"
                  : "BottomBar__container__right__addToBagButtonSticky BottomBar__mobileOnly"
              }
              title={addToBagLabel}
              id="goToCartButton"
              type="button"
              noArrow={true}
              disabled={buttonLoading}
              handleClick={onAddToBag}
              dataElementId="X_X_Prod_AddCart"
            >
              {references.priceSummary
                ? addToBagLabel
                : addToBagLabel +
                " - " +
                (currencyFormat.suffix ? "" : currencyFormat.prefix) +
                getPrice(pricesToDisplay, currencyFormat)}
            </GenericButton>
          </div>
        )}
      </BrandComponent>
    </>
  );
}
