import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PromoState = {
  loading: boolean;
};

const initialState: PromoState = {
  loading: false,
};

const promoSlice = createSlice({
  name: "promo",
  initialState: initialState,
  reducers: {
    setPromoLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const promoReducer = promoSlice.reducer;
export const promoActions = promoSlice.actions;
