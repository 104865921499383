import { useCurrentPackages } from "@hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../components/common/Image";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { usePriceDiscountBadge } from "../../configurator/hooks/usePriceDiscountBadge";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import { workflow } from "../../configurator/models/Workflow";
import { workflowActions } from "../../redux/slices/workflow";
import { BrandComponent } from "../common/BrandComponent";
import { colorActions } from "../../redux/slices/color";
import "./default.module.scss";

export interface LensColorCardProps {
  color: any;
  cardClassName: string;
  isSelected: boolean;
  changePriceStyle?: any;
  changeDiscountStyle?: any;
  onSelected: (indexG: number, indexC: number) => void;
  discountLoading?: boolean;
}

export function LensColorCard(props: LensColorCardProps) {
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const currentValidPkgs = useCurrentPackages();
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );

  const promoBadge = usePriceDiscountBadge(
    props.color.id,
    props.color.discount,
    props.color.price
  );
  const dispatch = useDispatch();

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  const handleClick = (indexG: number, indexC: number) => {
    if (promoBadge) {
      const currentStep = workflow.currentStep;
      const param = new AutomaticDiscountParameter(
        promoBadge.key,
        promoBadge.value,
        {
          key: currentStep.key,
          progressive: currentStep.progressive,
        }
      );
      dispatch(workflowActions.setAutomaticDiscountBadgeStepSelection(param));
    }
    props.onSelected(indexG, indexC);
  };

  useEffect(() => {
    if (props.isSelected) {
      if (
        currentValidPkgs.find(
          (pkg) =>
            pkg.lensPackage?.color === props.color.id &&
            pkg.lensPackage?.suitableForDriving === false
        )
      ) {
        dispatch(colorActions.setSuitableForDriving(false));
      } else {
        dispatch(colorActions.setSuitableForDriving(true));
      }
    }
  }, [props.isSelected]);

  return (
    <a
      tabIndex={0}
      data-element-id={getDataElementIdForCard()}
      data-description={props.color.name}
      key={
        props.color.cgI +
        "_" +
        props.color.groupName +
        "_" +
        props.color.name +
        "_" +
        props.color.colorIndex
      }
      className={
        "LensColorCard__container__card" +
        (props.color.disabled ? " disabled" : " cursor-hand ") +
        (props.isSelected ? "selected" : "") +
        props.cardClassName
      }
      onClick={
        props.color.disabled
          ? null
          : () => handleClick(props.color.cgI, props.color.colorIndex)
      }
    >
      <Image
        imageClass={"LensColorCard__container__card__img"}
        url={configProjectImage}
        fileName={props.color.value}
        alt={props.color.name}
        id={props.color.value}
      />
      <div className={"LensColorCard__container__card__text"}>
        <div> {props.color.name} </div>
      </div>
      {props.color.groupName && (
        <div className={"LensColorCard__container__card__colortype"}>
          <div className={"LensColorCard__container__card__colortype"}>
            {" "}
            {props.color.groupName}{" "}
          </div>
        </div>
      )}
      {/* {promoBadge && (
        <div className="LensColorCard__container__card__promoBadge">
          <BrandComponent
            componentName="StepPriceBadge"
            parameter={{ badge: promoBadge?.badge }}
          />
        </div>
      )} */}
      <div
        className={
          "LensColorCard__container__card__price" +
          (promoBadge ? " promoBadge" : "")
        }
      >
        {insuranceLoading && props.isSelected && (
          <BrandComponent componentName="Loader" />
        )}
        {!insuranceLoading && isInsuranceEnabled && props.isSelected && (
          <BrandComponent
            componentName="StepPrice"
            parameter={{
              price: props.color.price,
              discount: props.color.discount ? props.color.discount : null,
              insPrice: props.color.insPrice,
              hideSign: true,
              discountLoading: props.discountLoading,
            }}
          />
        )}
        {!insuranceLoading && !isInsuranceEnabled && !props.color.disabled && (
          <BrandComponent
            componentName="StepPrice"
            parameter={{
              price: props.color.price,
              discount: props.color.discount ? props.color.discount : null,
              priceStyle: props.changePriceStyle,
              strikePriceStyle: props.changeDiscountStyle,
              hideSign: true,
              discountLoading: props.discountLoading,
            }}
          />
        )}
      </div>
    </a>
  );
}
