import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isArray } from "lodash";

export type ConsoleState = {
  currentPackages: any;
  currentStep: any;
  configPreCheckLogs: object[];
};

const initialState: ConsoleState = {
  currentPackages: null,
  currentStep: null,
  configPreCheckLogs: [],
};

const consoleSlice = createSlice({
  name: "console",
  initialState: initialState,
  reducers: {
    setCurrentPackages: (state, { payload }: PayloadAction<any>) => {
      if (isArray(payload)) {
        if (payload?.length && payload?.length !== state?.currentPackages?.length) {
          state.currentPackages = payload.map((pkg) => pkg.lensPackage);
        }
      }
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload
    },
    setConfigPreCheckLogs: (state, { payload }) => {
      state.configPreCheckLogs.push(payload)
    },
  },
});

export const consoleReducer = consoleSlice.reducer;
export const consoleActions = consoleSlice.actions;
