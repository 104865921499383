import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";

export interface StepPriceBadgeProps {
  badge: string;
}

export function StepPriceBadge(props: StepPriceBadgeProps) {
  return <div className="StepPriceBadge__container">{props.badge}</div>;
}
