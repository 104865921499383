import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDefaultLogoIcons,
  useProductImage,
  useConfigContent,
  useEnableDefaultLogo,
  useMixAndMatch,
} from "@hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import HtmlParser from "html-react-parser";
import Image from "../common/Image";
import "./default.module.scss";
import { isMobile } from "react-device-detect";
import axios from "axios";

interface ProductContainerProps {
  productName?: string;
}

export function ProductContainer({ productName }: ProductContainerProps) {
  const brandImageUrl = useSelector(
    (state: any) => state.config?.data?.frame?.brandImageUrl
  );
  const enableDefaultLogo = useEnableDefaultLogo();
  const { urlImage: productImageUrl } = useProductImage();
  const defaultLogoIcon = useDefaultLogoIcons();
  const isLoading = useSelector((state: any) => state.color.isLoading);
  const brand = useSelector((state: any) => state.config?.data?.frame?.brand);
  const name = useSelector((state: any) => state.config?.data?.frame?.name);
  const size = useSelector((state: any) => state.config?.data?.frame?.size);
  const lensTransition = useSelector(
    (state: any) => state.color.transitionSelected
  );
  const hasImageFileName = useSelector(
    (state: any) => state.color.hasImageFileName
  );
  const frameSizeLabel = useTranslate("frameSize");
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const colorSelected = useSelector((state: any) => state.color.colorSelected);
  const configColor = useConfigContent("Color");
  const fallbackImageMessageTitle = useTranslate("fallbackImageMessageTitle");
  const fallbackImageMessageSubTitle = useTranslate(
    "fallbackImageMessageSubtitle"
  );
  const isMixAndMatchStep = useMixAndMatch();
  const isLoadingPackages = useSelector(
    (state: any) => state?.mixAndMatch?.loadingPackages
  );
  return (
    <div className={"ProductContainer__container"}>
      <div className={"ProductContainer__topBrandContainer"}>
        {(!brandImageUrl || brandImageUrl === "") && enableDefaultLogo && (
          <img
            className={"ProductContainer__defaultTopBrandImage"}
            src={defaultLogoIcon}
          />
        )}
        {(brandImageUrl || brandImageUrl === "") && !enableDefaultLogo && (
          <div className={"ProductContainer__topBrandImage"}></div>
        )}
      </div>
      <div className={"ProductContainer__productImageContainer"}>
        <div className="ProductContainer__productImageContainer__productImageContent">
          {isLoading || isLoadingPackages ? (
            <BrandComponent
              componentName="Loader"
              parameter={{ greyLoader: true }}
            />
          ) : (
            <>
              {!lensTransition ? (
                (!isMobile || isMixAndMatchStep) && (
                  <img
                    className={
                      "ProductContainer__productImageContainer__productImageContent__productImage"
                    }
                    alt={brand + " " + name}
                    src={productImageUrl}
                    height={"281.25px"}
                  />
                )
              ) : (
                <BrandComponent
                  componentName="LensPreviewTransition"
                  parameter={{
                    lensImage: hasImageFileName
                      ? hasImageFileName
                      : lensTransition,
                  }}
                />
              )}
              {showFallbackImageMessage &&
                configProjectImage &&
                (currentStep?.toLowerCase() === "lenscolor" ||
                  currentStep?.toLowerCase() === "mixandmatch") && (
                  <BrandComponent
                    componentName="LensPreviewFallback"
                    parameter={{
                      selectedColor: colorSelected,
                      configColor: configColor,
                      isLoading: isLoading,
                    }}
                  />
                )}
            </>
          )}
        </div>
        <div className={"ProductContainer__bottomBrandContainer"}>
          {brandImageUrl && brandImageUrl !== "" && (
            <img
              className={"ProductContainer__bottomBrandContainer__productBrand"}
              src={brandImageUrl}
              alt={brand}
            />
          )}
          <div
            className={
              "ProductContainer__bottomBrandContainer__productModelContainer"
            }
          >
            <div
              className={
                "ProductContainer__bottomBrandContainer__productModelContainer__model"
              }
            >
              {(brand ? brand : "") + " " + name}
            </div>
            {size ? (
              <div
                className={
                  "ProductContainer__bottomBrandContainer__productModelContainer__frameSize"
                }
              >
                {frameSizeLabel}: {size}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
