import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";

interface InsuranceErrorPopupOpt {
  onModalClose: () => void;
}

/**
 * Component that displays the insurance error modal at any step. It takes an input of type
 * ```InsuranceErrorPopupOpt``` which has a single parameter:
 *  - ```onModalClose```: callback function for the parent component
 */
export function InsuranceErrorPopup({ onModalClose }: InsuranceErrorPopupOpt) {
  const insuranceTitle = useTranslate("insuranceErrorModal.title");
  const insuranceContent = useTranslate("insuranceErrorModal.content");
  const confirm = useTranslate("insuranceErrorModal.confirm");

  const handleModalClose = () => {
    if (onModalClose) onModalClose();
  };

  return (
    <div className={"InsuranceErrorPopup__container"}>
      <div className={"InsuranceErrorPopup__container__overlay"}></div>
      <div className={"InsuranceErrorPopup__container__popup"}>
        <div className={"InsuranceErrorPopup__container__title"}>
          {insuranceTitle}
        </div>
        <div className={"InsuranceErrorPopup__container__subtitle"}>
          {insuranceContent}
        </div>
        <GenericButton
          noArrow={true}
          className={"InsuranceErrorPopup__container__button"}
          handleClick={handleModalClose}
        >
          {confirm}
        </GenericButton>
      </div>
    </div>
  );
}
