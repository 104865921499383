import React from "react";
import "./default.module.scss";

interface LoaderProps {
  style?: Object;
  greyLoader?: boolean;
}

export function Loader({ style, greyLoader }: LoaderProps) {
  return (
    <span className={"Loader__container"} style={style ? style : null}>
      <span
        className={!greyLoader ? "Loader__icon" : "Loader__iconNonGreen"}
        style={
          style && style["width"] && style["height"]
            ? { width: style["width"], height: style["height"] }
            : null
        }
      ></span>
    </span>
  );
}
