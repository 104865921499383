import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useEnablePrismComment() {
  const enablePrismCookie = getCookieByName("enablePrismComment", true);
  const enablePrismComment = useSelector(
    (state: any) => state.config.prescriptionModule?.enablePrismComment
  );

  if (enablePrismCookie !== null) {
    return enablePrismCookie as boolean;
  }

  if (enablePrismComment !== null && enablePrismComment !== undefined) {
    return enablePrismComment as boolean;
  }

  return true;
}
