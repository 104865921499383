import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import "./default.module.scss";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import { useCurrentBrand } from "@hooks";

interface MissingPrescriptionValuesProps { }

export function MissingPrescriptionValueModal({ }: MissingPrescriptionValuesProps) {
  const showMissingPrescriptionsValue = useSelector(
    (state: any) => state.modals?.showMissingPrescriptionsValue
  );
  const reduxDispatch = useDispatch();
  const californianModalTitle = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.title"
  );
  const californianModalSubTitle = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.subtitle"
  );
  const californianModalButtomY = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.bottonYes"
  );
  const californianModalButtomN = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.bottonNo"
  );

  const handleSubmit = (value: boolean) => {
    reduxDispatch(modalsActions.setShowMissingPrescriptionsValue(false));

    reduxDispatch(modalsActions.setMissingPrescriptionsValueResult(value));
  };
  const brand = useCurrentBrand();

  return (
    <>
      {showMissingPrescriptionsValue && (
        <>
          <div className="MissingPrescriptionValues__overlay" />
          <div className="MissingPrescriptionValues__container">
            {brand === "framesdirect" && (<svg onClick={() => handleSubmit(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_235_100125)">
                <path d="M15.8002 1.13255L14.8669 0.199219L8.0002 7.06589L1.13353 0.199219L0.200195 1.13255L7.06686 7.99922L0.200195 14.8659L1.13353 15.7992L8.0002 8.93255L14.8669 15.7992L15.8002 14.8659L8.93353 7.99922L15.8002 1.13255Z" fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_235_100125">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>)}
            <div className="MissingPrescriptionValues__container__title">
              {californianModalTitle}
            </div>
            <div className="MissingPrescriptionValues__container__text">
              {californianModalSubTitle}
            </div>
            <div className="MissingPrescriptionValues__container__buttons">
              <GenericButton
                id={"continueButton"}
                className={
                  "border-button " +
                  "MissingPrescriptionValues__container__continueButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalContinue"
                title={californianModalButtomY}
                tabIndex={0}
                handleClick={() => handleSubmit(true)}
              >
                {californianModalButtomY}
              </GenericButton>
              <GenericButton
                id={"cancelButton"}
                className={
                  "border-button " +
                  "MissingPrescriptionValues__container__cancelButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalCancel"
                title={californianModalButtomN}
                tabIndex={0}
                handleClick={() => handleSubmit(false)}
              >
                {californianModalButtomN}
              </GenericButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
