import { useSelector } from "react-redux";
import { useMemo } from "react";
import { sunRule } from "../ConfigLoader";

export function useCurrentType(initPackages?: any[]) {
  const packages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const noPlanoPackages = packages?.filter(
    (pkg: any) => pkg.lensPackage.type.toLowerCase() !== "frame only"
  );
  const type = useMemo(() => {
    /* if (process.env.CURRENT_MODE === "standalone") {
      const urlType = window?.localStorage?.getItem("type");
      if (urlType) {
        return urlType;
      }
    } */
    const pkgs = initPackages
      ? initPackages.filter(
          (pkg: any) => pkg.lensPackage.type.toLowerCase() !== "frame only"
        )
      : noPlanoPackages
      ? noPlanoPackages
      : [];

    return (
      (pkgs?.every((p) => sunRule(p.lensPackage)) && "SUNGLASSES") ||
      "EYEGLASSES"
    );
  }, [noPlanoPackages]);

  return type;
}
