import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { formatDate, isMoreThanYear } from "../../utils/date";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";
import { useCurrentBrand } from "@hooks";

type Props = {
  prescription: {
    id: string;
    name: string;
    lastUpdate: string;
    expired: boolean;
    issueDate?: string;
  };
  onSelect: () => void;
  onShowPrescription: () => void;
};

export function PrescriptionFromMyAccountCard(props: Props) {
  const prescriptionNameLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const uploadedOnLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.uploadedOn"
  );
  const showPrescriptionLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.showPrescription"
  );
  const olderThanYearBannerLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.olderThanYearBanner"
  );
  const expiredLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.expired"
  );
  const issueDateLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.issueDate"
  );
  const brand = useCurrentBrand();
  return (
    <div
      className={`PrescriptionFromMyAccountCard${props.prescription.expired ? " expired" : ""
        }`}
      onClick={() => !props.prescription.expired && props.onSelect()}
    >
      {props.prescription.expired && <span>{expiredLabel}</span>}
      <p className="PrescriptionFromMyAccountCard__name">
        {prescriptionNameLabel}: {props.prescription.name}
      </p>
      {props.prescription.issueDate ? (
        <span className="PrescriptionFromMyAccountCard__issueDate">
          {issueDateLabel}:{" "}
          {formatDate(new Date(props.prescription.issueDate), "MM.YYYY")}
        </span>
      ) : (
        <BrandComponent
          componentName="LastUpdate"
          path="PrescriptionFromMyAccountCard/LastUpdate"
          parameter={{ lastUpdate: props.prescription.lastUpdate }}
        />
      )}

      <p
        className="PrescriptionFromMyAccountCard__showPrescription"
        onClick={
          !props.prescription.expired
            ? (ev) => {
              props.onShowPrescription();
              ev.stopPropagation();
            }
            : () => { }
        }
      >
        {showPrescriptionLabel}
      </p>
      {!props.prescription.issueDate &&
        isMoreThanYear(new Date(), new Date(props.prescription.lastUpdate)) && (
          <p className="PrescriptionFromMyAccountCard__olderThanYearBanner">
            {brand === "framesdirect" && (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_541_22667)">
                <path d="M8.66766 5.33255H7.33432V10.6659H8.66766V5.33255Z" fill="#DC9F03" />
                <path d="M8.66766 11.9992H7.33432V13.3326H8.66766V11.9992Z" fill="#DC9F03" />
                <path d="M15.7643 12.9152L9.78332 1.16689C9.41232 0.438552 8.70666 0.0742188 8.00099 0.0742188C7.29532 0.0742188 6.58966 0.438552 6.21866 1.16689L0.237657 12.9152C-0.48101 14.3266 0.544324 15.9992 2.12832 15.9992H13.874C15.4577 15.9992 16.483 14.3266 15.7643 12.9152ZM14.5457 14.2896C14.4763 14.4029 14.272 14.6659 13.8737 14.6659H2.12832C1.72999 14.6659 1.52566 14.4029 1.45632 14.2896C1.38699 14.1766 1.24532 13.8749 1.42599 13.5202L7.40699 1.77189C7.57432 1.44322 7.87799 1.40755 8.00099 1.40755C8.12399 1.40755 8.42766 1.44289 8.59499 1.77189L14.576 13.5202C14.7567 13.8752 14.615 14.1766 14.5457 14.2896Z" fill="#DC9F03" />
              </g>
              <defs>
                <clipPath id="clip0_541_22667">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>)}
            {olderThanYearBannerLabel}
          </p>
        )}
      <div className="PrescriptionFromMyAccountCard__separator"></div>
    </div>
  );
}
