import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useEnableAccordionReview() {
  const enablaAccordionReviewCookie = getCookieByName(
    "enableAccordionReview",
    true
  );

  const enableAccordionConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.enableAccordionReview
  );

  if (
    enablaAccordionReviewCookie !== null &&
    enablaAccordionReviewCookie !== undefined
  ) {
    return enablaAccordionReviewCookie as boolean;
  }
  if (enableAccordionConfig !== null && enableAccordionConfig !== undefined) {
    return enableAccordionConfig as boolean;
  }
  return true;
}
