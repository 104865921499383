/* eslint-disable react/prop-types */
import React from "react";

/**
 * @param props.className
 * @param props.title
 * @param props.handleClick
 * @param props.dataDescription
 * @param props.dataElementId
 * @param props.disabled
 * @param props.id
 * @param props.type
 * @param props.tabIndex
 * @param props.noArrow
 */
const GenericButton = (props: any) => {
  return (
    <button
      className={`${props.className !== undefined ? props.className : ""}${
        props.disabled ? " disabled" : ""
      }`}
      onClick={
        props.disabled == undefined || !props.disabled
          ? props.handleClick
          : null
      }
      title={props.title}
      data-description={props.dataDescription}
      data-element-id={props.dataElementId}
      data-analytics_available_call={props.dataAnalyticsAvailableCall}
      disabled={props.disabled}
      tabIndex={props.tabIndex ?? "0"}
      id={props.id}
      type={props.type || "button"}
    >
      {props.children}{" "}
      {props.noArrow ? null : (
        <span className={`arrow arrow-right ${props.arrowClassName || ""}`} />
      )}
    </button>
  );
};

export default GenericButton;
