import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { colorActions } from "../../redux/slices/color";
import "./default.module.scss";

export interface optionsSliderProps {
  slider: SliderObject[];
  callback?: (prop) => void;
}
export interface SliderObject {
  name: string;
  id: string | any;
}

export function optionsSlider({ slider, callback }: optionsSliderProps) {
  const [anim, setAnim] = useState("");
  const [firstTime, setFirstTime] = useState(true);
  const [valueSelected, setValueSelected] = useState(
    slider ? (slider[0].id ? slider[0].id : slider[0]) : null
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      colorActions.setLensBrand(
        slider ? (slider[0].id ? slider[0].id : slider[0]) : null
      )
    );
  }, []);

  const buttonColorChangeAndCallback = (prop, index) => {
    if (index > 0) {
      setAnim("right");
    } else {
      setAnim("left");
    }
    dispatch(colorActions.setLensBrand(prop));
    setValueSelected(prop);
    setFirstTime(false);
    callback(prop);
  };

  return !slider || slider.length <= 1 ? null : (
    <div className={"optionsSlider__container"}>
      <div className={"optionsSlider__slider__container"}>
        <div className={"optionsSlider__slider__container__anim " + anim} />
        {slider.map((value, index) => {
          return (
            <button
              key={value.id}
              className={
                "optionsSlider__slider__container__btn" +
                (valueSelected == value?.id ? " clicked " : "") +
                (firstTime ? " firstTime" : "")
              }
              onClick={() => buttonColorChangeAndCallback(value?.id, index)}
              data-analytics_available_call="0"
            >
              {value?.name.toUpperCase()}
            </button>
          );
        })}
      </div>
    </div>
  );
}
