import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import {
  getCorrectAnalyticsStepContainerName,
  isShowFramePlusLensesPrice,
  checkTypeValueSelected,
} from "../../configurator/ConfigLoader";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import {
  useCurrentPrices,
  useImagery,
  useEnableVisionCareInsurance,
  useShowFramePlusLensesPrice
} from "@hooks";

import Image from "../common/Image";

interface BottomBarProps { }

/**
 * The component handling the logic of the bottom bar in the desktop view. It uses the
 * ```useCurrentPrices()``` hook to access the current prices for the frame, lens, and total.
 */
export function BottomBar({ }: BottomBarProps) {
  //const configProductImage = useSelector((state: any) => state.config?.baseURLs?.productImage)

  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const currentStepName = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const suitableForDriving = useSelector(
    (state: any) => state.color?.suitableForDriving
  );
  const isEligibleForInsurance = useEnableVisionCareInsurance();
  const currentPrices = useCurrentPrices();
  const showPriceForGVP = isShowFramePlusLensesPrice();
  const [prices, setPrices] = useState(null);

  const addToBagLabel = useTranslate("steps.treatments.addToBag");
  const reviewTitle = useTranslate("steps.review.title");
  const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
  const insuranceLegalCopy = useTranslate("insuranceLegalBanner");
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const removeInsuranceLabel = useTranslate("insuranceButtonLabelRemove");
  const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
  const eligibleForInsurance = useTranslate(
    "steps.review.eligibleForInsurance"
  );
  const visionCareTooltipTitle = useTranslate(
    "steps.review.visionCareTooltipTitle"
  );
  const visionCareTooltipDesc = useTranslate(
    "steps.review.visionCareTooltipDesc"
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const pricingMethodConfig = useSelector(
    (state: any) => state.insurance?.pricingMethodConfig
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );

  const genericImage = useSelector(
    (state: any) => state.config?.baseURLs?.genericImage
  );

  const reviewObjectForCart = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart
  );

  const stepsSelection = useSelector((state: any) => state.config.steps);

  const imageryObject = useImagery();

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;

  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();

  const isInsurable = () => {
    return !!configInsurance;
  };

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  useEffect(() => {
    setPrices(currentPrices);
  }, [currentPrices]);

  useEffect(() => {
    const setApplyButtonPosition = () => {
      if (!suitableForDriving && currentStepName === "LensColor") {
        const applyButton = document.querySelector(".LensColor__btn_container");
        if (applyButton) {
          applyButton.classList.add("fixed");
        }
      }
    };
    window.addEventListener("resize", setApplyButtonPosition);
    setApplyButtonPosition();

    return () => {
      window.removeEventListener("resize", setApplyButtonPosition);
    };
  }, [suitableForDriving, currentStepName]);

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const onAddToBag = () => {
    window.dispatchEvent(
      new CustomEvent("AddToCartEvent", {
        detail: {
          selectedWarranty:
            sessionWarranty?.id === "asIs" ? null : sessionWarranty,
          reviewObjectForCart: reviewObjectForCart,
          imagery: imageryObject,
        },
      })
    );
    setButtonLoading(true);
  };
  return (
    <div
      className={
        pricingMethodConfig &&
          (pricingMethodConfig === "QUICK" ||
            pricingMethodConfig === "STANDARD_REVIEW")
          ? "BottomBar__legalBanner"
          : (showFramePlusLensesPrice && currentStep <= lensBundleStep && currentStepName !== "AdvancedPrescription")
            ? "BottomBar__hide"
            : "BottomBar"
      }
      style={!suitableForDriving ? { height: "unset" } : { height: "72px" }}
    >
      <div
        className={
          "BottomBar__container" +
          (currentStep &&
            currentStep === workflow.getMaxStep(workflow.stepChain).progressive
            ? " BottomBar__container__containerLastPage"
            : "") +
          (!isInsurable() && workflow.currentStep?.key === "Review"
            ? " BottomBar__container__noInsurance__lastStep"
            : " BottomBar__container__noInsurance") +
          ((!checkTypeValueSelected(stepsSelection) ||
            isEligibleForInsurance === false) &&
            " flexEnd")
        }
        style={
          !suitableForDriving && currentStepName === "LensColor"
            ? { flexDirection: "column", alignItems: "flex-start" }
            : { flexDirection: "row" }
        }
      >
        <>
          {suitableForDriving === false && currentStepName === "LensColor" && (
            <div className="BottomBar__container__notSuitable">
              <p>This lens tint colour is not suitable for driving.</p>
            </div>
          )}
        </>
        <>
          {workflow.currentStep?.key === "Review" &&
            checkTypeValueSelected(stepsSelection) &&
            isEligibleForInsurance === true && (
              <>
                <div className="BottomBar__container__noInsurance__lastStep__visionCareContainer">
                  <p
                    className="BottomBar__container__noInsurance__lastStep__visionCareContainer__visionCareLabel"
                    dangerouslySetInnerHTML={{ __html: eligibleForInsurance }}
                  />
                  <div className="BottomBar__container__noInsurance__lastStep__visionCareContainer__visionCareIcon">
                    <div className="VisionCareTooltip">
                      <p className="VisionCareTooltip__title">
                        {visionCareTooltipTitle}
                      </p>
                      <p className="VisionCareTooltip__description">
                        {visionCareTooltipDesc}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          <div
            className={
              "BottomBar__container__right" +
              (!isInsurable()
                ? " BottomBar__container__right__noInsurance"
                : "")
            }
          >
            {prices && showPriceForGVP && !workflow.isLastStep() && (
              <>
                <BrandComponent
                  componentName="BottomBarTotalPrice"
                  parameter={{
                    total: prices.total,
                  }}
                />
              </>
            )}

            {currentStep && workflow.isLastStep() && (
              <GenericButton
                className={`BottomBar__container__right__addToBagButton ${buttonLoading ? "loading" : ""
                  }`}
                title={addToBagLabel}
                id="continueToReviewButton"
                dataElementId="X_X_Prod_AddCart"
                type="button"
                noArrow={true}
                disabled={buttonLoading}
                handleClick={onAddToBag}
              >
                {buttonLoading ? (
                  <Image url={genericImage} fileName="loader" />
                ) : (
                  addToBagLabel
                )}
              </GenericButton>
            )}
            {pricingMethodConfig === "QUICK" ||
              (pricingMethodConfig === "STANDARD_REVIEW" && (
                <div className="BottomBar__container__right__legalBanner">
                  {insuranceLegalCopy}
                </div>
              ))}
          </div>
        </>
      </div>
    </div >
  );
}
