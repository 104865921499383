import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { useCurrentBrand, useEnableAgreement, usePupillaryDistance } from "@hooks";
import "./default.module.scss";
import { AgreementCheckbox } from "../FullPrescriptionForm/components/AgreementCheckbox";

export interface PrescriptionUploadFormProps {
  fileInput: any;
  closePrescriptionForm: () => {};
  onSuccessPrescription: (prescription: any) => {};
  prescriptionObject: any;
}

const Subtitle = () => {
  const uploadSubtitle = useTranslate(
    "steps.advancedPrescription.upload.subtitle"
  );
  return (
    <>
      <div className={"PrescriptionUploadForm__subtitle"}>{uploadSubtitle}</div>
    </>
  );
};

export const UploadResult = (props) => {
  const currentBrand = useCurrentBrand();
  return (
    <div
      className={
        "PrescriptionUploadForm__UploadResult__container" +
        (props.isSuccessful ? " succ" : " error")
      }
    >
      {props.isSuccessful && currentBrand !== "costa" && (
        <>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99992 13.6666C11.1295 13.6666 13.6666 11.1295 13.6666 7.99992C13.6666 4.87031 11.1295 2.33325 7.99992 2.33325C4.87031 2.33325 2.33325 4.87031 2.33325 7.99992C2.33325 11.1295 4.87031 13.6666 7.99992 13.6666ZM7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
              fill="#247534"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4451 6.15454C10.7062 6.39024 10.7415 6.81062 10.5239 7.09347L7.95984 10.4268C7.84916 10.5707 7.6877 10.6575 7.51498 10.666C7.34226 10.6745 7.17421 10.6038 7.05195 10.4714L5.51349 8.80474C5.27317 8.5444 5.27317 8.12229 5.51349 7.86194C5.75382 7.60159 6.14345 7.60159 6.38378 7.86194L7.44574 9.0124L9.57844 6.23989C9.79601 5.95704 10.1841 5.91883 10.4451 6.15454Z"
              fill="#247534"
            />
          </svg>

          <div className="PrescriptionUploadForm__UploadResult__successText">
            {props.message}
          </div>
        </>
      )}
      {!props.isSuccessful && currentBrand !== "costa" && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__unsuccessfulIconContainer">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1834 10.3828L9.07776 1.91556C8.96195 1.7378 8.80393 1.59139 8.61775 1.48944C8.42829 1.38568 8.21575 1.3313 7.99974 1.3313C7.78373 1.3313 7.5712 1.38568 7.38173 1.48944C7.19555 1.59139 7.03754 1.7378 6.92172 1.91556L1.81727 10.3808C1.81711 10.3811 1.81694 10.3814 1.81677 10.3816C1.72678 10.532 1.67786 10.7034 1.67494 10.8786C1.67205 11.0523 1.71445 11.2237 1.79791 11.3759C1.90718 11.5654 2.06439 11.7228 2.25378 11.8323C2.44487 11.9428 2.66186 12.0006 2.88259 11.9997L2.88641 11.9997L13.1184 11.9997L13.1237 11.9998C13.343 12.0021 13.559 11.9466 13.75 11.8388C13.9384 11.7324 14.0958 11.5788 14.2069 11.3932C14.2919 11.24 14.3352 11.067 14.3323 10.8916C14.3294 10.7135 14.2789 10.5393 14.1861 10.3872L14.1834 10.3828ZM15.0731 11.893C14.8726 12.2336 14.5858 12.5152 14.2417 12.7095C13.8975 12.9038 13.5082 13.0039 13.1131 12.9997H2.88641C2.48862 13.0012 2.09757 12.8971 1.75321 12.698C1.40885 12.4989 1.12353 12.2119 0.926408 11.8664C0.755911 11.5594 0.669231 11.213 0.675082 10.8619C0.680934 10.5108 0.77911 10.1675 0.959741 9.86638L6.07307 1.38638C6.27984 1.06293 6.56471 0.79673 6.90142 0.61234C7.23813 0.42795 7.61585 0.331299 7.99974 0.331299C8.38364 0.331299 8.76135 0.42795 9.09806 0.61234C9.43478 0.79673 9.71965 1.06293 9.92641 1.38638L15.0397 9.86638C15.2254 10.1706 15.3263 10.5189 15.3322 10.8752C15.338 11.2315 15.2486 11.5829 15.0731 11.893Z"
                fill="#E32118"
              />
              <path
                d="M7.99967 10.3333C8.36786 10.3333 8.66634 10.0349 8.66634 9.66667C8.66634 9.29848 8.36786 9 7.99967 9C7.63148 9 7.33301 9.29848 7.33301 9.66667C7.33301 10.0349 7.63148 10.3333 7.99967 10.3333Z"
                fill="#E32118"
              />
              <path
                d="M7.99967 4.33325C7.82286 4.33325 7.65329 4.40349 7.52827 4.52851C7.40325 4.65354 7.33301 4.82311 7.33301 4.99992V7.66658C7.33301 7.8434 7.40325 8.01296 7.52827 8.13799C7.65329 8.26301 7.82286 8.33325 7.99967 8.33325C8.17649 8.33325 8.34605 8.26301 8.47108 8.13799C8.5961 8.01296 8.66634 7.8434 8.66634 7.66658V4.99992C8.66634 4.82311 8.5961 4.65354 8.47108 4.52851C8.34605 4.40349 8.17649 4.33325 7.99967 4.33325Z"
                fill="#E32118"
              />
            </svg>
          </div>
          <div
            className={
              "PrescriptionUploadForm__UploadResult__errorText" +
              (props.uppercase ? " uppercase" : "")
            }
          >
            {props.message}
          </div>
        </>
      )}
      {props.isSuccessful && currentBrand === "costa" && (
        <div className="PrescriptionUploadForm__UploadResult__successText">
          {props.message}
        </div>
      )}
      {!props.isSuccessful && currentBrand === "costa" && (
        <div
          className={
            "PrescriptionUploadForm__UploadResult__errorText" +
            (props.uppercase ? " uppercase" : "")
          }
        >
          {props.message}
        </div>
      )}
    </div>
  );
};

export const FilePreview = (props) => {
  const reduxDispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);

  const fallBackImageUrl =
    "https://assets.lenscrafters.com/extra/image/LensCrafters/projects/202005-rxreview/LC_UploadOK_Icon.png";

  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );

  useEffect(() => {
    if (props.forceFileInfo) {
      setFileData({
        url: props.forceFilePath,
        type: props.forceFileInfo.type,
        name: props.forceFileInfo.name,
        size: props.forceFileInfo.size,
      });
      if (props.forceFilePath) {
        setIsPreviewEnabled(true);
      }
    }
  }, [props.forceFileInfo, props.forceFilePath]);

  const handleFileChange = (file: any) => {
    const fileReader = new window.FileReader();
    fileReader.onload = (fileLoad) => {
      const { result } = fileLoad.target;
      setFileData({
        url: result,
        type: file.type.split("/")[1],
        name: file.name,
        size: file.size,
      });
      setIsPreviewEnabled(true);
    };
    fileReader.readAsDataURL(file);
  };

  const getSizeInMB = (size) => {
    return "[" + (parseInt(size) / 1024 / 1024).toFixed(2) + "MB]";
  };

  const setErrorImage = (e: any) => {
    setIsPreviewEnabled(false);
    e.target.src = fallBackImageUrl;
  };

  return (
    fileData && (
      <div className="PrescriptionUploadForm__FilePreview__container">
        <div
          className={
            "PrescriptionUploadForm__FilePreview__container__filePreview" +
            (isPreviewEnabled ? "" : " noPreview")
          }
          onClick={() => {
            if (isPreviewEnabled) {
              reduxDispatch(
                modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
              );
              reduxDispatch(
                prescriptionActions.setUploadFileDataPreview(
                  props.forceFilePath ? props.forceFilePath : fileData.url
                )
              );
            }
          }}
        >
          {props.forceFilePath && (
            <img
              src={props.forceFilePath}
              alt="Document"
              onError={setErrorImage}
            />
          )}
          {!props.forceFilePath && (
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 30, height: 30 },
              }}
            />
          )}
        </div>
        <div className="PrescriptionUploadForm__FilePreview__container__fileInfo">
          <div className="PrescriptionUploadForm__FilePreview__container__fileInfo__nameAndSize">
            {fileData.name + " - " + getSizeInMB(fileData.size)}
          </div>
          <div
            className="PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent"
            onClick={() => {
              props.handleUploadAgain();
            }}
          >
            {uploadDifferentFile}
          </div>
        </div>
      </div>
    )
  );
};

export const UploadError = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadError__container">
      <div className="PrescriptionUploadForm__UploadError__container__message">
        {props.message}
      </div>
      <div
        role={"button"}
        className="PrescriptionUploadForm__button"
        onClick={() => props.onButtonClick()}
      >
        {props.buttonText}
      </div>
    </div>
  );
};

const UploadBox = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);

  const changeMethod = useTranslate(
    "steps.advancedPrescription.upload.changeMethod"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  useEffect(() => {
    //TODO chiamata
    uploadFile(props.file);
  }, [props.file]);

  const getFileTypeFromFullName = (fileName: string) => {
    if (fileName) {
      let split = fileName.split(".");
      let lastElem = split[split.length - 1];
      return "." + lastElem;
    }
  };

  useEffect(() => {
    if (
      props.preloadedPrescription &&
      props.preloadedPrescription.fileName &&
      props.preloadedPrescription.fileSize &&
      props.preloadedPrescription.filePath &&
      props.preloadedPrescription.savedFileName
    ) {
      setIsUploading(false);
      setIsSuccessful(true);
      setPreloadedFileInfo({
        name: props.preloadedPrescription.fileName,
        size: parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(props.preloadedPrescription.fileName),
      });
      props.onFileChange({
        name: props.preloadedPrescription.fileName,
        filePath: props.preloadedPrescription.filePath,
        fileSize:
          parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: props.preloadedPrescription.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
  }, [props.preloadedPrescription]);

  useEffect(() => {
    let update = isSuccessful && !isUploading;
    props.onUploadSuccessStateChange(update);
  }, [isSuccessful, isUploading]);

  const uploadFile = (file) => {
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (checkFileExtension(file)) {
        if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
          const fileReader = new window.FileReader();
          fileReader.onload = (fileLoad) => {
            const { result } = fileLoad.target;
            let requestObj = {
              fileName: file.name,
              fileData: result,
            };
            prescriptionModule
              .uploadExtendedPrescription(requestObj)
              .then((res) => {
                props.onFileChange({
                  name: file.name,
                  savedFileName: res.savedFileName,
                  fileSize: file.size,
                });
                props.onPrescriptionIdReceived(res.prescriptionId);
                setIsFileTooBigError(false);
                setIsSuccessful(true);

                setPreloadedFileInfo({
                  name: file.name,
                  size: file.size,
                  type: getFileTypeFromFullName(file.name),
                });

                setIsUploading(false);

                if (prescriptionModule.downloadExtendedPrescription) {
                  prescriptionModule
                    .downloadExtendedPrescription({
                      savedFileName: res.savedFileName,
                    })
                    .then((res) => {
                      setFilePath(res.fileData);
                    })
                    .catch((err) => {
                      setFilePath("");
                    });
                } else {
                  console.error("Missing downloadExtendedPrescriptionFunction");
                  setFilePath("");
                }
              })
              .catch((err) => {
                setIsSuccessful(false);
                setIsUploading(false);
                setIsFileTooBigError(false);
              });
          };
          fileReader.readAsDataURL(file);
        }
      }
      else {
        setIsSuccessful(false);
        setIsUploading(false);
      }

    }
  };

  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      } else {
        if (file.name.indexOf(".") > 0) {
          let ext = file.name.split(".")[file.name.split(".").length - 1];
          if (prescriptionModule && prescriptionModule.fileExtensions) {
            if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
              try {
                //@ts-ignore
                window.tealium_data2track.push({
                  id: "Error",
                  Error_Source: "User",
                  Error_Code: "RX Configurator: prescription upload",
                  Error_Message: tryAgain,
                });
              } catch (error) {
                console.error(
                  "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
                );
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const checkFileExtension = (file) => {
    if (file.name.indexOf(".") > 0) {
      let ext = file.name.split(".")[file.name.split(".").length - 1];
      if (prescriptionModule && prescriptionModule.fileExtensions) {
        if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Source: "User",
              Error_Code: "RX Configurator: prescription upload",
              Error_Message: tryAgain,
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext.toUpperCase())
      );
      return parsedExt.join();
    }
  };

  const handleFileChange = (file: any) => {
    setFilePath(null);
    uploadFile(file);
  };

  const handleUploadErrorButtonClick = () => {
    let inputFile = document.getElementById("PrescriptionUploadInputId");
    if (inputFile) {
      inputFile.click();
    }
  };

  return (
    <>
      <div
        className={
          "PrescriptionUploadForm__UploadBox__container" +
          (!isSuccessful && !isUploading ? " error" : "")
        }
      >
        {isUploading && (
          <div className="PrescriptionUploadForm__UploadBox__container__spinnerContainer">
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 40, height: 40 },
              }}
            />
          </div>
        )}
        {!isUploading && (
          <>
            <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
              <UploadResult
                isSuccessful={isSuccessful}
                uppercase={isSuccessful || isFileTooBigError ? true : false}
                message={
                  isSuccessful
                    ? prescriptionUploaded
                    : isFileTooBigError
                      ? fileTooBigErrorTitle
                      : somethingWentWrong
                }
              />
              <div
                className="PrescriptionUploadForm__UploadBox__container__changeMethod"
                onClick={() => props.closePrescriptionForm()}
              >
                {changeMethod}
              </div>
            </div>
            <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
              {isSuccessful && (
                <FilePreview
                  handleUploadAgain={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                  forceFilePath={filePath}
                  forceFileInfo={preloadedFileInfo}
                />
              )}
              {!isSuccessful && (
                <UploadError
                  message={
                    isFileTooBigError
                      ? fileTooBigErrorDescription.replace(
                        "###FILE_SIZE###",
                        prescriptionModule?.maxFileSize
                          ? prescriptionModule?.maxFileSize
                          : 10
                      )
                      : tryAgain
                  }
                  buttonText={isFileTooBigError ? uploadDifferentFile : upload}
                  onButtonClick={handleUploadErrorButtonClick}
                />
              )}
            </div>
            <div
              className="PrescriptionUploadForm__UploadBox__container__changeMethodMobile"
              onClick={() => props.closePrescriptionForm()}
            >
              {changeMethod}
            </div>
          </>
        )}
      </div>
      <input
        id="PrescriptionUploadInputId"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={getSupportedFileExtensions()}
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export function PrescriptionUploadForm({
  fileInput,
  closePrescriptionForm,
  onSuccessPrescription,
  prescriptionObject,
}: PrescriptionUploadFormProps) {
  const saveExtendedPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const [fileInfoForSave, setFileInfoForSave] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showContinueButton, setShowContinueButton] = useState(false);

  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const confirmAndContinue = useTranslate(
    "steps.advancedPrescription.upload.confirmAndContinue"
  );
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enableAgreementCheckbox = useEnableAgreement();

  const getDataElementIdForConfirm = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-ValuesCompatibles";
  };

  const CustomCheckbox = (props) => {
    const isChecked = props.value
      ? typeof props.value === "boolean"
        ? props.value
        : props.value.value
      : false;

    return (
      <div
        tabIndex={0}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName ? " error" : "") +
          (props.marginTop ? " marginTop" : "")
        }
        onClick={() => {
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: false,
            });
          }
        }}
      ></div>
    );
  };

  const handleSubmitPrescription = () => {
    // if (!PD.state.checkPDSelected()) return;

    //Agreement validation
    if (enableAgreementCheckbox) {
      if (!agreement) {
        setFormErrors(errors => ({ ...errors, agreement: true }))
        return
      }
    }

    if (
      saveExtendedPrescription &&
      typeof saveExtendedPrescription === "function"
    ) {
      let fileSize = fileInfoForSave?.fileSize
        ? (parseInt(fileInfoForSave.fileSize) / 1024 / 1024).toFixed(2)
        : 0;
      let submitObj = {
        prescriptionFlow: "UPLOAD",
        prescriptionId: prescriptionId
          ? prescriptionId
          : previousPrescription
            ? previousPrescription.prescriptionId
            : null,
        fileName: fileInfoForSave?.name,
        savedFileName:
          fileInfoForSave && fileInfoForSave.savedFileName
            ? fileInfoForSave.savedFileName
            : previousPrescription.savedFileName,
        fileSize: fileSize,
        // PD: {
        //   OD: PD.state.PD1,
        //   OS: PD.state.PD2,
        // },
      };
      saveExtendedPrescription(submitObj)
        .then((res) => {
          onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Subtitle />
      <UploadBox
        preloadedPrescription={prescriptionObject}
        onUploadSuccessStateChange={(isSuccessful) =>
          setShowContinueButton(isSuccessful)
        }
        onFileChange={(fileInfo) => setFileInfoForSave(fileInfo)}
        onPrescriptionIdReceived={(id) => setPrescriptionId(id)}
        closePrescriptionForm={closePrescriptionForm}
        file={fileInput}
      />
      {enableAgreementCheckbox && (
        <div className="PrescriptionUploadForm__agreement">
          <AgreementCheckbox
            agreement={agreement}
            setAgreement={setAgreement}
            formErrors={formErrors}
            tooltipText={tooltipText}
            uploadPrescription
          />
        </div>
      )}
      {showContinueButton && (
        <div className="PrescriptionUploadForm__submitContainer">
          <button
            className="PrescriptionUploadForm__submitButton"
            onClick={handleSubmitPrescription}
            data-element-id={getDataElementIdForConfirm()}
          >
            {confirmAndContinue}
          </button>
        </div>
      )}
    </>
  );
}
