import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { optionsSlider as OptionsSlider } from "../optionsSlider/component";
import { isMobile } from "react-device-detect";
import { useCurrentBrand } from "@hooks";
import "./default.module.scss";

export function DigitalOptometryModal() {
  const brand = useCurrentBrand();
  const showDigitalOptometryModal = useSelector(
    (state: any) => state.modals.showDigitalOptometryModal
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const [section, setSection] = useState("app");
  const title = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.title"
  );
  const appSubtitle = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.appSubtitle"
  );
  const manuallySubtitle = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.manuallySubtitle"
  );
  const toggleApp = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.toggle.app"
  );
  const toggleManually = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.toggle.manually"
  );
  const opthyDesc = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.opthyDesc"
  );
  const manuallyContent = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.manuallyContent"
  );
  const closeLabel = useTranslate(
    "steps.advancedPrescription.upload.closeLabel"
  );
  const options = [
    {
      name: toggleApp,
      id: "app",
    },
    {
      name: toggleManually,
      id: "manually",
    },
  ];
  const dispatch = useDispatch();


  const handleToggle = (section: string) => {
    setSection(section);
  };
  const getSubtitle = () => {
    if (section === "app" && !isMobile) {
      return appSubtitle;
    } else {
      return manuallySubtitle;
    }
  };

  // useEffect(() => {
  //   if (showDigitalOptometryModal) {
  //     try {
  //       //@ts-ignore
  //       window.tealium_data2track.push({
  //         id: "Click",
  //         data_element_id: "X_X_MeasurePDOverlay_Manually",
  //         data_description: "AdvancedPrescription",
  //       });
  //       console.log("pushed");
  //     } catch (error) {
  //       console.log("error with tealium.push: " + error);
  //     }
  //   }
  // }, []);

  return (
    <div className="DigitalOptometryModal__container">
      <div
        className="DigitalOptometryModal__container__overlay"
        data-analytics_available_call="0"
        data-element-id="X_X_MeasurePDOverlay_Manually"
      />
      <div
        className="DigitalOptometryModal__container__box"
        data-analytics_available_call="0"
      >
        {brand === "persol" || brand === "oliverpeoples" ? (
          <div
            className="DigitalOptometryModal__container__box__close"
            onClick={() =>
              dispatch(
                modalsActions.setShowPrescriptionModalPD({
                  show: false,
                  modal: enableDigitalOptometry,
                })
              )
            }
          ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.32257 3.54647L1.92344 1.14734C1.4224 0.646296 1.41628 0.652415 0.938189 1.13069C0.931681 1.1372 0.925086 1.14379 0.918401 1.15048C0.440711 1.62817 0.422483 1.6552 0.919029 2.15174L3.31816 4.55088L4.26726 5.49997L3.31817 6.44907L0.919034 8.8482C0.417994 9.34924 0.424113 9.35536 0.902385 9.83345C0.908895 9.83995 0.915492 9.84655 0.922177 9.85323C1.39987 10.3309 1.42689 10.3492 1.92344 9.85261L4.32257 7.45347L5.27167 6.50438L6.22139 7.4541L8.62052 9.85323C9.12093 10.3536 9.12705 10.3475 9.60512 9.86928C9.61163 9.86276 9.61824 9.85616 9.62493 9.84946C10.1026 9.37177 10.1208 9.34474 9.62493 8.84883L7.22516 6.44906L6.27607 5.49997L7.2258 4.55025L9.62493 2.15111C10.1253 1.6507 10.1192 1.64459 9.64098 1.16652C9.63446 1.16 9.62786 1.1534 9.62116 1.14671C9.14347 0.669018 9.11644 0.650791 8.62053 1.14671L6.22076 3.54647L5.27167 4.49557L4.32257 3.54647Z" fill="black" />
            </svg><div className="DigitalOptometryModal__container__box__close__closeLabel">{closeLabel}</div></div>) : (<div
              className="DigitalOptometryModal__container__box__close"
              onClick={() =>
                dispatch(
                  modalsActions.setShowPrescriptionModalPD({
                    show: false,
                    modal: enableDigitalOptometry,
                  })
                )
              }
            ></div>)}
        <div className="DigitalOptometryModal__container__box__title">
          {title}
        </div>
        <div className="DigitalOptometryModal__container__box__subtitle">
          {appSubtitle}
        </div>
        <OptionsSlider
          slider={options}
          callback={(section: string) => handleToggle(section)}
        />
        <div className="DigitalOptometryModal__container__box__content">
          {brand === "framesdirect" && (
            <div className="DigitalOptometryModal__container__box__content__manuallyContainer">
              <div className="DigitalOptometryModal__container__box__content__manuallyContainer__manuallyImage" />
              <div
                className="DigitalOptometryModal__container__box__content__manuallyContainer__context"
                dangerouslySetInnerHTML={{
                  __html: manuallyContent,
                }}
              />
            </div>
          )}
          {section === "app" && (
            <>
              <div className="DigitalOptometryModal__container__box__content__iphoneImage"></div>
              <div className="DigitalOptometryModal__container__box__content__innerContainer">
                <div
                  className="DigitalOptometryModal__container__box__content__innerContainer__desc"
                  dangerouslySetInnerHTML={{
                    __html: opthyDesc,
                  }}
                />
                <div className="DigitalOptometryModal__container__box__content__innerContainer__images">
                  <div className="DigitalOptometryModal__container__box__content__innerContainer__images__qrCode" />
                  <div className="DigitalOptometryModal__container__box__content__innerContainer__images__appStore">
                    <p>
                      Problems with the QR code? See the app on the App Store.
                    </p>
                    <a
                      target="blank"
                      href="https://apps.apple.com/us/app/opthy/id1624663408"
                      className="DigitalOptometryModal__container__box__content__innerContainer__images__appStore__image"
                    ></a>
                  </div>
                </div>
              </div>
            </>
          )}
          {section === "manually" && (
            <div className="DigitalOptometryModal__container__box__content__manuallyContainer">
              <div className="DigitalOptometryModal__container__box__content__manuallyContainer__manuallyImage" />
              <div
                className="DigitalOptometryModal__container__box__content__manuallyContainer__context"
                dangerouslySetInnerHTML={{
                  __html: manuallyContent,
                }}
              />
            </div>
          )}

        </div>

      </div>
    </div>
  );
}
