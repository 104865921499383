import React, { useEffect, useLayoutEffect, useState } from "react";
import { BrandComponent } from "../../../../docFiles";
import { useDispatch, useSelector } from "react-redux";
import { checkGVP, getStepBadgeType, getCorrectKey } from "../../ConfigLoader";
import {
  useAddonsMapper,
  useReviewIncludedTreatmentsMapper,
  useContextualMessage,
  useAllCurrentPackages,
  useMoreInfo,
  useGetDiscount,
  useCurrentType,
  useRetriveContextualMessage,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { workflowActions } from "../../../redux/slices/workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import "./default.module.scss";
import { minBy } from "lodash";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
import ReferencedSection from "../../../components/common/ReferencedSection";

export function AddOns(props: StepComponentOptions) {
  const translate = useTranslation();
  const ctxMessage = useRetriveContextualMessage();
  const moreInfoMapper = useMoreInfo("antiReflective");
  const loadTreatments = useAddonsMapper();
  const currentPackages = useAllCurrentPackages();
  const dispatch = useDispatch();
  const [addons, setAddons] = useState([]);
  const [alreadyIncluded, setalreadyIncluded] = useState(null);
  const [isIncludedOpen, setIsIncludedOpen] = useState(false);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const currentType = useCurrentType();
  const loadIncludedTreatments = useReviewIncludedTreatmentsMapper();
  const { isLoading, getDiscount } = useGetDiscount();
  const reduxDispatch = useDispatch();
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.addOns.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.addOns.learnMoreCTA");
  useEffect(() => {
    if (alreadyIncluded) {
      dispatch(workflowActions.setCurrentIncluded(alreadyIncluded));
    }
  }, [alreadyIncluded]);

  useEffect(() => {
    if (currentPackages) {
      let prevAndCurrentParams = workflow.getPrevCurrentParameterStep();
      let gvpPackages = currentPackages.validRXPackages?.filter(
        (pkg: any) => pkg?.frame?.promoType === "GVP"
      );
      if (
        checkGVP(currentPackages.validRXPackages) &&
        gvpPackages?.length === 1
      ) {
        workflow.goNextStep(
          [prevAndCurrentParams[prevAndCurrentParams.length - 1]],
          true
        );
      }

      if (loadTreatments) {
        const { included, treatments } = loadTreatments(currentPackages, true);
        if (treatments) {
          const onlyAntyReflective = treatments.filter(
            (t) => t.id.split("$")[0] === "antiReflective"
          );
          onlyAntyReflective.sort((a, b) =>
            a.id.split("$")[1] === "null"
              ? -1
              : b.id.split("$")[1] === "null"
              ? 1
              : 0
          );

          const addonsAvaiable = onlyAntyReflective.map((t) => {
            const splittedKey = t.id.split("$");
            const value = splittedKey[1];

            if (value === "null" || value === "undefined" || value === "") {
              t.title = translate("proceedAsIs"); //replace to label
              t.image = "imagepath"; //replace to url path
            }
            return t;
          });
          let addOnsWithMoreInfo = moreInfoMapper(addonsAvaiable);
          setAddons(addOnsWithMoreInfo);
        }
      }
    }
  }, [loadTreatments, currentPackages]);

  useEffect(() => {
    if (!alreadyIncluded && loadIncludedTreatments && currentPackages) {
      const { included, treatments } = loadIncludedTreatments(
        currentPackages.validRXPackages
      );
      const includedTreatment = included.map((treatment) => ({
        id: treatment.id,
        name: translate(treatment.name),
      }));

      setalreadyIncluded(includedTreatment);
    }
  }, [currentPackages, loadIncludedTreatments, alreadyIncluded]);

  useEffect(() => {
    for (const addon of addons) {
      getDiscount(addon.id, currentPackages.validRXPackages, addon.filter);
    }
  }, [addons]);

  const checkIfSelectedIsPremium = (selectedAddOn: any) => {
    let gvpPackages = currentPackages.validRXPackages?.filter(
      (pkg: any) => pkg?.frame?.promoType === "GVP"
    );
    if (gvpPackages.length > 1) {
      return true;
    } else {
      let minAddOn = minBy(addons, "price");
      return selectedAddOn.id !== minAddOn["id"];
    }
  };

  const onAddonSelected = (addonId, skipped?: boolean) => {
    const splittedKey = addonId.split("$");
    const key = splittedKey[0];
    const value = splittedKey[1];

    const titleToShow = configContent[key][value]?.title;

    let selectedAddOn = addons.filter((addOn) => {
      return addOn.id === addonId;
    });
    if (value === "null") {
      workflow.goNextStep(
        [
          {
            key: "addOns",
            value: null,
            price: selectedAddOn[0].price,
            discount: selectedAddOn[0].discount,
            insPrice:
              skipped && selectedAddOn[0].price == 0
                ? 0
                : selectedAddOn[0].insPrice,
            attributes: {
              isPremium: checkIfSelectedIsPremium(selectedAddOn[0]),
            },
          },
        ],
        skipped
      );
    } else if (value === "undefined") {
      workflow.goNextStep(
        [
          {
            key: "addOns",
            value: undefined,
            price: selectedAddOn[0].price,
            discount: selectedAddOn[0].discount,
            insPrice:
              skipped && selectedAddOn[0].price == 0
                ? 0
                : selectedAddOn[0].insPrice,
            attributes: {
              isPremium: checkIfSelectedIsPremium(selectedAddOn[0]),
            },
          },
        ],
        skipped
      );
    } else {
      workflow.goNextStep(
        [
          {
            key: "addOns",
            value: {
              type: key,
              selection: value,
              value: titleToShow,
            },
            price: selectedAddOn[0].price,
            discount: selectedAddOn[0].discount,
            insPrice:
              skipped && selectedAddOn[0].price == 0
                ? 0
                : selectedAddOn[0].insPrice,
            attributes: {
              isPremium: checkIfSelectedIsPremium(selectedAddOn[0]),
            },
          },
        ],
        skipped
      );
    }
  };

  const toogleOpen = () => setIsIncludedOpen(!isIncludedOpen);

  const getKey = (title: string) => "Addons_sc_" + title.split(" ").join("_");

  const goNextOrSkip = (selected: any, skipped?: boolean) => {
    const prevAndCurrentParams = workflow.getPrevCurrentParameterStep();

    const params = ["glasses", "opsm"].includes(props.brand)
      ? [prevAndCurrentParams[prevAndCurrentParams.length - 1]]
      : [];

    if (!selected && !skipped) {
      const findedNullOption = addons.find((a) => a.id.indexOf("$null") >= 0);
      if (findedNullOption) onAddonSelected(findedNullOption.id, skipped);
    } else if (skipped && !selected) {
      workflow.goNextStep(params, true);
    } else if (selected && !skipped) {
      onAddonSelected(selected.id, skipped);
    } else {
      workflow.goNextStep(params, true);
    }
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.addOns.title"),
        subtitle: ctxMessage,
        showGrayOutBanner: addons && addons.find((ad) => ad.disabled),
      }}
    >
      {learnMoreButton !== "steps.addOns.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensType-LearnMore"
            }
          >
            {moreInfoLabel !== "" &&
            moreInfoLabel !== undefined &&
            moreInfoLabel !== null &&
            moreInfoLabel !== "steps.addOns.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"Addons__container"}>
        {alreadyIncluded && alreadyIncluded.length > 0 && (
          <div className={"AddOns__includedContainer"}>
            <input
              className={"AddOns__includedContainer__checkBox"}
              id="includedCheckbox"
              type="checkbox"
            />
            <a
              data-element-id={
                !isIncludedOpen ? "X_X_LensPanel_Type-ValuesOpen" : ""
              }
            >
              <label
                aria-label="Open accordion with included treatments"
                className={
                  "AddOns__includedContainer__accordionButton" +
                  (isIncludedOpen ? " open" : " closed")
                }
                htmlFor="includedCheckbox"
                tabIndex={0}
                onClick={toogleOpen}
              >
                {translate("steps.addOns.label")}
              </label>
              {["opsm"].includes(props.brand) && (
                <div
                  className={`AddOns__includedContainer__accordion__accordionArrow__${
                    isIncludedOpen ? "open" : "close"
                  }`}
                />
              )}
            </a>

            <div
              className={
                "AddOns__includedContainer__accordion" +
                (isIncludedOpen ? "__open" : "__closed")
              }
            >
              <ul>
                {alreadyIncluded.map((ai) => {
                  return (
                    <li key={"Addons_ai_" + ai.id}>{translate(ai.name)}</li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "AddOns",
            onNext: goNextOrSkip,
            parameterPreselection: ["antiReflective", "antiScratch"],
            onPreselect: (selected, skipped: boolean) => {
              let selectedT = null;
              let selectedB = false;
              addons.forEach((t) => {
                const id = t.id.split("$")[0];
                const val = t.id.split("$")[1];
                if (selected[id] === val && !selectedB) {
                  selectedT = t;
                  selectedB = true;
                }
              });

              goNextOrSkip(selectedT, skipped);
            },
          }}
        >
          {addons &&
            addons.map((c: any) => (
              <BrandComponent
                key={getKey(c.title)}
                componentName="StepCard"
                parameter={{
                  ...c,
                  price: c.price ? c.price : 0,
                  badge: c.badge ? getStepBadgeType(c.badge) : null,
                  // disabled: c.badge == "coming soon" ? true : false,
                  onClick:
                    c.badge == "coming soon" || c.disabled
                      ? null
                      : () => onAddonSelected(c.id),
                  addOnsPrice: ["glasses"].includes(props.brand) ? true : false,
                  discountLoading: isLoading(c.id),
                }}
              />
            ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
