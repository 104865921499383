import React, { useState } from "react";
import "./default.module.scss";
import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

export function VirtualMirrorButton(props: any) {
  const vmmvModule = useSelector((state: any) => state.config?.vmmvModule);
  const showButton = useSelector(
    (state: any) => state.virtualMirror?.showVirtualMirrorButton
  );
  const openLabel = useTranslate("steps.treatments.openButtonLabel");
  return (
    <>
      {vmmvModule && showButton && (
        <button
          className="OpenButton__container"
          onClick={() => {
            props.onClick();
          }}
          data-element-id="LensPanel_TreatmentDetail-VMStart"
        >
          <p className="OpenButton__container__label">{openLabel}</p>
          <div className="OpenButton__container__icon" />
        </button>
      )}
    </>
  );
}
