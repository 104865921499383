import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { useEnableAgreement, usePupillaryDistance } from "@hooks";
import "./default.module.scss";
import { AgreementCheckbox } from "../FullPrescriptionForm/components/AgreementCheckbox";

export interface PrescriptionUploadFormProps {
  fileInput: any;
  closePrescriptionForm: () => {};
  onSuccessPrescription: (prescription: any) => {};
  prescriptionObject: any;
}

const Subtitle = () => {
  const uploadSubtitle = useTranslate(
    "steps.advancedPrescription.upload.subtitle"
  );
  return (
    <>
      <div className={"PrescriptionUploadForm__subtitle"}>{uploadSubtitle}</div>
    </>
  );
};

export const UploadResult = (props) => {
  return (
    <div
      className={
        "PrescriptionUploadForm__UploadResult__container" +
        (props.isSuccessful ? " succ" : " error")
      }
    >
      {props.isSuccessful && (
        <>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
              fill="#202125"
            />
            <path
              d="M18.417 7.4744C17.812 6.8504 17.813 6.8344 17.197 7.4714L16.157 8.5474L10.954 13.9274L10.497 14.3994L10.053 13.9404L7.71302 11.5204L7.67303 11.4454C7.07103 10.8224 7.03803 10.8454 6.46003 11.4454C5.85503 12.0694 5.84003 12.0684 6.45503 12.7054L6.49503 12.7804L8.83502 15.2004L9.87602 16.2754C10.185 16.5964 10.346 16.7404 10.505 16.7284C10.662 16.7384 10.815 16.5894 11.131 16.2634L12.172 15.1874L17.374 9.8074L18.416 8.7314C19.018 8.1074 18.997 8.0744 18.417 7.4744Z"
              fill="white"
            />
          </svg>

          <div className="PrescriptionUploadForm__UploadResult__successText">
            {props.message}
          </div>
        </>
      )}
      {!props.isSuccessful && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__unsuccessfulIconContainer">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.609 10.661C7.268 10.661 6.993 10.936 6.993 11.266C6.993 11.607 7.268 11.893 7.609 11.893C7.95 11.893 8.225 11.607 8.225 11.266C8.225 10.936 7.95 10.661 7.609 10.661ZM7.158 10.067H8.06V3.456H7.158V10.067Z"
                fill="#D0021B"
              />
              <path
                d="M7.5 14C3.91015 14 1 11.0899 1 7.5H-1C-1 12.1944 2.80558 16 7.5 16V14ZM14 7.5C14 11.0899 11.0899 14 7.5 14V16C12.1944 16 16 12.1944 16 7.5H14ZM7.5 1C11.0899 1 14 3.91015 14 7.5H16C16 2.80558 12.1944 -1 7.5 -1V1ZM7.5 -1C2.80558 -1 -1 2.80558 -1 7.5H1C1 3.91015 3.91015 1 7.5 1V-1Z"
                fill="#D0021B"
              />
            </svg>
          </div>
          <div
            className={
              "PrescriptionUploadForm__UploadResult__errorText" +
              (props.uppercase ? " uppercase" : "")
            }
          >
            {props.message}
          </div>
        </>
      )}
    </div>
  );
};

export const FilePreview = (props) => {
  const reduxDispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);

  const fallBackImageUrl =
    "https://assets.lenscrafters.com/extra/image/LensCrafters/projects/202005-rxreview/LC_UploadOK_Icon.png";

  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );

  useEffect(() => {
    if (props.forceFileInfo) {
      setFileData({
        url: props.forceFilePath,
        type: props.forceFileInfo.type,
        name: props.forceFileInfo.name,
        size: props.forceFileInfo.size,
      });
      if (props.forceFilePath) {
        setIsPreviewEnabled(true);
      }
    }
  }, [props.forceFileInfo, props.forceFilePath]);

  const handleFileChange = (file: any) => {
    const fileReader = new window.FileReader();
    fileReader.onload = (fileLoad) => {
      const { result } = fileLoad.target;
      setFileData({
        url: result,
        type: file.type.split("/")[1],
        name: file.name,
        size: file.size,
      });
      setIsPreviewEnabled(true);
    };
    fileReader.readAsDataURL(file);
  };

  const getSizeInMB = (size) => {
    return "[" + (parseInt(size) / 1024 / 1024).toFixed(2) + "MB]";
  };

  const setErrorImage = (e: any) => {
    setIsPreviewEnabled(false);
    e.target.src = fallBackImageUrl;
  };

  return (
    fileData && (
      <div className="PrescriptionUploadForm__FilePreview__container">
        <div
          className={
            "PrescriptionUploadForm__FilePreview__container__filePreview" +
            (isPreviewEnabled ? "" : " noPreview")
          }
          onClick={() => {
            if (isPreviewEnabled) {
              reduxDispatch(
                modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
              );
              reduxDispatch(
                prescriptionActions.setUploadFileDataPreview(
                  props.forceFilePath ? props.forceFilePath : fileData.url
                )
              );
            }
          }}
        >
          {props.forceFilePath && (
            <img
              src={props.forceFilePath}
              alt="Document"
              onError={setErrorImage}
            />
          )}
          {!props.forceFilePath && (
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 30, height: 30 },
              }}
            />
          )}
        </div>
        <div className="PrescriptionUploadForm__FilePreview__container__fileInfo">
          <div className="PrescriptionUploadForm__FilePreview__container__fileInfo__nameAndSize">
            {fileData.name + " - " + getSizeInMB(fileData.size)}
          </div>
          <div
            className="PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent"
            onClick={() => {
              props.handleUploadAgain();
            }}
          >
            {uploadDifferentFile}
          </div>
        </div>
      </div>
    )
  );
};

export const UploadError = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadError__container">
      <div className="PrescriptionUploadForm__UploadError__container__message">
        {props.message}
      </div>
      <div
        role={"button"}
        className="PrescriptionUploadForm__button"
        onClick={() => props.onButtonClick()}
      >
        {props.buttonText}
      </div>
    </div>
  );
};

const UploadBox = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);

  const changeMethod = useTranslate(
    "steps.advancedPrescription.upload.changeMethod"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  useEffect(() => {
    //TODO chiamata
    uploadFile(props.file);
  }, [props.file]);

  const getFileTypeFromFullName = (fileName: string) => {
    let split = fileName.split(".");
    let lastElem = split[split.length - 1];
    return "." + lastElem;
  };

  useEffect(() => {
    if (
      props.preloadedPrescription &&
      props.preloadedPrescription.fileName &&
      props.preloadedPrescription.fileSize &&
      props.preloadedPrescription.filePath &&
      props.preloadedPrescription.savedFileName
    ) {
      setIsUploading(false);
      setIsSuccessful(true);
      setPreloadedFileInfo({
        name: props.preloadedPrescription.fileName,
        size: parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(props.preloadedPrescription.fileName),
      });
      props.onFileChange({
        name: props.preloadedPrescription.fileName,
        filePath: props.preloadedPrescription.filePath,
        fileSize:
          parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: props.preloadedPrescription.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
  }, [props.preloadedPrescription]);

  useEffect(() => {
    let update = isSuccessful && !isUploading;
    props.onUploadSuccessStateChange(update);
  }, [isSuccessful, isUploading]);

  const uploadFile = (file) => {
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (checkFileExtension(file)) {
        if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
          const fileReader = new window.FileReader();
          fileReader.onload = (fileLoad) => {
            const { result } = fileLoad.target;
            let requestObj = {
              fileName: file.name,
              fileData: result,
            };
            prescriptionModule
              .uploadExtendedPrescription(requestObj)
              .then((res) => {
                props.onFileChange({
                  name: file.name,
                  savedFileName: res.savedFileName,
                  fileSize: file.size,
                });
                props.onPrescriptionIdReceived(res.prescriptionId);
                setIsFileTooBigError(false);
                setIsSuccessful(true);

                setPreloadedFileInfo({
                  name: file.name,
                  size: file.size,
                  type: getFileTypeFromFullName(file.name),
                });

                setIsUploading(false);

                if (prescriptionModule.downloadExtendedPrescription) {
                  prescriptionModule
                    .downloadExtendedPrescription({
                      savedFileName: res.savedFileName,
                    })
                    .then((res) => {
                      setFilePath(res.fileData);
                    })
                    .catch((err) => {
                      setFilePath("");
                    });
                } else {
                  console.error("Missing downloadExtendedPrescriptionFunction");
                  setFilePath("");
                }
              })
              .catch((err) => {
                setIsSuccessful(false);
                setIsUploading(false);
                setIsFileTooBigError(false);
              });
          };
          fileReader.readAsDataURL(file);
        }
      }
      else {
        setIsSuccessful(false);
        setIsUploading(false);
      }
    }
  };

  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      } else {
        if (file.name.indexOf(".") > 0) {
          let ext = file.name.split(".")[file.name.split(".").length - 1];
          if (prescriptionModule && prescriptionModule.fileExtensions) {
            if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase)) {
              try {
                //@ts-ignore
                window.tealium_data2track.push({
                  id: "Error",
                  Error_Source: "User",
                  Error_Code: "RX Configurator: prescription upload",
                  Error_Message: tryAgain,
                });
              } catch (error) {
                console.error(
                  "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
                );
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const checkFileExtension = (file) => {
    if (file.name.indexOf(".") > 0) {
      let ext = file.name.split(".")[file.name.split(".").length - 1];
      if (prescriptionModule && prescriptionModule.fileExtensions) {
        if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Source: "User",
              Error_Code: "RX Configurator: prescription upload",
              Error_Message: tryAgain,
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext.toUpperCase())
      );
      return parsedExt.join();
    }
  };

  const handleFileChange = (file: any) => {
    setFilePath(null);
    uploadFile(file);
  };

  const handleUploadErrorButtonClick = () => {
    let inputFile = document.getElementById("PrescriptionUploadInputId");
    if (inputFile) {
      inputFile.click();
    }
  };

  return (
    <>
      <div
        className={
          "PrescriptionUploadForm__UploadBox__container" +
          (!isSuccessful && !isUploading ? " error" : "")
        }
      >
        {isUploading && (
          <div className="PrescriptionUploadForm__UploadBox__container__spinnerContainer">
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 40, height: 40 },
              }}
            />
          </div>
        )}
        {!isUploading && (
          <>
            <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
              <UploadResult
                isSuccessful={isSuccessful}
                uppercase={isSuccessful || isFileTooBigError ? true : false}
                message={
                  isSuccessful
                    ? prescriptionUploaded
                    : isFileTooBigError
                      ? fileTooBigErrorTitle
                      : somethingWentWrong
                }
              />
              <div
                className="PrescriptionUploadForm__UploadBox__container__changeMethod"
                onClick={() => props.closePrescriptionForm()}
              >
                {changeMethod}
              </div>
            </div>
            <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
              {isSuccessful && (
                <FilePreview
                  handleUploadAgain={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                  forceFilePath={filePath}
                  forceFileInfo={preloadedFileInfo}
                />
              )}
              {!isSuccessful && (
                <UploadError
                  message={
                    isFileTooBigError
                      ? fileTooBigErrorDescription.replace(
                        "###FILE_SIZE###",
                        prescriptionModule?.maxFileSize
                          ? prescriptionModule?.maxFileSize
                          : 10
                      )
                      : tryAgain
                  }
                  buttonText={isFileTooBigError ? uploadDifferentFile : upload}
                  onButtonClick={handleUploadErrorButtonClick}
                />
              )}
            </div>
            <div
              className="PrescriptionUploadForm__UploadBox__container__changeMethodMobile"
              onClick={() => props.closePrescriptionForm()}
            >
              {changeMethod}
            </div>
          </>
        )}
      </div>
      <input
        id="PrescriptionUploadInputId"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={getSupportedFileExtensions()}
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export function PrescriptionUploadForm({
  fileInput,
  closePrescriptionForm,
  onSuccessPrescription,
  prescriptionObject,
}: PrescriptionUploadFormProps) {
  const saveExtendedPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const [fileInfoForSave, setFileInfoForSave] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showContinueButton, setShowContinueButton] = useState(false);

  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const confirmAndContinue = useTranslate(
    "steps.advancedPrescription.upload.confirmAndContinue"
  );
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enableAgreementCheckbox = useEnableAgreement();

  const getDataElementIdForConfirm = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-ValuesCompatibles";
  };

  const CustomCheckbox = (props) => {
    const isChecked = props.value
      ? typeof props.value === "boolean"
        ? props.value
        : props.value.value
      : false;

    return (
      <div
        tabIndex={0}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName ? " error" : "") +
          (props.marginTop ? " marginTop" : "")
        }
        onClick={() => {
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: false,
            });
          }
        }}
      ></div>
    );
  };

  const handleSubmitPrescription = () => {
    if (!PD.state.checkPDSelected()) return;

    //Agreement validation
    if (enableAgreementCheckbox) {
      if (!agreement) {
        setFormErrors(errors => ({ ...errors, agreement: true }))
        return
      }
    }

    if (
      saveExtendedPrescription &&
      typeof saveExtendedPrescription === "function"
    ) {
      let fileSize = fileInfoForSave?.fileSize
        ? (parseInt(fileInfoForSave.fileSize) / 1024 / 1024).toFixed(2)
        : 0;
      let submitObj = {
        prescriptionFlow: "UPLOAD",
        prescriptionId: prescriptionId
          ? prescriptionId
          : previousPrescription
            ? previousPrescription.prescriptionId
            : null,
        fileName: fileInfoForSave?.name,
        savedFileName:
          fileInfoForSave && fileInfoForSave.savedFileName
            ? fileInfoForSave.savedFileName
            : previousPrescription.savedFileName,
        fileSize: fileSize,
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      saveExtendedPrescription(submitObj)
        .then((res) => {
          onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Subtitle />
      <UploadBox
        preloadedPrescription={prescriptionObject}
        onUploadSuccessStateChange={(isSuccessful) =>
          setShowContinueButton(isSuccessful)
        }
        onFileChange={(fileInfo) => setFileInfoForSave(fileInfo)}
        onPrescriptionIdReceived={(id) => setPrescriptionId(id)}
        closePrescriptionForm={closePrescriptionForm}
        file={fileInput}
      />
      <div className="PrescriptionUploadForm__Separator"></div>
      <BrandComponent
        componentName="PupillaryDistance"
        parameter={{
          PD1: PD.state.PD1,
          PD2: PD.state.PD2,
          showBothPdOptions: PD.state.showBothPdOptions,
          showPDWarning: PD.state.showPDWarning,
          onPD1Change: PD.state.setPD1,
          onPD2Change: PD.state.setPD2,
          setShowBothPdOptions: PD.state.setShowBothPdOptions,
          pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
          CustomCheckbox: CustomCheckbox,
        }}
      />
      {enableAgreementCheckbox && (
        <div className="PrescriptionUploadForm__agreement">
          <AgreementCheckbox
            agreement={agreement}
            setAgreement={setAgreement}
            formErrors={formErrors}
            tooltipText={tooltipText}
            uploadPrescription
          />
        </div>
      )}
      {showContinueButton && (
        <div className="PrescriptionUploadForm__submitContainer">
          <button
            className="PrescriptionUploadForm__submitButton"
            onClick={handleSubmitPrescription}
            data-element-id={getDataElementIdForConfirm()}
          >
            {confirmAndContinue}
          </button>
        </div>
      )}
    </>
  );
}
