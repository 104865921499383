import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";
import { useMandatoryManualInput } from "./useMandatoryManualInput";

export function useAdvancedPrescriptionOptions() {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [prescriptionOptions, setPrescriptionOptions] = useState(null);
  const enableSendItLaterConfig = useSelector(
    (state: any) => state.config.layoutSettings.enableSendItLater
  );
  const enableMandatory = useMandatoryManualInput();

  const enableSendItLater = useMemo(() => {
    let enableSendItLaterCookie = getCookieByName("enableSendItLater", true);
    if (![undefined, null].includes(enableSendItLaterCookie)) {
      return enableSendItLaterCookie;
    }
    return enableSendItLaterConfig;
  }, [enableSendItLaterConfig]);

  const getCookieValues = () => {
    let prescriptionEnabled = getCookieByName("prescriptionEnabled", true);
    let prescriptionManualEnabled = getCookieByName(
      "prescriptionManualEnabled",
      true
    );
    let prescriptionFromMyAccountEnabled = getCookieByName(
      "prescriptionFromMyAccountEnabled",
      true
    );
    let prescriptionUploadEnabled = getCookieByName(
      "prescriptionUploadEnabled",
      true
    );
    let prescriptionDoctorEnabled = getCookieByName(
      "prescriptionDoctorEnabled",
      true
    );
    let prescriptionSendLaterEnabled = getCookieByName(
      "prescriptionSendLaterEnabled",
      true
    );

    let prescriptionManualOrder = getCookieByName("prescriptionManualOrder");
    let prescriptionFromMyAccountOrder = getCookieByName(
      "prescriptionFromMyAccountOrder"
    );
    let prescriptionUploadOrder = getCookieByName("prescriptionUploadOrder");
    let prescriptionDoctorOrder = getCookieByName("prescriptionDoctorOrder");
    let prescriptionSendLaterOrder = getCookieByName(
      "prescriptionSendLaterOrder"
    );

    return {
      prescriptionEnabled: prescriptionEnabled,
      prescriptionManualEnabled: prescriptionManualEnabled,
      prescriptionFromMyAccountEnabled: prescriptionFromMyAccountEnabled,
      prescriptionUploadEnabled: prescriptionUploadEnabled,
      prescriptionDoctorEnabled: prescriptionDoctorEnabled,
      prescriptionSendLaterEnabled: prescriptionSendLaterEnabled,
      prescriptionManualOrder: prescriptionManualOrder,
      prescriptionFromMyAccountOrder: prescriptionFromMyAccountOrder,
      prescriptionUploadOrder: prescriptionUploadOrder,
      prescriptionDoctorOrder: prescriptionDoctorOrder,
      prescriptionSendLaterOrder: prescriptionSendLaterOrder,
    };
  };

  const isPrescriptionEnabled = (cookieValues, defaultValues) => {
    return cookieValues.prescriptionEnabled !== undefined &&
      cookieValues.prescriptionEnabled !== null
      ? cookieValues.prescriptionEnabled
      : prescriptionModule.abTest &&
        prescriptionModule.abTest.prescriptionEnabled !== undefined &&
        prescriptionModule.abTest.prescriptionEnabled !== null
        ? prescriptionModule.abTest.prescriptionEnabled
        : defaultValues.prescriptionEnabled;
  };

  const isPrescriptionManualEnabled = (cookieValues, defaultValues) => {
    let value =
      cookieValues.prescriptionManualEnabled !== undefined &&
        cookieValues.prescriptionManualEnabled !== null
        ? cookieValues.prescriptionManualEnabled
        : prescriptionModule.abTest &&
          prescriptionModule.abTest.prescriptionManualEnabled !== undefined &&
          prescriptionModule.abTest.prescriptionManualEnabled !== null
          ? prescriptionModule.abTest.prescriptionManualEnabled
          : prescriptionModule.prescriptionFlows.includes("MANUAL")
            ? true
            : prescriptionModule.prescriptionFlows.length
              ? false
              : defaultValues.prescriptionManualEnabled.value;

    let order = cookieValues.prescriptionManualOrder
      ? cookieValues.prescriptionManualOrder
      : prescriptionModule.abTest?.prescriptionManualOrder
        ? prescriptionModule.abTest?.prescriptionManualOrder
        : defaultValues.prescriptionManualOrder;

    return { value: value, order: order };
  };

  const isPrescriptionFromMyAccountEnabled = (cookieValues, defaultValues) => {
    let value =
      cookieValues.prescriptionFromMyAccountEnabled !== undefined &&
        cookieValues.prescriptionFromMyAccountEnabled !== null
        ? cookieValues.prescriptionFromMyAccountEnabled
        : prescriptionModule.abTest &&
          prescriptionModule.abTest.prescriptionFromMyAccountEnabled !==
          undefined &&
          prescriptionModule.abTest.prescriptionFromMyAccountEnabled !== null
          ? prescriptionModule.abTest.prescriptionFromMyAccountEnabled
          : prescriptionModule.prescriptionFlows.includes("ACCOUNT")
            ? true
            : prescriptionModule.prescriptionFlows.length
              ? false
              : defaultValues.prescriptionManualEnabled.value;

    let order = cookieValues.prescriptionFromMyAccountOrder
      ? cookieValues.prescriptionFromMyAccountOrder
      : prescriptionModule.abTest?.prescriptionFromMyAccountOrder
        ? prescriptionModule.abTest?.prescriptionFromMyAccountOrder
        : defaultValues.prescriptionFromMyAccountOrder;

    return { value: value, order: order };
  };

  const isPrescriptionUploadEnabled = (cookieValues, defaultValues) => {
    let value =
      cookieValues.prescriptionUploadEnabled !== undefined &&
        cookieValues.prescriptionUploadEnabled !== null
        ? cookieValues.prescriptionUploadEnabled
        : prescriptionModule.abTest &&
          prescriptionModule.abTest.prescriptionUploadEnabled !== undefined &&
          prescriptionModule.abTest.prescriptionUploadEnabled !== null
          ? prescriptionModule.abTest.prescriptionUploadEnabled
          : prescriptionModule.prescriptionFlows.includes("UPLOAD")
            ? true
            : prescriptionModule.prescriptionFlows.length
              ? false
              : defaultValues.prescriptionManualEnabled.value;

    let order = cookieValues.prescriptionUploadOrder
      ? cookieValues.prescriptionUploadOrder
      : prescriptionModule.abTest?.prescriptionUploadOrder
        ? prescriptionModule.abTest?.prescriptionUploadOrder
        : defaultValues.prescriptionUploadOrder;

    return { value: value, order: order };
  };

  const isPrescriptionDoctorEnabled = (cookieValues, defaultValues) => {
    let value =
      cookieValues.prescriptionDoctorEnabled !== undefined &&
        cookieValues.prescriptionDoctorEnabled !== null
        ? cookieValues.prescriptionDoctorEnabled
        : prescriptionModule.abTest &&
          prescriptionModule.abTest.prescriptionDoctorEnabled !== undefined &&
          prescriptionModule.abTest.prescriptionDoctorEnabled !== null
          ? prescriptionModule.abTest.prescriptionDoctorEnabled
          : prescriptionModule.prescriptionFlows.includes("CMD")
            ? true
            : prescriptionModule.prescriptionFlows.length
              ? false
              : defaultValues.prescriptionManualEnabled.value;

    let order = cookieValues.prescriptionDoctorOrder
      ? cookieValues.prescriptionDoctorOrder
      : prescriptionModule.abTest?.prescriptionDoctorOrder
        ? prescriptionModule.abTest?.prescriptionDoctorOrder
        : defaultValues.prescriptionDoctorOrder;

    return { value: value, order: order };
  };

  const isPrescriptionSendLaterEnabled = (cookieValues, defaultValues) => {
    if (enableSendItLater && !enableMandatory) {
      let value =
        cookieValues.prescriptionSendLaterEnabled !== undefined &&
          cookieValues.prescriptionSendLaterEnabled !== null
          ? cookieValues.prescriptionSendLaterEnabled
          : prescriptionModule.abTest &&
            prescriptionModule.abTest.prescriptionSendLaterEnabled !==
            undefined &&
            prescriptionModule.abTest.prescriptionSendLaterEnabled !== null
            ? prescriptionModule.abTest.prescriptionSendLaterEnabled
            : defaultValues.prescriptionSendLaterEnabled.value;

      let order = cookieValues.prescriptionSendLaterOrder
        ? cookieValues.prescriptionSendLaterOrder
        : prescriptionModule.abTest?.prescriptionSendLaterOrder
          ? prescriptionModule.abTest?.prescriptionSendLaterOrder
          : defaultValues.prescriptionSendLaterOrder;

      return { value: value, order: order };
    } else {
      let value = false;
      let order = undefined;

      return { value: value, order: order };
    }
  };
  useEffect(() => {
    if (
      prescriptionModule &&
      (prescriptionModule.prescriptionType === "FULL" ||
        prescriptionModule.prescriptionType == "NESTED" || prescriptionModule.prescriptionType == "NESTED_CA")
    ) {
      let cookieValues = getCookieValues();

      let defaultValues = {
        prescriptionEnabled: true,
        prescriptionManualEnabled: { value: true, order: 1 },
        prescriptionFromMyAccountEnabled: { value: true, order: 2 },
        prescriptionUploadEnabled: { value: true, order: 3 },
        prescriptionDoctorEnabled: { value: true, order: 4 },
        prescriptionSendLaterEnabled: { value: true, order: 5 },
      };

      let ret = {
        prescriptionEnabled: isPrescriptionEnabled(cookieValues, defaultValues),
        prescriptionManualEnabled: isPrescriptionManualEnabled(
          cookieValues,
          defaultValues
        ),
        prescriptionFromMyAccountEnabled: isPrescriptionFromMyAccountEnabled(
          cookieValues,
          defaultValues
        ),
        prescriptionUploadEnabled: isPrescriptionUploadEnabled(
          cookieValues,
          defaultValues
        ),
        prescriptionDoctorEnabled: isPrescriptionDoctorEnabled(
          cookieValues,
          defaultValues
        ),
        prescriptionSendLaterEnabled: isPrescriptionSendLaterEnabled(
          cookieValues,
          defaultValues
        ),
      };

      setPrescriptionOptions(ret);
    } else {
      setPrescriptionOptions({});
    }
  }, [prescriptionModule]);

  return prescriptionOptions;
}
