import { isFunction } from "lodash";
import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { discountsActions } from "../../redux/slices/discounts";
import { colorActions } from "../../redux/slices/color";
import { configActions } from "../../redux/slices/config";
import { insuranceActions } from "../../redux/slices/insurance";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { promoActions } from "../../redux/slices/promo";
import { tooltipActions } from "../../redux/slices/tooltip";
import { workflowActions } from "../../redux/slices/workflow";
import { workflow } from "../models/Workflow";
import { useCheapCurrentStep } from "./useCheapCurrentStep";
import { useCurrentPackages } from "./useCurrentPackages";
import { useMixAndMatch } from "./useMixAndMatch";
import { useBundlesPrices } from "./useBundlesPrices";

export function useExternalListener() {
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const configEarlyAccess = useSelector(
    (state: any) => state.config?.earlyAccessModule
  );
  const configAction = useSelector((state: any) => state.config?.actionsModule);
  const configPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const configData = useSelector((state: any) => state.config?.data);
  const configType = useSelector(
    (state: any) => state.config?.lensesData?.content?.type
  );

  const selector = useSelector((state: any) => state.config?.selector);
  const cartMode = useSelector((state: any) => state.config?.cartMode);
  const frame = useSelector((state: any) => state.config?.data?.frame);
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const config = useSelector((state: any) => state.config);

  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );

  const selectedTfamily = useSelector(
    (state: any) => state.mixAndMatch?.selectedTreatment
  );
  const selectedLens = useSelector(
    (state: any) => state.mixAndMatch?.selectedLens
  );
  const selectedFrame = useSelector(
    (state: any) => state.mixAndMatch?.selectedFrame
  );
  const isMixAndMatchStep = useMixAndMatch();
  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep?.progressive
  );
  const currentPackages = useCurrentPackages();
  const _selectedPackage = useCheapCurrentStep();

  const bundles = useSelector(
    (state: any) => state.config?.lensesData?.bundles
  );
  const bundlesPrices = useSelector(
    (state: any) => state.config?.lensesData?.bundlesPrices
  );

  const bundlesEnabled = useBundlesPrices();

  const selectedPackage = useMemo(() => {
    // restore prices before bundles
    let selectedPkg = _selectedPackage;
    const listPrice = _selectedPackage?.lensPackage?.originalListPrice;
    const offerPrice = _selectedPackage?.lensPackage?.originalOfferPrice;
    if (offerPrice && listPrice) {
      selectedPkg.lensPackage.listPrice = offerPrice;
      selectedPkg.lensPackage.offerPrice = offerPrice;
      delete selectedPkg.lensPackage.originalListPrice;
      delete selectedPkg.lensPackage.originalOfferPrice;
    }
    return selectedPkg;
  }, [_selectedPackage]);

  const bundleObject = useMemo(() => {
    let bundleObj = null;
    const lensPackageUpc = selectedPackage?.lensPackage?.upc;
    if (bundlesEnabled && lensPackageUpc) {
      const selectedBundle = bundles.find(
        (b) => b.lensPackageUPC === lensPackageUpc
      );
      const selectedBundlePrices = bundlesPrices.find(
        (b) => b.bundleUPC === selectedBundle?.bundleUPC
      );
      if (selectedBundle && selectedBundlePrices) {
        bundleObj = {
          ...selectedBundle,
          ...selectedBundlePrices,
        };
      }
    }
    return bundleObj;
  }, [selectedPackage, bundles, bundlesPrices, bundlesEnabled]);

  const dispatch = useDispatch();

  const onInsuranceEnabled = () => {
    dispatch(insuranceActions.setInsuranceEnabled(true));
  };

  const onInsuranceDisabled = () => {
    dispatch(insuranceActions.setInsuranceEnabled(false));
  };

  const onInsuranceLogin = () => {
    dispatch(insuranceActions.setInsuranceEnabled(true));
  };

  const onInsuranceLogout = () => {
    dispatch(insuranceActions.setInsuranceEnabled(false));
  };

  const onAddToCart = (evt) => {
    try {
      //@ts-ignore
      let products = utag_data.Products;

      //@ts-ignore
      tealium_data2track.push({
        id: "AddToCart",
        Products: products,
      });
    } catch (error) {
      console.log(
        "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
      );
    }
    if (configAction) {
      let frameCopy = {};
      if (!cartMode) {
        if (isMixAndMatchStep && currentStep === 1) {
          let selectedPkg = currentPackages.filter((p) => {
            const isNonPrescription = p.lensPackage.type === "Non_prescription";
            const isSun =
              p.lensPackage.color &&
              !p.lensPackage.transition &&
              !p.lensPackage.blueLight;
            const isTrans =
              p.lensPackage.color &&
              p.lensPackage.transition &&
              !p.lensPackage.blueLight;
            const treatment = selectedTfamily?.id?.split("$")[0];
            if (treatment === "sun") {
              return (
                isNonPrescription &&
                isSun &&
                selectedLens.id === p.lensPackage.color
              );
            } else if (treatment === "transition") {
              return (
                isNonPrescription &&
                isTrans &&
                selectedLens.id === p.lensPackage.color
              );
            } else {
              return isNonPrescription && !isTrans && !isSun;
            }
          })?.[0];
          console.log(selectedPkg);

          //adding insPrice to frameObject
          if (selectedPkg?.frame?.insPrice) {
            frameCopy = { ...frame, insPrice: selectedPkg?.frame?.insPrice };
          }
          configAction.genericAddToCart(
            selectedFrame
              ? selectedFrame
              : selectedPkg?.frame?.insPrice
              ? frameCopy
              : frame,
            selectedPkg,
            evt.detail.selectedWarranty, //selectedWarranty
            evt.detail.reviewObjectForCart,
            evt.detail.imagery
          );
        } else {
          console.log(selectedPackage);
          console.log(selectedPackage, bundleObject);
          //adding insPrice to frameObject
          if (selectedPackage?.frame?.insPrice) {
            frameCopy = {
              ...frame,
              insPrice: selectedPackage?.frame?.insPrice,
            };
          }
          configAction.genericAddToCart(
            selectedPackage?.frame?.insPrice ? frameCopy : frame,
            selectedPackage,
            evt.detail.selectedWarranty, //selectedWarranty
            evt.detail.reviewObjectForCart,
            evt.detail.imagery,
            bundleObject
          );
        }
      } else {
        if (isMixAndMatchStep && currentStep === 1) {
          let selectedPkg = currentPackages.filter((p) => {
            const isNonPrescription = p.lensPackage.type === "Non_prescription";
            const isSun =
              p.lensPackage.color &&
              !p.lensPackage.transition &&
              !p.lensPackage.blueLight;
            const isTrans =
              p.lensPackage.color &&
              p.lensPackage.transition &&
              !p.lensPackage.blueLight;
            const treatment = selectedTfamily.id.split("$")[0];
            if (treatment === "sun") {
              return (
                isNonPrescription &&
                isSun &&
                selectedLens.id === p.lensPackage.color
              );
            } else if (treatment === "transition") {
              return (
                isNonPrescription &&
                isTrans &&
                selectedLens.id === p.lensPackage.color
              );
            } else {
              return isNonPrescription && !isTrans && !isSun;
            }
          })?.[0];
          console.log(selectedPkg);

          //adding insPrice to frameObject
          if (selectedPkg?.frame?.insPrice) {
            frameCopy = { ...frame, insPrice: selectedPkg?.frame?.insPrice };
          }
          configAction.genericAddToCart(
            selectedFrame
              ? selectedFrame
              : selectedPkg?.frame?.insPrice
              ? frameCopy
              : frame,
            selectedPkg,
            evt.detail.selectedWarranty, //selectedWarranty
            cartMode,
            evt.detail.reviewObjectForCart,
            evt.detail.imagery
          );
        } else {
          //adding insPrice to frameObject
          if (selectedPackage?.frame?.insPrice) {
            frameCopy = {
              ...frame,
              insPrice: selectedPackage?.frame?.insPrice,
            };
          }
          configAction.genericSaveEditFromCart(
            selectedPackage?.frame?.insPrice ? frameCopy : frame,
            selectedPackage,
            evt.detail.selectedWarranty, //selectedWarranty,
            cartMode,
            evt.detail.reviewObjectForCart,
            evt.detail.imagery,
            bundleObject
          );
        }
      }
    }
  };

  const onSaveAndExit = (evt) => {
    let frameCopy = {};
    //adding insPrice to frameObject
    if (selectedPackage?.frame?.insPrice) {
      frameCopy = { ...frame, insPrice: selectedPackage?.frame?.insPrice };
    }
    configAction.genericSaveLensSelection(
      selectedPackage?.frame?.insPrice ? frameCopy : frame,
      configAction.enableFullLensData
        ? selectedPackage
        : selectedPackage.lensPackage,
      evt.detail.selectedWarranty?.id === "asIs"
        ? null
        : evt.detail.selectedWarranty,
      evt.detail.reviewObjectForCart,
      evt.detail.imagery,
      bundleObject
    );
  };

  const onBackToPdp = () => {
    if (isFunction(configAction.genericExit)) {
      configAction.genericExit();
    }
    resetRedux();
    ReactDOM.unmountComponentAtNode(document.querySelector(selector));
  };

  const resetRedux = () => {
    dispatch(colorActions.reset());
    dispatch(configActions.reset());
    dispatch(insuranceActions.reset());
    dispatch(modalsActions.reset());
    dispatch(prescriptionActions.reset());
    dispatch(tooltipActions.reset());
    dispatch(workflowActions.reset());
    dispatch(discountsActions.reset());
    workflow.cleanStepChain();
  };

  const onLoadPrescription = () => {
    const validateFullPrescriptionObject = (prescriptionObject) => {
      if (prescriptionObject) {
        if (
          prescriptionObject.SPH !== undefined &&
          prescriptionObject.SPH !== null &&
          prescriptionObject.SPH.OD !== undefined &&
          prescriptionObject.SPH.OD !== null &&
          !isNaN(prescriptionObject.SPH.OD) &&
          prescriptionObject.SPH.OS !== undefined &&
          prescriptionObject.SPH.OS !== null &&
          !isNaN(prescriptionObject.SPH.OS)
        ) {
          const CYLCheck =
            !prescriptionObject.CYL ||
            (prescriptionObject.CYL !== undefined &&
              prescriptionObject.CYL !== null &&
              ((prescriptionObject.CYL.OD !== undefined &&
                prescriptionObject.CYL.OD !== null &&
                !isNaN(prescriptionObject.CYL.OD)) ||
                (prescriptionObject.CYL.OS !== undefined &&
                  prescriptionObject.CYL.OS !== null &&
                  !isNaN(prescriptionObject.CYL.OS))));
          const AXCheck =
            !prescriptionObject.AX ||
            (prescriptionObject.AX !== undefined &&
              prescriptionObject.AX !== null &&
              ((prescriptionObject.AX.OD !== undefined &&
                prescriptionObject.AX.OD !== null &&
                !isNaN(prescriptionObject.AX.OD)) ||
                (prescriptionObject.AX.OS !== undefined &&
                  prescriptionObject.AX.OS !== null &&
                  !isNaN(prescriptionObject.AX.OS))));
          const ADDCheck =
            !prescriptionObject.ADD ||
            (prescriptionObject.ADD !== undefined &&
              prescriptionObject.ADD !== null &&
              ((prescriptionObject.ADD.OD !== undefined &&
                prescriptionObject.ADD.OD !== null &&
                !isNaN(prescriptionObject.ADD.OD)) ||
                (prescriptionObject.ADD.OS !== undefined &&
                  prescriptionObject.ADD.OS !== null &&
                  !isNaN(prescriptionObject.ADD.OS))));
          const PDCheck =
            !prescriptionObject.PD ||
            (prescriptionObject.PD !== undefined &&
              prescriptionObject.PD !== null &&
              prescriptionObject.PD.OD !== undefined &&
              prescriptionObject.PD.OD !== null &&
              !isNaN(prescriptionObject.PD.OD));
          return CYLCheck && AXCheck && ADDCheck && PDCheck;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const computeTypeFromPrescription = (prescriptionObject) => {
      let computedType = null;

      Object.keys(configType).map((type) => {
        if (prescriptionObject.ADD) {
          if (type.toLowerCase().indexOf("progressive") >= 0) {
            computedType = type;
          }
        } else {
          if (type.toLowerCase().indexOf("single") >= 0) {
            computedType = type;
          }
        }
      });

      return computedType;
    };

    const checkAndSetEditFromCart = () => {
      if (configData?.lens?.catEntryId) {
        dispatch(workflowActions.enablePreselect(true));
        dispatch(workflowActions.forceEnablePreselectSpinner(true));
      } else {
        dispatch(workflowActions.enablePreselect(false));
        dispatch(workflowActions.forceEnablePreselectSpinner(false));
      }
    };

    if (frame.rxValues && configPrescription?.loadExtendedPrescription) {
      const errorMessage = "ERROR while retrieving prescription data";

      let requestObject = { data: { lens: null } };
      if (configData?.lens) {
        requestObject.data.lens = configData.lens;
      }

      configPrescription
        .loadExtendedPrescription(requestObject)
        .then((response) => {
          if (!response) {
            throw new Error(errorMessage);
          }
          if (response.prescriptionFlow === "MANUAL") {
            if (validateFullPrescriptionObject(response)) {
              //ok
              dispatch(prescriptionActions.setCurrentPrescription(response));

              if (
                !loadPreselectEnabled &&
                response.prescriptionFlow &&
                response.prescriptionFlow !== ""
              ) {
                dispatch(prescriptionActions.setCurrentPrescription(response));
                if (!cartMode) {
                  dispatch(prescriptionActions.setLoadedWithoutEdit(true));
                }
              }
            } else {
              //error
              throw new Error(errorMessage);
            }
          } else {
            dispatch(prescriptionActions.setCurrentPrescription(response));
          }
        })
        .catch((ex) => {
          console.error(errorMessage);
        })
        .finally(() => {
          checkAndSetEditFromCart();
        });
    } else {
      checkAndSetEditFromCart();
    }
  };

  useEffect(() => {
    if (currentPackages && frame) {
      window.addEventListener("AddToCartEvent", onAddToCart);
      window.addEventListener("BackToPdp", onBackToPdp);
      window.addEventListener("LoadPrescription", onLoadPrescription);
      window.addEventListener("SaveAndExit", onSaveAndExit);
      (window as any).RXC.rxcWidget["listeners"] = {
        AddToCartEvent: onAddToCart,
        BackToPdp: onBackToPdp,
        LoadPrescription: onLoadPrescription,
        SaveAndExit: onSaveAndExit,
      };
    }

    return () => {
      window.removeEventListener("AddToCartEvent", onAddToCart);
      window.removeEventListener("BackToPdp", onBackToPdp);
      window.removeEventListener("LoadPrescription", onLoadPrescription);
      window.removeEventListener("SaveAndExit", onSaveAndExit);
    };
  }, [
    currentPackages,
    frame,
    selectedPackage,
    selectedTfamily,
    selectedFrame,
    selectedLens,
  ]);

  useEffect(() => {
    if (configInsurance) {
      window.addEventListener(
        configInsurance.getEvents().INSURANCE_ENABLED,
        onInsuranceEnabled
      );
      window.addEventListener(
        configInsurance.getEvents().INSURANCE_DISABLED,
        onInsuranceDisabled
      );
      window.addEventListener(
        configInsurance.getEvents().INSURANCE_LOGIN,
        onInsuranceLogin
      );
      window.addEventListener(
        configInsurance.getEvents().INSURANCE_LOGOUT,
        onInsuranceLogout
      );

      if (configInsurance.isEnabled()) {
        window.dispatchEvent(
          new CustomEvent(configInsurance.getEvents().INSURANCE_ENABLED)
        );
      }
    }

    return () => {
      if (configInsurance) {
        window.removeEventListener(
          configInsurance.getEvents().INSURANCE_ENABLED,
          onInsuranceEnabled
        );
        window.removeEventListener(
          configInsurance.getEvents().INSURANCE_DISABLED,
          onInsuranceDisabled
        );
        window.removeEventListener(
          configInsurance.getEvents().INSURANCE_LOGIN,
          onInsuranceLogin
        );
        window.removeEventListener(
          configInsurance.getEvents().INSURANCE_LOGOUT,
          onInsuranceLogout
        );
      }
    };
  }, [configInsurance]);

  const onEarlyAccessEligibilityChanged = () => {
    let isEligible = configEarlyAccess.isEligible();
    if (isEligible) {
      dispatch(promoActions.setPromoLoading(true));
      configEarlyAccess
        .getEarlyAccessDiscounts(frame, configPackages, configContent)
        .then((res) => {
          let newConfig = {
            ...config,
            lensesData: {
              ...config.lensesData,
              content: res.content,
              packages: res.packages,
            },
          };
          dispatch(configActions.changeOptions(newConfig));
          dispatch(promoActions.setPromoLoading(false));
        })
        .catch((err) => {
          console.error("Error while loading Early Access prices: " + err);
          dispatch(promoActions.setPromoLoading(false));
        });
    }
  };

  useEffect(() => {
    if (configEarlyAccess) {
      window.addEventListener(
        configEarlyAccess.getEvents().EARLY_ACCESS_ELIGIBILITY_CHANGED,
        onEarlyAccessEligibilityChanged
      );
    }

    return () => {
      if (configEarlyAccess) {
        window.removeEventListener(
          configEarlyAccess.getEvents().EARLY_ACCESS_ELIGIBILITY_CHANGED,
          onEarlyAccessEligibilityChanged
        );
      }
    };
  }, [configEarlyAccess]);
}
