import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import "./default.module.scss";
import { prescriptionActions } from "../../redux/slices/prescription";
import { advancedManualPrescriptionAdapter } from "../../configurator/adapters/AdvancedManualPrescriptionAdapter";

// interface PrescriptionModalContinueOpt {}

export const PrescriptionModalNoAdd = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const showMissingReadingAddValueModal = useSelector(
    (state: any) => state.modals?.showPrescriptionModalContinue
  );
  const reduxDispatch = useDispatch();

  const continueButton = useTranslate(
    "steps.advancedPrescription.manual.continueModal.continueNoAdd"
  );
  const cancelButton = useTranslate(
    "steps.advancedPrescription.manual.continueModal.cancel"
  );
  const titleNoAdd = useTranslate(
    "steps.advancedPrescription.manual.continueModal.titleNoAdd"
  );
  const messageNoAdd = useTranslate(
    "steps.advancedPrescription.manual.continueModal.messageNoAdd"
  );
  const reviewPrescriptionValue = useTranslate(
    "steps.advancedPrescription.later.review.title"
  );
  return (
    <>
      {
        <>
          <div className="PrescriptionModalNoAdd__overlay" />
          <div className="PrescriptionModalNoAdd__container">
            <div
              className="PrescriptionModalNoAdd__container__close"
              onClick={() =>
                reduxDispatch(
                  modalsActions.setShowMissingReadingAddValueModal(false)
                )
              }
            ></div>
            <div className="PrescriptionModalNoAdd__container__title">
              {titleNoAdd}
            </div>
            <div className="PrescriptionModalNoAdd__container__text">
              {messageNoAdd}
            </div>
            <div className="PrescriptionModalNoAdd__container__buttons">
              <GenericButton
                id={"continueButton"}
                className={
                  "border-button " +
                  "PrescriptionModalNoAdd__container__continueButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalContinue"
                title={continueButton}
                tabIndex={0}
                handleClick={() => {
                  reduxDispatch(
                    modalsActions.setShowMissingReadingAddValueModal(false)
                  );
                  props.onContinue();
                }}
              >
                {continueButton}
              </GenericButton>
              <GenericButton
                id={"cancelButton"}
                className={
                  "border-button " +
                  "PrescriptionModalNoAdd__container__cancelButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalCancel"
                title={cancelButton}
                tabIndex={0}
                handleClick={() =>
                  reduxDispatch(
                    modalsActions.setShowMissingReadingAddValueModal(false)
                  )
                }
              >
                {cancelButton}
              </GenericButton>
            </div>
          </div>
        </>
      }
    </>
  );
};
