import { Adapter } from "../models/Adapter";
import { Parameter } from "../models/Parameter";

class ParameterToPlainAdapter extends Adapter<Parameter, Record<string, any>> {
  adapt: (source: Parameter) => Record<string, any> = (source: Parameter) => {
    if (source) return { [source.key]: source.value };
    return null;
  };

  adaptReverse: (source: Record<string, any>) => Parameter = (
    source: Record<string, any>
  ) => {
    if (!source) return null;
    let ret = new Parameter();
    Object.entries<Record<string, any>>(source).forEach(([key, val]) => {
      ret.key = key;
      ret.value = val;
    });
    return ret;
  };
}

export const parameterToPlainAdapter = new ParameterToPlainAdapter();
