import React, { ReactNode } from "react";
import "./default.module.scss";

interface MainHeaderProps {
  children: ReactNode;
}

export function MainContent({ children }: MainHeaderProps) {
  return <div className={"MainContent__container"}>{children}</div>;
}
