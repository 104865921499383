//@ts-ignore
export default {
  costa: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            pupillaryDistanceSubtitle:
              'You can select the default settings of 61 for women and 64 for men if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact Pupillary Distance), please <a  data-element-id="X_X_LensPanel_AdvancedPrescription-MeasurePD">measure your Pupillary Distance.</a>',
            whatIsIt: "What is it?",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            digitalOptometry: {
              title: "How to measure your PD",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            incompatibleFrame:
              "We're sorry – the frame you’ve chosen isn't compatible with your prescription. Please select another style.\n </br>Have questions? You can <a href='https://www.costadelmar.com/en-us/c/contact-us'>contact our Customer Service</a> team.",
          },
          account: {
            card: {
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            card: {
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            review: {
              title: "Uploaded",
            },
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, <b>California residents are also required to electronically transmit their valid prescription.</b> Choose an option to proceed.",
            chooseTitle: "Choose how to send your prescription",
            uploadTitle: "Upload",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Upload",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
        },
        type: {
          moreInfo: "More info",
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        color: {
          polarized: "Polarized only",
          tooltip: {
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentLabel:
              "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in installments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments every two weeks with zero hidden fees. Payment options are offered by ###PAYMENT_TYPE###, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by ###PAYMENT_TYPE### Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      price: {
        vatIncluded: "Tax included",
      },
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: "",
        },
      },
    },
    es_MX: {
      steps: {
        prescription: {
          title: "Agrega tus valores de graduación.",
        },
        type: {
          title: "Selecciona su necesidad de visión",
          confirmedTitle: "Uso",
        },
        brand: {
          confirmedTitle: "Marca de la mica:",
        },
        thickness: {
          title: "Selecciona el grosor de tus cristales",
          designTypeTitle: "SELECCIONE EL DISEÑO DE LAS LENTES",
        },
        gvpTreatment: {
          title: "Elige tu paquete de buen precio",
        },
        treatments: {
          addToBag: "Agregar a la cesta",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluye tratamiento de resistencia a los rayones y protección UV.",
        },
        color: {
          confirmedTitle: "Color:",
          tooltip: {
            polarized:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            switch:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            antiScratch:
              "Protege la superficie de las lentes de los impactos fuertes ",
            antiReflective:
              "Reduce los reflejos y ofrece protección invisible. Resistente al resplandor, el polvo y las manchas para ofrecer una visión más clara y homogénea ",
            uvProtection:
              "Capas protectoras resistentes en la parte delantera y trasera de las micas para ofrecer una máxima protección contra rayos UV y los rayones.",
          },
        },
        review: {
          title: "REVISE SU CONFIGURACIÓN",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          paymentInstallments: {
            installmentLabel:
              "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
            installmentsLabel:
              "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Pago a plazos",
              subtitle:
                "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ###PAYMENT_TYPE### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
              affirm:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. ###PAYMENT_TYPE### ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de ###PAYMENT_TYPE### Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
              afterpay:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California.",
              klarna:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses de ###MONTHLY_PRICE###",
            },
            seeDetails: "Ve los detalles",
          },
          tooltip:
            "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
        },
        advancedPrescription: {
          confirmedTitle: "Receta",
          manual: {
            title: "Ingresa tu receta",
            review: {
              title: "Ingresado de manera manual",
            },
            card: {
              title: "Ingrésala de manera manual",
              description:
                "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
            },
            subtitle:
              "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            pupillaryDistanceSubtitle:
              "Puedes seleccionar la configuración predeterminada de <b>61 para mujeres</b> y <b>64 hombres</b> si tienes una receta de valores promedio o menores. Si tienes una receta detallada (o si deseas conocer tu distancia pupilar exacta),",
            pupillaryDistanceWarningValue:
              "Ingresa tu distancia pupilar; si no la conoces, puedes utilizar los valores predeterminados que aparecen arriba o puedes",
            pupillaryDistanceMisurePDAction: "medir tu distancia pupilar",
            alerts: {
              sphPositiveNegativeWarning:
                "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdValueWarning2:
                "La distancia pupilar seleccionada es menor al promedio, te sugerimos que revises la receta. Si no la tienes, puedes agregar los valores que aparecen arriba o puedes",
              incompatibleWithPackagesTitle:
                "¡Lo sentimos! La receta que elegiste no es compatible con ninguna de nuestras micas para este armazón",
              agreementText:
                "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia.",
            },
            continueModal: {
              title: "Envía la receta más tarde",
              message:
                "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              cancel: "No, regresar",
            },
            digitalOptometry: {
              title: "Cómo medir tu distancia pupilar",
              appSubtitle:
                "Tu distancia pupilar o PD es la distancia en milímetros (mm) desde el centro de una pupila hasta el centro de la otra. Indica exactamente por cuál parte de la mica verás, asegurándote una comodidad y claridad óptimas. Puedes medirla con la aplicación o de manera manual.",
              manuallySubtitle:
                "Tu distancia pupilar o PD es la distancia en milímetros (mm) desde el centro de una pupila hasta el centro de la otra. Puedes medirla con la aplicación o de manera manual.",
              toggle: {
                manually: "de manera manual",
                app: "con la aplicación",
              },
              opthyDesc:
                "<b>Haz que tus lentes sean perfectos con Opthy.</b><br/><p>Opthy mide la distancia pupilar para que puedas encontrar un ajuste personalizado y más cómodo.</p><p>Aplicación disponible en iPhone X y versiones superiores.</p>",
              manuallyContent:
                "<div>Si lo deseas, puedes medir tu distancia pupilar por ti mismo siguiendo estos pasos:</div><ul><li>Toma una regla pequeña y ten a la mano lápiz y papel.</li><li>Ahora colócate aproximadamente a 20 cm (8 pulgadas) de distancia de un espejo.</li><li>Cierra tu ojo izquierdo y alinea el 0 sobre el centro de tu pupila derecha.</li><li>Mide la distancia de tu pupila derecha a tu pupila izquierda.</li><li>El número que se alinea directamente sobre tu pupila izquierda es tu distancia pupilar (la distancia promedio para un adulto oscila entre 58 y 65).</li></ul>",
            },
            manualPreviewTitle: "Los detalles de tu receta",
            seeDetails: {
              sphere: "SPH (esfera)",
              cylinder: "CYL (cilindro)",
              axis: "Eje",
              add: "ADD (adición)",
              pd: "DP (distancia pupilar)",
              od: "OD (ojo derecho)",
              os: "OI (ojo izquierdo)",
              vertical: "Prisma vertical (Δ)",
              baseDirection: "Dirección base",
              horizontal: "Prisma horizontal (Δ)",
            },
          },
          upload: {
            title: "Tu receta",
            subtitle:
              "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora",
            },
            uploadDifferentFile: "Subir un archivo diferente",
            changeMethod: "Cambia el método de envío",
            prescriptionUploaded: "Receta subida",
            somethingWentWrong: "Lo sentimos, ocurrió un error.",
            fileTooBigErrorTitle: "El archivo es demasiado grande",
            fileTooBigErrorDescription:
              "El tamaño del archivo es demasiado grande. Tamaño máximo ###FILE_SIZE### MB",
            tryAgain: "Inténtalo de nuevo",
            upload: "Sube",
            pdForm: {
              pupillaryDistance: "DP (distancia pupilar)",
              whatIsIt: "¿Qué es?",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdLeft: "Izquierdo",
              pdRight: "Derecho",
              iHaveTwoPd: "Tengo dos números de DP",
              confirmAndContinue: "Confirma y continúa",
              filePreviewTitle: "Tu receta",
            },
          },
          doctor: {
            addNewDoctorModal: {
              title: "Agrega un nuevo doctor",
              clinicOrDoctorName: "Clínica o nombre del doctor",
              phoneNumber: "Número telefónico",
              addDoctor: "Agrega un doctor",
            },
            slider: {
              phone: "Número telefónico",
              clinicOrDoctorName: "Clínica o nombre del doctor",
            },
            table: {
              doctorName: "Nombre del doctor",
              clinicName: "Nombre de la clínica",
              address: "Domicilio",
              chooseButton: "Elige",
              phone: "Teléfono",
              fax: "Fax",
              phoneNumber: "Número telefónico",
              changeButton: "Cambia",
              wrongDoctor: "Doctor incorrecto",
            },
            pdForm: {
              pupillaryDistance: "DP (distancia pupilar)",
              whatIsIt: "¿Qué es?",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdLeft: "Izquierdo",
              pdRight: "Derecho",
              iHaveTwoPd: "Tengo dos números de DP",
            },
            addManuallyOption: {
              didntFind: "¿No encontraste a tu doctor?",
              addManually: "Agregar de manera manual",
            },
            missingInfoError: "Completa la información faltante",
            doctorNotFoundBoxMessage:
              "No nos fue posible encontrar a tu doctor.",
            doctorNotFoundBoxMessageAdd: "Agrega más detalles o ",
            doctorNotFoundBoxMessageLink: "agrega un nuevo doctor.",
            phoneNumber: "Número telefónico del doctor",
            search: "Búsqueda",
            clinicOrDoctorName: "Clínica o nombre del doctor",
            city: "Ciudad",
            state: "Estado",
            changeSubmissionMethod: "Cambia el método de envío",
            confirmAndContinue: "Confirma y continúa",
            selectedDoctorPageTitle: "La información de tu doctor",
            doctorPreviewTitle: "Llamaremos a tu doctor",
          },
          later: {
            card: {
              description:
                "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
            },
            review: {
              title:
                "Lo solicitaremos después de finalizar el proceso de compra",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          clear: {
            description:
              "Lentes tradicionales y translúcidas, perfectas para el uso diario.",
          },
        },
        addOns: {
          confirmedTitle: "COMPLETE SU CONFIGURACIÓN DE LENTES",
          title: "COMPLETE SU CONFIGURACIÓN DE LENTES",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
        },
      },
      price: {
        vatIncluded: "Impuestos incluidos",
        discountBadgePercentage: "###VALUE######SYMBOL### DE DESCUENTO",
        discountBadgeAmount: "###VALUE######SYMBOL### DE DESCUENTO",
        totalFrameLens: {
          frameLensTotal: "Precio del armazón + cristales",
        },
      },
      noTreatment: "Sin tratamiento",
      insuranceLabelRemove: "Quita el seguro de visión",
      insuranceErrorModal: {
        title: "Ocurrió un error…",
        content:
          "No nos fue posible aplicar los beneficios de tu seguro de visión. Tu plan de seguro dejará de sincronizarse automáticamente para poder continuar.",
        confirm: "Confirmar",
      },
      grayOutBannerMessage:
        "Algunas opciones pueden estar deshabilitadas ya que no son compatibles con los valores de tu receta.",
      error: {
        title: "Ocurrió un error…",
        description:
          "Estamos experimentando algunas dificultades técnicas, lo lamentamos. Mientras tanto, si tienes alguna duda o necesitas ayuda, siéntete en libertad de <a href='#'>ponerte en contacto con nuestro servicio de asistencia al cliente.</a>",
        buttonLabel: "Regresar",
      },
      exitTitle: "'¿SEGURO QUE QUIERES SALIR?'",
    },
    es_US: {
      steps: {
        prescription: {
          description:
            "O te sugeriremos la mica adecuada para tus necesidades de vista y puedas ir sin problemas",
        },
        type: {
          title: "Selecciona tus necesidades de visión",
          prescriptionTitle: "o",
          addToCart: "Agrega al carrito",
          moreInfo: "Más información",
        },
        brand: {
          title: "Selecciona la marca de tus cristales",
          confirmedTitle: "Lens brand:",
        },
        thickness: {
          confirmedTitle: "Grosor",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          confirmedTitle: "Paquete de mica",
        },
        treatments: {
          title: "Completa tus cristales",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Tratamiento",
          extraTreatments: {
            antiScratch: {
              name: "Antirayones",
              title: "Resistente a rayones",
            },
            antiReflective: {
              value: "Tratamiento antirreflejante",
            },
            uvProtection: {
              title: "Protección UV",
            },
          },
        },
        transitionColor: {
          title: "Color de transición",
          indoor: "INTERIORES",
          outdoor: "EXTERIORES",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Transmisión de luz",
            lightConditions: "Condiciones de luz",
            contrast: "Contraste",
            baseLensColor: "Color de mica de base",
          },
          categories: {
            "Ugly category": {
              title: "Categoría Ugly",
            },
            "Cool category": {
              title: "Categoría Cool",
            },
          },
        },
        color: {
          title: "Selecciona el color de la mica",
          titleMobile: "Selecciona el color de la mica",
          titleDesignTypeLayout: "Selecciona la tecnología de la mica",
          titleColorCategoryLayout: "Selecciona el tipo de la mica",
          polarized: "Solo polarizado",
          applyAndContinue: "Aplicar",
          subtitle:
            "Color y contraste mejorados para que puedas ver más detalles.",
          tooltip: {
            blueLight:
              "Reduce la exposición a la luz azul de las pantallas digitales y los rayos del sol, lo que puede ayudar a reducir la fatiga ocular.",
          },
        },
        review: {
          discountTitle:
            "Se aplica automáticamente un 40 % de descuento en micas graduadas",
          firstLineReview: "Armazón:",
          paymentInstallments: {
            modal: {
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Aplicar seguro",
          insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          oneYearCoverageAdd: "Agregar",
        },
        advancedPrescription: {
          title: "¿Tienes una receta?",
          manual: {
            rightEye: {
              initials: "OD",
              name: "(Ojo derecho)",
            },
            leftEye: {
              initials: "OI",
              name: "(Ojo izquierdo)",
            },
            sphere: "Esfera (ESF)",
            cylinder: "Cilindro (CIL)",
            axis: "Eje",
            add: "Adición",
            vertical: "Vertical (Δ)",
            baseDirection: "Dirección base",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "DP (Distancia pupilar)",
            pdLeft: "Izquierdo",
            pdRight: "Derecho",
            iHaveTwoPd: "Tengo dos números de DP",
            applyButton: "Continuar",
            howToRead: "Cómo leer tu receta",
            whatIsIt: "¿Qué es?",
            pupillaryDistanceWarningTooltip:
              "Proporcionar texto para esta descripción emergente",
            moreOptions: "Más opciones",
            alerts: {
              axisRangeError:
                "Los valores de EJE que introdujiste son incorrectos. Estos deben ser entre 1 y 180.",
              axisZeroError:
                "Los valores de EJE que introdujiste son incorrectos. Si el valor de CIL. es más alto o bajo que 0, el valor de EJE no puede ser 0.",
              missingValues: "Completa los valores que hacen falta",
              requiredToProceed: "Es necesario para continuar",
              incompatibleWithPrescriptionTitle:
                "¡Lo sentimos! El armazón que elegiste no es compatible con tu receta",
              incompatibleWithPrescriptionBody:
                "pero no te preocupes, hay armazones compatibles",
              shopCompatible: "Compra los armazones compatibles",
              clearAll: "Borra todo",
            },
            continueModal: {
              continue: "Sí, continuar",
            },
          },
          account: {
            card: {
              title: "Obtenerlo de mi cuenta",
              description: "Inicia sesión y selecciona tu receta",
            },
          },
          upload: {
            card: {
              description:
                "Y nosotros nos encargaremos del resto Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (10 MB máx.) y iWork Pages",
            },
          },
          doctor: {
            title: "Agrega la información de tu doctor",
            subtitle: "Obtendremos los detalles de tu receta con él/ella",
            searchBy: "Busca a tu oculista por",
            card: {
              title: "Llama a mi doctor",
              description: "Obtendremos tu receta con él/ella",
            },
          },
          later: {
            card: {
              title: "Envíala más tarde",
            },
          },
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo de mica",
          title: "Seleccione el tipo de lentes",
          blueLight: {
            title: "Filtro de luz azul",
            description:
              "Reduce la exposición a la luz azul de las pantallas digitales, lo que puede ayudar a prevenir la fatiga ocular.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un par para interiores y exteriores con micas Transitions®: se oscurecen rápidamente y se vuelven transparentes, para que nunca tengas que cambiar de lentes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparente",
            description:
              "Micas tradicionales y translúcidas, perfectas para el uso diario",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Elige entre diferentes colores y tonos de micas.",
            imageFileName: "",
          },
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          label: "Ya está incluido con tu mica",
          title: "Completa tus micas",
          reviewTitle: "Acabados",
        },
      },
      prescription: {
        toggleLabel:
          "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
        prescriptionTitle:
          "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
        rightEye: "(Ojo derecho)",
        rightEyeInitials: "OD",
        leftEye: "(Ojo izquierdo)",
        leftEyeInitials: "OS",
        readPrescription: "Cómo leer tu receta",
        addLabel: "También tengo un valor de adición",
        applyButton: "Aplicar y continuar",
        submitButton: "Enviar",
        clearButton: "Borrar todo",
        skipButton: "Saltar por ahora",
        compatibleFramesButton: "Comprar armazones compatibles",
        doctorDetails:
          "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
        missingValueError: "Agrega tus valores para continuar",
        positiveNegativeError:
          "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
        compatibleFrameError:
          "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
        compatibleFrameSubLabel:
          "No te preocupes, tenemos armazones que se adaptan a tu receta.",
        axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
        axisSubError: "Estos deben ser entre 0 y 180.",
        axisSubErrorZero:
          "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
        editLabel: "Editar receta",
        pdHelp: "¿Qué es esto?",
        twoPDNumbers: "Tengo dos números de DIP",
        missingInputMessage:
          "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
        defaultInputMessage:
          "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
        skipMessage: "Más adelante, te pediremos completar o cargar tu receta",
        readyMessage: "¡Ya te podemos sugerir los cristales adecuados para ti!",
        bestPrescription: "Los mejores para tu receta",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Necesario para continuar",
      },
      price: {
        totalFrameLens: {
          frame: "Precio del armazón",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluido",
        frameLensStartingAt: "Armazón + micas en",
        frameLens: "Armazón + micas",
        startingAt: "A partir de",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Ver cómo se hace",
      learnMoreTreatment: "Ver el tratamiento",
      exitSubtitle: "Tu selección de cristales no se guardará",
      exitYes: "Sí, salir",
      exitContinueEditing: "Seguir editando",
      exitSave: "Guardar y seguir comprando",
      insuranceButtonLabel: "Agregar beneficios del seguro",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
      applyingInsurance: "Aplicar beneficios del seguro",
      frameSize: "Precio del armazón",
      fallbackImageMessageTitle:
        "La vista previa del color de micas no está disponible",
      fallbackImageMessageSubtitle:
        "No te preocupes, recibirás tu armazón con el color de micas que seleccionaste.",
      proceedAsIs: "Proceder tal como están",
      yourSelections: "Tus selecciones",
      editButton: "Editar",
    },
  },
};
