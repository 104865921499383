import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";

interface InsurancePopupOpt {
  onModalClose: () => void;
}

/**
 * Component that displays the insurance modal at the first step. It takes an input of type
 * ```InsurancePopupOpt``` which has a single parameter:
 *  - ```onModalClose```: callback function for the parent component
 */
export function InsurancePopup({ onModalClose }: InsurancePopupOpt) {
  const insuranceTitle = useTranslate("insuranceTitle");
  const insuranceSubtitle = useTranslate("insuranceSubtitle");
  const applyInsurance = useTranslate("applyInsurance");

  const handleModalClose = () => {
    if (onModalClose) onModalClose();
  };

  return (
    <div className={"InsurancePopup__container"}>
      <div className={"InsurancePopup__container__overlay"}></div>
      <div className={"InsurancePopup__container__popup"}>
        <div className={"InsurancePopup__container__title"}>
          {insuranceTitle}
        </div>
        <div className={"InsurancePopup__container__subtitle"}>
          {insuranceSubtitle}
        </div>
        <GenericButton
          noArrow={true}
          className={"InsurancePopup__container_button"}
          handleClick={handleModalClose}
        >
          {applyInsurance}
        </GenericButton>
      </div>
    </div>
  );
}
