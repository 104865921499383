import React, { useState, forwardRef, useEffect } from "react";

export default forwardRef(function DragAndResize(props: any, ref: any) {
  const [size, setSize] = useState({ width: window.innerWidth / 2, height: window.innerHeight / 4, x: 0, y: window.innerHeight - (window.innerHeight / 4) });
  const [isResizing, setIsResizing] = useState(false);
  const [initialResize, setInitialResize] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDownResize = (e) => {
    setIsResizing(true);
    setInitialResize({ x: e.clientX, y: e.clientY });
  };

  const handleMouseDownDrag = (e) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    setIsDragging(false);
    setOptionsPanelWidth();
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = size.width + (e.clientX - initialResize.x);
      const newHeight = size.height + (e.clientY - initialResize.y);
      setSize({ ...size, width: newWidth, height: newHeight });
      setInitialResize({ x: e.clientX, y: e.clientY });
    } else if (isDragging) {
      const offsetX = e.clientX - dragStart.x;
      const offsetY = e.clientY - dragStart.y;
      setSize({
        ...size,
        x: size.x + offsetX,
        y: size.y + offsetY,
      });
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  // set options panel position and dimensions dynamically based on the console
  useEffect(() => {
    const options = document.querySelector(".OptionsPanel")
    if (options) {
      const panelHeight = Number(window.getComputedStyle(options).getPropertyValue("height").split("px")[0])
      options.style.top = `${size.y - panelHeight}px`
      options.style.left = `${size.x}px`
      options.style.width = ref.current.style.width
    }
  }, [size])

  const setOptionsPanelWidth = () => {
    const options = document.querySelector(".OptionsPanel")
    if (options) {
      options.style.width = ref.current.style.width
    }
  }

  return (
    <div
      style={{
        width: `${size.width}px`,
        height: `${size.height}px`,
        position: 'absolute',
        top: `${size.y}px`,
        left: `${size.x}px`,
      }}
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      className='DragAndResize'
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          background: '#fff',
          position: 'absolute',
          bottom: '0',
          right: '0',
          cursor: 'nwse-resize',
        }}

        onMouseDown={handleMouseDownResize}
      />
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: 'move',
          zIndex: '9999',
        }}
        onMouseDown={handleMouseDownDrag}
        className='DragAndResize__inner'
      >
        {props.children}
      </div>
    </div>
  )
})