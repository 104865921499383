import { referenceReducer } from "./slices/references";
import { colorReducer } from "./slices/color";
import { configReducer } from "./slices/config";
import { insuranceReducer } from "./slices/insurance";
import { modalsReducer } from "./slices/modals";
import { prescriptionReducer } from "./slices/prescription";
import { tooltipReducer } from "./slices/tooltip";
import { translationReducer } from "./slices/translation";
import { workflowReducer } from "./slices/workflow";
import { promoReducer } from "./slices/promo";
import { discountsReducer } from "./slices/discounts";
import { mixAndMatchReducer } from "./slices/mixAndMatch";
import { consoleReducer } from "./slices/console";
import { virtualMirrorReducer } from "./slices/virtualMirror";


const rootReducer = {
  config: configReducer,
  workflow: workflowReducer,
  color: colorReducer,
  tooltip: tooltipReducer,
  insurance: insuranceReducer,
  modals: modalsReducer,
  prescription: prescriptionReducer,
  translation: translationReducer,
  references: referenceReducer,
  promo: promoReducer,
  discounts: discountsReducer,
  mixAndMatch: mixAndMatchReducer,
  console: consoleReducer,
  virtualMirror: virtualMirrorReducer,
  // another reducers (if we have)
};

export default rootReducer;
