import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useEnablePrism() {
  const enablePrismValueCookie = getCookieByName("enablePrism", true);
  const enablePrism = useSelector(
    (state: any) => state.config.prescriptionModule?.enablePrism
  );

  if (enablePrismValueCookie !== null) {
    return enablePrismValueCookie as boolean;
  }

  if (enablePrism !== null && enablePrism !== undefined) {
    return enablePrism as boolean;
  }

  return true;
}
