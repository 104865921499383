import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Prescription } from "src/types";
import { usePrescriptionIssueDate } from "./usePrescriptionIssueDate";

export function usePrescriptionsFromMyAccount() {
  const [prescriptions, setPrescriptions] = useState<
    (Prescription & { expired: boolean })[]
  >([]);
  const isLoggedinConfig: () => boolean = useSelector(
    (state: any) => state.config.loginModule?.isLoggedIn
  );
  const loginConfig: (cb) => void = useSelector(
    (state: any) => state.config.loginModule?.login
  );
  const { isExpiredPrescription, convertToMMYYYY } = usePrescriptionIssueDate();

  const retrieveFromMyAccountConfig: () => Promise<Prescription[]> =
    useSelector(
      (state: any) => state.config.prescriptionModule?.retrieveFromMyAccount
    );
  const saveToMyAccountConfig: (p) => Promise<void> = useSelector(
    (state: any) => state.config.prescriptionModule?.saveToMyAccount
  );
  const isLoggedIn = () => isLoggedinConfig && isLoggedinConfig();

  const loginCallback = useCallback(
    (success: boolean, errorCallback) => {
      if (success === false) {
        console.warn(`Attempt to login failed!`);
        errorCallback();
        return
      }
      if (!retrieveFromMyAccountConfig) {
        console.log("missing prescriptionModule.retrieveFromMyAccount!");
        return;
      }
      retrieveFromMyAccountConfig()
        .then((prescriptions) =>
          setPrescriptions(
            prescriptions.map((p) => ({
              ...p,
              myAccount: true,
              expired: isExpiredPrescription(convertToMMYYYY(p.issueDate)),
            }))
          )
        )
        .catch((err) => {
          console.error(err);
        });
    },
    [retrieveFromMyAccountConfig]
  );

  const login = useCallback(
    (errorCallback) => {
      try {
        if (!loginConfig) {
          console.warn("missing loginModule.login!");
          return;
        }
        loginConfig((success) => loginCallback(success, errorCallback));
      } catch (err) {
        console.error(err);
      }
    },
    [loginConfig, loginCallback]
  );

  const saveToMyAccount = (prescriptionObject) => {
    saveToMyAccountConfig && saveToMyAccountConfig(prescriptionObject);
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      console.warn("user is not logged in");
      return;
    }
    if (!retrieveFromMyAccountConfig) {
      console.warn("missing prescriptionModule.retrieveFromMyAccount!");
      return;
    }
    retrieveFromMyAccountConfig()
      .then((prescriptions) =>
        setPrescriptions(
          prescriptions.map((p) => ({
            ...p,
            myAccount: true,
            expired: isExpiredPrescription(convertToMMYYYY(p.issueDate)),
          }))
        )
      )
      .catch((err) => {
        console.error(err);
      });
  }, [retrieveFromMyAccountConfig, isExpiredPrescription]);

  return {
    prescriptionsFromMyAccount: prescriptions,
    isLoggedIn,
    login,
    saveToMyAccount,
  };
}
