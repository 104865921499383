import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import HtmlParser from "html-react-parser";
import "./default.module.scss";
import { isMobile } from "react-device-detect";
import { BrandComponent } from "../common/BrandComponent";

export interface EarlyAccessTooltipProps {
  targetDivId: string;
  discount: number;
  children: React.ReactNode[];
}

export function EarlyAccessTooltip(props: EarlyAccessTooltipProps) {
  const configEarlyAccess = useSelector(
    (state: any) => state.config?.earlyAccessModule
  );
  const [tooltipBody, setTooltipBody] = useState(null);

  useEffect(() => {
    if (configEarlyAccess) {
      if (configEarlyAccess.isEligible()) {
        setTooltipBody(configEarlyAccess.getEligibleTooltip());
      } else {
        setTooltipBody(configEarlyAccess.getInvitationTooltip());
      }
      let tooltip = document.getElementById("EarlyAccessTooltipId");
      if (tooltip) {
        tooltip.classList.remove("invisible");
      }
      window.addEventListener(
        configEarlyAccess.getEvents().EARLY_ACCESS_ELIGIBILITY_CHANGED,
        onEarlyAccessEligibilityChanged
      );
    }

    return () => {
      if (configEarlyAccess) {
        window.removeEventListener(
          configEarlyAccess.getEvents().EARLY_ACCESS_ELIGIBILITY_CHANGED,
          onEarlyAccessEligibilityChanged
        );
      }
    };
  }, [configEarlyAccess]);

  const onEarlyAccessEligibilityChanged = () => {
    if (configEarlyAccess.isEligible()) {
      setTooltipBody(configEarlyAccess.getEligibleTooltip());
    } else {
      setTooltipBody(configEarlyAccess.getInvitationTooltip());
    }
  };

  useEffect(() => {
    let tooltipElement = document.getElementById("EarlyAccessTooltipId");
    if (tooltipElement) {
      tooltipElement.addEventListener(
        "transitionend",
        handleAnimationEnd,
        false
      );
    }
    return () => {
      let tooltipElement = document.getElementById("EarlyAccessTooltipId");
      if (tooltipElement) {
        tooltipElement.removeEventListener(
          "transitionend",
          handleAnimationEnd,
          false
        );
      }
    };
  }, []);

  const handleAnimationEnd = () => {
    let tooltipElement = document.getElementById("EarlyAccessTooltipId");

    if (tooltipElement.classList.contains("invisible")) {
      tooltipElement.classList.add("gone");
    }
  };

  const handleClose = () => {
    let tooltip = document.getElementById("EarlyAccessTooltipId");
    if (tooltip) {
      tooltip.classList.add("invisible");
    }
  };

  const addInvisibleClass = useMemo(
    () => tooltipBody?.trim() == "",
    [tooltipBody]
  );

  return (
    <div className="EarlyAccessTooltip">
      {configEarlyAccess && (
        <div
          id="EarlyAccessTooltipId"
          className={`EarlyAccessTooltip__container${
            addInvisibleClass ? " invisible" : ""
          }`}
        >
          <div
            className="EarlyAccessTooltip__container__close"
            onClick={handleClose}
          />
          {tooltipBody && (
            <div className="EarlyAccessTooltip__container__body">
              {HtmlParser(tooltipBody)}
            </div>
          )}
          <div className="EarlyAccessTooltip__container__arrow" />
        </div>
      )}
      {!isMobile && (
        <BrandComponent
          componentName="TotalPriceDiscountBadge"
          parameter={{
            discount: props.discount,
          }}
        />
      )}
      {props.children}
    </div>
  );
}
