import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function usePrismPrice() {
  const prismPriceCookie = getCookieByName(
    "prismPrice",
    false
  );

  const prismPrice = useSelector(
    (state: any) => state.config?.prescriptionModule?.prismPrice
  );

  if (
    prismPriceCookie !== null &&
    prismPriceCookie !== undefined
  ) {
    return prismPriceCookie;
  }
  if (
    prismPrice !== null &&
    prismPrice !== undefined
  ) {
    return prismPrice;
  }
  return false;
}
