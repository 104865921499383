import { InsuranceSettings } from "../RIA/core/types/InsuranceSettings";

export const getInsuranceParams = (
  insuranceSettings,
  ecomInsuranceSettings,
  brand,
  environment
): InsuranceSettings => {
  if (!brand) throw new Error("Missing Brand");
  if (!environment) throw new Error("Missing Environment");
  if (!insuranceSettings) throw new Error("Missing Static Insurance Settings");

  const obj: InsuranceSettings = {
    ...(insuranceSettings?.["default"] || {}),
    ...(insuranceSettings?.[brand] || {}),
    ...(insuranceSettings?.[environment]?.[brand] || {}),
    ...(ecomInsuranceSettings || {}),
  };

  if (!obj.method) {
    obj.method = "STANDARD";
  }
  return obj;
};
