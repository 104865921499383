import React, { useState } from 'react';
import { useDebugConsoleContext } from './DebugConsole';

export default function OptionsPanel(props: any) {

  const { options, onOptionChange } = useDebugConsoleContext()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={"OptionsPanel" + `${isOpen ? " open" : " closed"}`}>
      {!isOpen && <button className='openTrigger' onClick={() => setIsOpen(true)}>Show Options</button>}
      {isOpen && options.map(option => (
        <div className="checkbox">
          <input type="checkbox" name={option.value} onChange={onOptionChange} checked={option.active} />
          <label htmlFor={option.value}>{option.value}</label>
        </div>
      ))}
      {isOpen && <button className='closeTrigger' onClick={() => setIsOpen(false)}>Hide Options</button>}
    </div>
  )
}