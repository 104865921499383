import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import { BrandComponent } from "../common/BrandComponent";
import { VideoFrame } from "../VideoFrame/component";

export function LearnMoreVideoList(props) {
  const processData = () => {
    let data = props.data;
    let dataToSet = {};
    let videoMade = {};
    let videoTreatment = {};

    data.map((html, index) => {
      if (
        html.indexOf("background-learn-more-made") >= 0 &&
        html.indexOf("background-learn-more-made-tap") < 0
      ) {
        videoMade["video"] = { html: html, internalCardIndex: index };
      } else if (html.indexOf("background-learn-more-made-tap") >= 0) {
        videoMade["videoTap"] = { html: html, internalCardIndex: index };
      } else if (
        html.indexOf("background-learn-more-treatment") >= 0 &&
        html.indexOf("background-learn-more-treatment-tap") < 0
      ) {
        videoTreatment["video"] = { html: html, internalCardIndex: index };
      } else if (html.indexOf("background-learn-more-treatment-tap") >= 0) {
        videoTreatment["videoTap"] = { html: html, internalCardIndex: index };
      }
    });

    dataToSet = { videoMade: videoMade, videoTreatment: videoTreatment };
    return dataToSet;
  };

  const videoData = processData();
  const [isVideoMadeShown, setIsVideoMadeShown] = useState(false);
  const [isTapShown, setIsTapShown] = useState(false);
  const [forceShowPauseButtonTap, setForceShowPauseButtonTap] = useState(0);
  const [forceShowPlayButtonTap, setForceShowPlayButtonTap] = useState(0);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (props.selectedCarouselPage !== null) {
      if (props.selectedCarouselPage !== props.carouselIndex) {
        //stop all videos playing in this card
        handlePauseTap();
        setForceShowPlayButtonTap((prev) => prev + 1);
      }
    }
  }, [props.selectedCarouselPage]);

  const handleMadeButtonClick = () => {
    if (!isVideoMadeShown) {
      let classNameToHide = "background-learn-more-made";
      let classNameToShow = "background-learn-more-treatment";

      let toHideQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
      let toShowQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        classNameToShow.replace(" ", ".");

      document.querySelector(toShowQuery).classList.remove("card-invisible");
      document.querySelector(toHideQuery).classList.add("card-invisible");
      document
        .getElementById(
          "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            videoData["videoMade"].video.internalCardIndex
        )
        .classList.add("invisible");
      document
        .getElementById(
          "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            videoData["videoTreatment"].video.internalCardIndex
        )
        .classList.remove("invisible");

      setIsVideoMadeShown(true);

      if (videoData["videoTreatment"]["videoTap"]) {
        handlePlayTap(true);
      }
    } else {
      let classNameToShow = "background-learn-more-made";
      let classNameToHide = "background-learn-more-treatment";

      let toHideQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
      let toShowQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        classNameToShow.replace(" ", ".");

      document.querySelector(toShowQuery).classList.remove("card-invisible");
      document.querySelector(toHideQuery).classList.add("card-invisible");
      document
        .getElementById(
          "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            videoData["videoTreatment"].video.internalCardIndex
        )
        .classList.add("invisible");
      document
        .getElementById(
          "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            videoData["videoMade"].video.internalCardIndex
        )
        .classList.remove("invisible");

      setIsVideoMadeShown(false);
      if (videoData["videoMade"]["videoTap"]) {
        handlePlayTap(false);
      }
    }
  };

  const handleTapEvent = () => {
    let hasTap = null;

    if (!isVideoMadeShown) {
      if (videoData["videoMade"].videoTap) {
        hasTap = true;
      }
    } else {
      if (videoData["videoTreatment"].videoTap) {
        hasTap = true;
      }
    }
    if (hasTap) {
      showTapVideo();
    }
  };

  const showTapVideo = () => {
    if (!isVideoMadeShown) {
      if (!isTapShown) {
        let classNameToHide = "background-learn-more-made";
        let classNameToShow = "background-learn-more-made-tap";

        let toHideQuery =
          "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
        let toShowQuery =
          "#LeanMore_card_" +
          props.carouselIndex +
          " ." +
          classNameToShow.replace(" ", ".");

        document.querySelector(toShowQuery).classList.remove("card-invisible");
        document.querySelector(toHideQuery).classList.add("card-invisible");

        setIsTapShown(true);
      } else {
        let classNameToShow = "background-learn-more-made";
        let classNameToHide = "background-learn-more-made-tap";

        let toHideQuery =
          "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
        let toShowQuery =
          "#LeanMore_card_" +
          props.carouselIndex +
          " ." +
          classNameToShow.replace(" ", ".");

        document.querySelector(toShowQuery).classList.remove("card-invisible");
        document.querySelector(toHideQuery).classList.add("card-invisible");

        setIsTapShown(false);
      }
    } else {
      if (!isTapShown) {
        let classNameToHide = "background-learn-more-treatment";
        let classNameToShow = "background-learn-more-treatment-tap";

        let toHideQuery =
          "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
        let toShowQuery =
          "#LeanMore_card_" +
          props.carouselIndex +
          " ." +
          classNameToShow.replace(" ", ".");

        document.querySelector(toShowQuery).classList.remove("card-invisible");
        document.querySelector(toHideQuery).classList.add("card-invisible");

        setIsTapShown(true);
      } else {
        let classNameToShow = "background-learn-more-treatment";
        let classNameToHide = "background-learn-more-treatment-tap";

        let toHideQuery =
          "#LeanMore_card_" + props.carouselIndex + " ." + classNameToHide;
        let toShowQuery =
          "#LeanMore_card_" +
          props.carouselIndex +
          " ." +
          classNameToShow.replace(" ", ".");

        document.querySelector(toShowQuery).classList.remove("card-invisible");
        document.querySelector(toHideQuery).classList.add("card-invisible");

        setIsTapShown(false);
      }
    }
  };

  const handlePauseTap = (updatedIsVideoMadeShown?: boolean) => {
    let videoClass = null;
    let videoTapClass = null;
    let videoClassQuery = null;
    let videoTapClassQuery = null;

    let flagToCheck = isVideoMadeShown;

    if (updatedIsVideoMadeShown !== undefined) {
      flagToCheck = updatedIsVideoMadeShown;
    }

    if (!flagToCheck) {
      videoClass = "background-learn-more-made";
      videoTapClass = "background-learn-more-made-tap";
      videoClassQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + videoClass + " video";
      videoTapClassQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        videoTapClass +
        " video";
    } else {
      videoClass = "background-learn-more-treatment";
      videoTapClass = "background-learn-more-treatment-tap";
      videoClassQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + videoClass + " video";
      videoTapClassQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        videoTapClass +
        " video";
    }

    if (videoClassQuery && videoTapClassQuery) {
      //@ts-ignore
      document.querySelector(videoClassQuery)?.pause();
      //@ts-ignore
      document.querySelector(videoTapClassQuery)?.pause();
    }

    if (updatedIsVideoMadeShown !== undefined) {
      //using this to have VideoFrame child set the button as pause (since the video is playing)
      //it's only use to trigger this functionality, the value has no meaning
      setForceShowPlayButtonTap((prev) => prev + 1);
    }
  };

  const handlePlayTap = (updatedIsVideoMadeShown?: boolean) => {
    let videoClass = null;
    let videoTapClass = null;
    let videoClassQuery = null;
    let videoTapClassQuery = null;

    let flagToCheck = isVideoMadeShown;

    if (updatedIsVideoMadeShown !== undefined) {
      flagToCheck = updatedIsVideoMadeShown;
    }

    if (!flagToCheck) {
      videoClass = "background-learn-more-made";
      videoTapClass = "background-learn-more-made-tap";
      videoClassQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + videoClass + " video";
      videoTapClassQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        videoTapClass +
        " video";
    } else {
      videoClass = "background-learn-more-treatment";
      videoTapClass = "background-learn-more-treatment-tap";
      videoClassQuery =
        "#LeanMore_card_" + props.carouselIndex + " ." + videoClass + " video";
      videoTapClassQuery =
        "#LeanMore_card_" +
        props.carouselIndex +
        " ." +
        videoTapClass +
        " video";
    }

    if (videoClassQuery && videoTapClassQuery) {
      //@ts-ignore
      document.querySelector(videoClassQuery).play();
      //@ts-ignore
      document.querySelector(videoTapClassQuery).play();
    }

    if (updatedIsVideoMadeShown !== undefined) {
      //using this to have VideoFrame child set the button as pause (since the video is playing)
      //it's only use to trigger this functionality, the value has no meaning
      setForceShowPauseButtonTap((prev) => prev + 1);
    }
  };

  const closeModal = () => {
    reduxDispatch(modalsActions.setOpenLearnMoreModal(null));
  };

  return (
    <div
      key={"LeanMore_card_" + props.carouselIndex}
      id={"LeanMore_card_" + props.carouselIndex}
    >
      {Object.keys(videoData).map((videoType) => {
        return Object.keys(videoData[videoType]).map((videoKey) => (
          <div
            key={
              "card_" +
              props.carouselIndex +
              "_subcard_" +
              videoData[videoType][videoKey].internalCardIndex
            }
            id={
              "card_" +
              props.carouselIndex +
              "_subcard_" +
              videoData[videoType][videoKey].internalCardIndex
            }
          >
            <BrandComponent
              componentName="LearnMoreVideo"
              parameter={{
                html: videoData[videoType][videoKey].html,
                carouselIndex: props.carouselIndex,
                internalCardIndex:
                  videoData[videoType][videoKey].internalCardIndex,
                hasMadeButton:
                  Object.keys(videoData["videoTreatment"]).length > 0,
                handleMadeButtonClick: handleMadeButtonClick,
                hasTap: videoData[videoType]["videoTap"],
                shouldAutoPlayIfHasTap:
                  videoData[videoType]["videoTap"] &&
                  (videoData[videoType]["video"].html
                    .split('class="')[1]
                    .split('"')[0]
                    .indexOf("invisible") < 0 ||
                    videoData[videoType]["videoTap"].html
                      .split('class="')[1]
                      .split('"')[0]
                      .indexOf("invisible") < 0),
                handleTapEvent: handleTapEvent,
                forceShowPlayButton: forceShowPlayButtonTap,
              }}
            ></BrandComponent>
            {videoKey !== "videoTap" && videoData[videoType]["videoTap"] && (
              <VideoFrame
                videoIndex={0}
                videoClassName={
                  videoData[videoType][videoKey].html
                    .split('class="')[1]
                    .split('"')[0]
                }
                cardId={
                  "card_" +
                  props.carouselIndex +
                  "_subcard_" +
                  videoData[videoType][videoKey].internalCardIndex
                }
                id={
                  "VideoFrame_" +
                  props.carouselIndex +
                  "_subcard_" +
                  videoData[videoType][videoKey].internalCardIndex
                }
                onStart={null}
                onPause={() => {
                  handlePauseTap();
                }}
                onPlay={() => {
                  handlePlayTap();
                }}
                onExit={closeModal}
                hasMadeButton={
                  Object.keys(videoData["videoTreatment"]).length > 0
                }
                hasTap={true}
                forceShowPauseButton={forceShowPauseButtonTap}
                forceShowPlayButton={forceShowPlayButtonTap}
                onMadeButtonClick={() => handleMadeButtonClick()}
              />
            )}
          </div>
        ));
      })}
    </div>
  );
}
