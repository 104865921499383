import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { checkTileTitlePrefix, getStepBadgeType } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useContextualMessage,
  useConfigContent,
  usePriceStepItem,
  useCurrentType,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { sortingGreyOut } from "../../ConfigLoader";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";

export function DesignType(props: StepComponentOptions) {
  const translate = useTranslation();
  const currentPackages = useAllCurrentPackages();
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.designType
  );
  const configDesignType: any[] = useConfigContent("DesignType");
  const getPriceStep = usePriceStepItem();
  const config = useSelector((state: any) => state.config);
  const currentType = useCurrentType();
  const [designTypesAvaiable, setDesignTypesAvaiable] = useState(null);

  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.designType.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.type.learnMoreCTA");

  useEffect(() => {
    if (currentPackages && configDesignType) {
      let designTypes = configDesignType.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.designType === c.id
        )
      );
      let designTypesGreyOut = configDesignType
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.designType === c.id
          )
        )
        .map((designType) => {
          return {
            ...designType,
            disabled: true,
          };
        });
      // .filter(designType =>
      //     !designTypes.find(d => d.id === designType.id)
      // );
      designTypes
        .concat(designTypesGreyOut)
        .sort((a, b) =>
          a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
        );

      setDesignTypesAvaiable(designTypes);
    }
  }, [configDesignType, currentPackages]);

  const onDesignSelected = (id: string, skipped: boolean) => {
    const priceObj = getPriceStep({ designType: id });
    const addedParams = skipped ? workflow.currentStep.params : [];

    workflow.goNextStep(
      id
        ? [
            {
              key: "designType",
              price: priceObj?.price,
              discount: priceObj?.discount,
              insPrice:
                skipped && priceObj?.price == 0 ? 0 : priceObj?.insPrice,
              value: id || null,
            },
            ...addedParams,
          ]
        : [],
      skipped
    );
  };

  const getParameter = (c: any) => {
    if (designTypesAvaiable) {
      const priceObj = c.disabled ? null : getPriceStep({ designType: c.id });

      return {
        ...c,
        title: checkTileTitlePrefix(config, workflow.currentStep.key)
          ? priceObj?.price
            ? `${translate("steps.tilesTitlePrefixUpgrade")} ${c.title}`
            : `${translate("steps.tilesTitlePrefixProceed")} ${c.title}`
          : c.title,
        textBadge: c.badge,
        imageUrl: c.imageFileName,
        price: priceObj?.price || priceObj?.price == 0 ? priceObj?.price : null,
        discount:
          priceObj?.discount || priceObj?.discount == 0
            ? priceObj?.discount
            : null,
        insPrice:
          priceObj?.insPrice || priceObj?.insPrice == 0
            ? priceObj?.insPrice
            : null,
        badge: c.badge ? getStepBadgeType(c.badge) : null,
        disabled: c.disabled || c.badge == "coming soon",
        onClick:
          c.badge == "coming soon" || c.disabled
            ? null
            : () => onDesignSelected(c.id, false),
      };
    }
  };

  const getKey = (title: string) =>
    "DesignType_sc_" + title.split(" ").join("_");

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.thickness.designTypeTitle"),
        subtitle: ctxMessage,
        showGrayOutBanner:
          designTypesAvaiable && designTypesAvaiable.find((d) => d.disabled),
      }}
    >
      {learnMoreButton !== "steps.designType.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensDesign-LearnMore"
            }
          >
            {moreInfoLabel}
          </a>
        )}
      <div className={"DesignType__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "DesignType",
            onNext: (selected: any, skipped?: boolean) => {
              onDesignSelected(selected?.id, skipped);
            },
            parameterPreselection: ["designType"],
            onPreselect: (selected, skipped: boolean) => {
              onDesignSelected(selected.designType, skipped);
            },
          }}
        >
          {designTypesAvaiable &&
            designTypesAvaiable.map((c: any) => (
              <BrandComponent
                key={getKey(c.title)}
                componentName="StepCard"
                parameter={getParameter(c)}
              />
            ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
