import React from "react";

export const Separator = (props) => {
  return (
    <div
      className={"FullPrescriptionForm__separator"}
      style={props.marginTop ? { marginTop: props.marginTop } : {}}
    />
  );
};
