import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WarrantyOptionConfigModel } from "../../components/WarrantyOptions/component";
import { Step } from "../../configurator/models/Step";

type TPayload = {
  steps: Step[];
};

export type DiscountsState = {
  loading: string[];
  discountedLensPackages: string[];
};

const initialState: DiscountsState = {
  loading: [],
  discountedLensPackages: [],
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    activeLoader: (state, { payload: id }) => {
      if (state.loading.includes(id)) return;
      console.log("activating loader for ", id);
      state.loading = state.loading.concat(id);
    },
    disableLoader: (state, { payload: id }) => {
      console.log("disabling loader for ", id);
      state.loading = state.loading.filter((loadingId) => loadingId !== id);
    },
    addDiscountedLens: (state, { payload }) => {
      const { catEntryId } = payload.lensPackage;
      if (state.loading.includes(catEntryId)) return;
      console.log("add discounted lens ", catEntryId);
      state.discountedLensPackages =
        state.discountedLensPackages.concat(catEntryId);
    },
    removeDiscountedLens: (state, { payload }) => {
      const { catEntryId } = payload.lensPackage;
      if (state.loading.includes(catEntryId)) return;
      console.log("remove discounted lens ", catEntryId);
      state.discountedLensPackages = state.discountedLensPackages.filter(
        (lens) => lens !== catEntryId
      );
    },
  },
});

export const discountsReducer = discountsSlice.reducer;
export const discountsActions = discountsSlice.actions;
