//@ts-ignore
export default {
  glasses: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "My prescription includes prism values",
            pupillaryDistanceSubtitle:
              "You can select the default settings of 61 for women and 64 for men if you have an average or low prescription. If you have a strong prescription or if you want to know your exact Pupillary Distance, please ",
            applyButtonSave: "Save and continue",
            whatIsIt: "What is PD (Pupillary Distance)",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0,  AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarningSmaller: "The selected Pupillary Distance is smaller than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.<br>If we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarningLarger: "The selected Pupillary Distance is larger than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.<br>If we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarning:
                "The selected Pupillary Distance is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarning2:
                "The selected Pupillary Distance is smaller than average, we suggest double checking your prescription. If you don’t have the Pupillary Distance here .",
              pdValueWarningDigitalOptometrySmaller: "The selected Pupillary Distance is smaller than average, we suggest double checking your prescription. You can leave the default values written above or you can ",
              pdValueWarningDigitalOptometryLarger: "The selected Pupillary Distance is larger than average, we suggest double checking your prescription. You can leave the default values written above or you can ",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            digitalOptometry: {
              title: "How to measure your Pupillary Distance",
              toggle: {
                app: "WITH THE APP",
                manually: "MANUALLY",
              },
              appSubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              manuallyContent:
                "<div>If you want, you can measure your Pupillary Distance (PD) yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (an average PD for an adult ranges between 58 and 65).</li></ul>",
            },
            editTitle: "Edit your prescription",
            incompatibleFrame:
              "We're sorry - the frames you’ve chosen aren't compatible with your prescription.<br/>Please select another style. Have questions? You can <a href='https://www.glasses.com/gl-us/contact-us'>contact our Customer Service</a> team.",
            incompatibleLenses:
              "We're sorry, the prescription you've entered isn't compatible with our lenses offered online. Find a store near you or contact our <a href='https://www.glasses.com/gl-us/contact-us'>Customer Service</a> team for more information.",
            withoutPrescriptionFromMyAccount:
              "You don’t have a prescription saved in your account. Add your prescription below and save it for future purchases or go back and choose another method.",
            clearAll: "clear all",
            selectNewFrame: "Select new frame",
            issueDate: {
              label: "Issue date (MM/YYYY)",
              missingError: "Please insert date",
              formatError: "The date entered is not valid",
              tooltipText:
                "Before submitting your prescription, please check the expiration date to make sure it is current and valid. We only accept prescriptions that have been issued within the last 2 years. By selecting the tick box and continuing below, you agree to our privacy policy and T&Cs.",
            },
          },
          upload: {
            card: {
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            review: {
              title: "Uploaded",
            },
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            review: {
              title: "We'll call your doctor",
            },
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription.",
            infoCMD:
              "Please note that besides adding prescription values manually, <b>California residents are also required to electronically transmit their valid prescription</b>. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "UPLOAD YOUR PRESCRIPTION",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Upload",
            callMyDoc: "CALL MY DOCTOR",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
          account: {
            card: {
              description: "Log in and select your prescription.",
            },
            review: {
              title: "Loaded from the account",
            },
          },
          fromMyAccount: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
            review: {
              title: "Loaded from the account",
            },
            title: "Select your prescription",
            subtitle:
              "Choose the preferred prescription and we’ll take care of the rest. \n Can’t find it?",
            subtitleAdd: "Add a new prescription",
            prescriptionName: "Prescription name",
            uploadedOn: "Last updated: ",
            selectButton: "Select prescription",
            savePrescriptionInAccount: "Save prescription in My Account",
            newPrescriptionNameError: "Prescription name field cannot be empty",
            olderThanYearBanner:
              "This prescription was uploaded 1 year ago. Please check if it is still valid.",
            showPrescription: "show prescription",
            prescriptionDetailModal: {
              title: "Your prescription details",
              edit: "Edit prescription",
            },
            issueDate: "Issue date",
          },
        },
        thickness: {
          moreInfoStep: "Learn More about lens thickness",
        },
        type: {
          moreInfo: "More info",
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        treatments: {
          addToBag: "Confirm and add to bag",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        color: {
          polarized: "Polarized only",
          tooltip: {
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        protectionplan: {
          title: "Glasses Protection Plan",
          confirmedTitle: "Protection plan",
          asIs: "No, proceed without the Protection Plan",
          whatsCovered: "What's covered",
        },
        review: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of just ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of just ###MONTHLY_PRICE###",
              terms:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. \n <a href='#' style= 'color:rgba(87, 93, 155, 1); font-weight: 600; letter-spacing:0.16px'>Click here</a> for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law",
              learnMore: "Learn more",
            },
          },
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      price: {
        vatIncluded: "Tax included",
      },
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: "",
        },
      },
    },
    en_GB: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: "What is it?",
            alerts: {
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              requiredToProceed: "This is required to proceed",
            },
          },
          upload: {
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        transitionColor: {
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        color: {
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in instalments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
            },
          },
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      frameSize: "Frame size",
      yourSelections: "Your selections",
    },
    en_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: "What is it?",
            alerts: {
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              requiredToProceed: "This is required to proceed",
            },
          },
          upload: {
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        transitionColor: {
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        color: {
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in instalments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
            },
          },
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      frameSize: "Frame size",
      yourSelections: "Your selections",
    },
  },
};
