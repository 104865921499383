import React, { FC, useEffect, useState } from "react";

type SelectProps = {
  options: string[];
  isValidTick: boolean;
  isError: boolean;
  value: string;
  label: string;
  currentLanguage: string;
  labelClassName?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const Select: FC<SelectProps> = ({
  options,
  isValidTick,
  isError,
  value,
  label,
  currentLanguage,
  labelClassName,
  ...props
}) => {
  const [showLabel, setShowLabel] = useState(false);
  const inputFieldStyle = () => {
    let inputClass = "";
    if (isError) {
      inputClass = "input__field error";
    } else {
      if (isValidTick) {
        inputClass = "input__field green";
      } else {
        if (!value) {
          inputClass = "input__field placeholder";
        } else {
          inputClass = "input__field";
        }
      }
    }
    return inputClass;
  };

  useEffect(() => {
    setShowLabel(!!value);
  }, [value]);

  return (
    <label
      className={
        isValidTick
          ? `input ${labelClassName} valid`
          : `input ${labelClassName}`
      }
    >
      <select className={inputFieldStyle()} value={value} {...props}>
        {options.map((option, key) => (
          <option
            key={key}
            value={key === 0 ? null : option}
            selected={!!value ?? key === 0}
          >
            {option}
          </option>
        ))}
      </select>
      {showLabel && <span className="input__label">{label}</span>}
    </label>
  );
};

export default Select;
