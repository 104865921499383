import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import {
  Parameter,
  ParameterWithTitle,
} from "../../configurator/models/Parameter";
import { Step } from "../../configurator/models/Step";

type WorkflowState = {
  currentStep: Step;
  currentTreatments?: ParameterWithTitle[];
  currentIncludedTreatments?: Parameter[];
  promoType?: string;
  additionalParameters?: any;
  loadPreselect: boolean;
  loadingDynamicDiscounts: boolean;
  showFrameLensPriceAtStep: any;
  loadingStep: boolean;
  hasRecordedImpression: boolean;
  forceEnablePreselectSpinner: boolean;
  reviewObjectForCart: any;
  selectedPrescriptionMode: boolean;
  automaticDiscountBadgeStepSelection: AutomaticDiscountParameter;
  lensType?: string;
  isEditMode?: boolean;
};

const initialState: WorkflowState = {
  currentStep: null,
  loadPreselect: null,
  loadingDynamicDiscounts: null,
  showFrameLensPriceAtStep: null,
  loadingStep: false,
  hasRecordedImpression: false,
  forceEnablePreselectSpinner: true,
  reviewObjectForCart: null,
  selectedPrescriptionMode: false,
  automaticDiscountBadgeStepSelection: null,
  lensType: null,
  isEditMode: false
};

const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<Step>) {
      state.currentStep = action.payload;
    },
    setParameterPrice(state, action: PayloadAction<ParameterWithTitle[]>) {
      state.currentTreatments = action.payload;
    },
    setLensType(state, action: PayloadAction<string>) {
      state.lensType = action.payload;
    },
    setCurrentIncluded(state, action: PayloadAction<Parameter[]>) {
      state.currentIncludedTreatments = action.payload;
    },
    setAutomaticDiscountBadgeStepSelection(
      state,
      action: PayloadAction<AutomaticDiscountParameter>
    ) {
      state.automaticDiscountBadgeStepSelection = action.payload;
    },
    setPromoType(state, action: PayloadAction<string>) {
      state.promoType = action.payload;
    },
    setAdditionalParametersPackages: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.additionalParameters = payload;
    },
    enablePreselect: (state, { payload }: PayloadAction<boolean>) => {
      state.loadPreselect = payload;
    },
    forceEnablePreselectSpinner: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.forceEnablePreselectSpinner = payload;
    },
    setShowFrameLensPriceAtStep(state, action: PayloadAction<any>) {
      state.showFrameLensPriceAtStep = action.payload;
    },
    setLoadingStep: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingStep = payload;
    },
    setHasRecordedImpression: (state, { payload }: PayloadAction<boolean>) => {
      state.hasRecordedImpression = payload;
    },
    setLoadingDynamicDiscounts: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loadingDynamicDiscounts = payload;
    },
    setReviewObjectForCart: (state, { payload }: PayloadAction<any>) => {
      state.reviewObjectForCart = payload;
    },
    setSelectedPrescriptionMode: (state, { payload }: PayloadAction<any>) => {
      state.selectedPrescriptionMode = payload;
    },
    setIsEditMode: (state, { payload }: PayloadAction<any>) => {
      state.isEditMode = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const workflowActions = workflowSlice.actions;
export const workflowReducer = workflowSlice.reducer;
