import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useEnableNonPrescriptionRedirect() {
  const enableNonPrescriptionRedirectCookie = getCookieByName("enableNonPrescriptionRedirect", true);
  const enableNonPrescriptionRedirect = useSelector(
    (state: any) => state.config.prescriptionModule?.enableNonPrescriptionRedirect
  );

  if (enableNonPrescriptionRedirectCookie !== null) {
    return enableNonPrescriptionRedirectCookie as boolean;
  }

  if (enableNonPrescriptionRedirect !== null && enableNonPrescriptionRedirect !== undefined) {
    return enableNonPrescriptionRedirect as boolean;
  }

  return false;
}
