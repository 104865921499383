import React, { useEffect, useMemo, useState } from "react";
import { BrandComponent } from "../common/BrandComponent";
import { useAllCurrentPackages, useTreatmentsFamilyMapper } from "@hooks";
import { groupBy, sortBy } from "lodash";
import { useConfigContent, usePriceStepItem } from "@hooks";
import { getCheapPackageFromList } from "../../configurator/ConfigLoader";
import { useSelector, useDispatch } from "react-redux";
import { PlainLensColorPromoAdapter } from "../../configurator/adapters/PlainToLensColorPromoAdapter";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { mixAndMatchActions } from "../../redux/slices/mixAndMatch";
import { colorActions } from "../../redux/slices/color";
import { useGetDiscount } from "../../configurator/hooks";
import { orderBy } from "lodash";
export interface LensColorGroup {
  name: string;
  colors: LensColorModel[];
  hasPromo: boolean;
}
export interface LensColorPromo {
  title: string;
  id: string;
  amount: number | string;
}
interface LensColorModel {
  name: string;
  value: string;
  id: string;
  selected?: boolean;
  promo?: LensColorPromo[];
  price: number;
  discount: number;
  insPrice: number;
  recommendedUse: string;
  colorCategory: string;
  colorCategoryTitle: string;
  disabled?: boolean;
  promoBadge?: string;
}

export function NonPrescriptionLenses({ nonPrescriptionPackages }) {
  const colorCategoryContent = useSelector(
    (state: any) => state.config?.lensesData.content.colorCategory
  );
  const lens = useSelector((state: any) => state.config?.data?.lens);
  const frame = useSelector((state: any) => state.config.data.frame);
  const translation = useTranslation();
  const plainLensColorPromoAdapter = new PlainLensColorPromoAdapter(
    translation
  );
  const selectedTfamily = useSelector(
    (state: any) => state.mixAndMatch?.selectedTreatment
  );
  const selectedLens = useSelector(
    (state: any) => state.mixAndMatch?.selectedLens
  );
  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  //FALLBACK IMAGE
  const imageSrcsetTemplate = useSelector(
    (state: any) => state.config?.imageSrcsetTemplate
  );
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  //FALLBACK IMAGE
  const getUrlSwatch = (value: string) => {
    let format = value.indexOf(".") > 0 ? "svg" : "png";
    return imageSrcsetTemplate
      .replace("{url}", configProjectImage)
      .replace("{url}", configProjectImage)
      .replace("{filename}", value)
      .replace("{filename}", value)
      .replace("{format}", format)
      .replace("{format}", format);
  };

  const configColor = useConfigContent("Color");
  const [tfamilyOptions, setTfamilyOptions] = useState([]);
  const [colors, setColors] = useState({});

  const loadTreatmentsFamily = useTreatmentsFamilyMapper();
  const { getDiscount, isLoading } = useGetDiscount();

  const getPriceForColor = usePriceStepItem();

  const dispatch = useDispatch();

  const mapColors = (packages) => {
    return Object.entries(groupBy(packages, "lensPackage.colorCategory"))
      .map(([key, value]) => {
        if (key != null) {
          let hasPromo = false;
          let color: LensColorModel[] = [];
          if (value) {
            let groupedByColor = groupBy(value, "lensPackage.color");
            Object.keys(groupedByColor).map((cg) => {
              let cgPackages = groupedByColor[cg];
              let cheapestCgp = getCheapPackageFromList(cgPackages);

              if (key === "null") {
                key = null;
              }
              const prices = getPriceForColor(
                {
                  color: cg,
                  colorCategory: key,
                },
                packages
              );
              let listPrice = 0;
              let offerPrice = 0;
              let insPrice = 0;

              if (prices) {
                listPrice = prices.price;
                if (isInsuranceEnabled) {
                  if (prices.insPrice) {
                    offerPrice = prices.insPrice;
                  } else {
                    offerPrice = prices.price - prices.discount;
                  }
                } else {
                  offerPrice = prices.price - prices.discount;
                }
                insPrice = prices.insPrice;
              }

              const colorName = cheapestCgp.lensPackage.color;
              let recUse = cheapestCgp.lensPackage.recommendedUse;
              let promo: LensColorPromo[] = [];
              const promoFrame = plainLensColorPromoAdapter.adapt(
                cheapestCgp.frame.promo
              );
              const lensPromo = plainLensColorPromoAdapter.adapt(
                cheapestCgp.lensPackage.promo
              );
              if (promoFrame) {
                promo = [...promo, ...promoFrame];
                hasPromo = true;
              }
              if (lensPromo) {
                promo = [...promo, ...lensPromo];
                hasPromo = true;
              }
              const configColorp = configColor?.find((c) => c.id === colorName);
              const defSelected =
                lens &&
                lens.clen &&
                cheapestCgp.clen &&
                lens.clen === cheapestCgp.clen;
              if (configColorp) {
                const imageFileName = configColorp.imageFileName1
                  ? configColorp.imageFileName1
                  : configColorp.imageFileName;
                color.push({
                  ...configColorp,
                  id: configColorp.id,
                  name: configColorp.title,
                  value: imageFileName,
                  promo: promo.length ? promo : null,
                  selected: defSelected ? defSelected : false,
                  price: listPrice,
                  discount: listPrice - offerPrice,
                  insPrice: insPrice,
                  recommendedUse: recUse,
                  colorCategory: key,
                  colorCategoryTitle:
                    colorCategoryContent && colorCategoryContent[key]
                      ? colorCategoryContent[key].title
                      : key,
                  disabled: cheapestCgp.disabled,
                  promoBadge: configColorp.promoBadge,
                  polar: cheapestCgp.lensPackage.polar,
                  clen: cheapestCgp.lensPackage.clen
                });
              }
            });
          }
          return { name: key, colors: color, hasPromo };
        }
      })
      .reduce((acc, obj) => {
        return [...acc, ...obj.colors];
      }, []);
  };

  const getColors = () => {
    const sunPackages = [];
    const transitionPackages = [];
    nonPrescriptionPackages.validRXPackages.forEach((cp) => {
      const isSun =
        cp.lensPackage.color &&
        !cp.lensPackage.transition &&
        !cp.lensPackage.blueLight;
      const isTrans =
        cp.lensPackage.color &&
        cp.lensPackage.transition &&
        !cp.lensPackage.blueLight;
      if (isSun) {
        sunPackages.push(cp);
      } else if (isTrans) {
        transitionPackages.push(cp);
      }
    });
    return {
      sun: mapColors(sunPackages),
      transition: mapColors(transitionPackages),
    };
  };

  useEffect(() => {
    dispatch(mixAndMatchActions.setSelectedType("Non_prescription"));
  }, []);

  useEffect(() => {
    if (nonPrescriptionPackages && typeof loadTreatmentsFamily === "function") {
      let options = loadTreatmentsFamily(nonPrescriptionPackages);
      options = options.map((opt) => ({
        ...opt,
        discountLoading: isLoading(opt.id),
      }));
      setTfamilyOptions(options.reverse());
      if (!selectedTfamily && options.length) {
        // if the lens from pdp is clear
        const clearLensFromPdp = nonPrescriptionPackages?.validRXPackages?.find(p => p?.lensPackage?.blueLight && p?.lensPackage?.clen === lens?.clen)
        const transitionLensFromPdp = nonPrescriptionPackages?.validRXPackages?.find(p => p?.lensPackage?.transition && p?.lensPackage?.clen === lens?.clen)
        if (clearLensFromPdp) {
          const blueLightOption = options?.find(o => o?.id?.includes("blueLight"))
          dispatch(mixAndMatchActions.setSelectedTreatment(blueLightOption || options[0]));
        } else if (transitionLensFromPdp) {
          const transitionOption = options?.find(o => o?.id?.includes("transition"))
          dispatch(mixAndMatchActions.setSelectedTreatment(transitionOption || options[0]));
        } else {
          dispatch(mixAndMatchActions.setSelectedTreatment(options[0]));
        }

      }
    }
  }, [loadTreatmentsFamily, nonPrescriptionPackages, selectedTfamily]);

  //discount
  useEffect(() => {
    if (!tfamilyOptions.length) return;
    for (const options of tfamilyOptions) {
      getDiscount(
        options.id,
        nonPrescriptionPackages.validRXPackages,
        options.filter
      );
    }
  }, [tfamilyOptions, nonPrescriptionPackages]);

  useEffect(() => {
    if (!colors?.sun) return;
    for (const color of colors.sun) {
      getDiscount(`${color.id}`, nonPrescriptionPackages.validRXPackages, {
        color: color.id,
        colorCategory: color.colorCategory,
      });
    }
  }, [colors, nonPrescriptionPackages]);

  useEffect(() => {
    if (configColor && nonPrescriptionPackages) {
      const { sun, transition } = getColors();
      setColors({ sun: sun, transition: transition });
    }
  }, [configColor, nonPrescriptionPackages]);

  const lenses = useMemo(() => {
    if (!colors || !selectedTfamily) return [];
    const treatment = selectedTfamily.id.split("$")[0];
    if (treatment === "sun") {
      return orderBy(colors?.sun, "sequence");
    } else if (treatment === "transition") {
      return orderBy(colors?.transition, "sequence");
    } else {
      return [
        {
          ...selectedTfamily,
          id: treatment,
          imageFileName1: selectedTfamily.imageUrl,
          value: selectedTfamily.imageUrl,
          name: treatment,
        },
      ];
    }
  }, [colors, selectedTfamily]);

  useEffect(() => {
    if (lenses && lenses.length > 0) {
      let preselectedLens = lenses[0];
      if (lens?.clen && !selectedLens) {
        // preselected lens from pdp
        preselectedLens = lenses.find(l => l.clen === lens.clen)
      }
      if (selectedLens) {
        // previous selected lens
        preselectedLens = lenses.find(l => l.clen === selectedLens.clen)
      }
      dispatch(mixAndMatchActions.setSelectedLens(preselectedLens || lenses[0]));
    }
  }, [lenses, lens, selectedLens]);

  const selectTFamily = (tfamilyOption) => {
    dispatch(mixAndMatchActions.setSelectedTreatment(tfamilyOption));
  };
  const selectLens = (lens) => {
    dispatch(mixAndMatchActions.setSelectedLens(lens));
    dispatch(mixAndMatchActions.setLensPrice(lens.price - lens.discount));
    dispatch(mixAndMatchActions.setLensTotalPrice(lens.price));
    const treatment = selectedTfamily?.id?.split("$")?.[0];
    if (treatment === "sun") {
      dispatch(colorActions.selectColor(lens.id));
      dispatch(colorActions.selectTransition(null));
      dispatch(colorActions.setFrameImgUrl(null));
      dispatch(colorActions.setClearClen(""));
      dispatch(
        colorActions.setSwatchUrl({
          url: getUrlSwatch(lens.value),
          name: lens.name,
          value: lens.value,
        })
      );
    } else if (treatment === "transition") {
      dispatch(colorActions.selectTransition(lens.value));
      dispatch(colorActions.selectColor(null));
      dispatch(colorActions.setFrameImgUrl(null));
      dispatch(colorActions.setClearClen(""));
      dispatch(
        colorActions.setSwatchUrl({
          url: getUrlSwatch(lens.value),
          name: lens.name,
          value: lens.value,
        })
      );
    } else {
      // clear or bluelight
      dispatch(colorActions.selectTransition(null));
      dispatch(colorActions.selectColor(null));
      dispatch(colorActions.setFrameImgUrl(null));
      dispatch(colorActions.setClearClen("CLEAR"));
    }
  };

  return (
    <>
      <BrandComponent componentName="Chips">
        {tfamilyOptions?.map((tfamilyOption) => (
          <BrandComponent
            componentName="Chip"
            key={tfamilyOption.id}
            parameter={{
              item: tfamilyOption,
              selected: selectedTfamily?.id === tfamilyOption.id,
              onClick: selectTFamily,
            }}
          />
        ))}
      </BrandComponent>

      <BrandComponent componentName="Carousel">
        {lenses?.map((lens, i) => (
          <BrandComponent
            key={i}
            componentName="LensCard"
            parameter={{
              lensPackage: lens,
              selected: selectedLens?.id === lens.id,
              onClick: selectLens,
              discountLoading: isLoading(lens.id),
            }}
          />
        ))}
      </BrandComponent>
    </>
  );
}
