import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import "./default.module.scss";
import {
  useTranslate,
  useTranslation,
} from "../../configurator/translations/TranslationHooks";
import {
  SearchBy,
  SearchLoader,
  SearchResult,
  SelectConfirm,
} from "../CallMyDoctorPrescriptionForm/component";

interface CallMyDoctorModal {}

export function CallMyDoctorModal({}: CallMyDoctorModal) {
  const showCallMyDocCalifornianModal = useSelector(
    (state: any) => state.modals?.showCallMyDoctorCalifornianModal
  );
  const previousSearchResult = useSelector(
    (state: any) => state.modals?.previousSearchResult
  );
  const [visible, setVisible] = useState(true);
  const reduxDispatch = useDispatch();
  const handleExit = () =>
    reduxDispatch(modalsActions.setShowCallMyDoctorCalifornianModal(false));
  const searchDoctors = useSelector(
    (state: any) => state.config?.prescriptionModule?.searchDoctors
  );

  const [isResultLoading, setIsResultLoading] = useState(false);
  const callMyDoctorParams = useSelector(
    (state: any) => state.prescription.callMyDoctorParams
  );

  const showAddMyDoctorModal = useSelector(
    (state: any) => state.modals?.showAddMyDoctorModal
  );

  const [searchResult, setSearchResult] = useState(null);

  const [doctorSelected, setDoctorSelected] = useState(null);

  const didntFindLabel = useTranslate(
    "steps.advancedPrescription.doctor.addManuallyOption.didntFind"
  );
  const addManuallyLabel = useTranslate(
    "steps.advancedPrescription.doctor.addManuallyOption.addManually"
  );

  useEffect(() => {
    if (previousSearchResult) {
      setSearchResult(previousSearchResult);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const searchResultElement = document.querySelector(
        ".addManually__container"
      );
      if (!isResultLoading) {
        searchResultElement?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  }, [isResultLoading]);

  useEffect(() => {
    if (callMyDoctorParams) {
      reduxDispatch(modalsActions.setDoctorSelected(callMyDoctorParams));
      handleExit();
    }
  }, [callMyDoctorParams]);

  useEffect(() => {
    if (!showAddMyDoctorModal && !visible) {
      setVisible(true);
    }
  }, [showAddMyDoctorModal]);

  const handleSearch = (obj) => {
    if (searchDoctors && typeof searchDoctors === "function") {
      setIsResultLoading(true);
      searchDoctors(obj)
        .then((res) => {
          if (res.doctors && res.doctors.length) {
            setSearchResult(res.doctors);
          } else {
            setSearchResult([]);
          }
          setIsResultLoading(false);
        })
        .catch((err) => {
          //TODO
          setIsResultLoading(false);
          setSearchResult([]);
        });
    }
  };
  useEffect(() => {
    if (searchResult?.length === 0) {
      try {
        //@ts-ignore
        window.tealium_data2track.push({
          id: "Error",
          Error_Source: "User",
          Error_Code: "RX Configurator: choose doctor",
          Error_Message: "We couldn't find your doctor",
        });
      } catch (error) {
        console.error(
          "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
        );
      }
      console.error("Error while saving prescription");
    }
    if (searchResult) {
      reduxDispatch(modalsActions.setPreviousSearchResultState(searchResult));
    }
  }, [searchResult]);
  const handleModal = (show) => {
    setVisible(false);
    reduxDispatch(modalsActions.setShowAddMyDoctorModal(show));
  };
  const handleSelectDoctor = (selected) => {
    reduxDispatch(modalsActions.setDoctorSelected(selected));
    handleExit();
    if (selected === null) {
      //parameter.changeTitleCallMyDoctor(false);
    }
  };
  return (
    <>
      {showCallMyDocCalifornianModal && (
        <>
          <div className="CallMyDocCaliforninaModal__overlay" />
          <div
            className={
              "CallMyDocCaliforninaModal__container" +
              (!visible ? "__displayNone" : "")
            }
          >
            <div className={"CallMyDocCaliforninaModal__header"}>
              <a
                className="CallMyDocCaliforninaModal__close"
                onClick={handleExit}
                data-analytics_available_call="0"
              />
            </div>
            {!doctorSelected && (
              <SearchBy handleSearch={handleSearch} class={"california"}>
                {searchResult && searchResult.length > 0 && (
                  <div className="addManually__container">
                    <div className="addManually__container__plainText">
                      {didntFindLabel}
                      <a
                        className="addManually__container__linkText"
                        onClick={() => handleModal(true)}
                      >
                        {addManuallyLabel}
                      </a>
                    </div>
                  </div>
                )}
              </SearchBy>
            )}
            {!doctorSelected && !isResultLoading && searchResult && (
              <div className={"CallMyDocCaliforninaModal__searchResult"}>
                <SearchResult
                  data={searchResult}
                  handleSelect={handleSelectDoctor}
                />
              </div>
            )}
            {isResultLoading && <SearchLoader />}
          </div>
        </>
      )}
    </>
  );
}
