import { Adapter } from "../models/Adapter";
import { LensColorPromo } from "../steps/LensColor/component";

export class PlainLensColorPromoAdapter extends Adapter<
  Record<string, any>,
  LensColorPromo[]
> {
  protected _translate: (label: string) => string;

  constructor(translate: (label: string) => string) {
    super();
    this._translate = translate;
  }

  adapt: (source: Record<string, any>) => LensColorPromo[] = (
    source: Record<string, any>
  ) => {
    let ret: LensColorPromo[] = null;
    if (source) {
      ret = Object.keys(source).map((key: string) => {
        const promo: LensColorPromo = {
          amount: source[key].amount,
          id: key,
          title: this._translate("promo." + key + ".title"),
        };
        return promo;
      });
    }
    return ret;
  };

  adaptReverse: (source: LensColorPromo[]) => Record<string, any> = (
    source: LensColorPromo[]
  ) => {
    return null;
  };
}
