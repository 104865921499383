import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";

type Props = {
  text: string;
  dataContent: string;
  onClick?: Function;
};

export const WarningBanner = (props: Props) => {
  const tooltip = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceWarningTooltip"
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );

  return (
    <div className="WarningBanner__container">
      <div
        className="WarningBanner__container__icon"
        data-tip={tooltip}
        data-class={"Tooltip__tooltip"}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1834 10.3828L9.07776 1.91556C8.96195 1.7378 8.80393 1.59139 8.61775 1.48944C8.42829 1.38568 8.21575 1.3313 7.99974 1.3313C7.78373 1.3313 7.5712 1.38568 7.38173 1.48944C7.19555 1.59139 7.03754 1.7378 6.92172 1.91556L1.81727 10.3808C1.81711 10.3811 1.81694 10.3814 1.81677 10.3816C1.72678 10.532 1.67786 10.7034 1.67494 10.8786C1.67205 11.0523 1.71445 11.2237 1.79791 11.3759C1.90718 11.5654 2.06439 11.7228 2.25378 11.8323C2.44487 11.9428 2.66186 12.0006 2.88259 11.9997L2.88641 11.9997L13.1184 11.9997L13.1237 11.9998C13.343 12.0021 13.559 11.9466 13.75 11.8388C13.9384 11.7324 14.0958 11.5788 14.2069 11.3932C14.2919 11.24 14.3352 11.067 14.3323 10.8916C14.3294 10.7135 14.2789 10.5393 14.1861 10.3872L14.1834 10.3828ZM15.0731 11.893C14.8726 12.2336 14.5858 12.5152 14.2417 12.7095C13.8975 12.9038 13.5082 13.0039 13.1131 12.9997H2.88641C2.48862 13.0012 2.09757 12.8971 1.75321 12.698C1.40885 12.4989 1.12353 12.2119 0.926408 11.8664C0.755911 11.5594 0.669231 11.213 0.675082 10.8619C0.680934 10.5108 0.77911 10.1675 0.959741 9.86638L6.07307 1.38638C6.27984 1.06293 6.56471 0.79673 6.90142 0.61234C7.23813 0.42795 7.61585 0.331299 7.99974 0.331299C8.38364 0.331299 8.76135 0.42795 9.09806 0.61234C9.43478 0.79673 9.71965 1.06293 9.92641 1.38638L15.0397 9.86638C15.2254 10.1706 15.3263 10.5189 15.3322 10.8752C15.338 11.2315 15.2486 11.5829 15.0731 11.893Z"
            fill="#543C32"
          />
          <path
            d="M7.99967 10.3333C8.36786 10.3333 8.66634 10.0349 8.66634 9.66667C8.66634 9.29848 8.36786 9 7.99967 9C7.63148 9 7.33301 9.29848 7.33301 9.66667C7.33301 10.0349 7.63148 10.3333 7.99967 10.3333Z"
            fill="#543C32"
          />
          <path
            d="M7.99967 4.33325C7.82286 4.33325 7.65329 4.40349 7.52827 4.52851C7.40325 4.65354 7.33301 4.82311 7.33301 4.99992V7.66658C7.33301 7.8434 7.40325 8.01296 7.52827 8.13799C7.65329 8.26301 7.82286 8.33325 7.99967 8.33325C8.17649 8.33325 8.34605 8.26301 8.47108 8.13799C8.5961 8.01296 8.66634 7.8434 8.66634 7.66658V4.99992C8.66634 4.82311 8.5961 4.65354 8.47108 4.52851C8.34605 4.40349 8.17649 4.33325 7.99967 4.33325Z"
            fill="#543C32"
          />
        </svg>
      </div>
      <div
        className={
          enableDigitalOptometry
            ? "WarningBanner__container__text__withAfter"
            : "WarningBanner__container__text"
        }
        onClick={() => props.onClick && props.onClick()}
        dangerouslySetInnerHTML={{ __html: props.text }}
        data-content={enableDigitalOptometry ? props.dataContent : ""}
      />
    </div>
  );
};
