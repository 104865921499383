import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";
import { cloneDeep } from "lodash";
import { isMobile } from "react-device-detect";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

function switchToFirst(arr, value) {
  const index = arr.findIndex((obj) => obj.lensIdentifier === value); // Find the index of the object based on property
  if (index > -1) {
    // If the object exists in the array
    const switchedObject = arr[index]; // Store the object to be switched
    const newArr = [
      switchedObject,
      ...arr.slice(0, index), // Objects before the switched object
      ...arr.slice(index + 1), // Objects after the switched object
    ];
    return newArr; // Return the new array with the object at the front
  } else {
    return "Object not found in the array";
  }
}

export function VirtualMirror() {
  const [vmapp, setVmapp] = useState(null);
  const [lensIdentifierFromVmmv, setLensIdentifierFromVmmv] = useState(null);
  const lensesList = useSelector(
    (state: any) => state.virtualMirror.lensesList
  );
  const vmappConfig = useSelector(
    (state: any) => state.virtualMirror.vmappConfig
  );
  const lensIdentifier = useSelector(
    (state: any) => state.virtualMirror.lensIdentifier
  );
  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep.key
  );
  const initializationStepKey = useSelector(
    (state: any) => state.virtualMirror.initializationStepKey
  );
  const backLabel = useTranslate("vmmv.backButton");
  const dispatch = useDispatch();

  const declineButton = document?.getElementById("bipa-cancel-button");
  declineButton?.addEventListener("click", () => {
    handleClose();
  });

  useEffect(() => {
    const createVmmv = () => {
      // update configs from redux
      var config = {
        ...cloneDeep(vmappConfig),
        selector: ".vmmv",
        onLensChange: (lensIdentifier) => {
          console.log(
            "onLensChange callback invoked, the lens identifier return is " +
              lensIdentifier
          );
          //dispatch(virtualMirrorActions.setLensSelectedFromVmmv({ lensIdentifier }));
          setLensIdentifierFromVmmv(lensIdentifier);
        },
      };
      // create vmapp instance
      let vmappInstance = new window.vmmv.VMConfiguratorsWidget(config);
      vmappInstance.config.onPrivacyReject = async () => {
        vmappInstance.unmount();
        dispatch(virtualMirrorActions.setShowVirtualMirror(false));
      };
      // set the step key where is the vmapp is initialized
      dispatch(virtualMirrorActions.setInitializationStepKey(currentStep));
      // set instance to local state to call methods on it
      setVmapp(vmappInstance);
      dispatch(virtualMirrorActions.setGlobalVmapp(vmappInstance));
      // init/open app
      vmappInstance.init();
    };

    createVmmv();
  }, []);

  useEffect(() => {
    if (vmapp) {
      vmapp.setLensList({
        lensList: switchToFirst(lensesList, lensIdentifier),
      });
    }
  }, [lensesList, vmapp]);

  useEffect(() => {
    if (vmapp) {
      vmapp.setLens(lensIdentifier);
    }
  }, [lensIdentifier, vmapp]);

  useEffect(() => {
    // close vm on step change
    if (currentStep !== initializationStepKey && vmapp) {
      handleClose();
    }
  }, [currentStep, vmapp]);

  const handleClose = () => {
    vmapp.unmount().then(() => {
      dispatch(virtualMirrorActions.setShowVirtualMirror(false));
    });
  };
  const handleSelectAndClose = () => {
    const selectedLensfromVmmv = lensesList.find(
      (l) => l.lensIdentifier === lensIdentifierFromVmmv
    );
    if (selectedLensfromVmmv) {
      const swatchEl = document.querySelector(
        `[data-vmmv="${
          selectedLensfromVmmv.name + selectedLensfromVmmv.lensTechnology
        }"]`
      );
      console.log(swatchEl);
      //select swatch
      if (swatchEl) swatchEl.click();
    }
    // try {
    //   //@ts-ignore
    //   window.tealium_data2track.push({
    //     id: "Event",
    //     data_element_id: "LensPanel_TreatmentDetail-VMBackToProduct",
    //   });
    // } catch (error) {
    //   console.error(
    //     "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
    //   );
    // }

    handleClose();
  };

  return (
    <div className="vmmv-container-rxc">
      <button
        className="close"
        onClick={handleSelectAndClose}
        data-element-id="LensPanel_TreatmentDetail-VMBackToProduct"
        data-description="BACK TO PRODUCT"
      >
        {!isMobile && (
          <>
            <div className="close__icon" />
            <span>{backLabel}</span>
          </>
        )}
        {isMobile && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_12511_54755)">
              <g filter="url(#filter0_d_12511_54755)">
                <path
                  d="M23.6998 1.70078L22.2998 0.300781L11.9998 10.6008L1.6998 0.300781L0.299805 1.70078L10.5998 12.0008L0.299805 22.3008L1.6998 23.7008L11.9998 13.4008L22.2998 23.7008L23.6998 22.3008L13.3998 12.0008L23.6998 1.70078Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_12511_54755"
                x="-2.7002"
                y="-2.69922"
                width="29.4004"
                height="29.4004"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_12511_54755"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_12511_54755"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_12511_54755">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </button>
      <div className="vmmv"></div>
    </div>
  );
}
