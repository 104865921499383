import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepComponent from "../steps/StepComponent";
import { useWorkflowNavigation } from "../WorkflowUtils";

export function WorkflowStepFactory() {
  useWorkflowNavigation();
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  return <>{currentStep && <StepComponent step={currentStep} />}</>;
}
