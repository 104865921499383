import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { FullPrescription } from "../../types";
import { ManualTable } from "../PrescriptionReviewSeeDetails/component";
import { useFullPrescriptionForm } from "@hooks";
import "./default.module.scss";
import { BrandComponent } from "../common/BrandComponent";
import { useSelector } from "react-redux";
import utils from "../FullPrescriptionForm/utils";
import { usePupillaryDistance } from "../../configurator/hooks";

type Props = {
  prescription: FullPrescription;
  onEdit: (p, s) => void;
  onClose: () => void;
};

const CustomCheckbox = () => {
  return null
}

export function MyAccountPrescriptionDetailModal(props: Props) {
  const verticalName = useTranslate(
    "steps.advancedPrescription.manual.vertical"
  );
  const baseDirectionName = useTranslate(
    "steps.advancedPrescription.manual.baseDirection"
  );
  const horizontalName = useTranslate(
    "steps.advancedPrescription.manual.horizontal"
  );
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const {
    prescriptionTableColumns,
    formValues,
    formErrors,
    isAddDisabled,
    selectPlaceholder
  } = useFullPrescriptionForm(props.prescription)
  const PD = usePupillaryDistance({ prescription: props.prescription })
  const titleLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionDetailModal.title"
  );
  const editLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionDetailModal.edit"
  );
  const { prescription } = props;
  return (
    <div className="MyAccountPrescriptionDetailModal">
      <div className="MyAccountPrescriptionDetailModal__Overlay" />
      <div className="MyAccountPrescriptionDetailModal__Modal">
        <h2>{titleLabel}</h2>
        <span
          className="MyAccountPrescriptionDetailModal__Modal__closeIcon"
          onClick={() => props.onClose?.()}
        />
        <p onClick={() => props.onEdit(props.prescription, true)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 15.3333H0V16H16V15.3333Z" fill="#557b92" />
            <path
              d="M1.998 14C2.04167 14 2.08633 13.9957 2.13133 13.9867L4.469 13.373C4.598 13.3473 4.71667 13.2837 4.80967 13.1907L15.138 2.862C15.3983 2.60167 15.3983 2.17967 15.138 1.91933L13.414 0.195333C13.284 0.065 13.1133 0 12.9427 0C12.772 0 12.6013 0.065 12.4713 0.195333L2.14267 10.524C2.04967 10.617 1.986 10.7357 1.96033 10.8647L1.34667 13.2023C1.26233 13.6237 1.59 14 1.998 14ZM2.61033 11.015L2.61433 10.9957L12.9423 0.666667H12.943L14.6667 2.39067L4.338 12.7193L4.31867 12.7233L2.00233 13.3317L2.61033 11.015Z"
              fill="#557b92"
            />
          </svg>
          {editLabel}
        </p>
        <ManualTable {...prescription} />
        {/* <BrandComponent componentName="FormTable" parameter={{
          columns: prescriptionTableColumns,
          selector: "prescription",
          cylOD: formValues?.prescription?.right_eye_cyl?.value,
          cylOS: formValues?.prescription?.left_eye_cyl?.value,
          isAddDisabled: isAddDisabled,
          onSubmit: () => { },
          formErrors: formErrors,
          selectPlaceholder: selectPlaceholder,
          formValues: formValues,
          samePrescriptionBothEyes: false,
          highlightedColumnIndex: null,
          readOnly: true
        }}>

        </BrandComponent>
        <BrandComponent
          componentName="PupillaryDistance"
          parameter={{
            PD1: PD.state.PD1,
            PD2: PD.state.PD2,
            showBothPdOptions: PD.state.showBothPdOptions,
            showPDWarning: PD.state.showPDWarning,
            onPD1Change: PD.state.setPD1,
            onPD2Change: PD.state.setPD2,
            setShowBothPdOptions: PD.state.setShowBothPdOptions,
            pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
            CustomCheckbox: CustomCheckbox,
            readOnly: true
          }}
        />
        <BrandComponent componentName="FormTable" parameter={{
          columns: utils.getPrismTableColumns(prescriptionDefaults)(
            verticalName,
            horizontalName,
            baseDirectionName
          ),
          selector: "prism",
          cylOD: props.prescription?.cylOD,
          cylOS: props.prescription?.cylOS,
          isAddDisabled: isAddDisabled,
          onSubmit: () => { },
          formErrors: formErrors,
          selectPlaceholder: selectPlaceholder,
          formValues: formValues,
          samePrescriptionBothEyes: false,
          readOnly: true
        }} /> */}
      </div>
    </div>
  );
}
