import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { colorActions } from "../../redux/slices/color";
import "./default.module.scss";

export interface optionsSliderProps {
  slider: SliderObject[];
  callback?: (prop) => void;
}
export interface SliderObject {
  name: string;
  id: string | any;
}

export function optionsSlider({ slider, callback }: optionsSliderProps) {
  const [valueSelected, setValueSelected] = useState(
    slider ? (slider[0].id ? slider[0].id : slider[0]) : null
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      colorActions.setLensBrand(
        slider ? (slider[0].id ? slider[0].id : slider[0]) : null
      )
    );
  }, []);

  const buttonColorChangeAndCallback = (prop) => {
    dispatch(colorActions.setLensBrand(prop));
    setValueSelected(prop);
    callback(prop);
  };

  return !slider || slider.length <= 1 ? null : (
    <div className={"optionsSlider__container"}>
      <div className={"optionsSlider__slider__container"}>
        {slider.map((value) => {
          return (
            <button
              key={value.id}
              className={
                "optionsSlider__slider__container__btn" +
                (valueSelected == value?.id ? " clicked" : "")
              }
              onClick={() => buttonColorChangeAndCallback(value?.id)}
              data-analytics_available_call="0"
            >
              {value?.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}
