import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BrandComponent } from "../../../components/common/BrandComponent";
import GenericButton from "../../../components/common/GenericButton";
import { SwitchControl } from "../../../components/common/Switch";
import {
  callInsuranceService,
  checkLayoutConfigDesignType,
  getCheapPackageFromList,
  getCorrectKey,
} from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useConfigContent,
  useContextualMessage,
  usePriceStepItem,
  useProductImage,
  useReviewIncludedTreatmentsMapper,
  useGetDiscount,
  useEnableBrandLastStep,
  useCurrentType,
  useRetriveContextualMessage,
  useCurrentBrand,
  usePolarToggle,
  useCheapCurrentStep,
} from "@hooks";
import { StepComponentOptions } from "../StepComponent";
import "./default.module.scss";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { groupBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../translations/TranslationHooks";
import { useTranslate } from "../../translations/TranslationHooks";
import { colorActions } from "../../../redux/slices/color";
import { TranslateText } from "../../../components/common/TranslateText";
import { workflow } from "../../models/Workflow";
import { PlainLensColorPromoAdapter } from "../../adapters/PlainToLensColorPromoAdapter";
import ReactTooltip from "react-tooltip";
import { configObjectToSliderObject } from "../../adapters/ConfigObjectToSliderObject";
import { recommendedUseArrayToString } from "../../adapters/RecommendedUseArrayToString";
import { workflowActions } from "../../../redux/slices/workflow";
import { isMobile } from "react-device-detect";
import { LensColorDetails } from "./LensColorDetails/LensColorDetails";
import { createPortal } from "react-dom";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { useShowPolarToggle } from "../../hooks";

export interface LensColorGroup {
  name: string;
  colors: LensColorModel[];
  hasPromo: boolean;
}
export interface LensColorPromo {
  title: string;
  id: string;
  amount: number | string;
}
interface LensColorModel {
  name: string;
  value: string;
  id: string;
  selected?: boolean;
  promo?: LensColorPromo[];
  price: number;
  discount: number;
  insPrice: number;
  recommendedUse: string;
  colorCategory: string;
  colorCategoryTitle: string;
  disabled?: boolean;
  promoBadge?: string;
}

interface LensColorOpt {
  colorGroups?: LensColorGroup[];
  onSelectedChange?: (color: LensColorModel, group: LensColorGroup) => void;
}
interface LensColorStepOpt extends StepComponentOptions, LensColorOpt {}

/**
 * This component displays the 'lensColor' step. It uses the
 * ```LensColorStepOpt``` to work in his flow.
 * this component get the color list by method ```getColors``` ;
 * this method allow to get all color for currentPackages grouped by ```colorGroup```.
 */
export function LensColor({ brand }: LensColorStepOpt) {
  const [colorsG, setColorsG] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const currentPackages = useAllCurrentPackages();
  const [alreadyIncluded, setalreadyIncluded] = useState(null);
  const configColor: any[] = useConfigContent("Color");
  const colorCategoryContent = useSelector(
    (state: any) => state.config?.lensesData.content.colorCategory
  );
  const currentType = useCurrentType();
  const config = useSelector((state: any) => state.config);
  const lastLensColorSelected = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart?.LensColor?.description
  );
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );

  const lens = useSelector((state: any) => state.config?.data?.lens);

  const [ignorePreselection, setIgnorePreselection] = useState(false);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );

  const configBundle = useConfigContent("LensBundle");

  //FALLBACK IMAGE
  const imageSrcsetTemplate = useSelector(
    (state: any) => state.config?.imageSrcsetTemplate
  );

  const skipConfiguration = useSelector(
    (state: any) => state.config?.skipConfiguration
  );

  const prescription = useSelector(
    (state: any) => state.prescription.currentPrescription
  );
  const currentColor = useSelector((state: any) => state.color.colorSelected);

  const translation = useTranslation();
  const filterPillsCategoryTitle = useTranslate(
    "steps.lensColor.categories.all.title"
  );
  const isLoading = useSelector((state: any) => state.color.isLoading);
  const tooltipPolarized = translation("steps.color.tooltip.polarized");
  const dispatch = useDispatch();
  const ctxMessage = useRetriveContextualMessage();
  const brandLastStep = useEnableBrandLastStep();
  const [polarSwitch, setPolarSwitch] = useState(false);
  const currentBrand = useCurrentBrand();

  const changePriceStyle = {
    color: "#222222",
    fontSize: "14px",
    fontWeight: "normal",
  };
  const changeDiscountStyle = {
    color: "#222222",
    fontSize: "12px",
    fontWeight: "normal",
  };
  const plainLensColorPromoAdapter = new PlainLensColorPromoAdapter(
    translation
  );
  const [showAlert, setShowAlert] = useState(false);
  const bannerTooltiptext = translation("steps.brand.tooltipBannerText");

  const [showPolarSwitch, setShowPolarSwitch] = useState(true);
  const showPolarToggle = useShowPolarToggle();

  const getPriceForColor = usePriceStepItem();
  const loadIncludedTreatments = useReviewIncludedTreatmentsMapper();

  //Brands
  const configBrand: any[] = useConfigContent("Brand");
  const [brandSelected, setBrandselected] = useState(null);
  const [brandsAvaiable, setBrandsAvaiable] = useState(null);

  const [toSkip, setToSkip] = useState(false);
  const { getDiscount, isLoading: isDiscountLoading } = useGetDiscount();

  const [currentPackagesFiltered, setCurrentPackagesFiltered] =
    useState(currentPackages);

  //DesignType
  const [designTypesAvailable, setDesignTypesAvailable] = useState(null);
  const [designTypeSelected, setDesignTypeSelected] = useState(null);
  const configDesignType = useSelector(
    (state: any) => state.config?.lensesData?.content?.designType
  );
  const enableDesignTypeStep = useSelector(
    (state: any) => state.config?.layoutSettings?.enableDesignTypeStep
  );

  //FALLBACK IMAGE
  const getUrlSwatch = (value: string) => {
    let format = value?.indexOf(".") > 0 ? "svg" : "png";
    return imageSrcsetTemplate
      .replace("{url}", configProjectImage)
      .replace("{url}", configProjectImage)
      .replace("{filename}", value)
      .replace("{filename}", value)
      .replace("{format}", format)
      .replace("{format}", format);
  };

  useEffect(() => {
    if (brandSelected) {
      dispatch(colorActions.setLensBrand(brandSelected));
    }
  }, [brandSelected]);

  useEffect(() => {
    if (configBrand && currentPackages) {
      const currentBrands = configBrand?.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) =>
            cp.lensPackage.brand === c.id &&
            (designTypesAvailable?.length > 1
              ? cp.lensPackage.designType === designTypeSelected
              : true)
        )
      );
      const greyOutCurrentBrands = configBrand
        ?.filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.brand === c.id
          )
        )
        .filter((goB) => !currentBrands.find((b) => b.id === goB.id));

      if (currentBrands && currentBrands.length) {
        if (currentBrands.length) {
          setBrandsAvaiable(
            configObjectToSliderObject.adaptList(currentBrands)
          );
        }
        if (
          (!brandLastStep || currentType === "SUNGLASSES") &&
          !brandSelected
        ) {
          setBrandselected(currentBrands[0].id);
        }
      }
    }
  }, [configBrand, currentPackages, designTypeSelected]);

  useEffect(() => {
    if (currentColor && currentPackages) {
      const filtered = currentPackages.validRXPackages.filter((f) => {
        return f.lensPackage.color === currentColor;
      });
      setCurrentPackagesFiltered(filtered);
    }
  }, [currentPackages, currentColor]);

  const getAlreadyIncludedTreatments = useCallback(() => {
    if (loadIncludedTreatments) {
      //RXPANEL-1332
      const { included } = loadIncludedTreatments(currentPackagesFiltered);
      const includedTreatment = included.map((treatment) => ({
        id: treatment.id,
        name: translation(treatment.name),
      }));

      setalreadyIncluded(includedTreatment);
    }
  }, [currentPackagesFiltered, loadIncludedTreatments]);

  useEffect(() => {
    getAlreadyIncludedTreatments();
  }, [getAlreadyIncludedTreatments]);

  const handleBrandCallback = (callbackValue) => {
    setBrandselected(callbackValue);
    handlePolarFilterChange(false);
    setCategorySelected(categoriesAvaiable ? categoriesAvaiable[0]?.id : "All");
  };
  //end Brands

  //Polar
  useEffect(() => {
    if (currentPackages) {
      const listPolirazedList = currentPackages.validRXPackages.filter((cp) => {
        const polarCondition =
          cp.lensPackage.polar !== null && cp.lensPackage.polar !== false;

        const brandCondition =
          !brandSelected ||
          brandSelected === "All" ||
          cp.lensPackage.brand === brandSelected;

        const designTypeCondition = designTypeSelected
          ? cp.lensPackage.designType === designTypeSelected
          : true;

        return polarCondition && brandCondition && designTypeCondition;
      });
      const greyOutListPolirazedList = currentPackages.invalidRXPackages.filter(
        (cp) => {
          const polarCondition =
            cp.lensPackage.polar !== null && cp.lensPackage.polar !== false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        }
      );

      const listNotPolirazedList = currentPackages.validRXPackages.filter(
        (cp) => {
          const polarCondition =
            cp.lensPackage.polar === null || cp.lensPackage.polar === false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        }
      );
      const greyOutListNotPolirazedList =
        currentPackages.invalidRXPackages.filter((cp) => {
          const polarCondition =
            cp.lensPackage.polar === null || cp.lensPackage.polar === false;
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          return polarCondition && brandCondition;
        });

      setShowPolarSwitch(
        listPolirazedList.length + greyOutListPolirazedList.length >= 1 &&
          listNotPolirazedList.length + greyOutListNotPolirazedList.length >= 1
      );
    }
  }, [currentPackages, brandsAvaiable, designTypeSelected]);

  //end Polar

  useEffect(() => {
    if (currentPackages) {
      const currentDesignTypes = groupBy(
        currentPackages.validRXPackages
          .concat(currentPackages.invalidRXPackages)
          .filter((cp) => {
            const polarCondition =
              !polarSwitch ||
              (polarSwitch &&
                cp.lensPackage.polar !== null &&
                cp.lensPackage.polar !== false);
            // const brandCondition =
            //   !brandSelected ||
            //   brandSelected === "All" ||
            //   cp.lensPackage.brand === brandSelected;
            return polarCondition && cp.lensPackage.designType;
          }),
        "lensPackage.designType"
      );
      const deisgnTypesId = Object.keys(currentDesignTypes);
      if (deisgnTypesId.length && !enableDesignTypeStep) {
        let designTypes = deisgnTypesId
          .map((key) => ({
            id: key,
            title:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].title
                ? configDesignType[key].title
                : translation("steps.lensColor.designType." + key + ".title"),
            sequence:
              configDesignType &&
              configDesignType[key] &&
              configDesignType[key].sequence !== null &&
              configDesignType[key].sequence !== undefined
                ? configDesignType[key].sequence
                : deisgnTypesId.length,
          }))
          .sort((a, b) => {
            return a.sequence > b.sequence ? 1 : -1;
          });
        if (deisgnTypesId.length >= 1) {
          setDesignTypesAvailable(
            configObjectToSliderObject.adaptList(designTypes)
          );
          //if there's only one filterpill it's not shown to show it move this line after the if
          if (!designTypeSelected) setDesignTypeSelected(designTypes[0].id);
          // let lensPkg = currentPackages?.validRXPackages?.filter(
          //   (pkg: any) => pkg?.lensPackage.designType === designTypes[0].id
          // );
          // if (lensPkg) {
          //   setBrandselected(lensPkg?.[0]?.lensPackage?.brand);
          // }
          setBrandselected(brandsAvaiable?.[0]?.id);
        }
        if (deisgnTypesId.length === 1) {
          if (!designTypeSelected) setDesignTypeSelected(designTypes[0].id);
          // let lensPkg = currentPackages?.validRXPackages?.filter(
          //   (pkg: any) => pkg?.lensPackage.designType === designTypes[0].id
          // );
          // if (lensPkg) {
          //   setBrandselected(lensPkg?.[0]?.lensPackage?.brand);
          // }
          setBrandselected(brandsAvaiable?.[0]?.id);
        }
        categoriesAvaiable && setCategorySelected(categoriesAvaiable[0]?.id);
        setReccomendedUseSelected("All");
      }
    }
  }, [
    currentPackages,
    configDesignType,
    polarSwitch,
    // brandSelected,
    // designTypeSelected,
  ]);

  const handleDesignTypeSelected = (prop) => {
    //RXPANEL-3839 ex 3663
    setTimeout(() => {
      setPolarSwitch((p) => !p);
      setPolarSwitch((p) => !p);
    }, 1);
    setDesignTypeSelected(prop);
    let lensPkg = currentPackages?.validRXPackages?.filter(
      (pkg: any) => pkg?.lensPackage.designType === prop
    );
    if (lensPkg) {
      setBrandselected(lensPkg?.[0]?.lensPackage?.brand);
    }
  };
  //end DesignType

  //Reccomended Use
  const [reccomendedUseAvaiable, setReccomendedUseAvaiable] = useState(null);
  const [reccomendedUseSelected, setReccomendedUseSelected] = useState(null);
  const configReccomendedUse = useSelector(
    (state: any) => state.config?.lensesData?.content?.designType
  );
  useEffect(() => {
    if (currentPackages) {
      const currentReccomendedUse = currentPackages.validRXPackages
        .concat(currentPackages.invalidRXPackages)
        .filter((cp) => {
          const polarCondition =
            !polarSwitch ||
            (polarSwitch &&
              cp.lensPackage.polar !== null &&
              cp.lensPackage.polar !== false);
          const brandCondition =
            !brandSelected ||
            brandSelected === "All" ||
            cp.lensPackage.brand === brandSelected;
          const designTypeCondition =
            !designTypeSelected ||
            cp.lensPackage.designType === designTypeSelected;
          return (
            polarCondition &&
            brandCondition &&
            designTypeCondition &&
            cp.lensPackage.recommendedUse
          );
        });

      // RecommendedUseValue
      const reccomendedUseId = Object.keys(currentReccomendedUse);
      let allRecommendedUses = currentReccomendedUse.map((x) => {
        return x.lensPackage.recommendedUse;
      });
      allRecommendedUses =
        recommendedUseArrayToString.adapt(allRecommendedUses);
      if (allRecommendedUses?.length && !enableDesignTypeStep) {
        let reccomendedUses = allRecommendedUses.map((key) => ({
          id: key,
          title: key
            ? key
            : translation("steps.lensColor.categories." + key + ".title"),
        }));
        if (allRecommendedUses?.length > 1) {
          reccomendedUses.unshift({
            id: "All",
            title: "All",
          });
        }
        setReccomendedUseAvaiable(
          configObjectToSliderObject.adaptList(reccomendedUses)
        );
        if (!reccomendedUseSelected) {
          setReccomendedUseSelected(reccomendedUses[0].id);
        }
      } else {
        setReccomendedUseAvaiable(null);
        setReccomendedUseSelected(null);
        setCategorySelected(null);
      }
    }
  }, [
    currentPackages,
    configReccomendedUse,
    designTypeSelected,
    brandSelected,
    polarSwitch,
  ]);

  const handleReccomendedUseSelected = (prop) => {
    setReccomendedUseSelected(prop);
  };

  //end Reccomended Use

  //Category
  const [categoriesAvaiable, setCategoriesAvaiable] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const configCategory = useSelector(
    (state: any) => state.config?.lensesData?.content?.colorCategory
  );

  useEffect(() => {
    if (currentPackages) {
      let currentCategories;
      if (!enableDesignTypeStep) {
        currentCategories = groupBy(
          currentPackages.validRXPackages
            .concat(currentPackages.invalidRXPackages)
            .filter((cp) => {
              const polarCondition =
                !polarSwitch ||
                (polarSwitch &&
                  cp.lensPackage.polar !== null &&
                  cp.lensPackage.polar !== false);
              const brandCondition =
                !brandSelected ||
                brandSelected === "All" ||
                cp.lensPackage.brand === brandSelected;
              const designTypeCondition =
                !designTypeSelected ||
                cp.lensPackage.designType === designTypeSelected;
              const reccomendedUseCondition =
                !reccomendedUseSelected ||
                reccomendedUseSelected === "All" ||
                handleRecUseArray(
                  cp.lensPackage.recommendedUse,
                  reccomendedUseSelected
                );
              return (
                polarCondition &&
                brandCondition &&
                designTypeCondition &&
                reccomendedUseCondition &&
                cp.lensPackage.colorCategory
              );
            }),
          "lensPackage.colorCategory"
        );
      } else {
        currentCategories = groupBy(
          currentPackages.validRXPackages
            .concat(currentPackages.invalidRXPackages)
            .filter((cp) => {
              const polarCondition =
                !polarSwitch ||
                (polarSwitch &&
                  cp.lensPackage.polar !== null &&
                  cp.lensPackage.polar !== false);
              const brandCondition =
                !brandSelected ||
                brandSelected === "All" ||
                cp.lensPackage.brand === brandSelected;
              const reccomendedUseCondition =
                !reccomendedUseSelected ||
                reccomendedUseSelected === "All" ||
                handleRecUseArray(
                  cp.lensPackage.recommendedUse,
                  reccomendedUseSelected
                );
              return (
                polarCondition &&
                brandCondition &&
                reccomendedUseCondition &&
                cp.lensPackage.colorCategory
              );
            }),
          "lensPackage.colorCategory"
        );
      }
      //console.log("currentCategories", currentCategories);
      const categoriesId = Object.keys(currentCategories);
      if (categoriesId.length) {
        let categories = categoriesId.map((key) => ({
          id: key,
          title:
            configCategory && configCategory[key] && configCategory[key].title
              ? configCategory[key].title
              : translation("steps.lensColor.categories." + key + ".title"),
        }));
        if (categoriesId.length > 1) {
          categories.unshift({
            id: "All",
            title: filterPillsCategoryTitle,
          });
        }
        setCategoriesAvaiable(configObjectToSliderObject.adaptList(categories));
        if (!categorySelected) {
          let preselectedCategory = lens && lens.colorCategory;
          if (preselectedCategory) {
            if (categoriesId.find((cat) => cat === preselectedCategory)) {
              setCategorySelected(lens.colorCategory);
            } else {
              setCategorySelected(categories[0].id);
            }
          } else {
            setCategorySelected(categories[0].id);
          }
        } else if (categorySelected === "All") {
          setCategorySelected(categories[0].id);
        }
      } else setCategoriesAvaiable(null);
    }
  }, [
    currentPackages,
    configCategory,
    brandSelected,
    polarSwitch,
    designTypeSelected,
    categorySelected,
    reccomendedUseSelected,
  ]);

  //end Category

  const handleSelected = (indexG: number, indexC: number) => {
    let selectedColor: LensColorModel = null;
    let swatchString: any = null;
    const mapped = colorsG.map((cg, cgI) => {
      cg.colors = cg.colors.map((c: LensColorModel, cI: number) => {
        if (cI === indexC && cgI === indexG) {
          //FALLBACK
          swatchString = {
            url: getUrlSwatch(c.value),
            name: c.name,
            value: c.value,
          };
          c.selected = true;
          selectedColor = c;
        } else {
          c.selected = false;
        }

        return c;
      });
      return cg;
    });
    setColorsG(mapped);
    setSelectedColor(selectedColor);
    dispatch(
      colorActions.selectColorCategory(
        selectedColor?.colorCategoryTitle
          ? selectedColor?.colorCategoryTitle
          : selectedColor.colorCategory
      )
    );
    dispatch(
      colorActions.selectColorDesc(
        configCategory[selectedColor?.colorCategory]?.description
      )
    );
    dispatch(colorActions.selectColor(selectedColor.id));
    //FALLBACK
    dispatch(colorActions.setSwatchUrl(swatchString));
  };
  const handleNextStep = (skipped?: boolean, preselectedColor?: any) => {
    let selectedColorPrice = 0;
    let selectedColorDiscount = 0;
    let selectedColorInsPrice = 0;

    //if the color was pre-selected (like when editing from cart), dispatch the selection
    //to see the correct lens in the product container component
    if (preselectedColor) {
      dispatch(colorActions.selectColor(preselectedColor.id));
    }

    const colorSel = preselectedColor ? preselectedColor : selectedColor;

    colorsG.map((category) => {
      category.colors.map((color) => {
        if (
          color.id === colorSel.id &&
          color.value === colorSel.value &&
          colorSel.colorCategory === category.name
        ) {
          selectedColorPrice = color.price;
          selectedColorDiscount = color.discount;
          selectedColorInsPrice = color.insPrice;
        }
      });
    });
    if (checkLayoutConfigDesignType(config)) {
      workflow.goToStep(
        "DesignType",
        [
          {
            key: "color",
            value: colorSel.id,
            price: selectedColorPrice,
            discount: selectedColorDiscount,
            insPrice:
              skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
            attributes: {
              translatedName: colorSel.name,
              colorCategory: colorSel.colorCategory,
            },
            optionalAttributes: {
              key: brandSelected && brandSelected !== "ALL" ? "brand" : "",
              value: brandSelected,
            },
          },
          {
            key: "lensColor",
            value: colorSel?.name ? colorSel?.name : colorSel?.id,
            price: selectedColorPrice,
            discount: selectedColorDiscount,
            insPrice:
              skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
            attributes: {
              colorCategory: colorSel.colorCategory,
            },
            optionalAttributes: {
              key: brandSelected && brandSelected !== "ALL" ? "brand" : "",
              value: brandSelected,
            },
          },
        ],
        skipped
      );
    } else {
      workflow.goToStep(
        "Thickness",
        [
          {
            key: "color",
            value: colorSel.id,
            price: selectedColorPrice,
            discount: selectedColorDiscount,
            insPrice:
              skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
            attributes: {
              translatedName: colorSel.name,
              colorCategory: colorSel.colorCategory,
            },
            optionalAttributes: {
              key:
                brandSelected &&
                brandSelected !== "ALL" &&
                brandsAvaiable?.length > 1
                  ? "brand"
                  : designTypeSelected
                  ? "designType"
                  : null,
              value:
                brandSelected && brandsAvaiable?.length > 1
                  ? brandSelected
                  : designTypeSelected
                  ? designTypeSelected
                  : null,
            },
          },
          {
            key: "lensColor",
            value: colorSel?.name ? colorSel?.name : colorSel?.id,
            price: selectedColorPrice,
            discount: selectedColorDiscount,
            insPrice:
              skipped && selectedColorPrice == 0 ? 0 : selectedColorInsPrice,
            attributes: {
              colorCategory: colorSel.colorCategory,
            },
            optionalAttributes: {
              key:
                brandSelected &&
                brandSelected !== "ALL" &&
                brandsAvaiable?.length > 1
                  ? "brand"
                  : designTypeSelected
                  ? "designType"
                  : null,
              value:
                brandSelected && brandsAvaiable?.length > 1
                  ? brandSelected
                  : designTypeSelected
                  ? designTypeSelected
                  : null,
            },
          },
        ],
        skipped
      );
    }
  };
  const polarValue = usePolarToggle();
  const handlePolarFilterChange = (isChecked: boolean) => {
    setPolarSwitch(isChecked);
    setCategorySelected(categoriesAvaiable ? categoriesAvaiable[0]?.id : "All");
  };

  useEffect(() => {
    if (polarValue && polarValue === "ON") {
      handlePolarFilterChange(true);
    }
  }, [polarValue]);

  const handleFilterPillsCallback = (prop) => {
    setCategorySelected(prop);
  };

  const handleRecUseArray = (prop, value) => {
    let filterBy = new RegExp(value);
    if (prop) {
      if (typeof prop === "object") {
        if (filterBy.test(prop)) {
          return true;
        }
      } else if (prop === value) {
        return true;
      } else return false;
    } else return false;
  };

  const isColorDisabled = (color: string): boolean => {
    const validPackagesForColor = currentPackages.validRXPackages.filter(
      (cp) => cp.lensPackage.color === color
    );
    return validPackagesForColor.length === 0;
  };

  const getColors = (): LensColorGroup[] => {
    const temp = currentPackages.validRXPackages.filter((cp) => {
      const hasColor = cp.lensPackage.color;
      const polarCondition =
        !polarSwitch ||
        (polarSwitch &&
          cp.lensPackage.polar !== null &&
          cp.lensPackage.polar !== false);
      const brandCondition =
        !brandSelected ||
        brandSelected === "All" ||
        cp.lensPackage.brand === brandSelected;
      const categoryCondition =
        !categorySelected ||
        categorySelected === "All" ||
        cp.lensPackage.colorCategory === categorySelected;
      const designTypeCondition =
        !designTypeSelected ||
        cp.lensPackage.designType === designTypeSelected ||
        (brandCondition && !cp.lensPackage.designType);
      const reccomendedUseCondition =
        !reccomendedUseSelected ||
        reccomendedUseSelected === "All" ||
        handleRecUseArray(
          cp.lensPackage.recommendedUse,
          reccomendedUseSelected
        );
      return (
        hasColor &&
        polarCondition &&
        brandCondition &&
        categoryCondition &&
        designTypeCondition &&
        reccomendedUseCondition
      );
    });

    const greyOutTemp = currentPackages.invalidRXPackages
      .filter((cp) => {
        const hasColor = cp.lensPackage.color;
        const polarCondition =
          !polarSwitch ||
          (polarSwitch &&
            cp.lensPackage.polar !== null &&
            cp.lensPackage.polar !== false);
        const brandCondition =
          !brandSelected ||
          brandSelected === "All" ||
          cp.lensPackage.brand === brandSelected;
        const categoryCondition =
          !categorySelected ||
          categorySelected === "All" ||
          cp.lensPackage.colorCategory === categorySelected;
        const designTypeCondition =
          !designTypeSelected ||
          cp.lensPackage.designType === designTypeSelected;
        const reccomendedUseCondition =
          !reccomendedUseSelected ||
          reccomendedUseSelected === "All" ||
          handleRecUseArray(
            cp.lensPackage.recommendedUse,
            reccomendedUseSelected
          );
        return (
          hasColor &&
          polarCondition &&
          brandCondition &&
          categoryCondition &&
          designTypeCondition &&
          reccomendedUseCondition
        );
      })
      .map((cp) => {
        return {
          ...cp,
          disabled: isColorDisabled(cp.lensPackage.color),
        };
      });

    return Object.entries(
      groupBy(temp.concat(greyOutTemp), "lensPackage.colorCategory")
    ).map(([key, value]) => {
      if (key != null) {
        let hasPromo = false;
        let color: LensColorModel[] = [];
        if (value) {
          let groupedByColor = groupBy(value, "lensPackage.color");
          Object.keys(groupedByColor).map((cg) => {
            let cgPackages = groupedByColor[cg];
            let cheapestCgp = getCheapPackageFromList(cgPackages);

            if (key === "null") {
              key = null;
            }

            const prices = brandLastStep
              ? getPriceForColor({
                  color: cg,
                  colorCategory: key,
                })
              : getPriceForColor({
                  color: cg,
                  colorCategory: key,
                  brand: brandSelected,
                });
            let listPrice = 0;
            let offerPrice = 0;
            let insPrice = 0;

            if (prices) {
              listPrice = prices.price;
              if (isInsuranceEnabled) {
                if (prices.insPrice) {
                  offerPrice = prices.insPrice;
                } else {
                  offerPrice = prices.price - prices.discount;
                }
              } else {
                offerPrice = prices.price - prices.discount;
              }
              insPrice = prices.insPrice;
            }

            const colorName = cheapestCgp.lensPackage.color;
            let recUse = cheapestCgp.lensPackage.recommendedUse;
            let promo: LensColorPromo[] = [];
            const promoFrame = plainLensColorPromoAdapter.adapt(
              cheapestCgp?.frame?.promo
            );
            const lensPromo = plainLensColorPromoAdapter.adapt(
              cheapestCgp?.lensPackage?.promo
            );
            if (promoFrame) {
              promo = [...promo, ...promoFrame];
              hasPromo = true;
            }
            if (lensPromo) {
              promo = [...promo, ...lensPromo];
              hasPromo = true;
            }
            const configColorp = configColor?.find((c) => c.id === colorName);
            const defSelected =
              lens &&
              lens.clen &&
              cheapestCgp.clen &&
              lens.clen === cheapestCgp.clen;
            if (configColorp) {
              const imageFileName = configColorp.imageFileName1
                ? configColorp.imageFileName1
                : configColorp.imageFileName;
              color.push({
                ...configColorp,
                id: configColorp.id,
                name: configColorp.title,
                value: imageFileName,
                promo: promo.length ? promo : null,
                selected: defSelected ? defSelected : false,
                price: listPrice,
                discount: listPrice - offerPrice,
                insPrice: insPrice,
                recommendedUse: recUse,
                colorCategory: key,
                colorCategoryTitle:
                  colorCategoryContent && colorCategoryContent[key]
                    ? colorCategoryContent[key].title
                    : key,
                disabled: cheapestCgp.disabled,
                promoBadge: configColorp.promoBadge,
              });
            }
          });
        }
        return { name: key, colors: color, hasPromo };
      }
    });
  };

  useEffect(() => {
    if (getPriceForColor && configColor && currentPackages) {
      let filteredPackages = currentPackages.validRXPackages.filter(
        (p) => p.lensPackage.color
      );
      let greyOutFilteredPackages = currentPackages.invalidRXPackages.filter(
        (p) => p.lensPackage.color
      );
      const differentColors = groupBy(
        filteredPackages.concat(greyOutFilteredPackages),
        "lensPackage.color"
      );
      setToSkip(
        Object.keys(differentColors).length === 1 &&
          (!skipConfiguration ||
            !skipConfiguration.LensColor ||
            skipConfiguration.LensColor === "SKIP")
      );

      const colors = getColors();
      if (colors.length && colors[0]) {
        if (!selectedColor) {
          let preselectedColorPackage =
            lens && lens.clen
              ? currentPackages.validRXPackages.find(
                  (pkg) => pkg.lensPackage.clen === lens.clen
                )
              : null;
          let preselectedColor = preselectedColorPackage
            ? preselectedColorPackage.lensPackage.color
            : null;

          if (
            (!preselectedColor || (preselectedColor && ignorePreselection)) &&
            !lastLensColorSelected
          ) {
            let colorsArr = colors[0].colors;
            if (colorsArr && colorsArr.length) {
              let selectableColors = colorsArr.filter((c) => !c.disabled);

              if (selectableColors && selectableColors.length) {
                colors[0].colors[0].selected = true;
                setSelectedColor(colors[0].colors[0]);
                // Qui il primo colore
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(
                      colors[0].colors[0].value ? colors[0].colors[0].value : ""
                    ),
                    name: colors[0].colors[0].name
                      ? colors[0].colors[0].name
                      : "",
                    value: colors[0].colors[0].value
                      ? colors[0].colors[0].value
                      : "",
                  })
                );
                dispatch(colorActions.selectColor(colors[0].colors[0].id));
                dispatch(
                  colorActions.selectColorCategory(
                    colors[0].colors[0].colorCategory
                      ? colors[0].colors[0].colorCategory
                      : null
                  )
                );
                dispatch(
                  colorActions.selectColorDesc(
                    configCategory?.[colors[0].colors[0]?.colorCategory]
                      ?.description
                  )
                );
              }
            }
          } else if (lastLensColorSelected) {
            const newColors = colorsG.map((colorCat: any) => {
              const findedColor = colorCat.colors.find(
                (color: any) => color.id === lastLensColorSelected
              );
              let colors = [...colorCat.colors];
              if (!findedColor) {
                console.log(`Color not found for "${lastLensColorSelected}"`);
              } else {
                colors = colorCat.colors.map((color: any) => {
                  if (color.id === findedColor.id) {
                    color.selected = true;
                  } else {
                    color.selected = false;
                  }
                  return color;
                });
                setSelectedColor(findedColor);
              }
              return { ...colorCat, colors };
            });
            //console.log(newColors);
            setColorsG(newColors);
          } else {
            setIgnorePreselection(true);
            colors[0].colors = colors[0].colors.map((col) => {
              if (col.id !== preselectedColor) {
                return col;
              } else {
                let newCol = col;
                newCol.selected = true;

                setSelectedColor(newCol);
                // Qui il primo colore
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(newCol.value),
                    name: newCol.name,
                    value: newCol.value,
                  })
                );
                dispatch(colorActions.selectColor(newCol.id));

                return newCol;
              }
            });
          }
        } else {
          let hasSelectedColor = false;
          colors.map((c, indexC) => {
            c.colors.map((cc, indexCC) => {
              if (cc.id === selectedColor.id && !hasSelectedColor) {
                colors[indexC].colors[indexCC].selected = true;
                hasSelectedColor = true;
              }
              return cc;
            });
            return c;
          });

          if (!hasSelectedColor) {
            let colorsArr = colors[0].colors;
            if (colorsArr && colorsArr.length) {
              let selectableColors = colorsArr.filter((c) => !c.disabled);

              if (selectableColors && selectableColors.length) {
                colors[0].colors[0].selected = true;
                setSelectedColor(colors[0].colors[0]);
                // Qui il primo colore
                dispatch(
                  colorActions.setSwatchUrl({
                    url: getUrlSwatch(colors[0].colors[0].value),
                    name: colors[0].colors[0].name,
                    value: colors[0].colors[0].value,
                  })
                );
                dispatch(colorActions.selectColor(colors[0].colors[0].id));
              }
            }
          }
        }
      }

      dispatch(
        colorActions.selectRecommendedUse(
          !reccomendedUseSelected && reccomendedUseSelected === "All"
            ? ""
            : reccomendedUseSelected
        )
      );

      let colorsToSet = [];
      let greyOutColors = [];
      colors.forEach((cG) => {
        let ret = {
          ...cG,
          colors: cG.colors.filter((c) => !c.disabled),
        };
        greyOutColors = [
          ...greyOutColors,
          ...cG.colors.filter((c) => c.disabled),
        ];

        colorsToSet.push(ret);
      });

      colorsToSet.push({
        name: "DISABLED",
        hasPromo: false,
        colors: greyOutColors,
      });
      //console.log(lastLensColorSelected);
      setColorsG(colorsToSet);
    }
  }, [
    getPriceForColor,
    currentPackages,
    configColor,
    prescription,
    polarSwitch,
    categorySelected,
    brandSelected,
    designTypeSelected,
    reccomendedUseSelected,
    skipConfiguration,
  ]);

  // -------- INSURANCE CODE -------- //

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);

  useEffect(() => {
    if (isInsuranceEnabled && selectedColor) {
      let filteredPackages = currentPackages.validRXPackages.filter((p) => {
        return p.lensPackage.color === selectedColor.id;
      });
      // let cheapestPackages = null;
      // if (filteredPackages.length > 1) {
      //   cheapestPackages = [getCheapPackageFromList(filteredPackages)];
      // } else {
      //   cheapestPackages = filteredPackages;
      // }
      let cheapestPackages = filteredPackages;
      let insurablePackages = cheapestPackages.filter(
        (pkg) => pkg.lensPackage.insurable
      );
      if (insurablePackages.length) {
        callInsuranceService(
          cheapestPackages,
          configInsurance,
          frame,
          dispatch
        );
      }
    }
  }, [isInsuranceEnabled, selectedColor]);

  // -------- INSURANCE CODE -------- //

  useEffect(() => {
    if (toSkip && colorsG && selectedColor) {
      handleNextStep(true);
      setToSkip(false);
    }
  }, [toSkip, colorsG, selectedColor]);

  useEffect(() => {
    dispatch(
      workflowActions.setAdditionalParametersPackages({
        brand: brandSelected,
        color: selectedColor?.id,
      })
    );
    return () => {
      dispatch(workflowActions.setAdditionalParametersPackages(null));
    };
  }, [brandSelected, selectedColor]);

  useEffect(() => {
    for (const colorG of colorsG) {
      const { colors } = colorG;
      for (const color of colors) {
        getDiscount(`${color.id}`, currentPackages.validRXPackages, {
          color: color.id,
          colorCategory: color.colorCategory,
          brand: brandSelected,
        });
      }
    }
  }, [colorsG]);

  const colors = useMemo(() => {
    if (!colorsG) return [];
    const list = [];
    colorsG.forEach((cg: LensColorGroup, cgI) => {
      if (cg.colors) {
        const mappedColors = cg.colors.map((c, colorIndex) => ({
          ...c,
          cgI,
          groupName:
            c.colorCategoryTitle !== "null" ? c.colorCategoryTitle : null,
          colorIndex,
        }));
        list.push(...mappedColors);
      }
    });

    const cardClassName = list.find((c) => c.promoBadge) ? " promoBadge" : "";
    return list
      .sort((a, b) => a.sequence - b.sequence)
      .map((c, i) => {
        return (
          <BrandComponent
            componentName="LensColorCard"
            key={i}
            parameter={{
              color: c,
              cardClassName: cardClassName,
              isSelected: c.id === selectedColor?.id,
              changePriceStyle: changePriceStyle,
              changeDiscountStyle: changeDiscountStyle,
              onSelected: handleSelected,
              discountLoading: isDiscountLoading(c.id),
            }}
          />
        );
      });
  }, [colorsG]);

  const hasDetail = () => {
    let finded = null;
    if (selectedColor && configColor) {
      finded = configColor.find((c) => c.id === selectedColor.id);
      if (
        finded?.longTitle ||
        finded?.lightTransmission ||
        finded?.lightConditions ||
        finded?.contrast ||
        finded?.baseLensColor
      ) {
        return true;
      } else return false;
    } else return false;
  };

  const getPillsSubtitle = () => {
    const findedBrand = configBrand.find((b) => b.id === brandSelected);
    if (designTypeSelected && designTypesAvailable.length > 1) {
      return configDesignType[designTypeSelected].description;
    } else {
      if (findedBrand && findedBrand.description === "MISSING CONTENT")
        return "";
      return findedBrand ? findedBrand.description : "";
    }
  };

  const dataArrowColor = useMemo(() => {
    switch (brand) {
      case "davidclulow":
        return "#00303C";
      default:
        return "white";
    }
  }, [brand]);

  useEffect(() => {
    if (colors) {
      // resize to update LensColor__btn_container position (see BottomBar CL)
      window.dispatchEvent(new Event("resize"));
    }
  }, [colors]);

  const learnMoreButton = useTranslate("steps.lensColor.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.lensColor.learnMoreCTA");
  return (
    <>
      {showAlert && (
        <div className={"LensColor__alert_container"}>
          <div style={{ display: "flex" }}>
            <div
              className={"LensColor__alert_title__icon"}
              data-tip={bannerTooltiptext}
              data-class={"LensColor__polarized_container__moreinfo__tooltip"}
              data-arrow-color={dataArrowColor}
            />
            <p className={"LensColor__alert_title"}>
              <TranslateText label="steps.color.alertTitle" />
            </p>
          </div>
          <p className={"LensColor__alert_description"}>
            <TranslateText label="steps.color.alertText" />
          </p>
          <ReactTooltip effect="solid" type="light" place="bottom" />
        </div>
      )}
      <BrandComponent
        componentName="StepContainer"
        parameter={{
          showStepTitle: true,
          title:
            designTypesAvailable?.length > 1 && !enableDesignTypeStep
              ? translation("steps.color.titleDesignTypeLayout")
              : translation("steps.color.title"),
          subtitle: ctxMessage,
          pillsSubtitle:
            currentBrand === "clearly" ||
            currentBrand === "persol" ||
            (currentBrand === "rayban" && enableDesignTypeStep)
              ? ""
              : !brandLastStep
              ? getPillsSubtitle()
              : null,
          pills:
            (!enableDesignTypeStep || currentType === "SUNGLASSES") &&
            designTypesAvailable &&
            designTypesAvailable.length > 1
              ? designTypesAvailable
              : null,
          pillsCallback: handleDesignTypeSelected,
          slider:
            brandsAvaiable?.length > 1 && currentType === "SUNGLASSES"
              ? brandsAvaiable
              : null,
          pillSelected: designTypeSelected,
          brandCallback: handleBrandCallback,
          showGrayOutBanner:
            !designTypesAvailable &&
            colorsG &&
            colorsG.find(
              (cg) => cg.colors && cg.colors.find((c) => c.disabled)
            ),
        }}
      >
        {alreadyIncluded && (
          <div className={"LensColor__alreadyIncluded__container"}>
            <ul className={"LensColor__alreadyIncluded__container__list"}>
              {alreadyIncluded.map((ai, i) => (
                <Fragment key={i}>
                  <li
                    key={"Addons_ai_" + ai.id}
                    className={
                      "LensColor__alreadyIncluded__container__list__item"
                    }
                  >
                    {translation(ai.name)}
                    <span
                      data-tip={translation("steps.color.tooltip." + ai.id)}
                      data-class={
                        "LensColor__polarized_container__moreinfo__tooltip"
                      }
                      data-arrow-color={dataArrowColor}
                      className={
                        "LensColor__alreadyIncluded__container__info--icon"
                      }
                    />
                    <ReactTooltip
                      multiline={true}
                      type="light"
                      place={isMobile ? "right" : "bottom"}
                      effect="solid"
                      className="LensColor__alreadyIncluded__container__tooltip"
                    />
                  </li>
                </Fragment>
              ))}
            </ul>
          </div>
        )}
        {learnMoreButton !== "steps.lensColor.contentIdentifier" &&
          learnMoreButton !== undefined &&
          learnMoreButton !== null &&
          delegatingLearnMore &&
          delegateLearnMoreContent && (
            <a
              className="learnMoreButton"
              onClick={() => delegateLearnMoreContent(learnMoreButton)}
              data-element-id={
                (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
                "_LensColour-LearnMore"
              }
            >
              {moreInfoLabel !== "" &&
              moreInfoLabel !== undefined &&
              moreInfoLabel !== null &&
              moreInfoLabel !== "steps.lensColor.learnMoreCTA"
                ? moreInfoLabel
                : "More information"}
            </a>
          )}
        <div className={"LensColor__container"}>
          {designTypesAvailable?.length > 1 && !enableDesignTypeStep && (
            <>
              {!brandLastStep && (
                <div className={"LensColor__container_separator"}></div>
              )}
              <BrandComponent
                componentName="StepTitle"
                parameter={{
                  showStepTitle: true,
                  title: translation("steps.color.title"),
                  showGrayOutBanner:
                    colorsG &&
                    colorsG.find(
                      (cg) => cg.colors && cg.colors.find((c) => c.disabled)
                    ),
                  subtitle: ctxMessage ? ctxMessage : null,
                  slider:
                    brandsAvaiable?.length > 1 &&
                    designTypesAvailable?.length > 1 &&
                    currentType === "SUNGLASSES"
                      ? brandsAvaiable
                      : null,
                }}
              />
            </>
          )}

          {showPolarSwitch && showPolarToggle && (
            <div className={"LensColor__polarized_container"}>
              <div className={"LensColor__polarized_container_titleContainer"}>
                <div>
                  <TranslateText label={"steps.color.polarized"} />
                </div>
              </div>
              {tooltipPolarized?.length > 0 && (
                <div
                  className={"LensColor__polarized_container__moreinfo"}
                  data-tip={tooltipPolarized}
                  data-class={
                    "LensColor__polarized_container__moreinfo__tooltip"
                  }
                  data-arrow-color={dataArrowColor}
                />
              )}
              <div className={"LensColor__polarized_container_switch"}>
                <SwitchControl
                  name="PolarSwitch"
                  checked={polarSwitch}
                  onChange={handlePolarFilterChange}
                />
                {/* <ReactTooltip type="light" place="bottom" effect="solid" /> */}
              </div>
            </div>
          )}
          {reccomendedUseAvaiable && reccomendedUseAvaiable.length > 1 && (
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: reccomendedUseAvaiable,
                callback: handleReccomendedUseSelected,
                selected: reccomendedUseSelected,
              }}
            />
          )}
          {categoriesAvaiable && categoriesAvaiable.length > 1 && (
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: categoriesAvaiable,
                callback: handleFilterPillsCallback,
                selected: categorySelected,
              }}
            />
          )}

          <div className={"LensColor__groupsContainer"}>
            {colorsG && colorsG.length > 0 && (
              <BrandComponent
                componentName="SkipContainer"
                parameter={{
                  stepName: "LensColor",
                  ignoreSkip: true,
                  parameterPreselection: ["color", "colorCategory"],
                  onPreselect: (selected: any, skipped: boolean) => {
                    console.warn("clicco sul colore");
                    let colorToSelect = null;
                    let categoryToSelect = selected.colorCategory
                      ? selected.colorCategory
                      : "null";
                    colorsG.forEach((category) => {
                      if (category.name === categoryToSelect) {
                        category.colors.forEach((color) => {
                          if (color.id === selected.color) {
                            colorToSelect = color;
                          }
                        });
                      }
                    });
                    handleNextStep(skipped, colorToSelect);
                  },
                }}
              >
                <>{colors}</>
              </BrandComponent>
            )}
          </div>
          <div className="rxc-pseduo-track"></div>

          <div
            className={
              "LensColor__btn_container" + (hasDetail() ? " borderOutline" : "")
            }
          >
            {hasDetail() && !showFallbackImageMessage ? (
              <>
                <div className="LensColor__detailsContainer_mobile">
                  <LensColorDetails
                    configColor={configColor}
                    selectedColor={selectedColor}
                  />
                </div>
                {!isLoading && (
                  <div className="LensColor__detailsContainer_desktop">
                    {createPortal(
                      <LensColorDetails
                        configColor={configColor}
                        selectedColor={selectedColor}
                      />,
                      document.querySelector(
                        ".ProductContainer__productImageContainer__productImageContent"
                      )
                    )}
                  </div>
                )}
              </>
            ) : null}
            {colorsG?.length > 0 && (
              <GenericButton
                handleClick={() => handleNextStep(false)}
                className={"LensColor__btn"}
                noArrow
              >
                <TranslateText label="steps.color.applyAndContinue" />
              </GenericButton>
            )}
          </div>
        </div>
        <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
      </BrandComponent>
    </>
  );
}
