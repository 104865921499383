import { labelsParamsAdapters } from "../adapters/LabelsParamsAdapter";
import { IStep } from "../meta/IStep";
import { Parameter } from "./Parameter";

export class Step implements IStep<number, Parameter> {
  params: Parameter[];
  progressive: number;
  key: string;
  navigated: boolean;

  constructor(
    key: string,
    progressive: number,
    parameters: Parameter[],
    navigated = false
  ) {
    this.key = key;
    this.progressive = progressive;
    this.params = parameters;
    this.navigated = navigated;
  }

  getTitle(): string {
    let ret = "";
    if (this.params) {
      ret = labelsParamsAdapters.adaptReverse(this.params).title;
    }
    return ret;
  }
}
