import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { configActions } from "../../redux/slices/config";
import { discountsActions, DiscountsState } from "../../redux/slices/discounts";
import { getCheapestPackages } from "../ConfigLoader";

type Filter = null | Record<string, boolean>;
type Frame = any;
type LensesData = {
  lensPackage: any;
  frame: any;
};

type GetDiscountOut = {
  frame: Frame;
  lensesData: LensesData;
};

type UseGetDiscountOut = {
  getDiscount: (id: string, p: LensesData[], f: Filter) => Promise<void>;
  getPackageDiscount: (id: string, p: LensesData) => Promise<void>;
  isLoading: (id: string) => boolean;
  someIsLoading: () => boolean;
};

export function useGetDiscount(): UseGetDiscountOut {
  const dispatcher = useDispatch();

  const getDiscountCallback = useSelector(
    (state: any) => state.config.dynamicPromoModule?.getDiscount
  ) as (frame, lensPackage) => Promise<GetDiscountOut> | null;

  const frameConfig = useSelector((state: any) => state.config?.data.frame);

  const loader = useSelector(
    (state: { discounts: DiscountsState }) => state.discounts.loading
  );

  const discountedLensPackages = useSelector(
    (state: { discounts: DiscountsState }) =>
      state.discounts.discountedLensPackages
  );

  const someIsLoading = () => loader.length > 0;

  const isLoading = (id: string) => {
    return loader.indexOf(id) >= 0;
  };

  const isAlreadyDiscounted = ({ lensPackage }) => {
    return discountedLensPackages.includes(lensPackage.catEntryId);
  };

  const getDiscount = async (
    id: string,
    packages: LensesData[],
    filter: Filter
  ) => {
    if (!getDiscountCallback) return;
    const pack = getCheapestPackages(packages, filter);
    if (!pack) {
      console.log("Cheapest package not found for ", id);
      return;
    }
    const { minPackageFilterPrice } = pack;
    //console.log('discount for ', id, ':', minPackageFilterPrice.lensPackage.catEntryId)
    await getPackageDiscount(id, minPackageFilterPrice);
  };

  const getPackageDiscount = async (
    id: string,
    filteredPackage: LensesData
  ) => {
    if (isAlreadyDiscounted(filteredPackage)) {
      //console.log('already discounted ', filteredPackage.lensPackage.catEntryId)
      return;
    }
    let res;
    const timerLoader = setTimeout(
      () => !res && dispatcher(discountsActions.activeLoader(id)),
      1000
    );
    dispatcher(discountsActions.addDiscountedLens(filteredPackage));
    try {
      res = await getDiscountCallback(frameConfig, filteredPackage);
    } catch (e) {
      console.error(e);
      dispatcher(discountsActions.removeDiscountedLens(filteredPackage));
    } finally {
      clearTimeout(timerLoader);
      dispatcher(discountsActions.disableLoader(id));
    }
    dispatcher(configActions.setDiscountPackage(res));
  };

  return { getDiscount, getPackageDiscount, isLoading, someIsLoading };
}
