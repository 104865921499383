import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StateTooltip = {
  hideTooltip: boolean;
};

const initialState: StateTooltip = {
  hideTooltip: true,
};

const tooltipSlice = createSlice({
  name: "tooltip",
  initialState: initialState,
  reducers: {
    setHideTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.hideTooltip = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const tooltipReducer = tooltipSlice.reducer;
export const tooltipActions = tooltipSlice.actions;
