import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ExportCSVButton from './ExportCSVButton';
import DragAndResize from './DragAndResize';
import OptionsPanel from './OptionsPanel';
import "./debugConsole.scss"
import { cloneDeep } from 'lodash';

const DEFAULT_OPTIONS = [
  {
    value: "packages",
    active: false,
  },
  {
    value: "step",
    active: false,
  },
  {
    value: "prescription",
    active: false
  },
  {
    value: "configPreCheckLogs",
    active: true
  }
]

const DebugConsoleContext = createContext(null)

export const useDebugConsoleContext = () => {
  const context = React.useContext(DebugConsoleContext)
  if (!context) {
    throw new Error("useDebugConsoleContext must be used within the scope of <DebugConsoleProvider> component")
  }
  return context
}

const useLogs = (options) => {
  const [logs, setLogs] = useState([]);
  const currentPackages = useSelector((state: any) => state.console?.currentPackages);
  const currentStep = useSelector((state: any) => state.console?.currentStep);
  const currentPrescription = useSelector((state: any) => state.prescription?.currentPrescription)
  const configPreCheckLogs = useSelector((state: any) => state.console?.configPreCheckLogs)

  useEffect(() => {
    // setting new logs
    if (!currentStep || !currentPackages) return
    setLogs(prev => {
      let newLogs = [...prev];
      if (newLogs[newLogs.length - 1]?.step?.value?.key === currentStep?.key) {
        // remove duplicates
        newLogs[newLogs.length - 1] = {
          step: { value: currentStep, active: true },
          packages: { value: currentPackages, active: true }
        }
        return newLogs
      }

      return [
        ...newLogs,
        {
          step: { value: currentStep, active: true },
          packages: { value: currentPackages, active: true }
        }
      ]
    });
  }, [currentStep, currentPackages]);

  const filteredLogs = useMemo(() => {
    if (options.find(opt => opt.value === "configPreCheckLogs" && opt.active) && configPreCheckLogs.length) {
      return [{ configPreCheckLogs: { value: configPreCheckLogs, active: true } }]
    } else if (options.find(opt => opt.value === "prescription" && opt.active) && currentPrescription) {
      return [{ prescription: { value: currentPrescription, active: true } }]
    } else {
      return logs.map(log => {
        const keys = Object.keys(log)
        let _log = cloneDeep(log);
        keys.forEach(key => {
          const option = options.find(opt => opt.value === key)
          _log[option.value] = { value: log[option.value].value, active: option.active }
        })
        return _log
      })
    }
  }, [logs, options, currentPrescription])


  return { logs: filteredLogs }
}

export const DebugConsoleProvider = ({ children }) => {

  const [options, setOptions] = useState(DEFAULT_OPTIONS)

  const { logs } = useLogs(options)

  const onOptionChange = ({ target }) => {
    setOptions(prev => {
      return prev.map(option => {
        if (option.value === target.name) {
          return { ...option, active: !option.active }
        }
        return option
      })
    })
  }
  return (
    <DebugConsoleContext.Provider value={{ logs, options, onOptionChange }}>
      {children}
    </DebugConsoleContext.Provider>
  )
}

const DebugConsole = () => {

  const { logs } = useDebugConsoleContext()

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <DragAndResize ref={scrollRef}>
      <OptionsPanel />
      {logs?.map((log, index) => (
        <div className="log" key={index}>
          <ul>
            {log?.step?.active ? (
              <>
                <li><pre>step: <span>{log.step.value.key}</span></pre></li>
                <li><pre>parameters: {JSON.stringify(log.step.value.params, null, 2)}</pre></li>
              </>
            ) : null
            }
            {log?.packages?.active ? (
              <li><pre>filtered packages: {log.packages.value.length} <ExportCSVButton data={log.packages.value} filename="packages.csv" /></pre></li>
            ) : null}
            {log?.prescription?.active ? (
              <>
                <li><pre>prescription: {JSON.stringify(log.prescription.value, null, 2)}</pre></li>
              </>
            ) : null}
            {log?.configPreCheckLogs?.active ? (
              <>
                {log.configPreCheckLogs.value.map((_log) => {
                  return <li className={_log?.type}><pre>{_log?.message}</pre></li>
                })
                }
              </>
            ) : null}
          </ul>
          {Object.values(log).every(val => !val.active) ? null : <div className="divider"></div>}
        </div>
      ))}
    </DragAndResize>
  );
};

export default DebugConsole;
