import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { workflow } from "../models/Workflow";
import { useAllCurrentPackages } from "./useAllCurrentPackages";
import { useCheapCurrentStep } from "./useCheapCurrentStep";
import { useTreatmentsFamilyMapper } from "./useTreatmentsFamilyMapper";
import { useTreatmentsMapper } from "./useTreatmentsMapper";
import { useTransitionSelectedColor } from "./useTransitionSelectedColor";

export function useCheckPhotocromic() {
  const currentPackages = useAllCurrentPackages();
  const currentPackage = useCheapCurrentStep();
  const loadTreatments = useTreatmentsMapper();
  const loadTreatmentsFamily = useTreatmentsFamilyMapper();
  const setTransionColorImage = useTransitionSelectedColor();

  const [ret, setRet] = useState(null);

  const getFamilyFilter = () => {
    return workflow.currentStep.params.find((p) => p.key === "familyFilter");
  };

  const filterPackagesByFamily = (
    packages: any[],
    requiredoptions: string[],
    excludedoptions?: string[]
  ) => {
    let filteredPackages = packages;
    if (requiredoptions?.length) {
      filteredPackages = filteredPackages.filter((cp) => {
        let ret = true;
        requiredoptions.forEach(
          (t) => (ret = ret && cp.lensPackage[t] != null)
        );
        if (excludedoptions?.length)
          excludedoptions.forEach(
            (t) => (ret = ret && cp.lensPackage[t] === null)
          );
        return ret;
      });
    }
    return filteredPackages;
  };

  const filterTreatmentsByFamily = (
    treatments: any[],
    required: string[],
    additional?: string[]
  ) => {
    let toFilterData: any[] = [];
    if (required) {
      toFilterData = [...toFilterData, ...required];
    }
    if (additional) {
      toFilterData = [...toFilterData, ...additional];
    }
    if (treatments) {
      return treatments.filter((t) => {
        const key = t.id.split("$")[0];
        return toFilterData.some((f) => f === key);
      });
    }
    return null;
  };

  const checkFromTreatments = () => {
    let ret = false;
    const familyFilter = getFamilyFilter();
    let packageList = currentPackages?.validRXPackages;
    let greyOutPackageList = currentPackages?.invalidRXPackages;
    if (familyFilter) {
      packageList = filterPackagesByFamily(
        currentPackages?.validRXPackages,
        familyFilter.value?.required,
        familyFilter.value?.excluded
      );

      greyOutPackageList = filterPackagesByFamily(
        currentPackages?.invalidRXPackages,
        familyFilter.value?.required,
        familyFilter.value?.excluded
      );
    }

    let { treatments } = loadTreatments(
      { validRXPackages: packageList, invalidRXPackages: [] },
      false,
      ["transition"]
    );

    if (treatments && treatments.length === 1) {
      const transitionOptions = groupBy(
        packageList.filter((cp) => cp.lensPackage.transition),
        "lensPackage.transition"
      );
      if (Object.keys(transitionOptions).length === 1) {
        const valueTransition = Object.keys(transitionOptions)[0];
        const transitionPackages = packageList.filter(
          (cp) => cp.lensPackage.transition === valueTransition
        );
        const greyOutTransitionPackages = greyOutPackageList.filter(
          (cp) => cp.lensPackage.transition === valueTransition
        );
        const groupedByColor = groupBy(
          transitionPackages.concat(greyOutTransitionPackages),
          "lensPackage.color"
        );
        if (Object.keys(groupedByColor).length === 1) {
          //there is just on color
          //is photocromic
          ret = true;
          setTransionColorImage(Object.keys(groupedByColor)[0]);
        }
      }
    }

    return ret;
  };

  const checkFromTreatmentsFamily = () => {
    const options = loadTreatmentsFamily(currentPackages, currentPackage);
    let ret = false;
    if (options) {
      const onlyTransition = options.filter(
        (p) => p.id.indexOf("transition$") >= 0
      );
      if (options.length === 1 && onlyTransition.length === 1) {
        //there is only one transition
        const valueTransition = onlyTransition[0].id.split("$")[1];
        const transitionPackages = currentPackages.validRXPackages.filter(
          (cp) => cp.lensPackage.transition === valueTransition
        );
        const greyOutTransitionPackages =
          currentPackages.invalidRXPackages.filter(
            (cp) => cp.lensPackage.transition === valueTransition
          );
        const groupedByColor = groupBy(
          transitionPackages.concat(greyOutTransitionPackages),
          "lensPackage.color"
        );
        if (Object.keys(groupedByColor).length === 1) {
          //there is just on color
          //is photocromic
          ret = true;
          setTransionColorImage(Object.keys(groupedByColor)[0]);
        }
      }
    }

    return ret;
  };

  useEffect(() => {
    if (
      currentPackages &&
      currentPackage &&
      loadTreatments &&
      loadTreatmentsFamily &&
      setTransionColorImage
    ) {
      if (workflow.currentStep.key === "TreatmentsFamily") {
        setRet(checkFromTreatmentsFamily());
      } else if (workflow.currentStep.key === "Treatments") {
        setRet(checkFromTreatments());
      }
      return () => setTransionColorImage(null);
    }
  }, [
    currentPackages,
    currentPackage,
    loadTreatmentsFamily,
    loadTreatments,
    setTransionColorImage,
  ]);

  return ret;
}
