import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflowActions } from "../../redux/slices/workflow";
import { useShowFramePlusLensesPrice } from "@hooks";

interface MainHeaderProps { }

export function MainHeader({ }: MainHeaderProps) {
  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep
  );
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;
  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();
  const dispatch = useDispatch();

  const reduxDispatch = useDispatch();

  const getDataElementIdForClose = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-BackToProd";
  };

  const getDataElementIdForBack = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Back";
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const getDataDescriptionForBack = () => {
    let landingStep = workflow.getLadindingStepFromBack();
    if (landingStep) {
      return getCorrectAnalyticsStepContainerName(landingStep.key);
    }
  };

  const handleBack = () => {
    dispatch(workflowActions.setIsEditMode(true));
    if (selectedPrescriptionMode) {
      dispatch(workflowActions.setSelectedPrescriptionMode(false));
    } else {
      workflow.goPrevStep();
    }
  };

  const renderDesktop = () => {
    return (
      <div className={"MainHeader__container"}>
        <div className={"MainHeader__left"}>
          <a
            className={
              "MainHeader__back" +
              (workflow.currentStep?.progressive > 1 ? "" : " invisible")
            }
            data-element-id={getDataElementIdForBack()}
            data-description={getDataDescriptionForBack()}
            onClick={() => {
              handleBack();
            }}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28803 13.495L9.91847 12.5167C8.77716 10.5874 7.41848 9.03847 5.86957 7.95152H20V6.04934H5.86957C7.41848 4.96238 8.77716 3.41347 9.91847 1.48412L8.28803 0.505859C6.16846 4.11999 3.26087 6.04934 0 6.04934V7.95152C3.26087 7.95152 6.16846 9.88086 8.28803 13.495V13.495Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
        <div className={"MainHeader__center"}>
          <BrandComponent componentName="HeaderProgressBar" />
        </div>
        <div className={"MainHeader__right"}>
          <a
            className={"MainHeader__close"}
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.40516 4.81941L2.35562 0.675579C1.50991 -0.189828 1.50189 -0.181618 0.87507 0.460111C0.866538 0.468846 0.857892 0.477698 0.849127 0.486667C0.222799 1.12758 0.200557 1.16554 1.03868 2.02318L5.08822 6.16702L6.6901 7.8062L4.7096 9.83282L0.660067 13.9767C-0.185646 14.8421 -0.177618 14.8503 0.449847 15.492C0.458387 15.5008 0.467043 15.5096 0.475812 15.5186C1.10248 16.1599 1.13959 16.1826 1.97772 15.325L6.02725 11.1812L8.00775 9.15454L9.98861 11.1815L14.0381 15.3254C14.8828 16.1897 14.8908 16.1815 15.5176 15.5398L15.5436 15.5132C16.1699 14.8723 16.1922 14.8343 15.3551 13.9778L11.3045 9.83284L9.32469 7.80693L10.9276 6.16664L14.9772 2.02281C15.8218 1.15848 15.8138 1.15027 15.1864 0.508542C15.1778 0.499796 15.1692 0.490933 15.1604 0.481948C14.5337 -0.159313 14.4966 -0.182089 13.6595 0.67447L9.60893 4.81939L8.00703 6.4586L6.40516 4.81941Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    const configInsurance = useSelector(
      (state: any) => state.config?.insuranceModule
    );
    const insuranceEnabled = useSelector(
      (state: any) => state.insurance?.insuranceEnabled
    );
    const insuranceLoading = useSelector(
      (state: any) => state.insurance?.loading
    );
    const removeInsuranceFunction = useSelector(
      (state: any) => state.config?.insuranceModule?.removeInsuranceBenefits
    );
    const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
    const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
    const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
    const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);

    const isInsurable = () => {
      return !!configInsurance;
    };

    useEffect(() => {
      setIsInsuranceEnabled(insuranceEnabled);
    }, [insuranceEnabled]);

    useEffect(() => {
      setIsInsuranceLoading(insuranceLoading);
    }, [insuranceLoading]);

    const handleInsuranceClick = () => {
      if (configInsurance) {
        if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
          configInsurance.openInsurancePanel();
        } else if (
          isInsuranceEnabled &&
          configInsurance.removeInsuranceBenefits
        ) {
          configInsurance.removeInsuranceBenefits();
        }
      }
    };

    return (
      <div
        className={
          "MainHeader__containerMobile" +
          (isInsuranceEnabled && removeInsuranceFunction
            ? " insuranceEnabled"
            : "")
        }
      >
        <BrandComponent componentName="HeaderProgressBar" />
        <div
          id={"MainHeader__content"}
          className={
            "MainHeader__content" +
            (isInsuranceEnabled && removeInsuranceFunction
              ? " insuranceEnabled"
              : "")
          }
        >
          <div
            className={
              "MainHeader__left" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
          >
            <a
              className={
                "MainHeader__left__back" +
                " " +
                (!currentStep || currentStep?.progressive <= 1
                  ? "MainHeader__left__back_invisible"
                  : "")
              }
              onClick={() => handleBack()}
              data-element-id={getDataElementIdForBack()}
              data-description={getDataDescriptionForBack()}
            >
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.28803 13.495L9.91847 12.5167C8.77716 10.5874 7.41848 9.03847 5.86957 7.95152H20V6.04934H5.86957C7.41848 4.96238 8.77716 3.41347 9.91847 1.48412L8.28803 0.505859C6.16846 4.11999 3.26087 6.04934 0 6.04934V7.95152C3.26087 7.95152 6.16846 9.88086 8.28803 13.495Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
          <div
            id={"MainHeader__center"}
            className={
              "MainHeader__center" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "") +
              (showFramePlusLensesPrice && currentStep?.progressive <= lensBundleStep && currentStep?.key !== "AdvancedPrescription"
                ? " hideHeader"
                : ""
              )
            }
          >
            <BrandComponent componentName="HeaderPrice" />
            {isInsurable() && !isInsuranceEnabled && (
              <a
                className="MainHeader__insurance"
                onClick={handleInsuranceClick}
                data-element-id={getDataElementIdForInsurance()}
              >
                {addInsuranceLabel}
              </a>
            )}
            {isInsuranceEnabled && (
              <div
                className={"MainHeader__insurance__insuranceEnabled"}
                data-element-id={getDataElementIdForInsurance()}
              >
                {insuranceSyncedLabel}
                <BrandComponent
                  componentName="InsuranceTooltip"
                  parameter={{ id: "tooltipMobile", displayBelow: true }}
                />
              </div>
            )}
            {isInsuranceEnabled && removeInsuranceFunction && (
              <span
                className="MainHeader__removeInsuranceLabel"
                onClick={handleInsuranceClick}
              >
                {insuranceLabelRemove}
              </span>
            )}
          </div>
          <a
            className={
              "MainHeader__right" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.80392 3.61457L1.76672 0.506694C1.13243 -0.142361 1.12641 -0.136204 0.656289 0.345093C0.64989 0.351644 0.643405 0.358283 0.636831 0.36501C0.167077 0.845695 0.150395 0.874164 0.779001 1.5174L3.8162 4.62528L5.01769 5.85472L3.53233 7.37465L0.49513 10.4825C-0.139164 11.1316 -0.133144 11.1377 0.337462 11.6191L0.356937 11.639C0.826945 12.1199 0.854779 12.137 1.48338 11.4938L4.52058 8.3859L6.00594 6.86597L7.49155 8.38616L10.5288 11.494C11.1623 12.1423 11.1683 12.1361 11.6384 11.6548C11.6448 11.6483 11.6513 11.6416 11.6578 11.6349C12.1276 11.1542 12.1443 11.1258 11.5165 10.4833L8.47848 7.37464L6.99366 5.85527L8.19594 4.62502L11.2331 1.51714C11.8666 0.868898 11.8606 0.86274 11.39 0.381441C11.3836 0.374882 11.3771 0.368234 11.3705 0.361496C10.9005 -0.11945 10.8727 -0.136532 10.2449 0.505887L7.20689 3.61458L6.00541 4.84402L4.80392 3.61457Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderDesktop()}
      {renderMobile()}
    </>
  );
}
