import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { groupBy } from "lodash";
import {
  useCurrentBrand,
  usePriceStepItem,
  useRetriveContextualMessage,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { useBundles, useAllCurrentPackages } from "@hooks";
import {
  useConfigContent,
  useCurrentType,
  useEnableBrandLastStep,
} from "../../hooks";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
import { configObjectToSliderObject } from "../../adapters/ConfigObjectToSliderObject";

export function LensBundle(props: StepComponentOptions) {
  const currentType = useCurrentType();
  const translate = useTranslation();
  const ctxMessage = useRetriveContextualMessage();
  const getPriceStep = usePriceStepItem();
  const currentPackages = useAllCurrentPackages();

  const configBundles = useConfigContent("LensBundle");
  const { bundles } = useBundles();
  const [filteredBundles, setFilteredBundles] = useState([]);

  const configBrand: any[] = useConfigContent("Brand");
  const brandLastStep = useEnableBrandLastStep();
  const [brandsAvailable, setBrandsAvailable] = useState(null);
  const [brandSelected, setBrandselected] = useState(null);
  const [brandedBundles, setBrandedBundles] = useState(null);
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.lensBundle.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.lensBundle.learnMoreCTA");

  const onBundleSelectedFromStep = (id, skipped) => {
    onBundleSelected({ id, ...getPriceStep({ lensBundle: id }) }, skipped);
  };

  const onBundleSelected = (
    bundle: { id; price; discount; insPrice },
    skipped: boolean
  ) => {
    workflow.goNextStep(
      bundle?.id
        ? [
            ...(skipped ? workflow.currentStep.params : []),
            {
              key: "lensBundle",
              value: bundle.id,
              price: bundle.price,
              discount: bundle.discount,
              insPrice: skipped && bundle.price == 0 ? 0 : bundle.insPrice,
              attributes: {
                showSeeDetails: true,
              },
            },
          ]
        : [],
      skipped
    );
  };

  const checkMultipleBrands = () => {
    if (brandsAvailable?.length > 1 && currentType === "SUNGLASSES") {
      return true;
    }
    return false;
  };

  const handleBrandCallback = (callbackValue) => {
    setBrandselected(callbackValue);
  };

  useEffect(() => {
    if (configBrand && currentPackages) {
      const currentBrands = configBrand?.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.brand === c.id
        )
      );

      if (currentBrands && currentBrands.length) {
        if (currentBrands.length) {
          setBrandsAvailable(
            configObjectToSliderObject.adaptList(currentBrands)
          );
        }
      }

      const bundlesCard = getBundles();
      setFilteredBundles(bundlesCard);

      getBundlesKeys(bundlesCard);
    }
  }, [configBrand, currentPackages, brandSelected]);

  const getBundles = () => {
    const tempBundle = currentPackages.validRXPackages.filter((cp) => {
      const hasBundle = cp.lensPackage.lensBundle;
      const brandCondition =
        !brandSelected ||
        brandSelected === "All" ||
        cp.lensPackage.brand === brandSelected;
      return hasBundle && brandCondition;
    });

    if (!brandSelected) {
      const defaultBrandBundles = tempBundle.filter(
        (cp) => cp.lensPackage.brand === configBrand[0].id
      );
      return Object.entries(
        groupBy(defaultBrandBundles, "lensPackage.brand")
      ).map(([key, value]) => {
        let groupedByBundles = null;
        if (key != null) {
          if (value) {
            groupedByBundles = groupBy(value, "lensPackage.lensBundle");
          }
        }
        return groupedByBundles;
      });
    }

    return Object.entries(groupBy(tempBundle, "lensPackage.brand")).map(
      ([key, value]) => {
        let groupedByBundles = null;
        if (key != null) {
          if (value) {
            groupedByBundles = groupBy(value, "lensPackage.lensBundle");
          }
        }
        return groupedByBundles;
      }
    );
  };

  const getBundlesKeys = (bundlesCard: any) => {
    if (bundlesCard.length > 0) {
      let elements = bundlesCard[0];
      let keys = Object.keys(elements);
      let correctBundles = configBundles.filter((bundle: any) =>
        keys.some((key: string) => key === bundle.id)
      );
      if (correctBundles && correctBundles?.length > 0) {
        setBrandedBundles(correctBundles);
      }
    }
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        showStepTitle: true,
        title: checkMultipleBrands()
          ? translate("steps.brand.title")
          : translate("steps.lensBundle.title"),
        subtitle: checkMultipleBrands()
          ? translate("steps.brand.subtitle")
          : ctxMessage || translate("steps.lensBundle.subtitle"),
        slider: checkMultipleBrands() ? brandsAvailable : null,
        brandCallback: handleBrandCallback,
      }}
    >
      {learnMoreButton !== "steps.lensBundle.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensBundle-LearnMore"
            }
          >
            {moreInfoLabel !== "" &&
            moreInfoLabel !== undefined &&
            moreInfoLabel !== null &&
            moreInfoLabel !== "steps.lensBundle.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"LensBundle__container"}>
        {checkMultipleBrands() && (
          <BrandComponent
            componentName="StepTitle"
            parameter={{
              showStepTitle: true,
              title: translate("steps.lensBundle.title"),
              subtitle: ctxMessage || translate("steps.lensBundle.subtitle"),
              //slider: checkMultipleBrands() ? brandsAvailable : null,
            }}
          />
        )}

        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "LensBundle",
            onNext: (selected: any, skipped?: boolean) => {
              onBundleSelectedFromStep(selected?.id, skipped);
            },
            parameterPreselection: ["lensBundle"],
            onPreselect: (selected, skipped: boolean) => {
              onBundleSelectedFromStep(selected?.lensBundle, skipped);
            },
            ignoreSkip: true,
          }}
        >
          {checkMultipleBrands() && brandedBundles
            ? brandedBundles.map((bundle, i) => (
                <BrandComponent
                  key={bundle.id}
                  componentName="StepCard"
                  parameter={{
                    ...bundle,
                    onClick:
                      bundle?.badge == "coming soon" || bundle?.disabled
                        ? null
                        : () => onBundleSelected(bundle, false),
                    showSavingsLabel: true,
                    ignorePriceLabels: false,
                  }}
                />
              ))
            : bundles
                .filter(
                  (b) =>
                    b.price !== null && b.price !== undefined && b.price >= 0
                )
                .map((bundle, i) => (
                  <BrandComponent
                    key={bundle.id}
                    componentName="StepCard"
                    parameter={{
                      ...bundle,
                      onClick:
                        bundle?.badge == "coming soon" || bundle?.disabled
                          ? null
                          : () => onBundleSelected(bundle, false),
                      showSavingsLabel: true,
                      ignorePriceLabels: false,
                    }}
                  />
                ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
