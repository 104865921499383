import React, { useState, useEffect } from "react";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { useSelector } from "react-redux";
import Image from "../common/Image";
import "./default.module.scss";
import { useLensPreviewFallback } from "@hooks";

export const LensPreviewFallback = ({
  selectedColor,
  configColor,
  isLoading,
}) => {
  const { brandR } = useLensPreviewFallback();
  const colorCatTitle = useSelector((state: any) => state.color.colorCategory);
  const colorCatDesc = useSelector((state: any) => state.color.colorDesc);
  const [loaded, setLoaded] = useState(false);
  const translation = useTranslation();
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  }, []);

  if (selectedColor && configColor) {
    let finded = (({
      title,
      longTitle,
      lightTransmission,
      baseLensColor,
      contrast,
      lightConditions,
    }) => ({
      title,
      longTitle,
      lightTransmission,
      baseLensColor,
      contrast,
      lightConditions,
    }))(configColor.find((c) => c.id === selectedColor));
    const fullFindedColor = configColor.find((c) => c.id === selectedColor);
    const { title, ...valuesToShow } = finded;
    const hasValues = Object.values(valuesToShow).some(
      (property) =>
        property !== null && property !== undefined && property !== 0
    );
    !Object.values(finded).every(
      (property) =>
        property !== null && property !== undefined && property !== 0
    ) && delete finded.title;
    if (!isLoading && loaded) {
      return brandR ? (
        <>
          <div className={"LensPreviewFallback__btn_container__detailBox"}>
            <div className="LensPreviewFallback__btn_container__detailBox__content">
              <div
                className={`LensPreviewFallback__btn_container__detailBox__content__textContainer`}
              >
                <div
                  className={`LensPreviewFallback__btn_container__detailBox__content__textContainer__color`}
                >
                  {selectedColor}
                </div>
                <div
                  className={`LensPreviewFallback__btn_container__detailBox__content__textContainer__message`}
                >
                  {translation("steps.lensColor.detailBox.fallbackMessage")}
                </div>
              </div>
              <div className="LensPreviewFallback__btn_container__detailBox__content__imageContainer">
                <Image
                  imageClass={"LensColorCard__container__card__img"}
                  url={configProjectImage}
                  fileName={fullFindedColor.imageFileName1}
                // alt={props.color.name}
                // id={props.color.value}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="LensPreviewFallback__btn_container__doubleLayout">
          <div className={"LensPreviewFallback__btn_title"}>
            <div className="LensPreviewFallback__btn_title__wrapper">
              <div className="LensPreviewFallback__btn_title__wrapper__name">
                {selectedColor}
              </div>
              <div className="LensPreviewFallback__btn_title__wrapper__desc">
                {translation("steps.lensColor.detailBox.fallbackMessage")}
              </div>
              <Image
                imageClass={"LensPreviewFallback__btn_image"}
                url={configProjectImage}
                fileName={fullFindedColor.imageFileName1}
              // alt={props.color.name}
              // id={props.color.value}
              />
            </div>
          </div>

          <div className="LensPreviewFallback__btn_container__doubleLayout__wrapper__separator" />

          <div className="LensPreviewFallback__btn_container__doubleLayout__right">
            <div className="LensPreviewFallback__btn_container__doubleLayout__right__colorCategory">
              {colorCatTitle}
            </div>
            {/* <div className="LensPreviewFallback__btn_container__doubleLayout__right__desc">
              {colorCatDesc}
            </div> */}
            {hasValues ? (
              <>
                <div
                  className={
                    "LensPreviewFallback__btn_container__detailBox__content__wrapper"
                  }
                >
                  {Object.keys(finded).map((property, index) => {
                    return (
                      finded[property] !== undefined &&
                      finded[property] !== null && (
                        <div
                          className={`LensPreviewFallback__btn_container__detailBox__content__textContainerRight ${property}`}
                          style={{
                            order: index,
                          }}
                        >
                          {!["longTitle", "title"].includes(property) && (
                            <div
                              className={
                                "LensPreviewFallback__btn_container__detailBox__content__textContainerRight__text"
                              }
                            >
                              {translation(
                                `steps.lensColor.detailBox.${property}`
                              )}
                              :
                            </div>
                          )}
                          <div
                            className={`LensPreviewFallback__btn_container__detailBox__content__textContainerRight__inner ${property === "longTitle" ? "description" : ""
                              }`}
                          >
                            {finded[property] +
                              (property === "lightTransmission" ? "%" : "")}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        </div>
        // <>
        //   {
        //     <>
        //       <div className={"LensPreviewFallback__btn_title"}>
        //         <div className="LensPreviewFallback__btn_title__wrapper">
        //           {selectedColor}
        //           <Image
        //             imageClass={"LensPreviewFallback__btn_image"}
        //             url={configProjectImage}
        //             fileName={fullFindedColor.imageFileName1}
        //             // alt={props.color.name}
        //             // id={props.color.value}
        //           />
        //         </div>
        //       </div>
        //     </>
        //   }
        //   {hasValues ? (
        //     <>
        //       <div className={"LensPreviewFallback__btn_container__detailBox"}>
        //         <div className="LensPreviewFallback__btn_container__detailBox__content">
        //           {Object.keys(finded).map((property, index) => {
        //             return (
        //               finded[property] !== undefined &&
        //               finded[property] !== null && (
        //                 <div
        //                   className={`LensPreviewFallback__btn_container__detailBox__content__textContainer ${property}`}
        //                   style={{
        //                     order: index,
        //                   }}
        //                 >
        //                   {!["longTitle", "title"].includes(property) && (
        //                     <div
        //                       className={
        //                         "LensPreviewFallback__btn_container__detailBox__content__textContainer__text"
        //                       }
        //                     >
        //                       {translation(
        //                         `steps.lensColor.detailBox.${property}`
        //                       )}
        //                       :
        //                     </div>
        //                   )}
        //                   <div
        //                     className={`LensPreviewFallback__btn_container__detailBox__content__textContainer__inner ${
        //                       property === "longTitle" ? "description" : ""
        //                     }`}
        //                   >
        //                     {finded[property]}
        //                   </div>
        //                 </div>
        //               )
        //             );
        //           })}
        //         </div>
        //       </div>
        //     </>
        //   ) : null}
        // </>
      );
    }
  }
  return null;
};
