import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";
import GenericButton from "../common/GenericButton";
import { modalsActions } from "../../redux/slices/modals";
import { configActions } from "../../redux/slices/config";
import { workflow } from "../../configurator/models/Workflow";
import { fold } from "fp-ts/lib/Either";
import { Step } from "../../configurator/models/Step";


export function InsuranceBundlesModal({ }) {
  const title = useTranslate("insuranceBundlesModal.title");
  const titleRemove = useTranslate("insuranceBundlesModal.titleRemove");
  const subtitle = useTranslate("insuranceBundlesModal.subtitle");
  const subtitleRemove = useTranslate("insuranceBundlesModal.subtitleRemove");

  const yesProceedWithIns = useTranslate("insuranceBundlesModal.yesProceedWithIns");
  const noRemove = useTranslate("insuranceBundlesModal.noRemove");

  const yesAdd = useTranslate("insuranceBundlesModal.yesAdd");
  const noProceedWithoutIns = useTranslate("insuranceBundlesModal.noProceedWithoutIns");

  const yesRemove = useTranslate("insuranceBundlesModal.yesRemove");
  const noProceedWithIns = useTranslate("insuranceBundlesModal.noProceedWithIns");

  const { show, applyInsurance, removeInsurance } = useSelector(
    (state: any) => state.modals?.insuranceBundlesModal
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );

  const reduxDispatch = useDispatch();

  const closeModal = () => {
    reduxDispatch(modalsActions.setInsuranceBundlesModal({ show: false }))
  }

  const handleYes = () => {
    closeModal();
    if (applyInsurance) {
      reduxDispatch(configActions.setIgnoreBundles(true))
      fold(
        () => { },
        (newStep: Step) => {
          workflow.currentStep = newStep;
        }
      )(workflow.backToStepByKey("Type"));
      configInsurance.openInsurancePanel();
    } else if (removeInsurance) {
      configInsurance.removeInsuranceBenefits();
      fold(
        () => { },
        (newStep: Step) => {
          workflow.currentStep = newStep;
        }
      )(workflow.backToStepByKey("Type"));
      reduxDispatch(configActions.setIgnoreBundles(false))
    } else {
      reduxDispatch(configActions.setIgnoreBundles(true))
    }
  }

  const handleNo = () => {
    closeModal();
    if (applyInsurance || removeInsurance) {
      // do nothing
    } else {
      configInsurance.removeInsuranceBenefits();
    }
  }

  if (!show) return null

  return (
    <div className={"InsuranceBundlesModal__container"}>
      <div className={"InsuranceBundlesModal__container__overlay"}></div>
      <div className={"InsuranceBundlesModal__container__popup"}>
        {removeInsurance || applyInsurance && (
          <div className="InsuranceBundlesModal__container__close" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_370_59678)">
                <path d="M23.7001 1.6998L22.3 0.299805L12 10.5998L1.70005 0.299805L0.300049 1.6998L10.6 11.9998L0.300049 22.2998L1.70005 23.6998L12 13.3998L22.3 23.6998L23.7001 22.2998L13.4 11.9998L23.7001 1.6998Z" fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_370_59678">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
        <div className={"InsuranceBundlesModal__container__title"}>{removeInsurance ? titleRemove : title}</div>

        <div className={"InsuranceBundlesModal__container__subtitle"}>
          {removeInsurance ? subtitleRemove : subtitle}
        </div>

        <div className={"InsuranceBundlesModal__container__buttonsContainer"}>
          <GenericButton
            noArrow={true}
            className={"InsuranceBundlesModal__container__buttonsContainer__buttonYes"}
            handleClick={handleYes}
          >
            {removeInsurance ? yesRemove : applyInsurance ? yesAdd : yesProceedWithIns}
          </GenericButton>
          <GenericButton
            noArrow={true}
            className={"InsuranceBundlesModal__container__buttonsContainer__buttonNo"}
            handleClick={handleNo}
          >
            {removeInsurance ? noProceedWithIns : applyInsurance ? noProceedWithoutIns : noRemove}
          </GenericButton>
        </div>
      </div>
    </div >
  )
}