import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";

interface TooltipProps {
  title: string;
  body: string;
  x: number;
  y: number;
  elementWidth: number;
}

/**
 * This component displays a tooltip over a specific area passed as input. It uses the
 * ```hideTooltip``` state variable from redux to show and hide itself. It accepts an
 * input of type ```TooltipProps``` with the following parameters:
 *  - ```title```: the title of the tooltip
 *  - ```body```: the text content of the tooltip
 *  - ```x```: the x coordinate of the element over which the tooltip needs to be displayed
 *  - ```y```: the y coordinate of the element over which the tooltip needs to be displayed
 *  - ```elementWidth```: The width in pixels of the element over which the tooltip needs to
 * be displayed. It is needed to place the arrow in the middle of the element
 */
export function Tooltip({ title, body, x, y, elementWidth }: TooltipProps) {
  /** Redux variable that stores the flag for the tooltip visibility */
  const hideTooltipRedux = useSelector(
    (state: any) => state.tooltip?.hideTooltip
  );
  const [hideTooltip, setHideTooltip] = useState(true);

  useEffect(() => {
    setHideTooltip(hideTooltipRedux);
  }, [hideTooltipRedux]);

  return (
    !hideTooltip && (
      <div
        className={"Tooltip__body"}
        style={{ left: x + elementWidth / 2, top: y }}
      >
        <div className={"Tooltip__body__title"}>{title}</div>
        <div className={"Tooltip__body__text"}>{body}</div>
        <div className={"Tooltip__arrowContainer"}>
          <div className={"Tooltip__arrowContainer__arrow"} />
        </div>
      </div>
    )
  );
}
