import { Eq } from "fp-ts/lib/Eq";
import { IParameter } from "../meta/IParameter";

export class AutomaticDiscountParameter implements IParameter<string, any> {
  step: {
    key: string;
    progressive: number;
  };
  key: string;
  value: any;
  equal?: Eq<IParameter<string, any>> = {
    equals: (p1, p2) => p1.key === p2.key && p1.value === p2.value,
  };

  constructor(
    key: string,
    value: any,
    step: { key: string; progressive: number }
  ) {
    this.key = key;
    this.value = value;
    this.step = step;
  }
}
