import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import "./default.module.scss";
import { prescriptionActions } from "../../redux/slices/prescription";

interface PrescriptionModalContinueOpt {}

export const PrescriptionModalContinue = ({}: PrescriptionModalContinueOpt) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const showContinueModal = useSelector(
    (state: any) => state.modals?.showPrescriptionModalContinue
  );
  const reduxDispatch = useDispatch();

  const continueButton = useTranslate(
    "steps.advancedPrescription.manual.continueModal.continue"
  );
  const cancelButton = useTranslate(
    "steps.advancedPrescription.manual.continueModal.cancel"
  );
  const title = useTranslate(
    "steps.advancedPrescription.manual.continueModal.title"
  );
  const message = useTranslate(
    "steps.advancedPrescription.manual.continueModal.message"
  );
  const reviewPrescriptionValue = useTranslate(
    "steps.advancedPrescription.later.review.title"
  );

  return (
    <>
      {showContinueModal && (
        <>
          <div className="PrescriptionModalContinue__overlay" />
          <div className="PrescriptionModalContinue__container">
            <div
              className="PrescriptionModalContinue__container__close"
              onClick={() =>
                reduxDispatch(
                  modalsActions.setShowPrescriptionModalContinue(false)
                )
              }
            ></div>
            <div className="PrescriptionModalContinue__container__title">
              {title}
            </div>
            <div className="PrescriptionModalContinue__container__text">
              {message}
            </div>
            <div className="PrescriptionModalContinue__container__buttons">
              <GenericButton
                id={"continueButton"}
                className={
                  "border-button " +
                  "PrescriptionModalContinue__container__continueButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalContinue"
                title={continueButton}
                tabIndex={0}
                handleClick={() => {
                  reduxDispatch(
                    modalsActions.setShowPrescriptionModalContinue(false)
                  );
                  reduxDispatch(
                    prescriptionActions.setCurrentPrescription(null)
                  );
                  if (
                    prescriptionModule &&
                    typeof prescriptionModule.clearExtendedPrescription ===
                      "function"
                  ) {
                    prescriptionModule.clearExtendedPrescription();
                  }
                  workflow.goNextStep(
                    [
                      {
                        key: "advancedPrescription",
                        value: reviewPrescriptionValue,
                      },
                    ],
                    false
                  );
                }}
              >
                {continueButton}
              </GenericButton>
              <GenericButton
                id={"cancelButton"}
                className={
                  "border-button " +
                  "PrescriptionModalContinue__container__cancelButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalCancel"
                title={cancelButton}
                tabIndex={0}
                handleClick={() =>
                  reduxDispatch(
                    modalsActions.setShowPrescriptionModalContinue(false)
                  )
                }
              >
                {cancelButton}
              </GenericButton>
            </div>
          </div>
        </>
      )}
    </>
  );
};
