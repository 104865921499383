import { isFunction } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { BrandComponent } from "../../components/common/BrandComponent";
import { getStepSelections } from "../ConfigLoader";
import { workflow } from "../models/Workflow";
import { useEditStep } from "../WorkflowUtils";
import { WorkflowStepFactory } from "./WorkFlowStepFactory";
import { useStepAlias } from "@hooks";

export function WorkflowStep() {
  const [historyStep, setHistoryStep] = useState(null);
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(false);
  const onEdit = useEditStep();
  const getAliasStep = useStepAlias();

  useEffect(() => {
    if (isFunction(getAliasStep)) getHistory();
  }, [currentStep, getAliasStep, onEdit]);

  useEffect(() => {
    if (!currentStep) return;

    if (workflow.isFirstStep() || workflow.isLastStep()) {
      setIsBreadcrumbVisible(false);
    } else {
      setIsBreadcrumbVisible(true);
    }
  }, [currentStep, onEdit]);

  const getHistory = () => {
    setHistoryStep(
      getStepSelections(getAliasStep).map((p) => ({
        id: p.key,
        title: p.value,
        onClick: onEdit,
      }))
    );
  };

  return (
    <>
      {isBreadcrumbVisible && (
        <BrandComponent
          componentName="StepBreadcrumb"
          parameter={{ pills: historyStep }}
        />
      )}
      <BrowserRouter>
        <Switch>
          <Route exact path={""} component={WorkflowStepFactory} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
