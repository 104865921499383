import { useState, useEffect, useCallback } from "react";
import useDelegatingLearnMore from "./useDelegatingLearnMore";
import { useTranslation } from "../translations/TranslationHooks";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";

export const useLearnMore = (currentStep, contentIdentifier) => {
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const configActionLoadLearnMore = useSelector(
    (state: any) => state.config?.actionsModule?.loadLearnMoreContent
  );
  const reduxDispatch = useDispatch();
  const translation = useTranslation();
  const learnMoreVisible = () => {
    return (
      (delegatingLearnMore && contentIdentifier) ||
      (configActionLoadLearnMore && contentIdentifier)
    );
  };
  const delegateLearnMoreCheck = () => {
    if (
      currentStep.toLowerCase() === "protectionplan" &&
      configActionLoadLearnMore &&
      contentIdentifier
    ) {
      reduxDispatch(modalsActions.setListLearnMoreModal([contentIdentifier]));
      reduxDispatch(modalsActions.setOpenLearnMoreModal(contentIdentifier));
    } else if (delegateLearnMoreContent && contentIdentifier) {
      delegateLearnMoreContent(contentIdentifier);
    }
  };

  const getMoreInfoTitle = () => {
    return translation(
      "steps.type.moreInfo"
    );
  };
  return { learnMoreVisible, delegateLearnMoreCheck, getMoreInfoTitle };
};
