import React, { FC, ReactElement } from "react";

type InputProps = {
  isValidTick: boolean;
  isError: boolean;
  label: string | ReactElement;
  labelClassName?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "className">;

const Input: FC<InputProps> = ({
  isValidTick,
  isError,
  label,
  labelClassName,
  ...props
}) => {
  return (
    <label
      className={
        isValidTick
          ? `input ${labelClassName} valid`
          : `input ${labelClassName}`
      }
    >
      <span className="input__label">{label}</span>
      <input
        className={
          isValidTick
            ? "input__field green"
            : !isError
            ? "input__field"
            : "input__field error"
        }
        {...props}
      />
    </label>
  );
};

export default Input;
