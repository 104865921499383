import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./RootReducer";

export default function StoreFactory({ initState, labels }) {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
    preloadedState:
      {
        ...initState,
        translation: {
          labels: labels,
        },
      } || {},
  });
}
