import React from "react";
import "./default.module.scss";
import { StepBadgeType } from "./types/StepBadgeType";

interface StepBadgeOpt {
  type: StepBadgeType;
  text?: string;
}

/**
 * Component that displays a badge on top of a card. It accepts an input of type
 * ```StepBadgeOpt``` with the following parameters:
 *  - ```type```: parameter of type ```StepBadgeOpt```, which can be:
 *      * ```BEST_SELLER```
 *      * ```BEST_PRESCRIPTION```
 *      * ```MOST_POPULAR```
 *      * ```IS_NEW```
 *      * ```CUSTOM```
 *      * ```RECOMMENDED```
 *  - ```text``` (_optional_): the text to be displayed in the badge. If not provided,
 * a default text will be used.
 */
export function StepBadge({ type, text }: StepBadgeOpt) {
  const getStarBadge = () => {
    if (text?.includes("*")) {
      text = text?.replace("*", "");
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={"StepBadge__container" + (getStarBadge() ? "__star" : "")}>
      {type === StepBadgeType.BEST_PRESCRIPTION && (
        <div className={"StepBadge__badge_bestPrescription"}>
          {
            //TODO translate
            text || "Best prescription tot"
          }
        </div>
      )}

      {type === StepBadgeType.CUSTOM && (
        <div className={"StepBadge__badge_mostPopular"}>{text}</div>
      )}
      {type === StepBadgeType.RECOMMENDED && (
        <div className={"StepBadge__badge_recommended"}>{text}</div>
      )}
      {type === StepBadgeType.BEST_SELLER && (
        <div className={"StepBadge__badge_mostPopular"}>
          {
            //TODO translate
            "✰ Most Popular tot"
          }
        </div>
      )}

      {type === StepBadgeType.IS_NEW && (
        <div className={"StepBadge__badge_mostPopular"}>
          {
            //TODO translate
            "new Product tot"
          }
        </div>
      )}

      {type === StepBadgeType.COMING_SOON && (
        <div className={"StepBadge__badge_comingSoon"}>
          {
            //TODO translate
            "In store only"
          }
        </div>
      )}
    </div>
  );
}
