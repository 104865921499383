import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { useEnableAgreement, usePupillaryDistance } from "@hooks";
import "./default.module.scss";
import { AgreementCheckbox } from "../FullPrescriptionForm/components/AgreementCheckbox";

export interface PrescriptionUploadFormProps {
  fileInput: any;
  closePrescriptionForm: () => {};
  onSuccessPrescription: (prescription: any) => {};
  prescriptionObject: any;
}

const Subtitle = () => {
  const uploadSubtitle = useTranslate(
    "steps.advancedPrescription.upload.subtitle"
  );
  return (
    <>
      <div className={"PrescriptionUploadForm__subtitle"}>{uploadSubtitle}</div>
    </>
  );
};

export const UploadResult = (props) => {
  return (
    <div
      className={
        "PrescriptionUploadForm__UploadResult__container" +
        (props.isSuccessful ? " succ" : " error")
      }
    >
      {props.isSuccessful && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__successText">
            {props.message}
          </div>
        </>
      )}
      {!props.isSuccessful && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__unsuccessfulIconContainer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#CA3300" />
              <path
                fill="#fff"
                stroke="#fff"
                d="m4.936 5.408.471-.472 5.657 5.657-.471.472z"
              />
              <path
                fill="#fff"
                stroke="#fff"
                d="m10.593 4.936.471.472-5.657 5.657-.471-.472z"
              />
            </svg>
          </div>
          <div
            className={
              "PrescriptionUploadForm__UploadResult__errorText" +
              (props.uppercase ? " uppercase" : "")
            }
          >
            {props.message}
          </div>
        </>
      )}
    </div>
  );
};

export const FilePreview = (props) => {
  const reduxDispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);

  const fallBackImageUrl =
    "https://assets.lenscrafters.com/extra/image/LensCrafters/projects/202005-rxreview/LC_UploadOK_Icon.png";

  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );

  useEffect(() => {
    if (props.forceFileInfo) {
      setFileData({
        url: props.forceFilePath,
        type: props.forceFileInfo.type,
        name: props.forceFileInfo.name,
        size: props.forceFileInfo.size,
      });
      if (props.forceFilePath) {
        setIsPreviewEnabled(true);
      }
    }
  }, [props.forceFileInfo, props.forceFilePath]);

  const handleFileChange = (file: any) => {
    const fileReader = new window.FileReader();
    fileReader.onload = (fileLoad) => {
      const { result } = fileLoad.target;
      setFileData({
        url: result,
        type: file.type.split("/")[1],
        name: file.name,
        size: file.size,
      });
      setIsPreviewEnabled(true);
    };
    fileReader.readAsDataURL(file);
  };

  const getSizeInMB = (size) => {
    return "[" + (parseInt(size) / 1024 / 1024).toFixed(2) + "MB]";
  };

  const setErrorImage = (e: any) => {
    setIsPreviewEnabled(false);
    e.target.src = fallBackImageUrl;
  };

  return (
    fileData && (
      <div className="PrescriptionUploadForm__FilePreview__container">
        <div
          className={
            "PrescriptionUploadForm__FilePreview__container__filePreview" +
            (isPreviewEnabled ? "" : " noPreview")
          }
          onClick={() => {
            if (isPreviewEnabled) {
              reduxDispatch(
                modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
              );
              reduxDispatch(
                prescriptionActions.setUploadFileDataPreview(
                  props.forceFilePath ? props.forceFilePath : fileData.url
                )
              );
            }
          }}
        >
          {props.forceFilePath && (
            <img
              src={props.forceFilePath}
              alt="Document"
              onError={setErrorImage}
            />
          )}
          {!props.forceFilePath && (
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 30, height: 30 },
              }}
            />
          )}
        </div>
        <div className="PrescriptionUploadForm__FilePreview__container__fileInfo">
          <div className="PrescriptionUploadForm__FilePreview__container__fileInfo__nameAndSize">
            {fileData.name + " - " + getSizeInMB(fileData.size)}
          </div>
          <div
            className="PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent"
            onClick={() => {
              props.handleUploadAgain();
            }}
          >
            {uploadDifferentFile}
          </div>
        </div>
      </div>
    )
  );
};

export const UploadError = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadError__container">
      <div className="PrescriptionUploadForm__UploadError__container__message">
        {props.message}
      </div>
      <div
        role={"button"}
        className="PrescriptionUploadForm__button"
        onClick={() => props.onButtonClick()}
      >
        {props.buttonText}
      </div>
    </div>
  );
};

const UploadBox = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);

  const changeMethod = useTranslate(
    "steps.advancedPrescription.upload.changeMethod"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  useEffect(() => {
    //TODO chiamata
    uploadFile(props.file);
  }, [props.file]);

  const getFileTypeFromFullName = (fileName: string) => {
    if (fileName) {
      let split = fileName.split(".");
      let lastElem = split[split.length - 1];
      return "." + lastElem;
    }
  };

  useEffect(() => {
    if (
      props.preloadedPrescription &&
      props.preloadedPrescription.fileName &&
      props.preloadedPrescription.fileSize &&
      props.preloadedPrescription.filePath &&
      props.preloadedPrescription.savedFileName
    ) {
      setIsUploading(false);
      setIsSuccessful(true);
      setPreloadedFileInfo({
        name: props.preloadedPrescription.fileName,
        size: parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(props.preloadedPrescription.fileName),
      });
      props.onFileChange({
        name: props.preloadedPrescription.fileName,
        filePath: props.preloadedPrescription.filePath,
        fileSize:
          parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: props.preloadedPrescription.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
  }, [props.preloadedPrescription]);

  useEffect(() => {
    let update = isSuccessful && !isUploading;
    props.onUploadSuccessStateChange(update);
  }, [isSuccessful, isUploading]);

  const uploadFile = (file) => {
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (checkFileExtension(file)) {
        if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
          const fileReader = new window.FileReader();
          fileReader.onload = (fileLoad) => {
            const { result } = fileLoad.target;
            let requestObj = {
              fileName: file.name,
              fileData: result,
            };
            prescriptionModule
              .uploadExtendedPrescription(requestObj)
              .then((res) => {
                props.onFileChange({
                  name: file.name,
                  savedFileName: res.savedFileName,
                  fileSize: file.size,
                });
                props.onPrescriptionIdReceived(res.prescriptionId);
                setIsFileTooBigError(false);
                setIsSuccessful(true);

                setPreloadedFileInfo({
                  name: file.name,
                  size: file.size,
                  type: getFileTypeFromFullName(file.name),
                });

                setIsUploading(false);

                if (prescriptionModule.downloadExtendedPrescription) {
                  prescriptionModule
                    .downloadExtendedPrescription({
                      savedFileName: res.savedFileName,
                    })
                    .then((res) => {
                      setFilePath(res.fileData);
                    })
                    .catch((err) => {
                      setFilePath("");
                    });
                } else {
                  console.error("Missing downloadExtendedPrescriptionFunction");
                  setFilePath("");
                }
              })
              .catch((err) => {
                setIsSuccessful(false);
                setIsUploading(false);
                setIsFileTooBigError(false);
              });
          };
          fileReader.readAsDataURL(file);
        }
      }
      else {
        setIsSuccessful(false);
        setIsUploading(false);
      }
    }
  };

  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      } else {
        if (file.name.indexOf(".") > 0) {
          let ext = file.name.split(".")[file.name.split(".").length - 1];
          if (prescriptionModule && prescriptionModule.fileExtensions) {
            if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
              try {
                //@ts-ignore
                window.tealium_data2track.push({
                  id: "Error",
                  Error_Source: "User",
                  Error_Code: "RX Configurator: prescription upload",
                  Error_Message: tryAgain,
                });
              } catch (error) {
                console.error(
                  "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
                );
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const checkFileExtension = (file) => {
    if (file.name.indexOf(".") > 0) {
      let ext = file.name.split(".")[file.name.split(".").length - 1];
      if (prescriptionModule && prescriptionModule.fileExtensions) {
        if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Source: "User",
              Error_Code: "RX Configurator: prescription upload",
              Error_Message: tryAgain,
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext.toUpperCase())
      );
      return parsedExt.join();
    }
  };

  const handleFileChange = (file: any) => {
    setFilePath(null);
    uploadFile(file);
  };

  const handleUploadErrorButtonClick = () => {
    let inputFile = document.getElementById("PrescriptionUploadInputId");
    if (inputFile) {
      inputFile.click();
    }
  };

  return (
    <>
      <div
        className={
          "PrescriptionUploadForm__UploadBox__container" +
          (!isSuccessful && !isUploading ? " error" : "")
        }
      >
        {isUploading && (
          <div className="PrescriptionUploadForm__UploadBox__container__spinnerContainer">
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
              }}
            />
          </div>
        )}
        {!isUploading && (
          <>
            <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
              <UploadResult
                isSuccessful={isSuccessful}
                uppercase={isSuccessful || isFileTooBigError ? true : false}
                message={
                  isSuccessful
                    ? prescriptionUploaded
                    : isFileTooBigError
                      ? fileTooBigErrorTitle
                      : somethingWentWrong
                }
              />
              <div
                className="PrescriptionUploadForm__UploadBox__container__changeMethod"
                onClick={() => props.closePrescriptionForm()}
              >
                {changeMethod}
              </div>
            </div>
            <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
              {isSuccessful && (
                <FilePreview
                  handleUploadAgain={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                  forceFilePath={filePath}
                  forceFileInfo={preloadedFileInfo}
                />
              )}
              {!isSuccessful && (
                <UploadError
                  message={
                    isFileTooBigError
                      ? fileTooBigErrorDescription.replace(
                        "###FILE_SIZE###",
                        prescriptionModule?.maxFileSize
                          ? prescriptionModule?.maxFileSize
                          : 10
                      )
                      : tryAgain
                  }
                  buttonText={isFileTooBigError ? uploadDifferentFile : upload}
                  onButtonClick={handleUploadErrorButtonClick}
                />
              )}
            </div>
            <div
              className="PrescriptionUploadForm__UploadBox__container__changeMethodMobile"
              onClick={() => props.closePrescriptionForm()}
            >
              {changeMethod}
            </div>
          </>
        )}
      </div>
      <input
        id="PrescriptionUploadInputId"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={getSupportedFileExtensions()}
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export function PrescriptionUploadForm({
  fileInput,
  closePrescriptionForm,
  onSuccessPrescription,
  prescriptionObject,
}: PrescriptionUploadFormProps) {
  const saveExtendedPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const [fileInfoForSave, setFileInfoForSave] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showContinueButton, setShowContinueButton] = useState(false);
  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const confirmAndContinue = useTranslate(
    "steps.advancedPrescription.upload.confirmAndContinue"
  );
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enableAgreementCheckbox = useEnableAgreement();

  const getDataElementIdForConfirm = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-ValuesCompatibles";
  };

  const CustomCheckbox = (props) => {
    const isChecked = props.value
      ? typeof props.value === "boolean"
        ? props.value
        : props.value.value
      : false;

    return (
      <div
        tabIndex={0}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName ? " error" : "") +
          (props.marginTop ? " marginTop" : "")
        }
        onClick={() => {
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: false,
            });
          }
        }}
      ></div>
    );
  };

  const handleSubmitPrescription = () => {
    if (!PD.state.checkPDSelected()) return;

    //Agreement validation
    if (enableAgreementCheckbox) {
      if (!agreement) {
        setFormErrors(errors => ({ ...errors, agreement: true }))
        return
      }
    }

    if (
      saveExtendedPrescription &&
      typeof saveExtendedPrescription === "function"
    ) {
      let fileSize = fileInfoForSave?.fileSize
        ? (parseInt(fileInfoForSave.fileSize) / 1024 / 1024).toFixed(2)
        : 0;
      let submitObj = {
        prescriptionFlow: "UPLOAD",
        prescriptionId: prescriptionId
          ? prescriptionId
          : previousPrescription
            ? previousPrescription.prescriptionId
            : null,
        fileName: fileInfoForSave?.name,
        savedFileName:
          fileInfoForSave && fileInfoForSave.savedFileName
            ? fileInfoForSave.savedFileName
            : previousPrescription.savedFileName,
        fileSize: fileSize,
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      saveExtendedPrescription(submitObj)
        .then((res) => {
          onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Subtitle />
      <UploadBox
        preloadedPrescription={prescriptionObject}
        onUploadSuccessStateChange={(isSuccessful) =>
          setShowContinueButton(isSuccessful)
        }
        onFileChange={(fileInfo) => setFileInfoForSave(fileInfo)}
        onPrescriptionIdReceived={(id) => setPrescriptionId(id)}
        closePrescriptionForm={closePrescriptionForm}
        file={fileInput}
      />
      <div className="PrescriptionUploadForm__Separator"></div>
      <BrandComponent
        componentName="PupillaryDistance"
        parameter={{
          PD1: PD.state.PD1,
          PD2: PD.state.PD2,
          showBothPdOptions: PD.state.showBothPdOptions,
          showPDWarning: PD.state.showPDWarning,
          onPD1Change: PD.state.setPD1,
          onPD2Change: PD.state.setPD2,
          setShowBothPdOptions: PD.state.setShowBothPdOptions,
          pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
          CustomCheckbox: CustomCheckbox,
        }}
      />
      {enableAgreementCheckbox && (
        <div className="PrescriptionUploadForm__agreement">
          <AgreementCheckbox
            agreement={agreement}
            setAgreement={setAgreement}
            formErrors={formErrors}
            tooltipText={tooltipText}
            uploadPrescription
          />
        </div>
      )}
      {showContinueButton && (
        <div className="PrescriptionUploadForm__submitContainer">
          <button
            className="PrescriptionUploadForm__submitButton"
            onClick={handleSubmitPrescription}
            data-element-id={getDataElementIdForConfirm()}
          >
            {confirmAndContinue}
          </button>
        </div>
      )}
    </>
  );
}
