import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import {
  getCorrectAnalyticsStepContainerName,
  isBundlePriceEnabled,
} from "../../configurator/ConfigLoader";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useCurrentPrices, useImagery, useShowFramePlusLensesPrice, useShowSavingsLabel } from "@hooks";

interface BottomBarProps { }

/**
 * The component handling the logic of the bottom bar in the desktop view. It uses the
 * ```useCurrentPrices()``` hook to access the current prices for the frame, lens, and total.
 */
export function BottomBar({ }: BottomBarProps) {
  //const configProductImage = useSelector((state: any) => state.config?.baseURLs?.productImage)

  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const currentStepName = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const currentPrices = useCurrentPrices();
  const [prices, setPrices] = useState(null);

  const addToBagLabel = useTranslate("steps.treatments.addToBag");
  const reviewTitle = useTranslate("steps.review.title");
  const insuranceLegalCopy = useTranslate("insuranceLegalBanner");
  const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const removeInsuranceLabel = useTranslate("insuranceButtonLabelRemove");
  const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
  const imageryObject = useImagery();
  const config = useSelector((state: any) => state.config);
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const pricingMethodConfig = useSelector(
    (state: any) => state.insurance?.pricingMethodConfig
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const reviewObjectForCart = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart
  );

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isFirstManualStep, setIsFirstManualStep] = useState<boolean>(true);

  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;
  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();
  const showSavingsLabel = useShowSavingsLabel()

  const isInsurable = () => {
    return !!configInsurance;
  };

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  useEffect(() => {
    setPrices(currentPrices);
  }, [currentPrices]);

  useEffect(() => {
    currentStep && workflow.willManualSelectionBeDone()
      ? setIsFirstManualStep(true)
      : setIsFirstManualStep(false);
  }, [currentStep]);

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const onAddToBag = () => {
    window.dispatchEvent(
      new CustomEvent("AddToCartEvent", {
        detail: {
          selectedWarranty:
            sessionWarranty?.id === "asIs" ? null : sessionWarranty,
          reviewObjectForCart: reviewObjectForCart,
          imagery: imageryObject,
        },
      })
    );
    setButtonLoading(true);
  };

  return (
    (isBundlePriceEnabled(config) &&
      isFirstManualStep &&
      workflow.currentStep?.key !== "Review") || (
      <div
        className={
          pricingMethodConfig &&
            (pricingMethodConfig === "QUICK" ||
              pricingMethodConfig === "STANDARD_REVIEW")
            ? "BottomBar__legalBanner"
            : (showFramePlusLensesPrice && currentStep <= lensBundleStep && currentStepName !== "AdvancedPrescription")
              ? "BottomBar__hide"
              : "BottomBar"
        }
      >
        <div
          className={
            "BottomBar__container" +
            (currentStep &&
              currentStep === workflow.getMaxStep(workflow.stepChain).progressive
              ? " BottomBar__container__containerLastPage"
              : "") +
            (!isInsurable() && !showSavingsLabel ? " BottomBar__container__noInsurance" : "")
          }
        >
          {isInsurable() && (
            <div className={"BottomBar__container__left"}>
              {currentStep &&
                currentStep !==
                workflow.getMaxStep(workflow.stepChain).progressive &&
                (isInsuranceLoading || isInsuranceEnabled ? (
                  <div className="BottomBar__container__left__wrapper">
                    <div className="BottomBar__container__left__insuranceWrapper">
                      <div
                        className={
                          "BottomBar__container__left__insuranceEnabled"
                        }
                      >
                        {insuranceSyncedLabel}
                      </div>
                      <BrandComponent
                        componentName="InsuranceTooltip"
                        parameter={{ id: "tooltipDesktop" }}
                      />
                    </div>
                    <span
                      className="BottomBar__container__left__removeInsuranceLabel"
                      onClick={handleInsuranceClick}
                    >
                      {insuranceLabelRemove}
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className={"BottomBar__container__left__insurance"}
                    onClick={handleInsuranceClick}
                  >
                    {addInsuranceLabel}
                  </button>
                ))}
              {currentStep &&
                currentStep ===
                workflow.getMaxStep(workflow.stepChain).progressive && (
                  <>
                    {isInsuranceEnabled &&
                      !configInsurance.removeInsuranceBenefits ? (
                      <div
                        className={
                          "BottomBar__container__left__insuranceEnabled"
                        }
                      >
                        {insuranceSyncedLabel}
                      </div>
                    ) : (
                      <GenericButton
                        className={
                          "BottomBar__container__left__addInsuranceButton"
                        }
                        title={addToBagLabel}
                        id="continueToReviewButton"
                        type="button"
                        noArrow={true}
                        handleClick={handleInsuranceClick}
                        dataElementId={getDataElementIdForInsurance()}
                      >
                        {isInsuranceEnabled
                          ? removeInsuranceLabel
                          : addInsuranceLabel}
                      </GenericButton>
                    )}
                  </>
                )}
            </div>
          )}
          {!isInsurable() && showSavingsLabel && prices?.total?.discount > 0 && (
            <div className={"BottomBar__container__left"}>
              <div className="BottomBar__container__left__wrapper">
                <BrandComponent componentName="Savings" parameter={{ discount: prices?.total?.discount }}></BrandComponent>
              </div>
            </div>
          )}
          <div
            className={
              "BottomBar__container__right" +
              (!isInsurable() && !showSavingsLabel
                ? " BottomBar__container__right__noInsurance"
                : "")
            }
          >
            {prices && !workflow.isLastStep() && (
              <>
                <BrandComponent
                  componentName="BottomBarTotalPrice"
                  parameter={{
                    total: prices.total,
                  }}
                />
              </>
            )}

            {currentStep && workflow.isLastStep() && (
              <GenericButton
                className={`BottomBar__container__right__addToBagButton ${buttonLoading ? "loading" : ""
                  }`}
                title={addToBagLabel}
                id="continueToReviewButton"
                type="button"
                noArrow={true}
                disabled={buttonLoading}
                handleClick={onAddToBag}
                dataElementId="X_X_Prod_AddCart"
              >
                {addToBagLabel}
              </GenericButton>
            )}
            {pricingMethodConfig === "QUICK" ||
              (pricingMethodConfig === "STANDARD_REVIEW" && (
                <div className="BottomBar__container__right__legalBanner">
                  {insuranceLegalCopy}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
}
