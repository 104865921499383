import React from "react";
import "./default.module.scss";

export function Skeleton() {
  return (
    <div className="skeletonStep">
      <div className="left">
      </div>
      <div className="rigth">
        <div className="header"></div>
        <div className="title">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="cards">

          <div className="card">
            <div className="image"></div>
            <div className="content">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
          <div className="card">
            <div className="image"></div>
            <div className="content">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
          <div className="card">
            <div className="image"></div>
            <div className="content">
              <div className="line big"></div>
              <div className="line small"></div>
              <div className="line small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}