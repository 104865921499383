import React, { useEffect } from "react";
import "./default.module.scss";
import GenericButton from "../common/GenericButton";
import { useDispatch } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useCurrentBrand } from "@hooks";

export const ErrorModal = () => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
    reduxDispatch(workflowActions.setLoadingStep(false));
  }, []);

  const title = useTranslate("error.title");
  const description = useTranslate("error.description");
  const buttonLabel = useTranslate("error.buttonLabel");

  const brand = useCurrentBrand();

  return (
    <div className="ErrorModal__container">
      <div className="ErrorModal__overlay" />
      <div className="ErrorModal__container__content">
        {brand === "framesdirect" && (<svg onClick={() => window.RXC.rxcWidget.close("#rxcApp")} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_235_100125)">
            <path d="M15.8002 1.13255L14.8669 0.199219L8.0002 7.06589L1.13353 0.199219L0.200195 1.13255L7.06686 7.99922L0.200195 14.8659L1.13353 15.7992L8.0002 8.93255L14.8669 15.7992L15.8002 14.8659L8.93353 7.99922L15.8002 1.13255Z" fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_235_100125">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>)}
        <h2 className="ErrorModal__container__content__title">{title}</h2>
        <p
          className="ErrorModal__container__content__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <GenericButton
          className="ErrorModal__container__content__button"
          handleClick={() => window.RXC.rxcWidget.close("#rxcApp")}
        >
          {buttonLabel}
        </GenericButton>
      </div>
    </div>
  );
};
