import { useCallback } from "react";
import { useConfigContent } from "./useConfigContent";

export function useCollapsedTransitionColorStep() {
  const colors = useConfigContent("color");
  const transitions = useConfigContent("transition");

  const isCollapsedTransitionColorStep = useCallback(() => {
    return colors?.length === 1 && transitions?.length === 1;
  }, [colors, transitions]);

  return {
    isCollapsedTransitionColorStep,
  };
}
