import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useConfigContent(
  stepName: string
): { id: string; [key: string]: string }[] {
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const [config, setConfig] = useState([]);

  useEffect(() => {
    getConfig();
  }, [stepName, configContent]);

  const getConfig = () => {
    let ret = [];

    const key = Object.keys(configContent).find(
      (el) => el.toLowerCase() === stepName.toLowerCase()
    );

    if (key) {
      const entries = configContent[key];
      if (entries) {
        ret = Object.entries<Record<string, any>>(entries).map(
          ([key, val]) => ({ id: key, ...val })
        );
      }
    }
    ret = orderBy(ret, "sequence");
    setConfig(ret);
  };

  return config;
}
