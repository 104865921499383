import { IAdapter } from "../meta/IAdapter";

export abstract class Adapter<TSource, TTarget>
  implements IAdapter<TSource, TTarget>
{
  abstract adapt: (source: TSource) => TTarget;
  abstract adaptReverse: (source: TTarget) => TSource;
  adaptList: (source: TSource[]) => TTarget[] = (source: TSource[]) => {
    return source ? source.map(this.adapt) : null;
  };
  adaptListReverse: (source: TTarget[]) => TSource[] = (source: TTarget[]) => {
    return source ? source.map(this.adaptReverse) : null;
  };
}
