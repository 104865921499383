import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";

type Props = {
  onClick: () => void;
};

export function NoPrescriptionFromMyAccountModal(props: Props) {
  const messageLabel = useTranslate("noPrescriptionFromMyAccountModal.message");
  const buttonLabel = useTranslate("noPrescriptionFromMyAccountModal.button");
  return (
    <div className="NoPrescriptionFromMyAccountModal">
      <div className="overlay" />
      <div className="Modal">
        <span className="closeIcon" onClick={() => props.onClick()} />
        <p>{messageLabel}</p>
        <button onClick={props.onClick}>{buttonLabel}</button>
      </div>
    </div>
  );
}
