import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";
import { useBundlesPrices } from "./useBundlesPrices";

export function useShowFramePlusLensesPrice() {
  const showFramePlusLensesPriceValueCookie = getCookieByName("showFramePlusLensesPrice", true);
  const showFramePlusLensesPrice = useSelector(
    (state: any) => state.config.layoutSettings?.showFramePlusLensesPrice
  );
  const bundlesEnabled = useBundlesPrices();

  if (bundlesEnabled) {
    return true
  }

  if (showFramePlusLensesPriceValueCookie !== null) {
    return showFramePlusLensesPriceValueCookie as boolean;
  }

  if (showFramePlusLensesPrice !== null && showFramePlusLensesPrice !== undefined) {
    return showFramePlusLensesPrice as boolean;
  }

  return false;
}