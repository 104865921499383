import HtmlParser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { VideoFrame } from "../VideoFrame/component";
import { modalsActions } from "../../redux/slices/modals";

export function LearnMoreVideo(props) {
  const reduxDispatch = useDispatch();
  const [forceShowPlayButton, setForceShowPlayButton] = useState(0);

  useEffect(() => {
    if (props.shouldAutoPlayIfHasTap) {
      playVideo();
    }
  }, []);

  useEffect(() => {
    if (props.forceShowPlayButton) {
      setForceShowPlayButton(props.forceShowPlayButton);
    }
  }, [props.forceShowPlayButton]);

  const playVideo = () => {
    let toPlayQuery =
      "#card_" +
      props.carouselIndex +
      "_subcard_" +
      props.internalCardIndex +
      " video";
    let video = document.querySelector(toPlayQuery);
    if (video) {
      //@ts-ignore
      video.play();
    }
  };

  const pauseVideo = () => {
    let toPauseQuery =
      "#card_" +
      props.carouselIndex +
      "_subcard_" +
      props.internalCardIndex +
      " video";
    let video = document.querySelector(toPauseQuery);
    if (video) {
      //@ts-ignore
      video.pause();
    }
  };

  const closeModal = () => {
    reduxDispatch(modalsActions.setOpenLearnMoreModal(null));
  };

  return (
    <>
      <div
        className={props.hasTap ? "tappable" : ""}
        onMouseDown={() => {
          props.hasTap ? props.handleTapEvent() : null;
        }}
        onMouseUp={() => {
          props.hasTap ? props.handleTapEvent() : null;
        }}
      >
        {HtmlParser(props.html)}
      </div>
      {!props.hasTap && props.html.indexOf("video") >= 0 && (
        <VideoFrame
          videoIndex={
            props.html.indexOf("<video") !== -1 ? props.internalCardIndex : null
          }
          videoClassName={props.html.split('class="')[1].split('"')[0]}
          cardId={
            "card_" +
            props.carouselIndex +
            "_subcard_" +
            props.internalCardIndex
          }
          id={
            "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            props.internalCardIndex
          }
          onStart={null}
          onPause={() => {
            pauseVideo();
          }}
          onPlay={() => {
            playVideo();
          }}
          onExit={closeModal}
          hasMadeButton={props.hasMadeButton}
          forceShowPlayButton={forceShowPlayButton}
          onMadeButtonClick={() => props.handleMadeButtonClick()}
        />
      )}
      {!props.hasTap && props.html.indexOf("video") < 0 && (
        <VideoFrame
          onStart={null}
          onPause={null}
          onPlay={null}
          onMadeButtonClick={() => props.handleMadeButtonClick()}
          hasMadeButton={props.hasMadeButton}
          videoClassName={props.html.split('class="')[1].split('"')[0]}
          id={
            "VideoFrame_" +
            props.carouselIndex +
            "_subcard_" +
            props.internalCardIndex
          }
          videoIndex={null}
          onExit={closeModal}
        />
      )}
    </>
  );
}
