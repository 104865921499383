import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceActions } from "../../redux/slices/insurance";
import { BrandComponent } from "../common/BrandComponent";
import { TranslateText } from "../common/TranslateText";
import ReactTooltip from "react-tooltip";
import "./default.module.scss";
import { useTranslation } from "../../configurator/translations/TranslationHooks";

export interface WarrantyOptionConfigModel {
  id: string;
  title: string;
  price: string;
}

export function WarrantyOptions() {
  const [warrantySelected, setWarrantySelected] = useState(null);
  const warrantyOptions: WarrantyOptionConfigModel[] = useSelector(
    (state: any) => state.config?.lensesData?.warrantyOptions
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const translation = useTranslation();
  const reviewTooltipText = translation("steps.review.tooltip");
  const dispatch = useDispatch();
  const tooltipLearnMoreLink = useSelector(
    (state: any) => state.config?.linksData?.warrantyLearnMore
  );

  const handleCheck = (warrantyItem: WarrantyOptionConfigModel) => {
    let toSelect = null;
    if (warrantyItem && warrantyItem.id !== warrantySelected) {
      toSelect = warrantyItem;
    }
    dispatch(insuranceActions.setWarranty(toSelect));
  };

  useEffect(() => {
    if (!sessionWarranty || warrantySelected != sessionWarranty.id) {
      setWarrantySelected(sessionWarranty?.id);
    }
  }, [sessionWarranty]);

  return (
    <>
      {warrantyOptions &&
        warrantyOptions.map((warranty) => (
          <div
            key={"WarrantyOptions_" + warranty.id}
            className={"WarrantyOptions__warrantyContainer"}
          >
            <input
              type={"checkbox"}
              id={"warrantyCheckbox"}
              checked={warranty.id === warrantySelected}
              onChange={() => handleCheck(warranty)}
            />
            <div className={"WarrantyOptions__price_container"}>
              <div className={"WarrantyOptions__price_container__addText"}>
                <TranslateText label={"steps.review.oneYearCoverageAdd"} />
              </div>
              <div
                className={"WarrantyOptions__price_container__text"}
                data-multiline={true}
                data-tip={
                  reviewTooltipText +
                  "<a href=" +
                  tooltipLearnMoreLink +
                  " target='_blank'> Learn More </a>"
                }
                data-html={true}
                data-class={"Tooltip__tooltip"}
                data-event="click"
              >
                <TranslateText label={warranty.title} />
                <ReactTooltip
                  type="light"
                  place="top"
                  effect="solid"
                  clickable={true}
                  html={true}
                />
              </div>
              <div className={"WarrantyOptions__price_container__digit"}>
                &nbsp;(
                <BrandComponent
                  componentName="Price"
                  parameter={{
                    price: parseFloat(warranty.price),
                  }}
                />
                )
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
