import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Image from "../common/Image";
import { useLargeIcons } from "../../configurator/hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

export function Chip({ item, selected, onClick }) {
  const { isLargeIconsActive, getIcons } = useLargeIcons();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );
  const handleChange = (e) => {
    console.log(e.target.value);
    onClick?.(item);
  };

  const title = useTranslate(item?.title);

  const fileNameImages = useMemo(() => {
    return getIcons(item.transitionId || item.id);
  }, [item.id, getIcons]);

  return (
    <>
      <input
        type="radio"
        name="lensType"
        value={item.id}
        id={item.id}
        onChange={handleChange}
      />
      <label
        className={`LensesSection__chip ${selected ? "selected" : ""}`}
        htmlFor={item.id}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <div className="LensesSection__chip__icon">
          {!isLargeIconsActive ? (
            <Image
              imageClass={``}
              url={configProjectImage}
              fileName={item?.imageUrl}
              alt={item?.imageUrl}
            />
          ) : (
            <>
              <Image
                imageClass={``}
                url={largeIconBasepath}
                fileName={fileNameImages?.[0]}
                alt={item?.imageUrl}
                style={{
                  display:
                    isMouseOver && fileNameImages?.length === 2
                      ? "none"
                      : "inherit",
                }}
              />
              {fileNameImages?.length === 2 && (
                <Image
                  imageClass={``}
                  url={largeIconBasepath}
                  fileName={fileNameImages[1]}
                  alt={item?.imageUrl}
                  style={{
                    display: !isMouseOver ? "none" : "inherit",
                  }}
                />
              )}
            </>
          )}
        </div>
        <span className="LensesSection__chip__title">{title}</span>
      </label>
    </>
  );
}
