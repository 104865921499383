import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { workflow } from "../../configurator/models/Workflow";
import { ParameterWithTitle } from "../../configurator/models/Parameter";

const initialState = {
  selectedFrame: null,
  framePrice: 0,
  lensPrice: 0,
  lensTotalPrice: 0,
  selectedLens: null,
  selectedTreatment: null,
  selectedType: null,
  loadingPackages: false,
  externalSelectedPackage: null
};

const mixAndMatchSlice = createSlice({
  name: "mixAndMatch",
  initialState: initialState,
  reducers: {
    setExternalSelectedPackage: (state, { payload }: PayloadAction<any>) => {
      state.externalSelectedPackage = payload;
    },
    setFramePrice: (state, { payload }: PayloadAction<any>) => {
      state.framePrice = payload;
    },
    setSelectedFrame: (state, { payload }: PayloadAction<any>) => {
      state.selectedFrame = payload;
    },
    setSelectedType: (state, { payload }: PayloadAction<any>) => {
      const parameters = [
        {
          key: "type",
          value: payload,
        },
      ];
      // MixAndMatch as Type

      workflow.setMixAndMatchSteps("MixAndMatch", parameters)

      state.selectedType = payload;
    },
    setLensPrice: (state, { payload }: PayloadAction<any>) => {
      state.lensPrice = payload;
    },
    setLensTotalPrice: (state, { payload }: PayloadAction<any>) => {
      state.lensTotalPrice = payload;
    },
    setSelectedLens: (state, { payload }: PayloadAction<any>) => {
      const selectedColor = payload
      if (!selectedColor) {
        state.selectedLens = null
        return
      }
      const prices = {
        price: selectedColor.price,
        discount: selectedColor.discount,
        insPrice: selectedColor.insPrice,
      }
      if (selectedColor.categoryType === "EYEGLASSES") {
        workflow.setMixAndMatchSteps(
          "Thickness",
          [
            {
              key: "color",
              value: selectedColor.id,
              ...prices
            },
            {
              key: "transitionColor",
              value: selectedColor.id,
              ...prices
            },
          ],
        )
      }
      if (selectedColor.categoryType === "SUNGLASSES") {
        workflow.setMixAndMatchSteps(
          "Thickness",
          [
            {
              key: "color",
              value: selectedColor.id,
              attributes: {
                translatedName: selectedColor.name,
                colorCategory: selectedColor.colorCategory,
              },
              ...prices
            },
            {
              key: "lensColor",
              value: selectedColor?.name ? selectedColor?.name : selectedColor?.id,

              attributes: {
                colorCategory: selectedColor.colorCategory,
              },
              ...prices
            },
          ],
        )
      }
      state.selectedLens = payload;
    },
    setSelectedTreatment: (state, { payload }: PayloadAction<any>) => {
      const selectedTreatment = payload
      if (!selectedTreatment) {
        state.selectedTreatment = null
        return
      }
      if (selectedTreatment.id.indexOf("$") >= 0) {
        const idT = selectedTreatment.id.split("$")[0];
        const valueT = selectedTreatment.id.split("$")[1];

        //to reload price
        const onlySelected = new ParameterWithTitle();
        const idTa = idT;
        const valueTa = valueT;
        onlySelected.key = idTa;
        onlySelected.value = valueTa;
        onlySelected.title = selectedTreatment.title;
        onlySelected.price = selectedTreatment.price;
        onlySelected.discount = selectedTreatment.discount;
        onlySelected.insPrice = selectedTreatment.insPrice;


        if (idT === "transition") {
          workflow.setMixAndMatchSteps(
            "TransitionColor",
            [
              {
                key: "transition",
                value: valueT,
              },
              {
                key: "familyFilter",
                value: {
                  required: ["transition"],
                  additional: [],
                  excluded: [],
                  nextStep: "TransitionColor",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: selectedTreatment.price,
                discount: selectedTreatment.discount,
                insPrice: selectedTreatment.insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: false,
                },
              },
            ],
            false
          );
        } else if (idT === "blueLight") {
          workflow.setMixAndMatchSteps(
            "Thickness",
            [
              {
                key: idT,
                value: valueT,
              },
              {
                key: "familyFilter",
                value: {
                  required: ["blueLight"],
                  excluded: ["transition"],
                  additional: [],
                  nextStep: "Thickness",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: selectedTreatment.price,
                discount: selectedTreatment.discount,
                insPrice: selectedTreatment.insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: false,
                },
              },
            ],
            false
          );
        } else {
          workflow.setMixAndMatchSteps(
            "Thickness",
            [
              {
                key: idT,
                value: valueT,
              },
              {
                key: "familyFilter",
                value: {
                  required: [],
                  excluded: ["blueLight", "transition", "color"],
                  additional: [],
                  nextStep: "Thickness",
                },
                price: selectedTreatment.price,
                discount: selectedTreatment.discount,
                insPrice: selectedTreatment.insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: false,
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: selectedTreatment.price,
                discount: selectedTreatment.discount,
                insPrice: selectedTreatment.insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: false,
                },
              },
            ],
            false
          );
        }
      } else {

        let dontSendPrice = selectedTreatment.thereAreNonIncludedOptions;
        if (selectedTreatment.id === "transition") {
          workflow.setMixAndMatchSteps(
            "Treatments",
            [
              {
                key: "familyFilter",
                value: {
                  required: ["transition"],
                  additional: [],
                  excluded: [],
                  nextStep: "TransitionColor",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
            ]
          );
        } else if (selectedTreatment.id === "sun") {
          workflow.setMixAndMatchSteps(
            "LensColor",
            [
              {
                key: "blueLight",
                value: null,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0
                  : selectedTreatment.insPrice,
              },
              {
                key: "transition",
                value: null,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0
                  : selectedTreatment.insPrice,
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
            ],

          );
        } else if (selectedTreatment.id === "blueLight") {
          workflow.setMixAndMatchSteps(
            "Treatments",
            [
              {
                key: "familyFilter",
                value: {
                  required: ["blueLight"],
                  excluded: ["transition"],
                  additional: [],
                  nextStep: "Thickness",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
            ],

          );
        } else {
          workflow.setMixAndMatchSteps(
            "Thickness",
            [
              {
                key: "blueLight",
                value: null,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
              {
                key: "transition",
                value: null,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
              {
                key: "color",
                value: null,
                price: dontSendPrice ? 0 : selectedTreatment.price,
                discount: dontSendPrice ? 0 : selectedTreatment.discount,
                insPrice: dontSendPrice
                  ? 0

                  : selectedTreatment.insPrice,
              },
              {
                key: "treatmentsFamily",
                value: selectedTreatment.title,
                price:
                  dontSendPrice || !selectedTreatment.price
                    ? 0
                    : selectedTreatment.price,
                discount:
                  dontSendPrice || !selectedTreatment.discount
                    ? 0
                    : selectedTreatment.discount,
                insPrice:
                  dontSendPrice || !selectedTreatment.insPrice
                    ? 0

                    : selectedTreatment.insPrice,
              },
            ],

          );
        }
      }
      state.selectedTreatment = payload;
    },
    setLoadingPackages: (state, { payload }: PayloadAction<any>) => {
      if (payload === true) {
        document.documentElement.classList.add("skeleton")
      } else if (payload === false) {
        document.documentElement.classList.remove("skeleton")
      }

      state.loadingPackages = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const mixAndMatchReducer = mixAndMatchSlice.reducer;
export const mixAndMatchActions = mixAndMatchSlice.actions;