import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { useEnableAgreement, usePupillaryDistance } from "@hooks";

import "./default.module.scss";
import { AgreementCheckbox } from "../FullPrescriptionForm/components/AgreementCheckbox";

export interface PrescriptionUploadFormProps {
  fileInput: any;
  closePrescriptionForm: () => {};
  onSuccessPrescription: (prescription: any) => {};
  prescriptionObject: any;
}

const Subtitle = () => {
  const uploadSubtitle = useTranslate(
    "steps.advancedPrescription.upload.subtitle"
  );
  return (
    <>
      <div className={"PrescriptionUploadForm__subtitle"}>{uploadSubtitle}</div>
    </>
  );
};

const UploadResult = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadResult__container">
      {props.isSuccessful && (
        <>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99992 13.6666C11.1295 13.6666 13.6666 11.1295 13.6666 7.99992C13.6666 4.87031 11.1295 2.33325 7.99992 2.33325C4.87031 2.33325 2.33325 4.87031 2.33325 7.99992C2.33325 11.1295 4.87031 13.6666 7.99992 13.6666ZM7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
              fill="#247534"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4451 6.15454C10.7062 6.39024 10.7415 6.81062 10.5239 7.09347L7.95984 10.4268C7.84916 10.5707 7.6877 10.6575 7.51498 10.666C7.34226 10.6745 7.17421 10.6038 7.05195 10.4714L5.51349 8.80474C5.27317 8.5444 5.27317 8.12229 5.51349 7.86194C5.75382 7.60159 6.14345 7.60159 6.38378 7.86194L7.44574 9.0124L9.57844 6.23989C9.79601 5.95704 10.1841 5.91883 10.4451 6.15454Z"
              fill="#247534"
            />
          </svg>

          <div className="PrescriptionUploadForm__UploadResult__successText">
            {props.message}
          </div>
        </>
      )}
      {!props.isSuccessful && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__unsuccessfulIconContainer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1844 11.3829L9.07874 2.91568C8.96292 2.73792 8.80491 2.59152 8.61872 2.48956C8.42926 2.3858 8.21673 2.33142 8.00072 2.33142C7.78471 2.33142 7.57217 2.3858 7.38271 2.48956C7.19653 2.59152 7.03851 2.73792 6.92269 2.91568L1.81825 11.3809C1.81808 11.3812 1.81792 11.3815 1.81775 11.3818C1.72775 11.5321 1.67884 11.7035 1.67592 11.8787C1.67303 12.0524 1.71542 12.2238 1.79889 12.3761C1.90816 12.5655 2.06537 12.7229 2.25476 12.8324C2.44584 12.9429 2.66284 13.0007 2.88356 12.9998L2.88738 12.9998L13.1194 12.9998L13.1247 12.9999C13.344 13.0022 13.56 12.9467 13.751 12.8389C13.9394 12.7325 14.0968 12.5789 14.2078 12.3934C14.2929 12.2401 14.3362 12.0671 14.3333 11.8917C14.3303 11.7136 14.2799 11.5395 14.1871 11.3873L14.1844 11.3829ZM15.0741 12.8932C14.8735 13.2337 14.5868 13.5154 14.2426 13.7097C13.8985 13.904 13.5092 14.0041 13.1141 13.9998H2.88738C2.4896 14.0014 2.09855 13.8972 1.75419 13.6981C1.40982 13.499 1.1245 13.212 0.927384 12.8665C0.756888 12.5595 0.670208 12.2131 0.676059 11.862C0.68191 11.511 0.780087 11.1676 0.960718 10.8665L6.07405 2.3865C6.28081 2.06305 6.56568 1.79685 6.9024 1.61246C7.23911 1.42807 7.61682 1.33142 8.00072 1.33142C8.38461 1.33142 8.76233 1.42807 9.09904 1.61246C9.43575 1.79685 9.72062 2.06305 9.92739 2.3865L15.0407 10.8665C15.2263 11.1707 15.3273 11.519 15.3331 11.8753C15.339 12.2316 15.2496 12.583 15.0741 12.8932Z"
                fill="#AB5820"
              />
              <path
                d="M8.00065 11.3333C8.36884 11.3333 8.66732 11.0349 8.66732 10.6667C8.66732 10.2985 8.36884 10 8.00065 10C7.63246 10 7.33398 10.2985 7.33398 10.6667C7.33398 11.0349 7.63246 11.3333 8.00065 11.3333Z"
                fill="#AB5820"
              />
              <path
                d="M8.00065 5.33337C7.82384 5.33337 7.65427 5.40361 7.52925 5.52864C7.40422 5.65366 7.33398 5.82323 7.33398 6.00004V8.66671C7.33398 8.84352 7.40422 9.01309 7.52925 9.13811C7.65427 9.26314 7.82384 9.33337 8.00065 9.33337C8.17746 9.33337 8.34703 9.26314 8.47206 9.13811C8.59708 9.01309 8.66732 8.84352 8.66732 8.66671V6.00004C8.66732 5.82323 8.59708 5.65366 8.47206 5.52864C8.34703 5.40361 8.17746 5.33337 8.00065 5.33337Z"
                fill="#AB5820"
              />
            </svg>
          </div>
          <div
            className={
              "PrescriptionUploadForm__UploadResult__errorText" +
              (props.uppercase ? " uppercase" : "")
            }
          >
            {props.message}
          </div>
        </>
      )}
    </div>
  );
};

const FilePreview = (props) => {
  const reduxDispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);

  const fallBackImageUrl =
    "https://assets.lenscrafters.com/extra/image/LensCrafters/projects/202005-rxreview/LC_UploadOK_Icon.png";

  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );

  useEffect(() => {
    if (props.forceFileInfo) {
      setFileData({
        url: props.forceFilePath,
        type: props.forceFileInfo.type,
        name: props.forceFileInfo.name,
        size: props.forceFileInfo.size,
      });
      if (props.forceFilePath) {
        setIsPreviewEnabled(true);
      }
    }
  }, [props.forceFileInfo, props.forceFilePath]);

  const handleFileChange = (file: any) => {
    const fileReader = new window.FileReader();
    fileReader.onload = (fileLoad) => {
      const { result } = fileLoad.target;
      setFileData({
        url: result,
        type: file.type.split("/")[1],
        name: file.name,
        size: file.size,
      });
      setIsPreviewEnabled(true);
    };
    fileReader.readAsDataURL(file);
  };

  const getSizeInMB = (size) => {
    return "[" + (parseInt(size) / 1024 / 1024).toFixed(2) + "MB]";
  };

  const setErrorImage = (e: any) => {
    setIsPreviewEnabled(false);
    e.target.src = fallBackImageUrl;
  };

  return (
    fileData && (
      <div className="PrescriptionUploadForm__FilePreview__container">
        <div
          className={
            "PrescriptionUploadForm__FilePreview__container__filePreview" +
            (isPreviewEnabled ? "" : " noPreview")
          }
          onClick={() => {
            if (isPreviewEnabled) {
              reduxDispatch(
                modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
              );
              reduxDispatch(
                prescriptionActions.setUploadFileDataPreview(
                  props.forceFilePath ? props.forceFilePath : fileData.url
                )
              );
            }
          }}
        >
          {props.forceFilePath && (
            <img
              src={props.forceFilePath}
              alt="Document"
              onError={setErrorImage}
            />
          )}
          {!props.forceFilePath && (
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 30, height: 30 },
              }}
            />
          )}
        </div>
        <div className="PrescriptionUploadForm__FilePreview__container__fileInfo">
          <div className="PrescriptionUploadForm__FilePreview__container__fileInfo__nameAndSize">
            {fileData.name + " - " + getSizeInMB(fileData.size)}
          </div>
          <div
            className="PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent"
            onClick={() => {
              props.handleUploadAgain();
            }}
          >
            {uploadDifferentFile}
          </div>
        </div>
      </div>
    )
  );
};

const UploadError = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadError__container">
      <div className="PrescriptionUploadForm__UploadError__container__message">
        {props.message}
      </div>
      <button
        className="PrescriptionUploadForm__button"
        onClick={() => props.onButtonClick()}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

const UploadBox = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);

  const changeMethod = useTranslate(
    "steps.advancedPrescription.upload.changeMethod"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  useEffect(() => {
    //TODO chiamata
    uploadFile(props.file);
  }, [props.file]);

  const getFileTypeFromFullName = (fileName: string) => {
    let split = fileName.split(".");
    let lastElem = split[split.length - 1];
    return "." + lastElem;
  };

  useEffect(() => {
    if (
      props.preloadedPrescription &&
      props.preloadedPrescription.fileName &&
      props.preloadedPrescription.fileSize &&
      props.preloadedPrescription.filePath &&
      props.preloadedPrescription.savedFileName
    ) {
      setIsUploading(false);
      setIsSuccessful(true);
      setPreloadedFileInfo({
        name: props.preloadedPrescription.fileName,
        size: parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(props.preloadedPrescription.fileName),
      });
      props.onFileChange({
        name: props.preloadedPrescription.fileName,
        filePath: props.preloadedPrescription.filePath,
        fileSize:
          parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: props.preloadedPrescription.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
  }, [props.preloadedPrescription]);

  useEffect(() => {
    let update = isSuccessful && !isUploading;
    props.onUploadSuccessStateChange(update);

    if (!isSuccessful && !isUploading) {
      props.onUploadError();
    }

    if (isUploading) {
      props.onUploadStarted();
    }
  }, [isSuccessful, isUploading]);

  const uploadFile = (file) => {
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (checkFileExtension(file)) {
        if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
          const fileReader = new window.FileReader();
          fileReader.onload = (fileLoad) => {
            const { result } = fileLoad.target;
            let requestObj = {
              fileName: file.name,
              fileData: result,
            };
            prescriptionModule
              .uploadExtendedPrescription(requestObj)
              .then((res) => {
                props.onFileChange({
                  name: file.name,
                  savedFileName: res.savedFileName,
                  fileSize: file.size,
                });
                props.onPrescriptionIdReceived(res.prescriptionId);
                setIsFileTooBigError(false);
                setIsSuccessful(true);

                setPreloadedFileInfo({
                  name: file.name,
                  size: file.size,
                  type: getFileTypeFromFullName(file.name),
                });

                setIsUploading(false);

                if (prescriptionModule.downloadExtendedPrescription) {
                  prescriptionModule
                    .downloadExtendedPrescription({
                      savedFileName: res.savedFileName,
                    })
                    .then((res) => {
                      setFilePath(res.fileData);
                    })
                    .catch((err) => {
                      setFilePath("");
                    });
                } else {
                  console.error("Missing downloadExtendedPrescriptionFunction");
                  setFilePath("");
                }
              })
              .catch((err) => {
                setIsSuccessful(false);
                setIsUploading(false);
                setIsFileTooBigError(false);
              });
          };
          fileReader.readAsDataURL(file);
        }
      }
      else {
        setIsSuccessful(false);
        setIsUploading(false);
      }
    }
  };

  const checkFileExtension = (file) => {
    if (file.name.indexOf(".") > 0) {
      let ext = file.name.split(".")[file.name.split(".").length - 1];
      if (prescriptionModule && prescriptionModule.fileExtensions) {
        if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Source: "User",
              Error_Code: "RX Configurator: prescription upload",
              Error_Message: tryAgain,
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      } else {
        if (file.name.indexOf(".") > 0) {
          let ext = file.name.split(".")[file.name.split(".").length - 1];
          if (prescriptionModule && prescriptionModule.fileExtensions) {
            if (!prescriptionModule.fileExtensions.includes(ext.toLowerCase())) {
              try {
                //@ts-ignore
                window.tealium_data2track.push({
                  id: "Error",
                  Error_Source: "User",
                  Error_Code: "RX Configurator: prescription upload",
                  Error_Message: tryAgain,
                });
              } catch (error) {
                console.error(
                  "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
                );
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext.toUpperCase())
      );
      return parsedExt.join();
    }
  };

  const handleFileChange = (file: any) => {
    setFilePath(null);
    uploadFile(file);
  };

  const handleUploadErrorButtonClick = () => {
    let inputFile = document.getElementById("PrescriptionUploadInputId");
    if (inputFile) {
      inputFile.click();
    }
  };

  return (
    <>
      <div
        className={
          "PrescriptionUploadForm__UploadBox__container" +
          (!isSuccessful && !isUploading ? " error" : "")
        }
      >
        {isUploading && (
          <div className="PrescriptionUploadForm__UploadBox__container__spinnerContainer">
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 40, height: 40 },
              }}
            />
          </div>
        )}
        {!isUploading && (
          <>
            <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
              <UploadResult
                isSuccessful={isSuccessful}
                uppercase={isSuccessful || isFileTooBigError ? true : false}
                message={
                  isSuccessful
                    ? prescriptionUploaded
                    : isFileTooBigError
                      ? fileTooBigErrorTitle
                      : somethingWentWrong
                }
              />
              <div
                className="PrescriptionUploadForm__UploadBox__container__changeMethod"
                onClick={() => props.closePrescriptionForm()}
              >
                {changeMethod}
              </div>
            </div>
            <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
              {isSuccessful && (
                <FilePreview
                  handleUploadAgain={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                  forceFilePath={filePath}
                  forceFileInfo={preloadedFileInfo}
                />
              )}
              {!isSuccessful && (
                <UploadError
                  message={
                    isFileTooBigError
                      ? fileTooBigErrorDescription.replace(
                        "###FILE_SIZE###",
                        prescriptionModule?.maxFileSize
                          ? prescriptionModule?.maxFileSize
                          : 10
                      )
                      : tryAgain
                  }
                  buttonText={isFileTooBigError ? uploadDifferentFile : upload}
                  onButtonClick={handleUploadErrorButtonClick}
                />
              )}
            </div>
            <div
              className="PrescriptionUploadForm__UploadBox__container__changeMethodMobile"
              onClick={() => props.closePrescriptionForm()}
            >
              {changeMethod}
            </div>
          </>
        )}
      </div>
      <input
        id="PrescriptionUploadInputId"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={getSupportedFileExtensions()}
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export function PrescriptionUploadForm({
  fileInput,
  closePrescriptionForm,
  onSuccessPrescription,
  prescriptionObject,
}: PrescriptionUploadFormProps) {
  const saveExtendedPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const [fileInfoForSave, setFileInfoForSave] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [showPDForm, setShowPDForm] = useState(true);

  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const confirmAndContinue = useTranslate(
    "steps.advancedPrescription.upload.confirmAndContinue"
  );
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enableAgreementCheckbox = useEnableAgreement();

  const CustomCheckbox = (props) => {
    const isChecked = props.value
      ? typeof props.value === "boolean"
        ? props.value
        : props.value.value
      : false;

    return (
      <div
        tabIndex={0}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName ? " error" : "") +
          (props.marginTop ? " marginTop" : "")
        }
        onClick={() => {
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: false,
            });
          }
        }}
      ></div>
    );
  };

  const handleSubmitPrescription = () => {
    if (!PD.state.checkPDSelected()) return;

    //Agreement validation
    if (enableAgreementCheckbox) {
      if (!agreement) {
        setFormErrors(errors => ({ ...errors, agreement: true }))
        return
      }
    }

    if (
      saveExtendedPrescription &&
      typeof saveExtendedPrescription === "function"
    ) {
      let fileSize = fileInfoForSave?.fileSize
        ? (parseInt(fileInfoForSave.fileSize) / 1024 / 1024).toFixed(2)
        : 0;
      let submitObj = {
        prescriptionFlow: "UPLOAD",
        prescriptionId: prescriptionId
          ? prescriptionId
          : previousPrescription
            ? previousPrescription.prescriptionId
            : null,
        fileName: fileInfoForSave?.name,
        savedFileName:
          fileInfoForSave && fileInfoForSave.savedFileName
            ? fileInfoForSave.savedFileName
            : previousPrescription.savedFileName,
        fileSize: fileSize,
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      saveExtendedPrescription(submitObj)
        .then((res) => {
          onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Subtitle />
      <UploadBox
        preloadedPrescription={prescriptionObject}
        onUploadSuccessStateChange={(isSuccessful) =>
          setShowContinueButton(isSuccessful)
        }
        onUploadError={() => setShowPDForm(false)}
        onFileChange={(fileInfo) => {
          setShowPDForm(true);
          setFileInfoForSave(fileInfo);
        }}
        onUploadStarted={() => setShowPDForm(true)}
        onPrescriptionIdReceived={(id) => setPrescriptionId(id)}
        closePrescriptionForm={closePrescriptionForm}
        file={fileInput}
      />
      {showPDForm && (
        <>
          <div className="PrescriptionUploadForm__Separator"></div>
          <BrandComponent
            componentName="PupillaryDistance"
            parameter={{
              PD1: PD.state.PD1,
              PD2: PD.state.PD2,
              showBothPdOptions: PD.state.showBothPdOptions,
              showPDWarning: PD.state.showPDWarning,
              onPD1Change: PD.state.setPD1,
              onPD2Change: PD.state.setPD2,
              setShowBothPdOptions: PD.state.setShowBothPdOptions,
              pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
              CustomCheckbox: CustomCheckbox,
            }}
          />
        </>
      )}
      {enableAgreementCheckbox && (
        <div className="PrescriptionUploadForm__agreement">
          <AgreementCheckbox
            agreement={agreement}
            setAgreement={setAgreement}
            formErrors={formErrors}
            tooltipText={tooltipText}
            uploadPrescription
          />
        </div>
      )}
      {showContinueButton && (
        <div className="PrescriptionUploadForm__submitContainer">
          <button
            className="PrescriptionUploadForm__submitButton"
            onClick={handleSubmitPrescription}
          >
            {confirmAndContinue}
          </button>
        </div>
      )}
    </>
  );
}
