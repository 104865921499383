import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { workflow } from "../../configurator/models/Workflow";
import { useSelector } from "react-redux";

interface HeaderProgressBarProps {}

/**
 * Component handling the progressbar on mobile. It uses the ```currentStep``` value stored
 * in redux to update itself.
 */
export function HeaderProgressBar({}: HeaderProgressBarProps) {
  const currentStep = useSelector((state: any) => state.workflow.currentStep);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (currentStep) {
      let newWidth = getProgressBarValue();
      setWidth(newWidth);
    }
  }, [currentStep]);

  const getProgressBarValue = () => {
    let maxProgressive = workflow.getMaxStep(workflow.stepChain).progressive;
    return currentStep.progressive * (100.0 / maxProgressive);
  };

  return (
    <div className="HeaderProgressBar__container">
      <div
        className={"HeaderProgressBar__bar"}
        style={{ width: width + "%" }}
      ></div>
    </div>
  );
}
