import { useEffect, useState } from "react";
import { getCheapPackageFromList } from "../ConfigLoader";
import { useCurrentPackages } from "./useCurrentPackages";

export function useCheapCurrentStep() {
  const currentPackage = useCurrentPackages();
  const [cheapPackage, setCheapPackage] = useState(null);

  useEffect(() => {
    if (currentPackage) getCheapPackage();
  }, [currentPackage]);

  const getCheapPackage = () => {
    setCheapPackage(getCheapPackageFromList(currentPackage));
  };

  return cheapPackage;
}
