import React, { useState } from "react";
import usePromoBanner, {
  PromoBanner,
} from "../../configurator/hooks/usePromoBanner";
import HtmlParser from "html-react-parser";
import { useSelector } from "react-redux";
import "./default.module.scss";
import { useCurrentPackages } from "@hooks";

export function PromoBanners() {
  const promoBanner: PromoBanner | undefined = usePromoBanner();
  const [promoInPackage, setPromoInPackage] = useState("");
  const promoType = useSelector((state: any) => state.workflow?.promoType);
  const currentPackages = useCurrentPackages();
  return (
    ((promoBanner as PromoBanner).desktop && promoInPackage) ||
    (promoType &&
      currentPackages[0] &&
      promoType === currentPackages[0].frame.promoTypeDetail) ||
    (!currentPackages[0].frame.promoTypeDetail && (
      <div className={"PromoBanner__badge__desktop"}>
        {HtmlParser((promoBanner as PromoBanner).desktop)}
      </div>
    ))
  );
  // (promoBanner as PromoBanner).promoBanners?.mobile &&
  //   (promoBanner as PromoBanner).promoInPackage ||
  //     (promoBanner as PromoBanner).promoType &&
  //       (promoBanner as PromoBanner)).currentPackages[0] &&
  //       ((promoBanner as PromoBanner).promoType ===
  //         (promoBanner as PromoBanner).currentPackages[0].frame.promoTypeDetail) ||
  //         !(promoBanner as PromoBanner).currentPackages[0].frame.promoTypeDetail && (
  //     <div className={"Review__topBadge__mobile"}>
  //       {HtmlParser((promoBanner as PromoBanner).promoBanners.mobile)}
  //     </div>
  //   )
}
