import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { colorActions } from "../../redux/slices/color";
import { useConfigContent } from "./useConfigContent";

export function useTransitionSelectedColor() {
  const defTranslation = null;

  const [imagefn, setImage] = useState(() => defTranslation);
  const colors = useConfigContent("Color");
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (colors) {
      getImage();
    }
  }, [colors]);

  const getImage = () => {
    const hh = () => (colorId: string) => {
      if (!colorId) {
        reduxDispatch(colorActions.selectTransition(null));
      } else {
        const colorFind = colors.find((c) => c.id === colorId);
        const imageFileName = colorFind.imageFileName2
          ? colorFind.imageFileName2
          : colorFind.imageFileName1 ? colorFind.imageFileName1 : colorFind.imageFileName;
        if (colorFind)
          reduxDispatch(colorActions.selectTransition(imageFileName));
      }
    };
    setImage(hh);
  };

  return imagefn;
}
