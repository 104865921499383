import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { useCurrentBrand, useCurrentType } from "@hooks";
import { Step } from "../models/Step";

export interface StepComponentOptions {
  step: Step;
  brand?: string;
  parameter?: any;
  children?: ReactNode;
  noRender?: boolean;
}

export default function StepComponent(props: StepComponentOptions) {
  const currentBrand = useCurrentBrand();
  const [BrandedStep, setBrandedStep] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(true);

  const loadingStepState = useSelector(
    (state: any) => state.workflow?.loadingStep
  );

  const currentType = useCurrentType();
  const reduxDispatch = useDispatch();
  useEffect(() => {
    setShowBackdrop(loadingStepState);
  }, [loadingStepState]);

  useEffect(() => {
    if (currentType) {
      reduxDispatch(workflowActions.setLoadingStep(true));
      setBrandedStep(
        React.lazy(() => {
          const brand = props.brand || currentBrand;
          if (!brand) {
            if (currentType === "SUNGLASSES") {
              return import("./" + props.step.key + "/sun.component")
                .then((comp) => ({ default: comp[props.step.key] }))
                .catch(() =>
                  import("./" + props.step.key + "/component").then((comp) => ({
                    default: comp[props.step.key],
                  }))
                );
            }
            return import("./" + props.step.key + "/component").then(
              (comp) => ({ default: comp[props.step.key] })
            );
          }

          if (currentType === "SUNGLASSES") {
            return import(
              "./" + props.step.key + "/sun." + brand + ".component"
            )
              .then((comp) => ({ default: comp[props.step.key] }))
              .catch(() =>
                import("./" + props.step.key + "/" + brand + ".component")
                  .then((comp) => ({ default: comp[props.step.key] }))
                  .catch(() =>
                    import("./" + props.step.key + "/sun.component")
                      .then((comp) => ({ default: comp[props.step.key] }))
                      .catch(() =>
                        import("./" + props.step.key + "/component").then(
                          (comp) => ({ default: comp[props.step.key] })
                        )
                      )
                  )
              );
          }

          return import("./" + props.step.key + "/" + brand + ".component")
            .then((comp) => ({ default: comp[props.step.key] }))
            .catch(() =>
              import("./" + props.step.key + "/component").then((comp) => ({
                default: comp[props.step.key],
              }))
            );
        })
      );
    }
  }, [currentBrand, props.step, currentType]);

  // EX SKELETON - don't remove!

  // useEffect(() => {
  //   document.documentElement.classList.add("showSkeleton")
  //   const removeSkeleton = setTimeout(() => {
  //     document.documentElement.classList.remove("showSkeleton")
  //   }, 2000);

  //   return () => {
  //     clearTimeout(removeSkeleton);
  //   };
  // }, [props.step])

  return (
    <Suspense fallback={null}>
      {BrandedStep && (
        <>
          <BrandedStep {...props.parameter} step={props.step}>
            {props.children}
          </BrandedStep>
          {showBackdrop && (
            <div
              style={{
                position: "absolute",
                zIndex: 10000,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: "#fff",
              }}
            ></div>
          )}
        </>
      )}
    </Suspense>
  );
}
