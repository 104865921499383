import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useInstallmentsLabel,
  usePaymentLabelInModal,
} from "../../configurator/hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";

interface PaymentInstallmentsModalOpt {}

export function PaymentInstallmentsModal({}: PaymentInstallmentsModalOpt) {
  const dispatch = useDispatch();
  const currentBrand = useSelector((state: any) => state.config?.brandName);
  const configPaymentInstallments = useSelector(
    (state: any) => state.config?.paymentInstallment
  );
  const modalTitle = useTranslate(
    "steps.review.paymentInstallments.modal.title"
  );
  const subtitle = useTranslate(
    "steps.review.paymentInstallments.modal.subtitle"
  );
  const modalSubtitle = subtitle.replace("###BRAND###", currentBrand);
  const payment = (type: string) => {
    return useTranslate(`steps.review.paymentInstallments.modal.${type}`);
  };
  const modalFooter = useTranslate(
    "steps.review.paymentInstallments.modal.footer"
  );
  const exitButton = useTranslate(
    "steps.review.paymentInstallments.modal.exitButton"
  );

  const {
    type: paymentType,
    multiplePaymentInstallment,
    types: paymentTypes,
  } = configPaymentInstallments;

  const handleCloseModal = () => () => {
    dispatch(modalsActions.setShowPaymentInstallmentsModal(false));
  };
  const paymentLabel = (type: string) => {
    return usePaymentLabelInModal({ payment: payment(type), type });
  };
  const installmentsLabel = useInstallmentsLabel({
    initialString: modalSubtitle,
    withQuotes: true,
  });

  return (
    <div className="PaymentInstallmentsModal__container">
      <div className="PaymentInstallmentsModal__container__overlay"></div>
      <div className="PaymentInstallmentsModal__container__popup">
        <div className="PaymentInstallmentsModal__container__popup__closeContainer">
          <a
            className={
              "PaymentInstallmentsModal__container__popup__closeContainer__close"
            }
            onClick={handleCloseModal()}
          />
        </div>
        <div className="PaymentInstallmentsModal__container__popup__content">
          <div className="PaymentInstallmentsModal__container__popup__content__title">
            {modalTitle}
          </div>
          <div className="PaymentInstallmentsModal__container__popup__content__subtitle">
            {installmentsLabel}
          </div>
          <div className="PaymentInstallmentsModal__container__popup__content__payments_container">
            {multiplePaymentInstallment ? (
              <>
                {paymentTypes.map((type: string) => (
                  <div className="PaymentInstallmentsModal__container__popup__content__payments_container__payment">
                    {paymentLabel(type)}
                  </div>
                ))}
              </>
            ) : (
              <div className="PaymentInstallmentsModal__container__popup__content__payments_container__payment">
                {paymentLabel(paymentType)}
              </div>
            )}
          </div>
          <div className="PaymentInstallmentsModal__container__popup__content__footer">
            {modalFooter}
          </div>
          <div
            className={
              "PaymentInstallmentsModal__container__popup__content__exit_button_container"
            }
          >
            <GenericButton
              noArrow={true}
              className={
                "PaymentInstallmentsModal__container__popup__content__exit_button_container__exit_button"
              }
              handleClick={handleCloseModal()}
            >
              {exitButton}
            </GenericButton>
          </div>
        </div>
      </div>
    </div>
  );
}
