import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

export function useDistanceAndReading() {
  const distanceAndReadingCookie = getCookieByName(
    "activateDistanceAndReading",
    true
  );
  const distanceAndReadingConfig = useSelector(
    (state: any) => state.config?.prescriptionModule?.activateDistanceAndReading
  );

  const isDistanceAndReadingActive = useMemo(() => {
    if (![null, undefined].includes(distanceAndReadingCookie)) {
      return distanceAndReadingCookie as boolean;
    }

    if (![null, undefined].includes(distanceAndReadingConfig)) {
      return distanceAndReadingConfig as boolean;
    }

    return false;
  }, [distanceAndReadingCookie, distanceAndReadingConfig]);

  return {
    isDistanceAndReadingActive,
  };
}
