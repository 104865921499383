import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";

interface BottomBarSummaryPriceProps {
  frame: { price: number; discount: number };
  lens: { price: number; discount: number };
}

/**
 * Small component that displays the frame and lens prices in the bottom bar. It accepts
 * an input of type ```BottomBarSummaryPriceProps``` which is an interface defining two
 * parameters:
 *
 * ```typescript
 * {
 *      frame: { price: number, discount: number },
 *      lens: { price: number, discount: number }
 * }
 * ```
 *
 * The component displays these prices using the ```Price``` component.
 */
export function BottomBarSummaryPrice({
  lens,
  frame,
}: BottomBarSummaryPriceProps) {
  const showLensPriceAtStep = useSelector(
    (state: any) => state.config?.showLensPriceAtStep
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const [showPrice, setShowPrice] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);

  const noSelectionLabel = useTranslate("noSelection");
  const priceFrameLabel = useTranslate("price.frame");
  const priceLensLabel = useTranslate("price.lenses");

  useEffect(() => {
    if (currentStep && showLensPriceAtStep) {
      const stepToShow = workflow.findByStepKey(showLensPriceAtStep);
      setShowPrice(currentStep.progressive >= stepToShow.progressive);
    }
  }, [currentStep, showLensPriceAtStep]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  return (
    <div className={"BottomBarSummaryPrice"}>
      <div className={"BottomBarSummaryPrice__row"}>
        <div className={"BottomBarSummaryPrice__row__label"}>
          {priceFrameLabel}:
        </div>
        <div className={"BottomBarSummaryPrice__row__price"}>
          {isInsuranceLoading ? (
            <BrandComponent componentName="Loader" />
          ) : (
            <BrandComponent
              componentName="Price"
              parameter={{
                price: frame.price,
                discount: frame.discount,
              }}
            />
          )}
        </div>
      </div>
      {isInsuranceLoading && (
        <div className={"BottomBarSummaryPrice__rowSpacer"} />
      )}
      <div className={"BottomBarSummaryPrice__row"}>
        <div className={"BottomBarSummaryPrice__row__label"}>
          {priceLensLabel}:
        </div>
        <div className={"BottomBarSummaryPrice__row__price"}>
          {isInsuranceLoading ? (
            <BrandComponent componentName="Loader" />
          ) : showPrice ? (
            <BrandComponent
              componentName="Price"
              parameter={{
                price: lens.price,
                discount: lens.discount,
              }}
            />
          ) : (
            <label>{noSelectionLabel}</label>
          )}
        </div>
      </div>
    </div>
  );
}
