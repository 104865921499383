import { capitalize, lowerCase } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "../translations/TranslationHooks";
import { useCurrentBrand } from "./useCurrentBrand";
import { useCurrentPrices } from "./useCurrentPrices";

export const useInstallmentsLabel = ({
  initialString,
  withQuotes,
  isSvg,
}: {
  initialString: string;
  withQuotes?: boolean;
  isSvg?: boolean;
}) => {
  const translation = useTranslation();
  const currentBrand = useCurrentBrand();
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const currentPrices = useCurrentPrices();
  const configPaymentInstallments = useSelector(
    (state: any) => state.config?.paymentInstallment
  );
  const orLabel = translation("or");
  const quotes = withQuotes ? "'" : "";
  const { type, types, installments, multiplePaymentInstallment } =
    configPaymentInstallments;
  const hasMontlhyPrice =
    currentBrand === "costa" ||
    currentBrand === "sgh" ||
    currentBrand === "oliverpeoples";

  const priceMonthly = insuranceEnabled
    ? currentPrices.total.insPrice / installments
    : (currentPrices.total.price - currentPrices.total.discount) / installments;
  let paymentTypes = "";
  if (multiplePaymentInstallment) {
    if (types.indexOf("klarna") !== -1 && currentBrand === "default") {
      let sortedArray = [...types]
        .sort((a: string, b: string) =>
          a === "klarna" ? -1 : b === "klarna" ? 1 : 0
        )
        .map((type: string) => {
          return capitalize(type);
        });
      paymentTypes = sortedArray.reduce(
        (acc: string, current: string, index: number) => {
          let newValue = capitalize(current);
          if (isSvg) {
            newValue = `<div class="ImageInstallments__copy_${lowerCase(
              current
            )}"></div>`;
          } else {
          }
          if (index !== types.length - 1) {
            return `${quotes}${acc}${quotes}${types.length - 1 !== index ? ", " : orLabel + " "
              }${quotes}${newValue}${quotes}`;
          } else {
            return `${quotes}${acc}${quotes} ${types.length - 1 !== index && types.length - 2 !== 0
              ? ","
              : orLabel + " "
              }${quotes}${newValue}${quotes}`;
          }
        }
      );
    } else {
      let capitalizedArray = types.map((type: string) => {
        if (isSvg) {
          return `<div class="ImageInstallments__copy_${lowerCase(
            type
          )}"></div>`;
        }
        else {
          return capitalize(type)
        }
      });
      paymentTypes = capitalizedArray.reduce(
        (acc: string, current: string, index: number) => {
          let newValue = current;
          if (index !== types.length - 1) {
            return `${quotes}${"<span>" + acc + "</span>"}${quotes}${types.length - 1 !== index ? ",&nbsp;" : orLabel + "&nbsp;"
              }${quotes}${"<span>" + newValue + "</span>"}${quotes}`;
          } else {
            return `${quotes}${"<span>" + acc + "</span>"}${quotes} ${types.length - 1 !== index && types.length - 2 !== 0
              ? ","
              : orLabel + "&nbsp;"
              }${quotes}${"<span>" + newValue + "</span>"}${quotes}`;
          }
        }
      );
    }
    return initialString
      .replace("###PAYMENT_TYPE###", paymentTypes)
      .replace(
        "###MONTHLY_PRICE###",
        hasMontlhyPrice ? `${currencyFormat.prefix} ${priceMonthly}` : ""
      )
      .replace("###INSTALLMENTS###", installments);
  } else {
    let newvalue = capitalize(type);
    if (isSvg) {
      newvalue = `<div class="ImageInstallments__copy_${type}"></div>`;
    }
    return initialString
      .replace(
        "###PAYMENT_TYPE###",
        isSvg
          ? `${quotes}${newvalue}${quotes}`
          : hasMontlhyPrice
            ? installments
            : `${quotes}${newvalue}${quotes}`
      )
      .replace(
        "###MONTHLY_PRICE###",
        `${currencyFormat.prefix} ${priceMonthly}`
      )
      .replace("###INSTALLMENTS###", installments);
  }
};
