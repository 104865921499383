import { useSelector } from "react-redux";
import { getFrameOnlyKey } from "../ConfigLoader";
import { useState, useEffect } from "react";
import { isFunction } from "lodash";
import { useCurrentPackages } from "./useCurrentPackages";
import { useReviewSteps } from "./useReviewStep";

export type PromoBanner = {
  desktop: string;
  mobile: string;
};
export default function usePromoBanner() {
  const [promoBanners, setPromoBanners] = useState({
    desktop: "",
    mobile: "",
  });
  const [promoInPackage, setPromoInPackage] = useState("");
  const configActionLoadContent = useSelector(
    (state: any) => state.config?.actionsModule?.loadContent
  );
  const promoType = useSelector((state: any) => state.workflow?.promoType);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);
  const currentPackages = useCurrentPackages();
  const [historyStep, setHistoryStep] = useState(null);
  const reviewSteps = useReviewSteps();

  useEffect(() => {
    if (reviewSteps) {
      let history = (reviewSteps as any).map((p: any) => ({
        id: p.key,
        title: p.title,
        originalStep: p.originalStep,
        onClick: () => {},
        strikePrice: p.price,
        offerPrice: p.price - p.discount,
        insPrice: p.insPrice,
        attributes: p.attributes,
      }));
      setHistoryStep(history);
    }
  }, [reviewSteps]);

  useEffect(() => {
    if (
      configActionLoadContent &&
      historyStep &&
      configContent &&
      currentPackages
    ) {
      let frameOnlyTitle = getFrameOnlyKey(configPackages, configData);
      if (
        frameOnlyTitle &&
        currentPackages.length &&
        currentPackages[0].lensPackage.type === frameOnlyTitle
      ) {
        return;
      } else {
        loadPromoBanner();
      }
    }
  }, [configActionLoadContent, historyStep, configContent, currentPackages]);

  const loadPromoBanner = () => {
    const packageWithPromo = currentPackages?.find((item) => {
      return item.lensPackage.promo !== undefined;
    });
    let promoKey = "";
    let promoInConfig = "";
    if (packageWithPromo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoKey = Object.keys(packageWithPromo?.lensPackage.promo)[0];
        if (promoKey !== null && promoKey !== "") {
          setPromoInPackage(promoKey);
        }
      }
    }
    if (configContent && configContent.promo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoInConfig = Object.keys(configContent?.promo)[0];
      }
    }
    const promoContent =
      promoInConfig && configContent?.promo
        ? configContent?.promo[promoInConfig].title
        : "";

    if (promoKey && promoInConfig && promoKey === promoInConfig) {
      setPromoBanners({
        desktop: promoContent || "",
        mobile: promoContent || "",
      });
    } else if (isFunction(configActionLoadContent)) {
      const contentIds = ["DESK_PromoBanner", "MOB_PromoBanner"];
      let promises: Promise<string>[] = contentIds.map((ids) =>
        configActionLoadContent(ids)
      );
      Promise.all(promises).then((htmls: string[]) => {
        setPromoBanners({
          desktop: htmls[0] || "",
          mobile: htmls[1] || "",
        });
      });
    }
  };
  return promoBanners;
}
