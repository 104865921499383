import React from 'react'
import "./default.module.scss"
import { useSelector } from 'react-redux';
import Image from '../common/Image';

interface FrameCard {
  id?: String,
  title?: String,
  imageFileName1?: String,
  sequence?: Number,
  imageFileName2?: String,
  onSelectFrameCard?: () => void,
  selected?: boolean,
  loading?: boolean
}

export function FrameCard({ id, title, imageFileName1, sequence, imageFileName2, onSelectFrameCard, selected, loading }: FrameCard) {
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  return (
    <button disabled={loading} className={`FrameCard__container ${selected ? "selected" : ""}`} onClick={onSelectFrameCard}>
      <span className="FrameCard__color">
        <Image
          imageClass={"FrameCard__color__img"}
          url={configProjectImage}
          fileName={imageFileName1 || ""}
          alt={title || ""}
          id={id}
        />
      </span>
      <span className="FrameCard__name">{title || "MISSING CONTENT"}</span>
    </button>
  )
}
