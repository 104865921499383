import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { useCurrentPrices } from "./useCurrentPrices";

export const usePaymentLabelInModal = ({
  payment,
  type,
}: {
  payment: string;
  type: string;
}) => {
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const installments = useSelector(
    (state: any) => state.config?.paymentInstallment.installments
  );
  const currentPrices = useCurrentPrices();

  const priceMonthly = insuranceEnabled
    ? currentPrices.total.insPrice / installments
    : (currentPrices.total.price - currentPrices.total.discount) / installments;

  const paymentLabel = new RegExp("###PAYMENT_TYPE###", "g");

  return payment
    .replace(paymentLabel, capitalize(type))
    .replace("###INSTALLMENTS###", installments)
    .replace(
      "###MONTHLY_PRICE###",
      `${currencyFormat.prefix}${priceMonthly.toFixed(2)}`
    );
};
