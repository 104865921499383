import React from "react";
import { StepPriceOpt } from "../StepPrice/component";
import "./default.module.scss";
import Image from "../common/Image";
import { BrandComponent } from "../common/BrandComponent";
import { useSelector } from "react-redux";
import { TranslateText } from "../common/TranslateText";

export interface IncludedTreatment {
  id: string;
  name: string;
  price?: StepPriceOpt;
  key?: string;
}

interface IncludedBoxOpt {
  imgName?: string;
  listIncluded?: IncludedTreatment[];
}

/**
 * Component that displays the box of included treatments during the Treatments selection step
 * on desktop and mobile. It accepts the following parameters of type ```IncludedBoxOpt```:
 *
 *  - ```imgName``` (_optional_): the name of the image to display in the header of the box
 * (this name is the filename of the image, and it is added to the base path of images provided in
 * the configuration file)
 *  - ```listIncluded``` (_optional_): a list of type ```IncludedTreatment``` which has the following props:
 *      * ```id```: the id of the treatment (for example _'uvprotection'_)
 *      * ```name```: the name of the treatment (which will be displayed in the box)
 *      * ```price``` (_optional_): the price of the treatment, an object with props (see ```StepPrice``` for details).
 */
export function IncludedBox({ imgName, listIncluded }: IncludedBoxOpt) {
  const genericImage = useSelector(
    (state: any) => state.config?.baseURLs?.genericImage
  );

  return (
    <div className={"IncludedBox"}>
      <p>
        <TranslateText label={"included"} />
      </p>
      {imgName && <Image url={genericImage} fileName={imgName} />}
      {listIncluded && (
        <ul>
          {listIncluded.map((iT, index) => (
            <li key={"iT_" + iT.name + "_" + index}>
              {iT.name}
              {iT.price && (
                <BrandComponent
                  componentName="StepPrice"
                  parameter={iT.price}
                />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
