import React, { useEffect, useState } from "react";
import { useCurrentPackages } from "@hooks";
import { workflow } from "../../configurator/models/Workflow";
import "./default.module.scss";

export function InsuranceTooltip(props) {
  const [showTooltip, setShowTooltip] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [tooltipX, setTooltipX] = useState(0);
  const [arrowX, setArrowX] = useState(0);

  const currentPackages = useCurrentPackages();

  useEffect(() => {
    let tooltipElement = document.getElementById(props.id + "_tooltip");
    if (tooltipElement) {
      tooltipElement.addEventListener(
        "animationend",
        handleAnimationEnd,
        false
      );
    }

    window.addEventListener("resize", calculateTooltipPosition);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      let scrollViewElements = document.getElementsByClassName(
        "ScrollView__container"
      );
      let scrollViewElement =
        scrollViewElements && scrollViewElements.length
          ? scrollViewElements[0]
          : null;
      if (scrollViewElement) {
        scrollViewElement.removeEventListener("scroll", handleScroll);
      }

      let mobileScrollViewElements = document.getElementsByClassName(
        "ScrollView__scrollbar"
      );
      let mobileScrollViewElement =
        mobileScrollViewElements && mobileScrollViewElements.length
          ? mobileScrollViewElements[0]
          : null;
      if (mobileScrollViewElement) {
        mobileScrollViewElement.removeEventListener("scroll", handleScroll);
      }

      if (tooltipElement) {
        tooltipElement.removeEventListener(
          "animationend",
          handleAnimationEnd,
          false
        );
      }

      window.removeEventListener("resize", calculateTooltipPosition);
    };
  }, []);

  useEffect(() => {
    let scrollViewElements = document.getElementsByClassName(
      "ScrollView__container"
    );
    let scrollViewElement =
      scrollViewElements && scrollViewElements.length
        ? scrollViewElements[0]
        : null;
    let mobileScrollViewElements = document.getElementsByClassName(
      "ScrollView__scrollbar"
    );
    let mobileScrollViewElement =
      mobileScrollViewElements && mobileScrollViewElements.length
        ? mobileScrollViewElements[0]
        : null;
    let tooltipElement = document.getElementById(props.id + "_tooltip");
    if (showTooltip) {
      calculateTooltipPosition();
      if (scrollViewElement) {
        scrollViewElement.addEventListener("scroll", handleScroll);
      }
      if (mobileScrollViewElement) {
        mobileScrollViewElement.addEventListener("scroll", handleScroll);
      }
      if (tooltipElement) {
        tooltipElement.classList.remove("invisible");
        tooltipElement.classList.add("fadeIn");
        tooltipElement.classList.remove("fadeOut");
      }
    } else {
      if (scrollViewElement) {
        scrollViewElement.removeEventListener("scroll", handleScroll);
      }
      if (mobileScrollViewElement) {
        mobileScrollViewElement.removeEventListener("scroll", handleScroll);
      }
      if (showTooltip === false && tooltipElement) {
        tooltipElement.classList.remove("fadeIn");
        tooltipElement.classList.add("fadeOut");
      }
    }
  }, [showTooltip]);

  useEffect(() => {
    if (
      workflow.currentStep &&
      workflow.willManualSelectionBeDone() &&
      isNonPrescriptionSelected()
    ) {
      setShowTooltip(true);
      let timeoutId = setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
      setTimeoutId(timeoutId);
    } else {
      setShowTooltip(false);
    }
  }, [workflow.currentStep]);

  const isNonPrescriptionSelected = () => {
    let typeSelection = workflow
      .getAllCurrentParameterStep()
      .find((par) => par.key === "type");
    if (typeSelection && typeSelection.value === "Non_prescription") {
      return true;
    }
    return false;
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const handleScroll = () => {
    setShowTooltip(false);
  };

  const handleAnimationEnd = () => {
    let tooltipElement = document.getElementById(props.id + "_tooltip");

    if (tooltipElement.classList.contains("fadeOut")) {
      tooltipElement.classList.add("invisible");
    }
  };

  const calculateTooltipPosition = () => {
    const icon = document.getElementById(props.id + "_icon");
    const WINDOW_WIDTH =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const TOOLTIP_SIZE = 306;
    const PAGE_PADDING = 40;

    if (icon) {
      const positionX = icon.getBoundingClientRect().left;
      if (!props.displayBelow) {
        if (positionX + TOOLTIP_SIZE <= WINDOW_WIDTH - PAGE_PADDING) {
          //fits horizontally in the window
          setTooltipX(positionX - 22);
          //arrow is at default position inside the tooltip
          setArrowX(21);
        } else {
          const newTooltipX = WINDOW_WIDTH - PAGE_PADDING - TOOLTIP_SIZE;
          setTooltipX(newTooltipX - 11);
          setArrowX(positionX - newTooltipX + 11);
        }
      } else {
        const TOOLTIP_OFFSET = 60;
        const PAGE_PADDING_MOBILE = 18;
        const ARROW_SIZE = 12;
        const ICON_SIZE = 16;

        if (positionX - (TOOLTIP_SIZE - TOOLTIP_OFFSET) > PAGE_PADDING_MOBILE) {
          //fits horizontally in the window
          setTooltipX(positionX - (TOOLTIP_SIZE - TOOLTIP_OFFSET));
          //arrow is at default position inside the tooltip
          setArrowX(40);
        } else {
          const newTooltipX = (WINDOW_WIDTH - TOOLTIP_SIZE) / 2;
          setTooltipX(newTooltipX);
          setArrowX(
            TOOLTIP_SIZE -
              ARROW_SIZE -
              (positionX - newTooltipX) -
              ICON_SIZE / 2
          );
        }
      }
    }
  };

  return isNonPrescriptionAvailable() ? (
    <>
      <div
        className="InsuranceTooltip__icon"
        id={props.id + "_icon"}
        onClick={() => {
          setShowTooltip((prev) => !prev);
        }}
      />

      <div
        className="InsuranceTooltip__container invisible"
        id={props.id + "_tooltip"}
        style={{ left: tooltipX }}
      >
        <div
          className="InsuranceTooltip__container__close"
          onClick={() => setShowTooltip(false)}
        />
        <span className="InsuranceTooltip__container__text">
          Non-prescription lenses are not covered by vision insurance benefits.
        </span>
        <div
          className={
            "InsuranceTooltip__container__arrow" +
            (props.displayBelow ? "Up" : "Down")
          }
          style={props.displayBelow ? { right: arrowX } : { left: arrowX }}
        />
      </div>
    </>
  ) : null;
}
