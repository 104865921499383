import { Eq } from "fp-ts/lib/Eq";
import { IParameter } from "../meta/IParameter";

export class Parameter implements IParameter<string, any> {
  key: string;
  value: string;
  equal?: Eq<IParameter<string, any>> = {
    equals: (p1, p2) => p1.key === p2.key && p1.value === p2.value,
  };
  price?: number;
  discount?: number;
  insPrice?: number;
  attributes?: any;
  optionalAttributes?: {
    key: string;
    value: any;
  };

  constructor(
    key?: string,
    value?: any,
    price?: number,
    discount?: number,
    insPrice?: number,
    attributes?: any
  ) {
    this.key = key;
    this.value = value;
    this.price = price;
    this.discount = discount;
    this.insPrice = insPrice;
    this.attributes = attributes;
  }
}

export class ParameterWithTitle extends Parameter {
  title?: string;
  price?: number;
  discount?: number;
  skipped?: boolean;

  constructor(
    key?: string,
    value?: any,
    title?: string,
    price?: number,
    discount?: number,
    skipped?: boolean
  ) {
    super(key, value);
    this.title = title;
    this.price = price;
    this.discount = discount;
    this.skipped = skipped;
  }
}
