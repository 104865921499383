import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isArray, isFunction } from "lodash";
import { useSelector } from "react-redux";
import HtmlParser from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrandComponent } from "../common/BrandComponent";
import { isMobile } from "react-device-detect";

interface LearnMoreModalOpt {
  contentIdentifierList?: string[];
}

export function LearnMoreModal(props: LearnMoreModalOpt) {
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const [listCard, setListCard] = useState(null);
  const configActionLoadLearnMore = useSelector(
    (state: any) => state.config?.actionsModule?.loadLearnMoreContent
  );
  const contentIdsRedux = useSelector(
    (state: any) => state.modals?.learnMoreIds
  );
  const modalLearnMoreFocus = useSelector(
    (state: any) => state.modals?.learnMoreFocus
  );
  const [selectedPage, setSelectedPage] = useState(null);
  const [dynamicClassName, setDynamicClassName] = useState(null);
  const currentStep = useSelector(
    (state: any) => state?.workflow?.currentStep?.key
  );
  const [EPPModalHtmlContent, setEPPModalHtmlContent] = useState(null);

  useEffect(() => {
    if (isArray(contentIdsRedux) && contentIdsRedux.length) {
      getData(contentIdsRedux);
      const indexCard = contentIdsRedux.indexOf(modalLearnMoreFocus);
      if (indexCard === 0) {
        setDynamicClassName("LearnMoreModal__container__carouselMarginfirst");
      }

      if (contentIdsRedux.length === 1) {
        setDynamicClassName("LearnMoreModal__container__carouselMiddle");
      } else {
        if (indexCard === 0) {
          setDynamicClassName("LearnMoreModal__container__carouselMarginfirst");
        } else if (contentIdsRedux.length - 1 === indexCard) {
          setDynamicClassName("LearnMoreModal__container__carouselMarginlast");
        } else {
          setDynamicClassName("LearnMoreModal__container__carouselMiddle");
        }
      }
      setSelectedPage(indexCard);
    }
  }, [contentIdsRedux]);

  useEffect(() => {
    if (listCard) {
      injectArrows(listCard.length);
    }
  }, [listCard]);

  useEffect(() => {
    if (selectedPage && listCard) {
      if (listCard.length === 1) {
        setDynamicClassName("LearnMoreModal__container__carouselMiddle");
      } else {
        if (selectedPage === 0) {
          setDynamicClassName("LearnMoreModal__container__carouselMarginfirst");
        } else if (listCard?.length - 1 === selectedPage) {
          setDynamicClassName("LearnMoreModal__container__carouselMarginlast");
        } else {
          setDynamicClassName("LearnMoreModal__container__carouselMiddle");
        }
      }
    }
  }, [selectedPage, listCard]);

  useEffect(() => {
    if (EPPModalHtmlContent) {
      const closeButton = document.getElementById("closeModal")
      closeButton.addEventListener("click", closeModal)
      return () => closeButton.removeEventListener("click", closeModal)
    }
  }, [EPPModalHtmlContent])

  const closeModal = () => setEPPModalHtmlContent(null)

  const handleOverlayClick = () => {
    closeModal()
  }

  const removeCommentsFromResponse = (response) => {
    if (response.indexOf("<!--") < 0) {
      return response;
    } else {
      let splitByEndComment = response.split("-->");
      let splitByBeginComment = splitByEndComment
        .map((str) => {
          return str.split("<!--")[0];
        })
        .filter((str) => {
          return str.trim() !== "";
        });
      return splitByBeginComment.join("");
    }
  };

  const getData = (contentIds: string[]) => {
    if (isFunction(configActionLoadLearnMore)) {
      let promises: Promise<string>[] = contentIds.map((ids) =>
        configActionLoadLearnMore(ids)
      );
      Promise.all(promises).then((htmls: string[]) => {
        if (isArray(htmls)) {
          if (currentStep.toLowerCase() === "protectionplan") {
            const closeButton = '<div id="closeModal" class="close"></div>'
            const [firstEl, ...rest] = htmls[0]?.split("\n")
            setEPPModalHtmlContent([firstEl, closeButton, ...rest].join(""))
          } else {
            let cardsToSet = [];
            htmls.map((content) => {
              let cleanedContent = removeCommentsFromResponse(content);

              let espotVideoMade = null;
              let espotVideoMadeTap = null;
              let espotVideoTreatment = null;
              let espotVideoTreatmentTap = null;
              let contentArray = cleanedContent?.split("</div>");
              for (let i = 0; i < contentArray.length - 1; i++) {
                contentArray[i] += "</div>";
                let id = contentArray[i]?.split('class="')?.[1]?.split('"')?.[0];
                switch (id) {
                  case "background-learn-more-made":
                    espotVideoMade = contentArray[i];
                    break;
                  case "background-learn-more-treatment":
                    espotVideoTreatment = contentArray[i].replace(
                      "background-learn-more-treatment",
                      "background-learn-more-treatment card-invisible"
                    );
                    break;
                  case "background-learn-more-made-tap":
                    espotVideoMadeTap = contentArray[i].replace(
                      "background-learn-more-made-tap",
                      "background-learn-more-made-tap card-invisible"
                    );
                    break;
                  case "background-learn-more-treatment-tap":
                    espotVideoTreatmentTap = contentArray[i].replace(
                      "background-learn-more-treatment-tap",
                      "background-learn-more-treatment-tap card-invisible"
                    );
                    break;
                }
              }
              let cards = [
                espotVideoMade,
                espotVideoMadeTap,
                espotVideoTreatment,
                espotVideoTreatmentTap,
              ];
              let cardsNotNull = cards.filter((card) => {
                return card !== null;
              });
              cardsToSet = [...cardsToSet, cardsNotNull];
            });
            setListCard(cardsToSet);
          }
        }
      });
    }
  };

  const injectArrows = (slidesCount: number) => {
    setTimeout(() => {
      if (
        !document.getElementsByClassName("control-dots") ||
        !document.getElementsByClassName("control-dots")[0]
      ) {
        injectArrows(slidesCount);
      } else {
        let initialSelectedPage = contentIdsRedux.indexOf(modalLearnMoreFocus);

        let leftButton = document.createElement("div");
        leftButton.onclick = (e) => {
          setSelectedPage((selectedPage) =>
            selectedPage != null
              ? selectedPage - 1
              : contentIdsRedux.indexOf(modalLearnMoreFocus) - 1
          );
        };
        leftButton.className =
          "LearnMoreModal__leftCarouselArrow" +
          (initialSelectedPage === 0 ? " invisible" : "");
        leftButton.id = "learnMoreLeftButtonCarouselId";

        let rightButton = document.createElement("button");
        rightButton.onclick = (e) => {
          setSelectedPage((selectedPage) =>
            selectedPage != null
              ? selectedPage + 1
              : contentIdsRedux.indexOf(modalLearnMoreFocus) + 1
          );
        };
        rightButton.className =
          "LearnMoreModal__rightCarouselArrow" +
          (initialSelectedPage === slidesCount - 1 ? " invisible" : "");
        rightButton.id = "learnMoreRightButtonCarouselId";

        document.getElementsByClassName("control-dots")[0].prepend(leftButton);
        document.getElementsByClassName("control-dots")[0].append(rightButton);
      }
    }, 50);
  };

  const changeArrowsVisibility = (index) => {
    let leftButton = document.getElementById("learnMoreLeftButtonCarouselId");
    let rightButton = document.getElementById("learnMoreRightButtonCarouselId");

    if (index === 0) {
      if (leftButton) {
        if (leftButton.className.indexOf("invisible") === -1) {
          leftButton.className = leftButton.className + " invisible";
        }
      }
    } else if (index !== 0) {
      if (leftButton) {
        leftButton.className = leftButton.className.replace("invisible", "");
      }
    }
    if (listCard && index === listCard.length - 1) {
      if (rightButton) {
        if (rightButton.className.indexOf("invisible") === -1) {
          rightButton.className = rightButton.className + " invisible";
        }
      }
    } else {
      if (rightButton) {
        rightButton.className = rightButton.className.replace("invisible", "");
      }
    }
  };

  const CardInfoPanel = (props) => {
    let contentId = props.contentIdentifier
      .replace("DESK_", "")
      .replace("MOB_", "");
    let contentData = null;

    Object.keys(configContent).map((content) => {
      let contentToRet =
        configContent[content] &&
        Object.keys(configContent[content]).filter((subContent) => {
          return (
            configContent[content][subContent].contentIdentifier === contentId
          );
        });
      if (!contentData && contentToRet) {
        contentData = configContent[content][contentToRet[0]];
      }
    });

    let title = "";
    let body = "";
    if (contentData) {
      title = contentData.title;
      body = contentData.longDescription;
      if (!body) {
        body = contentData.description;
      }
    }
    return (
      <div className={"CardInfoPanel__container"}>
        <div className={"CardInfoPanel__title"}>{title}</div>
        <div className={"CardInfoPanel__body"}>
          {body ? HtmlParser(body) : ""}
        </div>
      </div>
    );
  };

  return (
    <>
      {listCard && (
        <div
          className="LearnMoreModal__container"
          data-analytics_available_call="0"
        >
          <Carousel
            className={dynamicClassName}
            showArrows={false}
            centerMode
            centerSlidePercentage={!isMobile ? 70 : 87} //70 DESKTOP 87 MOBILE
            showThumbs={false}
            selectedItem={
              selectedPage === null
                ? contentIdsRedux.indexOf(modalLearnMoreFocus)
                : selectedPage
            }
            onChange={(index) => {
              changeArrowsVisibility(index);

              if (listCard.length === 1) {
                setDynamicClassName(
                  "LearnMoreModal__container__carouselMiddle"
                );
              } else {
                if (listCard?.length - 1 === index) {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMarginlast"
                  );
                } else if (index === 0) {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMarginfirst"
                  );
                } else {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMiddle"
                  );
                }
              }
            }}
            onClickItem={(index) => {
              changeArrowsVisibility(index);

              if (listCard.length === 1) {
                setDynamicClassName(
                  "LearnMoreModal__container__carouselMiddle"
                );
              } else {
                if (selectedPage !== index) {
                  setSelectedPage(index);
                }

                if (listCard?.length - 1 === index) {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMarginlast"
                  );
                } else if (index === 0) {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMarginfirst"
                  );
                } else {
                  setDynamicClassName(
                    "LearnMoreModal__container__carouselMiddle"
                  );
                }
              }
            }}
            autoPlay={true}
            interval={3000000} //workaround due to bug in carousel, see https://github.com/leandrowd/react-responsive-carousel/pull/583
          >
            {listCard &&
              listCard.map((htmlList, carouselIndex) => (
                <>
                  <BrandComponent
                    componentName="LearnMoreVideoList"
                    parameter={{
                      data: htmlList,
                      carouselIndex: carouselIndex,
                      selectedCarouselPage: selectedPage,
                    }}
                  />

                  <CardInfoPanel
                    contentIdentifier={contentIdsRedux[carouselIndex]}
                  />
                </>
              ))}
          </Carousel>
        </div>
      )}
      {
        EPPModalHtmlContent && (
          <div className="EPPModal">
            <div
              className="overlay"
              onClick={handleOverlayClick}
            />
            {HtmlParser(EPPModalHtmlContent)}
          </div>
        )
      }
    </>
  );
}
