import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "../../redux/slices/config";
import { mixAndMatchActions } from "../../redux/slices/mixAndMatch";

export function useDynamicPackages() {
  const reduxDispatch = useDispatch();
  const config = useSelector((state: any) => state.config)

  const getPackages = useSelector((state: any) => state.config?.actionsModule?.getPackages)
  const getDynamicPackages = useCallback((frame) => {
    reduxDispatch(mixAndMatchActions.setLoadingPackages(true));
    getPackages(frame || config.data.frame).then(packages => {
      let configClone = cloneDeep(config);
      configClone.lensesData.packages = packages
      configClone.data.frame = frame
      reduxDispatch(configActions.changeOptions(configClone));
      reduxDispatch(mixAndMatchActions.setLoadingPackages(false));
    })
    //configClone.lensesData.packages = await getPackages(frame || config.data.frame);

  }, [getPackages, config])
  return getDynamicPackages
}