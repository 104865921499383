import { useSelector } from "react-redux";
import { useProductImage } from "./useProductImage";

export function useImagery() {
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  // const { urlImage: productImageUrl } = useProductImage();
  const productImageUrl = useSelector((state: any) => state.color.urlImage);
  const frameImageUrl = useSelector((state: any) => state.config?.data?.frame?.imageUrl);

  const getImagery = (pdImageUrl, swatchImageObj) => {
    let url = null;
    if (swatchImage && showFallbackImageMessage && productImageUrl) {
      url = swatchImageObj.url.split(",")[0].split(" ")[0];
    }
    return {
      productImage: pdImageUrl || frameImageUrl,
      fallbackImage: url,
    };
  };

  return getImagery(productImageUrl, swatchImage);
}
