import React from "react";
import { TranslateText } from "../common/TranslateText";
import { StepCardProps } from "../StepCard/component";
import "./default.module.scss";

export interface StepBreadcrumbOpt {
  pills: StepCardProps[];
}

/**
 * Component handling the breadcrumb with steps on both desktop (by showing the completed
 * steps during the configuration) and mobile (by showing the scrollable list of pills on
 * top of the screen). It accepts an input of type ```StepBreadcrumbOpt``` with one parameter:
 *  - ```pills```: an array of type ```StepCardProps``` (see the ```StepCard``` component for
 *  details)
 */
export function StepBreadcrumb({ pills }: StepBreadcrumbOpt) {
  /*
    const renderDesktop = () => {
        return <>
            {pills && pills.map((stepPill, index) => (
                <div key={stepPill.id + '_desktop'} className={"StepBreadcrumb_wrapper"}>
                    <div key={stepPill.id + '_desktop' + index} id={stepPill.id} className={"StepBreadcrumb_confirmed"}>
                        <button className={"StepBreadcrumb_icon"} data-description={stepPill.id} data-element-id="X_X_LensPanel_Performance-Back" tabIndex={0}></button>
                        <div className={"StepBreadcrumb_confirmed_container"}>
                            <div className={"StepBreadcrumb_confirmed_title"}>
                                <span><TranslateText label={`steps.${stepPill.id}.confirmedTitle`} /></span>
                            </div>
                            <div className={"StepBreadcrumb_confirmed_selection"}>
                                <span>{stepPill.title}</span>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} onClick={() => { stepPill.onClick(stepPill) }} data-element-id="X_X_LensPanel_Performance-Back" data-description={stepPill.id} className={"StepBreadcrumb_edit"}>
                        <TranslateText label="editButton" />
                    </div>
                </div>

            ))}
            { pills && pills.length > 0 && <div className={"StepBreadcrumb_divider"}></div>}
        </>
    }

    const renderMobile = () => {
        return <div key={"breadcrumbWrapperMobile"} className={"StepBreadcrumb_wrapperMobile"}>
            {pills && pills.map((stepPill, index) => (
                <div key={stepPill.id + '_mobile' + index} id={stepPill.id} className={"StepBreadcrumb_confirmed"} onClick={() => { stepPill.onClick(stepPill) }}>
                    <button className={"StepBreadcrumb_icon"} data-description={stepPill.id} data-element-id="X_X_LensPanel_Performance-Back" tabIndex={0}></button>
                    <div className={"StepBreadcrumb_confirmed_container"}>
                        <div className={"StepBreadcrumb_confirmed_title"}>
                            <span><TranslateText label={`steps.${stepPill.id}.confirmedTitle`} /></span>
                        </div>
                        <div className={"StepBreadcrumb_confirmed_selection"}>
                            <span>{stepPill.title}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    }
    */
  return (
    <div className={"StepBreadcrumb_container"}>
      {/*renderDesktop()*/}
      {/*renderMobile()*/}
    </div>
  );
}
