import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { Step } from "../../configurator/models/Step";

type TPayload = {
  steps: Step[];
};

type TInsurancePackages = {
  packages: any[];
  clearPrice?: boolean;
  frame: any;
};

type SetDiscountedPackage = {
  lensesData: {
    lensPackage: any;
    frame: any;
  };
  frame: any;
};

export type ConfigState = {
  steps?: Step[];
  imageSrcsetTemplate?: string;
  baseURLs?: Record<string, string>;
  lensPackages?: any;
  lensesData?: any;
  defaultContents?: Object;
  missingProps?: { props: string[]; catEntryId: string }[];
  prescriptionDefaults: null | { [key: string]: number | string[] };
  ctxMessages: [];
  ignoreBundles: boolean;
  nonPrescriptionPkgs: { check: boolean, pkgs: any };
};

type SetDefaultTitlePayload = PayloadAction<{
  property: string;
  value: string;
}>;
type SetMissingPropsPayload = PayloadAction<{
  missingProps: string[];
  catEntryId: string;
}>;

const initialState: ConfigState = {
  steps: null,
  imageSrcsetTemplate: null,
  baseURLs: null,
  lensPackages: null,
  lensesData: null,
  defaultContents: {},
  missingProps: [],
  prescriptionDefaults: null,
  ctxMessages: [],
  ignoreBundles: false,
  nonPrescriptionPkgs: { check: false, pkgs: [] }
};

type SetDefaultTitle = { property: string; value: string };

const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setCtxMessages: (state, { payload }) => {
      state.ctxMessages = payload;
    },
    changeSteps: (state, { payload }: PayloadAction<TPayload>) => {
      state.steps = payload.steps;
    },
    changeOptions: (state, { payload }: PayloadAction<ConfigState>) => {
      payload.lensesData.content = merge(
        payload.lensesData.content,
        state.defaultContents
      );
      if (payload.lensesData.content?.bundles) {
        payload.lensesData.content.lensBundle = payload.lensesData.content.bundles
        delete payload.lensesData.content.bundles
      }
      Object.keys(payload.lensesData.content).forEach((rootKey) => {
        payload.lensesData.content[rootKey] &&
          Object.keys(payload.lensesData.content[rootKey]).forEach(
            (innerKey) => {
              const content = payload.lensesData.content[rootKey][innerKey];
              if (!content) return;

              if (!content.title) content.title = "MISSING CONTENT";
              if (!content.description) content.description = "MISSING CONTENT";
            }
          );
      });

      const { missingProps } = state;
      const { lensesData } = payload;
      missingProps.forEach(({ props, catEntryId }) => {
        for (let i = 0; i < lensesData.packages.length; i++) {
          const pack = lensesData.packages[i].lensPackage;
          if (pack.catEntryId !== catEntryId) continue;
          props.forEach((prop) => {
            pack[prop] = null;
          });
          payload.lensesData.packages[i].lensPackage = pack;
        }
      });

      // replace "" with null
      for (let i = 0; i < lensesData.packages.length; i++) {
        const pack = lensesData.packages[i].lensPackage;
        Object.entries(pack).forEach(([key, val]) => {
          if (val === "") {
            payload.lensesData.packages[i].lensPackage[key] = null
          }
        })
      }

      return { ...state, ...payload };

    },
    changeFrame: (state, { payload }) => {
      state.data.frame = payload
    },
    changeLensPackages: (state, { payload }: PayloadAction<any>) => {
      return { ...state, lensPackages: payload };
    },
    setInsurancePackage: (
      state,
      { payload }: PayloadAction<TInsurancePackages>
    ) => {
      let copyPackages = payload.packages.map((pkg) => {
        return JSON.parse(JSON.stringify(pkg));
      });

      let mappedPayload = copyPackages.map((pkg) => {
        if (
          !payload.clearPrice &&
          !pkg.lensPackage.insPrice &&
          pkg.lensPackage.insPrice !== 0
        ) {
          pkg.lensPackage.insPrice = parseFloat(pkg.lensPackage.listPrice);
        }
        if (!pkg.frame.insPrice && pkg.frame.insPrice !== 0) {
          pkg.frame.insPrice = parseFloat(payload.frame.listPrice);
        }

        return pkg;
      });

      if (state.lensesData && state.lensesData.packages) {
        state.lensesData.packages = state.lensesData.packages.map((p) => {
          const findedByUpc = mappedPayload.find(
            (insPackage) =>
              insPackage.lensPackage &&
              insPackage.lensPackage.upc === p.lensPackage.upc
          );
          if (findedByUpc) return findedByUpc;
          return p;
        });
      }
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      /* Object.keys(initialState).forEach(key => {
                state[key] = initialState[key];
            }) */
      return initialState;
    },
    addDefaultContent: (state, { payload }: SetDefaultTitlePayload) => {
      state.defaultContents[payload.property] = {
        ...state.defaultContents[payload.property],
        [payload.value]: {
          imageFileName: "image",
          title: payload.property === "frameColor" ? "MISSING CONTENT" : payload.value,
          description: "MISSING CONTENT",
          promoBadge: null,
          sequence: null,
          contentIdentifier: null,
          longDescription: null,
          badge: null,
        },
      };
    },

    setMissingProps: (state, { payload }: SetMissingPropsPayload) => {
      const { missingProps, catEntryId } = payload;
      state.missingProps.push({ catEntryId, props: missingProps });
    },

    setDiscountPackage: (
      state,
      { payload }: PayloadAction<SetDiscountedPackage>
    ) => {
      if (state.lensesData && state.lensesData.packages) {
        state.lensesData.packages = state.lensesData.packages.map(
          (packageState) => {
            if (
              packageState.lensPackage?.catEntryId ===
              payload?.lensesData.lensPackage.catEntryId
            ) {
              return payload.lensesData;
            }
            return packageState;
          }
        );
      }
    },
    setIgnoreBundles: (state, { payload }) => {
      state.ignoreBundles = payload
    },
    setNonPrescriptionPkgs: (state, { payload }: PayloadAction<any>) => {
      state.nonPrescriptionPkgs = { check: payload.check, pkgs: payload.pkgs }
    }
  },
});

export const configReducer = configSlice.reducer;
export const configActions = configSlice.actions;
