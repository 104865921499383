import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

/**
 * @param props.imageClass
 * @param props.url
 * @param props.fileName
 * @param props.handleClick
 * @param props.alt
 * @param props.style
 * @param props.id
 * @param props.onMouseOver
 * @param props.onMouseLeave
 * @param props.onLoad
 */
export default function Image(props: any) {
  const imageSrcsetTemplate = useSelector(
    (state: any) => state.config?.imageSrcsetTemplate
  );
  const imgRef = useRef();
  const withExtension = props?.fileName?.indexOf(".") > 0;
  const extension = withExtension ? props.fileName?.split(".")[1] : "png";

  const src = `${props.url}${props.fileName}${
    withExtension ? "" : `.${extension}`
  }`;

  const fileName = withExtension
    ? props.fileName.split(".")?.[0]
    : props.fileName;

  const hideLoader = () => {
    const imgEl = imgRef?.current;
    if (imgEl) {
      imgEl.parentElement.classList.add("hideLoader");
    }
  };

  return props.fileName && imageSrcsetTemplate ? (
    // !props.crossOrigin ? (
    //   <img
    //     ref={imgRef}
    //     className={props.imageClass}
    //     crossOrigin=""
    //     src={src}
    //     onLoad={() => (props.onLoad ? props.onLoad() : hideLoader())}
    //     srcSet={imageSrcsetTemplate
    //       .replace("{url}", props.url)
    //       .replace("{url}", props.url)
    //       .replace("{filename}", fileName)
    //       .replace("{filename}", fileName)
    //       .replace("{format}", extension)
    //       .replace("{format}", extension)}
    //     onClick={props.handleClick ? props.handleClick : null}
    //     alt={props.alt}
    //     style={props.style}
    //     id={props.id}
    //     onMouseOver={() =>
    //       props.onMouseOver ? props.onMouseOver(props.id, props.alt) : {}
    //     }
    //     onMouseLeave={() => (props.onMouseLeave ? props.onMouseLeave() : {})}
    //   />
    // ) : (
    //   <img
    //     ref={imgRef}
    //     className={props.imageClass}
    //     src={src}
    //     onLoad={() => (props.onLoad ? props.onLoad() : hideLoader())}
    //     srcSet={imageSrcsetTemplate
    //       .replace("{url}", props.url)
    //       .replace("{url}", props.url)
    //       .replace("{filename}", fileName)
    //       .replace("{filename}", fileName)
    //       .replace("{format}", extension)
    //       .replace("{format}", extension)}
    //     onClick={props.handleClick ? props.handleClick : null}
    //     alt={props.alt}
    //     style={props.style}
    //     id={props.id}
    //     onMouseOver={() =>
    //       props.onMouseOver ? props.onMouseOver(props.id, props.alt) : {}
    //     }
    //     onMouseLeave={() => (props.onMouseLeave ? props.onMouseLeave() : {})}
    //   />
    // )
    <img
      ref={imgRef}
      className={props.imageClass}
      src={src}
      onLoad={() => (props.onLoad ? props.onLoad() : hideLoader())}
      srcSet={imageSrcsetTemplate
        .replace("{url}", props.url)
        .replace("{url}", props.url)
        .replace("{filename}", fileName)
        .replace("{filename}", fileName)
        .replace("{format}", extension)
        .replace("{format}", extension)}
      onClick={props.handleClick ? props.handleClick : null}
      alt={props.alt}
      style={props.style}
      id={props.id}
      crossOrigin=""
      onMouseOver={() =>
        props.onMouseOver ? props.onMouseOver(props.id, props.alt) : {}
      }
      onMouseLeave={() => (props.onMouseLeave ? props.onMouseLeave() : {})}
    />
  ) : null;
}
