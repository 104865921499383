import React, { FC } from "react";
import Input from "../common/Input";
import Select from "../common/Select";

type StateInputProps = {
  isSelect: boolean;
  currentLanguage: string;
  options?: string[];
  isValidTick: boolean;
  isError: boolean;
  country: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  labelClassName?: string;
  whiteBG?: boolean;
};

const CallMyDoctorStateInput: FC<StateInputProps> = ({
  isSelect,
  currentLanguage,
  options,
  isValidTick,
  isError,
  country,
  label,
  labelClassName,
  children,
  ...props
}) => {
  return (
    <>
      {isSelect ? (
        <Select
          options={options}
          isValidTick={isValidTick}
          isError={isError}
          value={country}
          label={label}
          currentLanguage={currentLanguage}
          labelClassName={labelClassName ?? null}
          {...props}
        />
      ) : (
        <Input
          isValidTick={isValidTick}
          isError={isError}
          type="text"
          placeholder=" "
          label={label}
          labelClassName={labelClassName ?? null}
          {...props}
          style={
            props.whiteBG
              ? { backgroundColor: "white" }
              : { backgroundColor: "transparent" }
          }
        />
      )}
    </>
  );
};

export default CallMyDoctorStateInput;
