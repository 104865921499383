import React, { useEffect, useMemo, useState } from "react";
import "./default.module.scss";
import { modalsActions } from "../../redux/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { useBundles } from "@hooks";
import Image from "../common/Image";

export function BundleReviewSeeDetailsModal(props) {
  const reduxDispatch = useDispatch();
  const { bundles } = useBundles();
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const bundle = useMemo(() => {
    if (bundles?.length) {
      return bundles?.find(b => b.id === props.id)
    }
    return {}
  }, [bundles])



  return (
    <div className="BundleReviewSeeDetailsModal">
      <div
        className={"BundleReviewSeeDetailsModal__container__overlay"}
      ></div>
      <div
        className={
          "BundleReviewSeeDetailsModal__container__popup"
        }
      >
        <a
          className={"BundleReviewSeeDetailsModal__container__exit"}
          onClick={() =>
            reduxDispatch(
              modalsActions.setShowBundleReviewSeeDetails(false)
            )
          }
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z"
              fill="#222222"
              stroke="#222222"
              strokeWidth="0.75"
            />
          </svg>
        </a>
        <div className={"BundleReviewSeeDetailsModal__container__title"}>
          {bundle?.longTitle}
        </div>
        <div className={"BundleReviewSeeDetailsModal__container__img"}>
          <Image
            imageClass={`StepCard__layout__imgcontainer__img`}
            url={configProjectImage}
            fileName={bundle?.imageFileName1}
            alt={bundle?.imageFileName1}
          />
        </div>
        <div
          className={
            "BundleReviewSeeDetailsModal__container__description__text"
          }
          dangerouslySetInnerHTML={{ __html: bundle?.description }}
        >
        </div>
      </div>
    </div>
  );
}


