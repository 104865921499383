import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useCurrentPrices } from "./useCurrentPrices";

const usePriceDiscountLabel = (discount) => {
  const currentPrices = useCurrentPrices();
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat.prefix
  );
  const savingLabel = useTranslate("steps.review.moneySavingsBadge").replace(
    "###AMOUNT###",
    currencyFormat +
      (discount
        ? discount?.toFixed(2).toString()
        : currentPrices?.total?.discount.toFixed(2).toString())
  );
  return savingLabel;
};

export default usePriceDiscountLabel;
