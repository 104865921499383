import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import HtmlParser from "html-react-parser";

export const CallMyDoctorReadOnly = (props) => {
  const doctorNotFoundTitle = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessage"
  );
  const doctorNotFoundAdd = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessageAdd"
  );
  const doctorNotFoundLink = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessageLink"
  );
  const doctorNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.doctorName"
  );
  const clinicNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.clinicName"
  );
  const addressTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.address"
  );
  const phoneTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phone"
  );
  const faxTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.fax"
  );
  const chooseTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.chooseButton"
  );

  if (props?.props?.name && props?.props?.clinic && props?.props?.phone) {
    let params = props.props;
    return (
      props && (
        <div className="SearchResult">
          <table>
            <thead>
              <tr>
                <th scope="col">{doctorNameTableLabel}</th>
                <th scope="col">{clinicNameTableLabel}</th>
                <th scope="col">{addressTableLabel}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td key={params.name} data-label={doctorNameTableLabel}>
                  {params.name}
                </td>
                <td key={params.clinic} data-label={clinicNameTableLabel}>
                  {params.clinic}
                </td>
                <td key={params.address} data-label={addressTableLabel}>
                  {" "}
                  {HtmlParser(
                    params.address +
                      "<br>" +
                      phoneTableLabel +
                      ": " +
                      params.phone +
                      "<br>" +
                      faxTableLabel +
                      ": " +
                      params.fax
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  } else {
    return (
      <div className="DoctorNotFound__box">
        <div className="DoctorNotFound__box__container">
          <div className="DoctorNotFound__box__container__img"></div>
          <div className="DoctorNotFound__box__container__plainText">
            {doctorNotFoundTitle}
          </div>
        </div>
      </div>
    );
  }
};
