export enum RIAAPICallType {
  SINGLE_LP,
  MULTIPLE_LP,
}

export enum RIAAPICallStatus {
  RUNNING = 0,
  OK = 1,
  KO = -1,
}

export interface RIAAPICall {
  type: RIAAPICallType;
  packages: any[];
}
