import React, { useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { workflowActions } from "../../redux/slices/workflow";
import { workflow } from "../../configurator/models/Workflow";
import { useCurrentType, useEnableBrandLastStep } from "../../configurator/hooks";
import { checkGVPForType } from "../../configurator/ConfigLoader";
import { prescriptionActions } from "../../redux/slices/prescription";

export function ProceedWithNoPowerModal(){
  const modalTitle = useTranslate("steps.advancedPrescription.manual.noPowerModal.title");
  const modalSubtitle = useTranslate("steps.advancedPrescription.manual.noPowerModal.subtitle");
  const modalContinue = useTranslate("steps.advancedPrescription.manual.noPowerModal.continue");
  const modalCancel = useTranslate("steps.advancedPrescription.manual.noPowerModal.cancel");

  const reduxDispatch = useDispatch();
  
  const currentType = useCurrentType();
  const brandLastStep = useEnableBrandLastStep();
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const state = useSelector(
    (state:any) => state
  );
  console.log(state);

  const setNonPrescriptionChoise = () => {
    const parameters =  [{
      key: "type",
      value: "Non_prescription",
    }]
    if(currentType === "SUNGLASSES"){
      workflow.goToStep("LensColor", parameters, false);
    } else {
      if(brandLastStep){
        //Prendere tutti i pacchetti non prescription e continuare i check su quale step atterrare
        if (checkGVPForType(configPackages, "Non_prescription")) {
          reduxDispatch(workflowActions.setPromoType("GVP"));
          workflow.goToStep("GVPTreatment", parameters, true);
        } else {
          workflow.goToStep("TreatmentsFamily", parameters, true);
        }
      } else {
        workflow.goToStep("Brand", parameters, true);
      }
    }
  }

  return(
    <div className="ProceedWithNoPowerModal__overlay">
      <div className="ProceedWithNoPowerModal__overlay__container">
        <div className="ProceedWithNoPowerModal__overlay__container__close" 
          onClick={() => {reduxDispatch(modalsActions.setShowProceedWithNoPowerModal(false))}}
        />
        <div className="ProceedWithNoPowerModal__overlay__container__title">{modalTitle}</div>
        <div className="ProceedWithNoPowerModal__overlay__container__subtitle">{modalSubtitle}</div>
        <div className="ProceedWithNoPowerModal__overlay__container__buttonsGroup">
          <div className="ProceedWithNoPowerModal__overlay__container__buttonsGroup__cancel"
            onClick={() => {reduxDispatch(modalsActions.setShowProceedWithNoPowerModal(false))}}
          >
            {modalCancel}
          </div>
          <div 
            className="ProceedWithNoPowerModal__overlay__container__buttonsGroup__continue"
            onClick={() => {setNonPrescriptionChoise()}}
            >{modalContinue}</div>
        </div>
      </div>
    </div>
  )

}