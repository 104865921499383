import { Adapter } from "../models/Adapter";
import { Parameter } from "../models/Parameter";

export interface InsuranceFrame {
  partNumber: string;
  listPrice: number;
}
export interface Frame {
  upc: string;
  listPrice: number;
}

class FrameToInsuranceFrameAdapter extends Adapter<Frame, InsuranceFrame> {
  adapt: (source: Frame) => InsuranceFrame = (source: Frame) => {
    return {
      ...source,
      listPrice: source?.listPrice,
      partNumber: source?.upc,
    };
  };
  adaptReverse: (source: InsuranceFrame) => Frame = (
    source: InsuranceFrame
  ) => {
    return {
      listPrice: source.listPrice,
      upc: source?.partNumber,
    };
  };
}

export const frameToInsuranceFrameAdapter = new FrameToInsuranceFrameAdapter();
