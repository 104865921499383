import React from "react";
type CustomCheckboxProps = {
  value;
  formErrors?;
  setter;
  setFormErrors?;
  disabled?: boolean;
  formErrorName?: string;
  marginTop?;
  showBothPdOption?: Function;
};
export const CustomCheckbox = (props: CustomCheckboxProps) => {
  const isChecked = props.value
    ? typeof props.value === "boolean"
      ? props.value
      : props.value.value
    : false;

  return (
    <div
      tabIndex={0}
      className={
        "CustomCheckbox__checkbox" +
        (isChecked ? " checked" : "") +
        (props.formErrorName && props.formErrors[props.formErrorName]
          ? " error"
          : "") +
        (props.marginTop ? " marginTop" : "") +
        (props.disabled ? " disabled" : "")
      }
      onClick={() => {
        if (props.disabled) return;
        if (typeof props.value === "boolean") {
          props.setter(!props.value);
        } else {
          props.setter({
            value: !props.value.value,
            ignoreSplitLogic: true,
          });
        }
        if (props.formErrorName && props.formErrors[props.formErrorName]) {
          props.setFormErrors({
            ...props.formErrors,
            [props.formErrorName]: false,
          });
        }
      }}
      onKeyPress={() => {
        if (props.disabled) return;
        if (typeof props.value === "boolean") {
          props.setter(!props.value);
        } else {
          props.setter({
            value: !props.value.value,
            ignoreSplitLogic: true,
          });
        }
        if (props.formErrorName && props.formErrors[props.formErrorName]) {
          props.setFormErrors({
            ...props.formErrors,
            [props.formErrorName]: false,
          });
        }
      }}
    ></div>
  );
};
