import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { mixAndMatchActions } from "../../redux/slices/mixAndMatch";
import { isFunction } from "lodash";

export function useMixAndMatch() {
  const dispatch = useDispatch();
  const getPackages = useSelector(
    (state: any) => state?.config?.actionsModule?.getPackages
  );
  const frames = useSelector((state: any) => state?.config?.data?.frames);

  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const externalSelectedPackage = useSelector(
    (state: any) => state.config.data?.lens?.catEntryId
  );

  const frameColors = useSelector(
    (state: any) => state.config?.lensesData?.content?.frameColor
  );

  useEffect(() => {
    if (!isFunction(getPackages)) {
      //console.warn("RB - Mix&Match - Missing getPackages function in actionsModule")
    }

    if (!frames || !Array.isArray(frames)) {
      //console.warn("RB - Mix&Match - Missing data.frames")
    }
  }, [getPackages, frames])

  const isMixAndMatch = useMemo(() => {
    const selectedPackage = configPackages?.find(
      (p) => p.lensPackage.catEntryId === externalSelectedPackage
    );
    if (selectedPackage) {
      dispatch(mixAndMatchActions.setExternalSelectedPackage(selectedPackage));
    }

    if (
      isFunction(getPackages) &&
      ((frames && !Array.isArray(frames) &&
        selectedPackage?.lensPackage?.type === "Non_prescription" &&
        frameColors) ||
        Array.isArray(frames))
    ) {
      return true;
    } else {
      return false;
    }
  }, [getPackages, frames, configPackages, externalSelectedPackage, frameColors]);



  return isMixAndMatch;
  //return false
}
