import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";

export function GrayOutBanner() {
  const message = useTranslate("grayOutBannerMessage");

  return (
    <div className="GrayOutBanner__container">
      <div className="GrayOutBanner__container__icon"></div>
      <div className="GrayOutBanner__container__message">{message}</div>
    </div>
  );
}
