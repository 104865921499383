import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";
import { useCurrentPackages } from "./useCurrentPackages";

export function useAllCurrentPackages() {
  const config = useSelector((state: any) => state.config);
  const currentPackages = useCurrentPackages(true);
  const [packages, setPackages] = useState(
    currentPackages && currentPackages.validRXPackages
      ? {
          validRXPackages: currentPackages?.validRXPackages,
          invalidRXPackages: currentPackages?.invalidRXPackages,
        }
      : null
  );
  // const getGreyOutCookie = () => {
  //     var nameEQ = "enableGrayout=";
  //     var ca = document.cookie.split(";");
  //     for (var i = 0; i < ca.length; i++) {
  //         var c = ca[i];
  //         while (c.charAt(0) == " ") c = c.substring(1, c.length);
  //         if (c.indexOf(nameEQ) == 0)
  //             return (
  //                 c.substring(nameEQ.length, c.length).toLowerCase() ===
  //                 "true"
  //             );
  //     }
  //     return null;
  // };

  const getGreyOutParam = () => {
    return config?.layoutSettings?.enableGrayout
      ? config?.layoutSettings?.enableGrayout
      : false;
  };

  useEffect(() => {
    if (config && currentPackages && !isArray(currentPackages)) {
      let isGreyOut = getCookieByName("enableGrayout") || getGreyOutParam();
      setPackages({
        validRXPackages: currentPackages.validRXPackages,
        invalidRXPackages: isGreyOut ? currentPackages.invalidRXPackages : [],
      });
    }
  }, [currentPackages, config]);

  return packages;
}
