import React from 'react';
import { isObject } from "lodash";

const convertToCSV = (data) => {
  const header = Object.keys(data[0]).join(',') + '\n';
  const body = data.map(obj => Object.values(obj).join(',')).join('\n');
  return header + body;
};

const ExportCSVButton = ({ data, filename }) => {
  const formattedData = (data) => {
    const _data = data.map(pkg => {
      let _pkg = pkg
      if (_pkg.promo) {
        _pkg = { ..._pkg, promo: Object.keys(_pkg.promo)[0] }
      }
      if (_pkg.rxValues) {
        let _rxValues = {}
        Object.entries(_pkg.rxValues).forEach(([key, val]) => {
          if (isObject(val)) {
            return Object.entries(val).forEach(([key2, val2]) => {
              _rxValues[key + "_" + key2] = val2
            })
          } else {
            _rxValues[key] = val
          }
        })
        _pkg = { ..._pkg, ..._rxValues }
      }
      delete _pkg.rxValues
      return _pkg
    })
    return _data
  }
  // Function to handle the export action
  const handleExport = () => {
    const csv = convertToCSV(formattedData(data));
    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = filename || 'data.csv';
    link.click();

    URL.revokeObjectURL(csvUrl);
  };

  return (
    <button onClick={handleExport}>Export CSV</button>
  );
};


export default ExportCSVButton;