import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";
import NumberFormat from "react-currency-format";

interface PriceOpt {
  price: number;
  discount?: number;
  showSign?: boolean;
  style?: Object;
}

/**
 * Component used to display generic prices. It accepts an input of type ```PriceOpt``` with
 * 4 parameters:
 *  - ```price```: the full, non-discounted price of the object
 *  - ```discount``` (_optional_): the discount applied to the full price
 *  - ```showSign``` (_optional_): flag that enables the '+' sign before the price value
 *  - ```style``` (_optional_): object of style that overrides the style of the price
 */
export function Price({ price, discount, showSign, style }: PriceOpt) {
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  const renderPrice = (value: number) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        prefix={currencyFormat.suffix ? "" : currencyFormat.prefix}
        suffix={currencyFormat.suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        thousandSeparator={currencyFormat.thousandSeparator}
        decimalScale={
          !isNaN(Number(currencyFormat?.decimalScale))
            ? Number(currencyFormat?.decimalScale)
            : 2
        }
        fixedDecimalScale={true}
        renderText={(value: string) => <>{value}</>}
      />
    );
  };

  const getPrice = () => price - discount;

  const getClassNameForPrice = (priceNonStrike: boolean) => {
    let returnClass = "Price__container__inner__price";

    if (isInsuranceEnabled) {
      if (!priceNonStrike) {
        returnClass = returnClass + " " + "Price__container__inner__green";
      } else {
        returnClass =
          "Price__container__inner__priceNonStrike" +
          " " +
          "Price__container__inner__green";
      }
    }
    return returnClass;
  };

  const getClassNameForStrikePrice = () => {
    if (isInsuranceEnabled) {
      return (
        "Price__container__inner__strikePrice" +
        " " +
        "Price__container__inner__green"
      );
    }
    return "Price__container__inner__strikePrice";
  };

  return (
    <div className="step-button-price">
      <div className={"Price__container"}>
        <div className={"Price__container__inner"}>
          {discount ? (
            <>
              <span className={getClassNameForStrikePrice()}>
                {showSign ? "+" : ""}
                {renderPrice(price)}
              </span>
              <span
                className={getClassNameForPrice(false)}
                style={style ? style : null}
              >
                {showSign ? "+" : ""}
                {renderPrice(getPrice())}
              </span>
            </>
          ) : (
            <span className={getClassNameForPrice(true)}>
              {renderPrice(price)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
