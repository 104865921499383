import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";

const useDelegatingLearnMore = () => {
  const delegatingLearnMoreCookie = getCookieByName(
    "delegatingLearnMore",
    true
  );

  const delegatingLearnMoreConfig = useSelector(
    (state: any) => state.config.layoutSettings?.delegatingLearnMore
  );

  if (
    delegatingLearnMoreCookie !== null &&
    delegatingLearnMoreCookie !== undefined
  ) {
    return delegatingLearnMoreCookie;
  }
  if (
    delegatingLearnMoreConfig !== null &&
    delegatingLearnMoreConfig !== undefined
  ) {
    return delegatingLearnMoreConfig;
  }
  return false;
};

export default useDelegatingLearnMore;
