import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { useDispatch, useSelector } from "react-redux";
import { getStepSelections } from "../../configurator/ConfigLoader";
import {
  useCurrentPackages,
  useReviewIncludedTreatmentsMapper,
  useReviewSteps,
  useStepAlias,
  useTransitionColors
} from "@hooks";
import { BrandComponent } from "../common/BrandComponent";
import { useEditStep } from "../../configurator/WorkflowUtils";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";

export const YourSelection = () => {
  const translation = useTranslation();
  const [currentSelectionClassName, setCurrentSelectionClassName] =
    useState("");

  const [alreadyIncluded, setalreadyIncluded] = useState(null);
  const loadTreatments = useReviewIncludedTreatmentsMapper();
  const currentPackages = useCurrentPackages();

  const onEdit = useEditStep();
  const dispatch = useDispatch();
  const yourSelectionsLabel = translation("yourSelections");
  const editLabel = translation("editButton");
  const stepTitle = (step: string) =>
    translation(`steps.${step}.confirmedTitle`);

  const currentStep = useSelector((state: any) => state.workflow.currentStep);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const showYourSelectionsModal = useSelector(
    (state: any) => state.modals?.showYourSelectionsModal
  );
  const isLoadingStep = useSelector((state: any) => state.workflow.loadingStep);
  const getAliasStep = useStepAlias();

  const steps = getStepSelections(getAliasStep, true, configContent);
  const thereIsTreatmentStep = steps.find((step) => step.key == "treatments");
  const thereIsTreatmentFamilyStep = steps.find(
    (step) => step.key == "treatmentsFamily"
  );
  const thereIsFamily =
    !configContent.treatmentFamily ||
    Object.keys(configContent.treatmentFamily).length == 0;

  const reviewSteps = useReviewSteps();

  const [selections, setSelection] = useState([]);
  const includedLabel = useTranslate("price.included");
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const [isDone, setDone] = useState(false);

  const { isNestedTransitionSwatchEnabled } = useTransitionColors(null);

  useEffect(() => {
    if (workflow.isLastStep() && loadTreatments && !isDone) {
      const { included, treatments } = loadTreatments(currentPackages);

      setalreadyIncluded({ included: included, treatments: treatments });

      setDone(true);
    }
  }, [currentPackages, loadTreatments, alreadyIncluded]);

  useEffect(() => {
    if (!workflow.isLastStep()) {
      setDone(false);
    }
  }, [workflow.currentStep]);

  useEffect(() => {
    if (reviewSteps && reviewSteps.length > 0) {
      let newSel = reviewSteps
        .filter(
          (step) =>
            !(
              step.key === "treatments" &&
              thereIsFamily &&
              thereIsTreatmentFamilyStep &&
              thereIsTreatmentStep
            )
        )
        .map((step) => {
          return {
            ...step,
            attributes: {
              ...step.attributes,
              skipped: !step.attributes?.skipped
                ? false
                : step.attributes.skipped,
            },
          };
        });
      let addOnsStep = newSel.find(
        (step) => step.key.toLowerCase() === "addons"
      );
      if (
        workflow.isLastStep() &&
        alreadyIncluded &&
        alreadyIncluded.included.length
      ) {
        if (addOnsStep) {
          let toPush = [];
          alreadyIncluded.treatments.map((x) => {
            toPush.push(translation(x.name));
          });
          alreadyIncluded.included.map((x) => {
            toPush.push(translation(x.name));
          });
          addOnsStep.attributes["skipped"] =
            addOnsStep.title == null || addOnsStep.title == undefined;
          addOnsStep.title = toPush;
          if (
            isNaN(addOnsStep.offerPrice) &&
            (addOnsStep.insPrice == null || addOnsStep.insPrice == undefined)
          ) {
            addOnsStep.offerPrice = 0;
            addOnsStep.insPrice = 0;
          }
          if (
            isNaN(addOnsStep.price) ||
            addOnsStep.price == null ||
            addOnsStep.price == undefined
          ) {
            addOnsStep.price = 0;
            addOnsStep.discount = 0;
          }
          //newSel.push(addOnsStep);
        } else {
          let toPush = [];
          alreadyIncluded.included.map((x) => {
            toPush.push(translation(x.name));
          });
          newSel.push({
            attributes: {
              skipped: true,
            },
            key: "addOns",
            offerPrice: 0,
            insPrice: 0,
            price: 0,
            discount: 0,
            originalStep: "AddOns",
            strikePrice: undefined,
            title: toPush,
            hideEdit: true,
          });
        }
      }
      if (
        workflow.isLastStep() &&
        alreadyIncluded?.included.length == 0 &&
        alreadyIncluded?.treatments.length == 0 &&
        addOnsStep
      ) {
        newSel.pop();
        addOnsStep.title = [translation("noTreatment")];
        addOnsStep.attributes["skipped"] = true;
        newSel.push(addOnsStep);
      }

      if (
        workflow.isLastStep() &&
        newSel.find((sel: any) => sel?.key?.toLowerCase() === "protectionplan")
      ) {
        let protectionSel = newSel.find(
          (sel: any) => sel?.key?.toLowerCase() === "protectionplan"
        );
        newSel = newSel.filter(
          (sel: any) => sel?.key?.toLowerCase() !== "protectionplan"
        );
        newSel.push(protectionSel);
      }
      setSelection(newSel);
    }
  }, [reviewSteps]);

  const currentSelection =
    selections && selections.length ? selections[selections.length - 1] : [];

  useEffect(() => { });

  useEffect(() => {
    !isLoadingStep
      ? setCurrentSelectionClassName("animated")
      : setCurrentSelectionClassName("");
  }, [isLoadingStep]);

  const getClassesIncludedLabel = (insEnab: boolean, stepName: string) => {
    if (!insEnab) {
      return "";
    }
    if (insEnab && stepName !== "addOns") {
      return " hideIncluded";
    } else {
      return " includedInsurance";
    }
  };

  const handleEditStep = (_stepName: string, currentStep: string) => () => {
    let stepName = _stepName
    if (isNestedTransitionSwatchEnabled) {
      if (stepName === "TransitionColor") {
        if (selections.find((sel: any) => sel?.key === "treatments")) {
          stepName = "Treatments";
        } else {
          stepName = "TreatmentsFamily";
        }
      }
    }
    try {
      //@ts-ignore
      window.tealium_data2track.push({
        id: "Click",
        data_element_id: "X_X_LensPanel_" + currentStep + "-Back", // data-element-id requested
        data_description: stepName, // data-description requested
      });
    } catch (error) {
      console.log("error with tealium.push: " + error);
    }
    onEdit({ id: stepName });
    handleModal();
  };

  const handleModal = () => {
    if (showYourSelectionsModal) {
      dispatch(modalsActions.setShowYourSelectionsModal(false));
    } else {
      dispatch(modalsActions.setShowYourSelectionsModal(true));
    }
  };

  const isCurrentTitleVisible =
    !showYourSelectionsModal &&
    !currentSelection?.attributes?.skipped &&
    currentSelection?.key !== "advancedPrescription" &&
    currentSelection?.key !== currentStep?.key;

  return currentStep &&
    currentStep.key !== "Type" &&
    currentStep.key !== "Review" ? (
    <div className={"YourSelection"}>
      <div
        className={`YourSelection__container ${showYourSelectionsModal ? "isOpen" : ""
          }`}
      >
        <div
          className={`YourSelection__container__title_wrapper ${showYourSelectionsModal ? "isOpen" : "isClose"
            }`}
          onClick={showYourSelectionsModal ? null : handleModal}
        >
          <div className="YourSelection__container__title_wrapper__listIcon" />
          <div className="YourSelection__container__title_wrapper__title">
            {yourSelectionsLabel}
          </div>
          {showYourSelectionsModal && (
            <a
              className="YourSelection__container__title_wrapper__close"
              onClick={handleModal}
              data-analytics_available_call="0"
            />
          )}
        </div>
        {showYourSelectionsModal && (
          <div className="YourSelection__container__content_wrapper">
            {selections.map((step) => (
              <>
                <div className="YourSelection__container__content_wrapper__separator" />

                <div className="YourSelection__container__content_wrapper__title">
                  <div className="YourSelection__container__content_wrapper__title__step">
                    {stepTitle(step.key)}
                  </div>
                  {(!step.attributes.skipped ||
                    (step.attributes.skipped &&
                      step.key.toLowerCase() !== "addons")) && (
                      <>
                        <div
                          data-element-id={
                            "X_X_LensPanel_" + currentStep.key + "-Back"
                          }
                          data-description={step.originalStep}
                          className="YourSelection__container__content_wrapper__title__edit"
                          onClick={handleEditStep(
                            step.originalStep,
                            currentStep.key
                          )}
                        >
                          {editLabel}
                        </div>
                        {!!step.price && (
                          <div className="YourSelection__container__content_wrapper__title__price_container">
                            <BrandComponent
                              componentName="StepPrice"
                              parameter={{
                                price: step.price,
                                discount: step.discount,
                                insPrice: step.insPrice,
                                ignorePriceLabels: true,
                              }}
                            />
                          </div>
                        )}
                        {step.price === 0 && step.discount === 0 && (
                          <div
                            className={
                              "YourSelection__container__content_wrapper__title__price_container" +
                              getClassesIncludedLabel(insuranceEnabled, step.key)
                            }
                          >
                            {includedLabel}
                          </div>
                        )}
                      </>
                    )}
                </div>
                <div className="YourSelection__container__content_wrapper__selection">
                  {step.key.toLowerCase() === "addons" &&
                    Array.isArray(step?.title)
                    ? step.title?.map((t) => (
                      <div className="YourSelection__addons" key={t}>
                        {t}
                      </div>
                    ))
                    : step.title}
                </div>
              </>
            ))}
          </div>
        )}
        {isCurrentTitleVisible && (
          <div
            className={`YourSelection__container__current_title ${currentSelectionClassName}`}
          >
            {Array.isArray(currentSelection?.title)
              ? currentSelection.title[
              alreadyIncluded.treatments &&
                alreadyIncluded.treatments.length > 0
                ? 0
                : currentSelection.title.length - 1
              ]
              : currentSelection?.title}
          </div>
        )}
      </div>
      {showYourSelectionsModal && <div className={"YourSelection__overlay"} />}
    </div>
  ) : null;
};
